import { css } from '@linaria/core';

const decorativeBackgroundImage = css`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 0;
  img {
    object-fit: cover;
    width: 100%;
    transition: all 0.2s;
  }
  @media screen and (min-width: 786px) {
    img {
      width: 100%;
    }
  }
`;

const container = css`
  position: relative;
  background-color: transparent;
  z-index: 2;
  height: fit-content;
`;

const header = css`
  font-size: 1.3rem;
  font-weight: 600;
`;

const layout = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease-in;
  margin: 0px auto;
  max-width: 1440px;
  width: 100%;
  box-sizing: border-box;
  @media screen and (min-width: 768px) {
    top: 0px;
  }
`;

export default {
  container,
  header,
  layout,
  decorativeBackgroundImage,
};
