import { Input, Select } from 'antd';
import React, { useState } from 'react';
import * as Styles from './styles';

const { Option } = Select;

const SearchFilter = ({ onSearch, onSort, sortValue, minSearchLength = 2 }) => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);

    // Only trigger search if empty or meets minimum length
    if (!value || value.length >= minSearchLength) {
      onSearch(value);
    }
  };

  const handleSort = (value) => {
    onSort(value);
  };

  return (
    <div className={Styles.searchFilterContainer}>
      <div className={Styles.searchInputWrapper}>
        <Input.Search placeholder="Search" value={searchValue} onChange={handleSearch} className={Styles.searchInput} />
      </div>
      <Select defaultValue="newest" value={sortValue} className={Styles.selectFilter} onChange={handleSort}>
        <Option value="newest">Newest to Oldest</Option>
        <Option value="oldest">Oldest to Newest</Option>
        <Option value="name_asc">A to Z</Option>
        <Option value="name_desc">Z to A</Option>
      </Select>
    </div>
  );
};

export default SearchFilter;
