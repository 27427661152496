import { Col, Row } from 'antd';
import React from 'react';
import ConditionalRender from 'src/components/conditionalRender';
import ClusterDetails from './ClusterDetails';
import Style from './clusterPersonas.style';
import useClusterPersonas from './useClusterPersonas';

const ClusterPersonas = ({ data, clusterData }) => {
  const clusterType = clusterData?.clusters_type;
  const { onTabSwitch, tab } = useClusterPersonas();
  return (
    <div className={Style.container}>
      <Row gutter={[3, 3]} style={{ marginBottom: '1rem' }}>
        <ConditionalRender shouldRender={clusterType !== 'CRM_PRIORITIZE_REACH'}>
          <Col>
            <div
              className={`${Style.tabs} ${tab === 'demographic' && Style.activeTab}`}
              onClick={() => onTabSwitch('demographic')}
            >
              Demographic
            </div>
          </Col>
        </ConditionalRender>
        <ConditionalRender shouldRender={!clusterType.toUpperCase().includes('Acquisition'.toUpperCase())}>
          <Col>
            <div
              className={`${Style.tabs} ${tab === 'behavioural' && Style.activeTab}`}
              onClick={() => onTabSwitch('behavioural')}
            >
              Behavioural
            </div>
          </Col>
        </ConditionalRender>
        <ConditionalRender shouldRender={clusterType !== 'CRM_PRIORITIZE_REACH'}>
          <Col>
            <div
              className={`${Style.tabs} ${tab === 'activation' && Style.activeTab}`}
              onClick={() => onTabSwitch('activation')}
            >
              Activation
            </div>
          </Col>
          <Col>
            <div className={`${Style.tabs} ${tab === 'brand' && Style.activeTab}`} onClick={() => onTabSwitch('brand')}>
              Brand Engagement
            </div>
          </Col>
        </ConditionalRender>
      </Row>
      <ClusterDetails data={data} tab={tab} clusterData={clusterData} />
    </div>
  );
};

export default ClusterPersonas;
