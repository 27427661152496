import React from 'react';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Collapse, Input, List } from 'antd';
import OperatorValueSelector from 'src/modules/audienceTool/audienceManager/components/createAudience/ruleBased/BrowseFeatures/OperatorValueSelector';
import queryBuilderService from 'src/modules/audienceTool/audienceManager/services/queryBuilderService';
import useBrowseFeaturesHook from './useBrowseFeaturesHook';
import './clusterbrowseFeatureDrawer.style.less';
import { ACQUISITION_CRM_LOOKALIKES } from '../../../constants/constants';

const { Panel } = Collapse;

const BrowseFeatureDrawerComponent = ({
  fields,
  behaviourDirectory,
  browsedSelectedItems = [],
  onUpdateBrowsedItems,
  clusterConfig,
  clusterType,
  rule,
  targetRule,
  setTargetRule,
}) => {
  const {
    searchQuery,
    folderStructure,
    selectedItems,
    modalVisible,
    selectedField,
    handleSearchChange,
    handleCheckboxChange,
    handleRemoveItem,
    handleEditItem,
    updateSelectedItem,
    closeModal,
  } = useBrowseFeaturesHook({
    fields,
    behaviourDirectory,
    browsedSelectedItems,
    clusterConfig,
    clusterType,
    rule,
    targetRule,
    setTargetRule,
  });

  const renderTree = (nodes, path = '') =>
    nodes.map((node) => {
      const currentPath = path ? `${path} > ${node.name}` : node.name;
      const isLeaf = !node.children || node.children.length === 0;

      return (
        <div key={currentPath} className="tree-node">
          {isLeaf ? (
            <Checkbox
              checked={selectedItems.some((item) => item.feature_name === node.feature_name)}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => handleCheckboxChange(e.target.checked, node)}
              disabled={
                (!selectedItems.some((item) => item.feature_name === node.feature_name) &&
                  rule === 'cluster_target_rules' &&
                  selectedItems.length === 1) ||
                selectedItems.length >= 25
              }
            >
              {node.name}
            </Checkbox>
          ) : (
            <Collapse ghost>
              <Panel header={node.name} key={currentPath}>
                {renderTree(node.children, currentPath)}
              </Panel>
            </Collapse>
          )}
        </div>
      );
    });

  return (
    <div className="browse-feature-drawer">
      <Input
        className="dark-theme-input"
        placeholder="Search..."
        prefix={<SearchOutlined />}
        value={searchQuery}
        onChange={handleSearchChange}
        style={{ marginBottom: '16px' }}
      />

      <div className="drawer-content">
        <div className="tree-container">
          {folderStructure.length > 0 ? renderTree(folderStructure) : <p>No features found.</p>}
        </div>

        <div className="selected-items">
          <h3>Selected Items</h3>
          <List
            dataSource={selectedItems}
            renderItem={(item) => (
              <List.Item
                key={item.feature_name}
                actions={[
                  rule === 'cluster_target_rules' && item.source !== 'E1' && (
                    <Button key="edit" type="text" icon={<EditOutlined />} onClick={() => handleEditItem(item)} />
                  ),
                  <Button key="delete" type="text" icon={<DeleteOutlined />} onClick={() => handleRemoveItem(item)} />,
                ].filter(Boolean)}
              >
                {item.value_description || item.name}{' '}
                {rule === 'cluster_target_rules' && targetRule && targetRule !== null
                  ? ' ' +
                    targetRule.operator +
                    ' ' +
                    (Array.isArray(targetRule.value)
                      ? targetRule.value.map((v) => v.value_description).join(', ')
                      : targetRule.value?.value_description)
                  : null}
              </List.Item>
            )}
            locale={{ emptyText: 'No items selected.' }}
          />
        </div>
      </div>

      {selectedField && (
        <OperatorValueSelector
          key={selectedField.feature_name + String(modalVisible)}
          visible={modalVisible}
          onClose={closeModal}
          onConfirm={(operator, value) => updateSelectedItem(selectedField, operator, value)}
          field={selectedField}
          operators={queryBuilderService.getOperatorsByValueEditorType(
            selectedField.valueEditorType || 'text',
            selectedField.feature_name
          )}
          initialOperator={targetRule && targetRule !== null ? targetRule.operator : null}
          initialValue={
            targetRule && targetRule !== null
              ? Array.isArray(targetRule.value)
                ? targetRule.value.map((v) => v.value)
                : targetRule.value?.value
              : null
          }
          showClear={rule === 'cluster_target_rules' && clusterType !== ACQUISITION_CRM_LOOKALIKES}
        />
      )}

      <div className="browse_drawer-footer">
        <Button type="primary" onClick={() => onUpdateBrowsedItems(selectedItems)}>
          Apply
        </Button>
      </div>
    </div>
  );
};

export default BrowseFeatureDrawerComponent;
