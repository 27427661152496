import { useEffect, useState } from 'react';
import { ClusterToolApiServices } from '../../services/ClusterToolApiServices';

const useCorrelationChart = ({ getFieldValue, clusterConfigId }) => {
  // State variables
  const [loading, setLoading] = useState(true);
  const [chartOption, setChartOption] = useState(null);

  const getData = async () => {
    try {
      setLoading(true);
      const salientFeature = getFieldValue('salient_features');
      const userSelected = [];
      let userSelectedMapping = {};

      let reverseUserSelectedMapping = [];

      salientFeature.forEach((item) => {
        userSelected.push(item.id);
        userSelectedMapping = {
          ...userSelectedMapping,
          [item.id]: item.feature_readable_name,
        };
      });

      const payload = {
        cluster_config_id: clusterConfigId,
        salient_feature_ids: userSelected,
      };

      const res = await ClusterToolApiServices.clusterFeatureCorrelation(payload);
      if (res.data.length > 0) {
        if (res.data[0].feature1_name === 'target') {
          userSelected.push(res.data[0].feature1_id);
          userSelectedMapping = {
            ...userSelectedMapping,
            [res.data[0].feature1_id]: 'Target',
          };
        }
      }

      for (let i = userSelected.length - 1; i >= 0; i--) {
        reverseUserSelectedMapping.push(userSelected[i]);
      }
      let featuresObj = {};
      res.data.forEach((item) => {
        let feature1 = item.feature1_id;
        let feature2 = item?.feature2_id;

        featuresObj = {
          ...featuresObj,
          [feature1]: {
            ...featuresObj[feature1],
            [feature2]: item.correlation_value,
          },
        };
      });
      const corelationMatrix = [];
      userSelected.forEach((item) => {
        let featuresArray = featuresObj[item];
        let row = [];
        for (let i = userSelected.length - 1; i >= 0; i--) {
          let feature = userSelected[i];
          if (featuresArray[feature]) {
            row.push(+featuresArray[feature].toFixed(2));
          } else {
            row.push('');
          }
        }
        corelationMatrix.push(row);
      });

      const yAxis = userSelected.map((item) => item);
      yAxis.reverse();

      const option = {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return `<b>Feature 1: </b>${userSelectedMapping[userSelected[params.data[0]]]}<br/><b>Feature 2: </b>${
              userSelectedMapping[reverseUserSelectedMapping[params.data[1]]]
            }<br/><b>Correlation Value: </b>${params.data[2]}`;
          },
        },
        grid: {
          height: '50%',
          top: '10%',
        },
        xAxis: {
          type: 'category',
          data: userSelected.map((item) => userSelectedMapping[item]),
          splitArea: {
            show: true,
          },
          axisLabel: {
            interval: 0,
            rotate: 40,
            hideOverlap: true, // hide this to show the overlap value
            formatter: (value) => {
              const len = value.length;
              const ellipsis = len > 17 ? '...' : '';
              if (len > 17) {
                return value.slice(0, 4) + ellipsis + value.slice(-14);
              }
              return value;
            },
            textStyle: {
              color: '#ffffff',
            },
          },
        },
        yAxis: {
          data: yAxis.map((item) => userSelectedMapping[item]),
          type: 'category',
          splitArea: {
            show: false,
          },
          axisLabel: {
            interval: 0,
            formatter: (value) => {
              const len = value.length;
              const ellipsis = len > 12 ? '...' : '';
              if (len > 12) {
                return value.slice(0, 4) + ellipsis + value.slice(-9);
              }
              return value;
            },
            textStyle: {
              color: '#ffffff',
            },
          },
        },
        visualMap: {
          min: -1,
          max: 1,
          calculable: true,
          color: 'white',
          orient: 'vertical',
          left: '90%',
          bottom: '40%',
          formatter: function (value) {
            return value.toFixed(2);
          },
          inRange: {
            color: ['#F57980', '#F3ECEC', '#63B13B'],
          },
          textStyle: {
            color: '#ffffff',
          },
          itemWidth: 30,
          itemHeight: 300,
        },
        series: [
          {
            name: 'Correlation',
            type: 'heatmap',
            data: corelationMatrix.flatMap((row, i) => row.map((value, j) => [i, j, value])),
            label: {
              show: true,
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(0, 0.5)',
              },
            },
          },
        ],
      };

      setChartOption(option);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching correlation data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return {
    loading,
    chartOption,
  };
};

export default useCorrelationChart;
