import ApiUrls from 'src/constants/apiUrls';
import axios from 'src/http/index';

export default class LabelerApiService {
  // Get all Database Connections
  static async getDatabaseConnections(clientSpaceId) {
    const res = await axios.get(ApiUrls.GET_SQL_CONNECTIONS(clientSpaceId));
    return res.data;
  }

  // Get Google Sheet Schema
  static async getGoogleSheetSchema(payload) {
    const res = await axios.post(ApiUrls.GET_GOOGLE_SHEET_SCHEMA, payload);
    return res.data;
  }

  // Get all Labeling Tasks
  static async getLabelingTasks(clientSpaceId, searchTerm = '', sortBy = 'newest', page = 1, pageSize = 10) {
    const params = new URLSearchParams();
    if (searchTerm) params.append('search', searchTerm);
    if (sortBy) params.append('sort', sortBy);

    // Add pagination parameters
    params.append('page', page);
    params.append('page_size', pageSize);

    const res = await axios.get(ApiUrls.LABELING_TASKS(clientSpaceId), {
      params,
    });
    return res.data;
  }

  // Create Labeling Task
  static async createLabelingTask(clientSpaceId, taskData) {
    const res = await axios.post(ApiUrls.LABELING_TASKS(clientSpaceId), taskData);
    return res.data;
  }

  // Get Labeling Task Details
  static async getLabelingTaskDetails(clientSpaceId, taskId) {
    const baseUrl = ApiUrls.LABELING_TASKS(clientSpaceId);
    const url = `${baseUrl}${taskId}/`;
    const res = await axios.get(url);
    return res.data;
  }

  // Get Labeling Task Preview
  static async getLabelingTaskPreview(clientSpaceId, taskData) {
    const baseUrl = ApiUrls.LABELING_TASKS(clientSpaceId);
    const url = `${baseUrl}preview/`;
    const res = await axios.post(url, taskData);
    return res.data;
  }

  // Trigger Labeling Task
  static async triggerLabelingTask(clientSpaceId, taskId) {
    const baseUrl = ApiUrls.LABELING_TASKS(clientSpaceId);
    const url = `${baseUrl}${taskId}/trigger/`;
    const res = await axios.post(url);
    return res.data;
  }

  // Trigger Labeling Task
  static async cancelLabelingTask(clientSpaceId, taskId) {
    const baseUrl = ApiUrls.LABELING_TASKS(clientSpaceId);
    const url = `${baseUrl}${taskId}/run/cancel/`;
    const res = await axios.post(url);
    return res.data;
  }
}
