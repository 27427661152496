import { Button, Dropdown, Modal } from 'antd';
import React, { useContext, useState } from 'react';
import Assets from 'src/assets';
import CaretIconGenerator from 'src/components/CaretIconGenerator';
import ConditionalRender from 'src/components/conditionalRender';
import LoadingSpinner from 'src/components/loadingSpinner';
import AppContext from 'src/context/appContext';
import { PageLayoutContext } from 'src/context/pageLayoutContext';
import AssistantAppIcon from 'src/modules/assistantAppIcon/assistantAppIcon';
import Conversation from 'src/modules/ConversationContainer/conversations/components/Conversation';
import ConversationStarters from 'src/modules/ConversationContainer/conversations/components/ConversationStarters';
import StreamingResponse from 'src/modules/ConversationContainer/conversations/components/StreamingResponse';
import FeedbackForm from 'src/modules/ConversationContainer/FeedbackForm';
import FileUploadForm from 'src/modules/ConversationContainer/FileUploadForm';
import InputContainer from 'src/modules/ConversationContainer/inputContainer/InputContainer';
import SideNavBar from 'src/modules/ConversationContainer/sideNavBar/components/sideNavBar/SideNavBar';
import Footer from 'src/modules/footer/Footer';
import QuestionTabs from 'src/modules/socialInsights/components/questionAnswerSideBar/components/QuestionCard';
import useAiAppHook from '../hooks/useAiAppHook';
import Style from './aiApp.style';
import './aiAppLlmModal.less';
import TaskCreateEditForm from './taskCreateEditForm/TaskCreateEditForm';
import { DownOutlined } from '@ant-design/icons';

export default function AiApp({
  history,
  singleChatSessions,
  multipleChatSessions,
  starterConversation,
  data,
  sessionId,
  sessionToggle,
  setSessionToggle,
  addNewSession,
  sessionLoading,
  scrollSingleSesion,
  renameSession,
  mode,
  clientId,
  task,
  taskLoading,
  taskMode,
  updateTask,
  scrollTaskSesion,
  deleteSingleSession,
}) {
  const appContext = React.useContext(AppContext.Context);
  const { isNavOpen, setIsNavOpen, inDesktop } = useContext(PageLayoutContext);
  const [socialInsightsExpanded, setSocialInsightExpanded] = useState(true);
  const scrollRef = React.useRef([]);
  const {
    session,
    getResponse,
    userMessage,
    generatingResponse,
    setUserMessage,
    feedbackFormModal,
    feedbackData,
    closeFeedBackmodal,
    submitFeedback,
    feedbackReaction,
    feedbackLoading,
    deleteChatHIstory,
    streamingData,
    formUploadModal,
    setFormUploadModal,
    uploadFile,
    fileList,
    deleteFile,
    filePreview,
    fileDelete,
    llmOutput,
    terminateChatProcessing,
    streamingChatID,
    terminatingStream,
    chatWindowLoading,
    streamingUserData,
    cancellationStreamingRef,
    setStreamingChatID,
    setGeneratingResponse,
    createSession,
    createNewSessionRef,
    openFeedBackFormModal,
    cancelApiCall,
    createSesionWithFile,
    loadNextPage,
    nextPageLoading,
    setModelName,
    selectedValue,
    setSelectedValue,
    cascaderOptions,
    taskCreateModal,
    setTaskCreateModal,
    chatTaskData,
    isOpen,
    setIsOpen,
    capitalizeFirstLetter,
  } = useAiAppHook({
    history,
    data,
    sessionId,
    appContext,
    addNewSession,
    mode,
    clientId,
    scrollRef,
    taskMode,
    deleteSingleSession,
    updateTask,
  });

  const renderNoMessageState = () => {
    return (
      <div className={Style.noMessageContainer}>
        <div className={Style.helpContainer}>
          <div className={Style.helpImage}>
            <AssistantAppIcon data={data} size={64} showBackground={false} />
          </div>
          <div className={Style.helpImage}>How can I help you today?</div>
        </div>
        <div className={Style.conversationStarterContainer}>
          <ConversationStarters
            starterConversation={starterConversation}
            getResponse={getResponse}
            sessionId={sessionId}
            createSession={createSession}
          />
        </div>
      </div>
    );
  };

  // Convert cascaderOptions to a Menu structure
  const menuItems = cascaderOptions.map((parent) => ({
    key: parent.value,
    label: parent.label,
    children: parent.children
      .filter(
        // temporary, allow only below prod assistant id access to gemini 2.5 since it is in preview.
        (child) => data.id === '13f55833-7e4b-46f3-8c9a-79fa891881ab' || child.value !== 'gemini-2.5-pro-exp-03-25'
      )
      .map((child, index, arr) => ({
        key: child.value,
        label: child.label,
        style: {
          borderRadius: '8px',
          marginBottom: index !== arr.length - 1 ? '8px' : '0px',
        },
        onClick: () => {
          setModelName(child.label);
          setSelectedValue([parent.value, child.value]);
          setIsOpen(false);
        },
      })),
    popupClassName: Style.customDropdownOverlay,
    expandIcon: null,
  }));

  return (
    <div className={Style.layoutContainer}>
      <SideNavBar
        data={data}
        deleteChatHIstory={deleteChatHIstory}
        session={session}
        fileList={fileList}
        deleteFile={deleteFile}
        fileDelete={fileDelete}
        sessionToggle={sessionToggle}
        singleChatSessions={singleChatSessions}
        multipleChatSessions={multipleChatSessions}
        setSessionToggle={setSessionToggle}
        history={history}
        sessionId={sessionId}
        cancellationStreamingRef={cancellationStreamingRef}
        generatingResponse={generatingResponse}
        setStreamingChatID={setStreamingChatID}
        setGeneratingResponse={setGeneratingResponse}
        setUserMessage={setUserMessage}
        createNewSessionRef={createNewSessionRef}
        sessionLoading={sessionLoading}
        scrollSingleSesion={scrollSingleSesion}
        renameSession={renameSession}
        cancelApiCall={cancelApiCall}
        mode={mode}
        clientId={clientId}
        setTaskCreateModal={setTaskCreateModal}
        task={task}
        taskLoading={taskLoading}
        updateTask={updateTask}
        scrollTaskSesion={scrollTaskSesion}
      />
      <div
        className={Style.mainContainer}
        style={{ transform: !inDesktop && isNavOpen ? 'translateX(320px)' : 'translateX(0)' }}
      >
        <ConditionalRender shouldRender={chatWindowLoading}>
          <LoadingSpinner className={Style.sessionLoader} centered={true} />
        </ConditionalRender>
        <ConditionalRender shouldRender={!chatWindowLoading}>
          <ConditionalRender shouldRender={data.version != 'v1'}>
            <div className="chatToolContainer">
              <Dropdown
                menu={{ items: menuItems }}
                trigger={['click']}
                open={isOpen}
                onOpenChange={setIsOpen}
                overlayClassName={Style.customDropdownOverlay}
              >
                <Button style={{ width: 'auto', minWidth: '150px', maxWidth: '100%' }}>
                  {selectedValue[1] ? capitalizeFirstLetter(selectedValue[1]) : 'Select Model'}
                  <DownOutlined style={{ marginLeft: 8 }} />
                </Button>
              </Dropdown>
            </div>
          </ConditionalRender>
          <ConditionalRender shouldRender={mode === 'socialInsights'}>
            <div
              className={Style.questionTabsContainer + `${socialInsightsExpanded ? ' expanded' : ''}`}
              aria-expanded={socialInsightsExpanded}
            >
              <QuestionTabs />
              <button
                className={Style.questionTabExpandBtn + `${socialInsightsExpanded ? ' expanded' : ''}`}
                onClick={() => {
                  setSocialInsightExpanded(!socialInsightsExpanded);
                }}
              >
                <CaretIconGenerator size={24} color="currentColor" />
              </button>
            </div>
          </ConditionalRender>
          <div
            className={Style.chatContainer}
            onClick={() => {
              if (window.innerWidth < 700) {
                setIsNavOpen(false);
              }
            }}
          >
            <div
              className={
                Style.messageContainer + `${mode === 'socialInsights' ? ` ${Style.socialInsightsChatContainer}` : ''}`
              }
              id={'masterChat'}
              onScroll={(event) => {
                if (event.target.scrollTop === 0) {
                  loadNextPage();
                }
              }}
            >
              <ConditionalRender shouldRender={!session.length && !generatingResponse}>
                {renderNoMessageState()}
              </ConditionalRender>
              <ConditionalRender shouldRender={nextPageLoading}>
                <div className={Style.sessionLoadingLoader} style={{ marginBottom: '0.5rem' }}>
                  <img alt="ai loader img" height={30} width={30} src={Assets.getImage('AiLoader')} />
                </div>
              </ConditionalRender>
              {session?.map((item, idx) => {
                return (
                  <div ref={(el) => (scrollRef.current[item?.id] = el)} key={`session-${item?.id}`}>
                    <Conversation
                      key={`conversation-${idx}`}
                      data={data}
                      item={item}
                      idx={idx}
                      task={task}
                      latestConversation={idx === session.length - 1}
                      feedbackReaction={feedbackReaction}
                      openFeedBackFormModal={openFeedBackFormModal}
                      feedbackLoading={feedbackLoading}
                      setTaskCreateModal={setTaskCreateModal}
                      chatTaskData={chatTaskData}
                    />
                  </div>
                );
              })}
              <ConditionalRender shouldRender={generatingResponse && !!userMessage}>
                <StreamingResponse
                  data={data}
                  userMessage={userMessage}
                  streamingData={streamingData}
                  llmOutput={llmOutput}
                  streamingUserData={streamingUserData}
                />
              </ConditionalRender>
            </div>
            <InputContainer
              setUserMessage={setUserMessage}
              getResponse={getResponse}
              generatingResponse={generatingResponse}
              userMessage={userMessage}
              data={data}
              setFormUploadModal={setFormUploadModal}
              filePreview={filePreview}
              terminateChatProcessing={terminateChatProcessing}
              streamingChatID={streamingChatID}
              terminatingStream={terminatingStream}
              sessionId={sessionId}
              createSession={createSession}
            />
            <Footer transparent={true} relativePosition={true} />
          </div>
        </ConditionalRender>
        <Modal maskClosable={true} destroyOnClose open={feedbackFormModal} onCancel={closeFeedBackmodal} footer={null}>
          <FeedbackForm feedbackData={feedbackData} submitFeedback={submitFeedback} data={data} />
        </Modal>
        <Modal
          maskClosable={true}
          destroyOnClose
          open={formUploadModal}
          onCancel={() => setFormUploadModal(false)}
          footer={null}
        >
          <FileUploadForm
            uploadFile={uploadFile}
            createSesionWithFile={createSesionWithFile}
            sessionId={sessionId}
            fileList={fileList}
            deleteFile={deleteFile}
          />
        </Modal>
        <Modal
          className={Style.taskCreateContainer}
          maskClosable={false}
          title="Task"
          destroyOnClose
          open={taskCreateModal.visible}
          footer={null}
          width="60%"
          onCancel={() => {
            setTaskCreateModal({
              visible: false,
              taskId: null,
            });
          }}
        >
          <TaskCreateEditForm
            data={data}
            taskCreateModal={taskCreateModal}
            updateTask={updateTask}
            setTaskCreateModal={setTaskCreateModal}
          />
        </Modal>
      </div>
    </div>
  );
}
