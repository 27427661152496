import { Modal } from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { RULE_BASED } from '../../../constants/constants';
import {
  BEHAVIOUR,
  COMBINATOR_AND,
  defaultBehaviourValues,
  DEMOGRAPHIC,
  FIRST_PARTY,
  initialQuery,
} from '../../../constants/queryBuilderConstants';
import createAudienceService from '../../../services/createAudienceService';

const useDataSourceTabLayout = ({
  clientId,
  selectedAudienceData,
  audienceDescription,
  setSelectedAudienceData,
  getAudienceSize,
}) => {
  const [loading, setLoading] = useState(true);
  const [selectedTable, setSelectedTable] = useState('');
  const [fields, setFields] = useState({ demographic_features: [], behaviour_features: [], first_party_features: [] });
  const [rulesRecommendations, setRulesRecommendations] = useState({});
  const [ruleRecommendationLoading, setRuleRecommendationLoading] = useState(false);
  const [searchResults, setSearchResults] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);
  const [query, setQuery] = useState(initialQuery);
  const [conditions, setConditions] = useState({ combinator: COMBINATOR_AND, rules: [] });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [aiPrompt, setAiPrompt] = useState(audienceDescription);
  const [recommendationsApplied, setRecommendationsApplied] = useState(false);
  const [withReflection, setWithReflection] = useState(false);
  const [availableRuleTypes, setAvailableRuleTypes] = useState([]);

  const transformBehaviourToFields = (fields, behaviourFields = []) => {
    const existingFieldNames = new Set(fields.behaviour_features.map((field) => field.name));

    const behaviourTransformedFields = behaviourFields
      .filter((behaviour) => !existingFieldNames.has(behaviour.behaviour_name))
      .map((behaviour) => ({
        name: behaviour.behaviour_name,
        label: behaviour.behaviour_name,
        category: BEHAVIOUR,
        source: 'TransUnion',
        valueEditorType: 'select',
        values: defaultBehaviourValues,
      }));

    return {
      ...fields,
      behaviour_features: [...fields.behaviour_features, ...behaviourTransformedFields],
    };
  };

  const addSearchBehaviourFields = (fields, behaviourFeatureNames = []) => {
    const existingFieldNames = new Set(fields.behaviour_features.map((field) => field.name));
    const behaviourTransformedFields = behaviourFeatureNames
      .filter((behaviour) => !existingFieldNames.has(behaviour))
      .map((behaviour) => ({
        name: behaviour,
        label: behaviour,
        category: BEHAVIOUR,
        source: 'TransUnion',
        valueEditorType: 'select',
        values: defaultBehaviourValues,
      }));

    return {
      ...fields,
      behaviour_features: [...fields.behaviour_features, ...behaviourTransformedFields],
    };
  };

  const transformRecommendedData = (recommendedData) => {
    const transformRule = (rule) => {
      if (rule.field) {
        return {
          ...rule,
        };
      } else if (rule.behaviour_name) {
        return {
          ...rule,
          field: rule.behaviour_name,
          field_readable_name: rule.behaviour_name,
          operator: 'Is',
          value:
            rule.operator === 'NOT'
              ? { value: '0', value_description: 'False' }
              : { value: '1', value_description: 'True' },
        };
      } else if (rule.rules) {
        return {
          ...rule,
          rules: rule.rules.map(transformRule),
        };
      }
      return rule;
    };

    return {
      ...recommendedData,
      rules: recommendedData.rules.map(transformRule),
    };
  };

  const getData = async () => {
    try {
      setLoading(true);
      const metaDataRes = await createAudienceService.getAudienceFeatureMetadata(clientId, true, false);
      let demographicColumns = metaDataRes.data.TRANSUNION;
      let firstPartyColumns = metaDataRes.data.FIRST_PARTY;
      const demographicFields = createAudienceService.transformColumnsToFields(demographicColumns, DEMOGRAPHIC);
      const firstPartyFields = createAudienceService.transformColumnsToFields(firstPartyColumns, FIRST_PARTY);
      if (firstPartyFields.length > 0) {
        setAvailableRuleTypes([DEMOGRAPHIC, BEHAVIOUR, FIRST_PARTY]);
      } else {
        setAvailableRuleTypes([DEMOGRAPHIC, BEHAVIOUR]);
      }
      const isRulesEmpty =
        !selectedAudienceData.rules ||
        Object.keys(selectedAudienceData.rules).length === 0 ||
        selectedAudienceData.rules.rules.length === 0;
      if (isRulesEmpty) {
        setFields({
          demographic_features: demographicFields,
          behaviour_features: [],
          first_party_features: firstPartyFields,
        });
      } else {
        const extractBehaviourFields = (rules) => {
          return rules.flatMap((rule) => {
            if (rule.rules) {
              return extractBehaviourFields(rule.rules);
            }
            return rule.behaviour_name ? [{ behaviour_name: rule.behaviour_name, operator: rule.operator }] : [];
          });
        };

        const behaviourFieldsFromRules = extractBehaviourFields(selectedAudienceData.rules.rules);

        const updatedFields = transformBehaviourToFields({
          demographic_features: demographicFields,
          behaviour_features: behaviourFieldsFromRules,
          first_party_features: firstPartyFields,
        });
        setFields(updatedFields);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onTableChange = (val) => {
    setLoading(true);
    setSelectedTable(val);
    setQuery(initialQuery);
    setLoading(false);
  };

  const getAiRecommendedRules = async (aiPrompt) => {
    try {
      setRuleRecommendationLoading(true);
      const ruleRecommendation = await createAudienceService.getAudienceRecommendations(
        clientId,
        aiPrompt,
        RULE_BASED,
        withReflection,
        availableRuleTypes
      );

      const behaviourFields = ruleRecommendation.data.rules.flatMap((rule) =>
        (rule.rules || [rule])
          .filter((subRule) => subRule.behaviour_name)
          .map((subRule) => ({
            behaviour_name: subRule.behaviour_name,
            operator: subRule.operator,
          }))
      );

      const updatedFields = transformBehaviourToFields(fields, behaviourFields);
      setFields(updatedFields);
      const updatedRecommendations = transformRecommendedData(ruleRecommendation.data);
      setRulesRecommendations(updatedRecommendations);
      setRuleRecommendationLoading(false);
    } catch (e) {
      console.error(e);
      setRuleRecommendationLoading(false);
    }
  };

  const addFeaturesToFields = (selectedFields) => {
    if (selectedFields.length > 0) {
      const newUpdatedFields = addSearchBehaviourFields(fields, selectedFields);
      setFields(newUpdatedFields);
    }
  };

  const handleSearch = useCallback(
    debounce(async (value) => {
      setSearchLoading(true);
      try {
        const res = await createAudienceService.getAudienceFeature(clientId, value);
        setSearchResults(res.data);
        if (res.data?.behaviour_features && res.data.behaviour_features.length > 0) {
          const newUpdatedFields = addSearchBehaviourFields(fields, res.data.behaviour_features);
          setFields(newUpdatedFields);
        }
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
      setSearchLoading(false);
    }, 800),
    [clientId, fields]
  );

  const showAiRecommendationModal = () => {
    Modal.confirm({
      title: 'Get AI Recommended Rules',
      content: <p>Would you like to see AI-recommended rules?</p>,
      okText: 'Yes',
      okType: 'default',
      cancelText: 'No',
      icon: null,
      onOk: () => getAiRecommendedRules(audienceDescription),
    });
  };

  const handleAskAiClick = () => {
    setRulesRecommendations({});
    setIsModalVisible(true);
  };

  const handleApplyRecommendations = () => {
    setRecommendationsApplied(true);
    setConditions(rulesRecommendations);
    setIsModalVisible(false);
    setRulesRecommendations({});
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setRulesRecommendations({});
  };

  const handleAskRecommendation = () => {
    getAiRecommendedRules(aiPrompt);
  };

  const formatRuleValue = (value) => {
    if (Array.isArray(value)) {
      return value.map((val) => val.value_description).join(', ');
    } else {
      return typeof value === 'object' ? value.value_description : value;
    }
  };

  const formatRule = (rule) => {
    if (Array.isArray(rule.rules) && rule.rules.length > 0) {
      return generateQueryRecursive(rule);
    } else {
      const value = formatRuleValue(rule.value);
      return `${rule.field} ${rule.operator} '${value}'`.trim();
    }
  };

  const generateQueryRecursive = (condition) => {
    const combinator = condition.combinator.toUpperCase();
    const number = condition.number ? ` ${condition.number}` : '';
    const formattedCombinator = combinator === 'ANY' ? `${combinator}${number} of the` : combinator;

    const rules = condition.rules.map(formatRule).join(', ');

    return `${formattedCombinator} (${rules})`;
  };

  const generateQuery = (condition) => {
    return generateQueryRecursive(condition);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const isRulesEmpty =
      !selectedAudienceData.rules ||
      Object.keys(selectedAudienceData.rules).length === 0 ||
      selectedAudienceData.rules.rules.length === 0;
    if (!isRulesEmpty) {
      setConditions(selectedAudienceData.rules);
    }
  }, [audienceDescription, loading]);

  useEffect(() => {
    setSelectedAudienceData({ ...selectedAudienceData, rules: conditions });
  }, [conditions]);

  useEffect(() => {
    if (recommendationsApplied) {
      getAudienceSize();
      setRecommendationsApplied(false);
    }
  }, [selectedAudienceData]);

  useEffect(() => {
    if (Object.keys(rulesRecommendations).length > 0 && !isModalVisible) {
      setIsModalVisible(true);
    }
  }, [rulesRecommendations]);

  return {
    loading,
    fields,
    query,
    setQuery,
    selectedTable,
    onTableChange,
    getAiRecommendedRules,
    rulesRecommendations,
    setRulesRecommendations,
    ruleRecommendationLoading,
    searchResults,
    setSearchResults,
    searchLoading,
    setSearchLoading,
    handleSearch,
    addFeaturesToFields,
    conditions,
    setConditions,
    isModalVisible,
    setIsModalVisible,
    aiPrompt,
    setAiPrompt,
    recommendationsApplied,
    setRecommendationsApplied,
    showAiRecommendationModal,
    handleAskAiClick,
    handleApplyRecommendations,
    handleCancel,
    handleAskRecommendation,
    generateQuery,
    availableRuleTypes,
    setAvailableRuleTypes,
    setWithReflection,
    withReflection,
  };
};

export default useDataSourceTabLayout;
