import { useState, useEffect } from 'react';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { notification } from 'antd';
import CreateClientApiServices from '../services/createClientSpaceApiServices';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const useAddEditClientSpaceHook = () => {
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState('configure');
  const [metaData, setMetaData] = useState(null);
  const { clientId } = useParams();

  const getMetaData = async () => {
    try {
      setLoading(true);
      const res = await CreateClientApiServices.getClientSpaceMetaData(clientId);
      setMetaData(res.data);
      setLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        placement: 'bottomRight',
        message: msg,
      });
    }
  };

  useEffect(() => {
    getMetaData();
  }, []);

  return {
    loading,
    tab,
    setTab,
    metaData,
  };
};

export default useAddEditClientSpaceHook;
