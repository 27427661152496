import React from 'react';
import { Tree } from 'react-d3-tree';
import { Modal } from 'antd';
import Style from './treeChart.style';
import TreeChartHooks from './treeChart.hooks';
import TreeNodeHover from 'src/modules/treeNodeHover/treeNodeHover.component';
import Portal from '../portal/portal.component';

const TreeChart = ({ data, bestSolution, feedbackScreen = false }) => {
  const {
    tooltipData,
    treeTranslate,
    treeContainerRef,
    chartRef,
    renderCustomNode,
    formatData,
    getDynamicPathClass,
    showModal,
    setShowModal,
    setTooltipData,
  } = TreeChartHooks();

  return (
    <div ref={treeContainerRef} className={Style.treeChart}>
      <Tree
        ref={chartRef}
        data={formatData([data])}
        renderCustomNodeElement={renderCustomNode}
        orientation="vertical"
        separation={{ siblings: 1.5, nonSiblings: 2 }}
        translate={treeTranslate}
        pathClassFunc={getDynamicPathClass}
        zoom={0.7}
      />
      {tooltipData && !showModal && !feedbackScreen && (
        <Portal
          anchorNodeRef={document.getElementById('masterChat')}
          containerRef={treeContainerRef}
          cssClassName={Style.nodeDetails}
          offsetPx={10}
        >
          <TreeNodeHover
            data={tooltipData}
            bestSolution={bestSolution}
            onShrink={() => setShowModal(false)}
            onExpand={() => setShowModal(true)}
            onClose={() => setTooltipData(null)}
          />
        </Portal>
      )}
      <Modal
        open={showModal && tooltipData ? true : false}
        onCancel={() => setShowModal(false)}
        maskClosable={true}
        footer={false}
        closable={false}
        className={Style.nodeModal}
        centered
      >
        <TreeNodeHover
          data={tooltipData}
          bestSolution={bestSolution}
          onShrink={() => setShowModal(false)}
          onExpand={() => setShowModal(true)}
          onClose={() => {
            setTooltipData(null);
            setShowModal(false);
            return;
          }}
          isModal={true}
        />
      </Modal>
    </div>
  );
};

export default TreeChart;
