import { Table, Tabs } from 'antd';
import React from 'react';
import useForecastTable from '../audienceForecast/useForecastTable';
import './MediaPlanForecast.less';

const ForecastTable = ({ tabData }) => {
  const { getColumns, formatData } = useForecastTable();
  return (
    <div className="media-plan-overview-container">
      <Tabs defaultActiveKey="0" className="tableAudienceContainer">
        {tabData?.map((audience, id) => {
          const audienceName = audience?.audience_name;
          const channels = audience?.audience_name_value || [];
          const columns = getColumns(channels);
          const formattedData = formatData(channels);
          return (
            <Tabs.TabPane tab={audienceName} key={id}>
              <div>
                <div className="forecasttableContainer" style={{ marginBottom: '20px' }}>
                  <Table
                    className="dark-table"
                    columns={columns}
                    dataSource={formattedData}
                    pagination={false}
                    rowKey={(record) => record.channel}
                    scroll={{
                      x: 'max-content',
                      y: 400,
                    }}
                    rowClassName={(record, index) => (index === 0 ? 'fixed-row' : '')}
                  />
                </div>
              </div>
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default ForecastTable;
