import { useEffect, useState } from 'react';

const useBehaviourProbabilityHook = (behaviourProbabilityData, setBehaviourSegmentCount) => {
  const [adjustedAudienceData, setAdjustedAudienceData] = useState([]);
  const [sliderValue, setSliderValue] = useState(1);

  const getInitialValue = (behaviourProbabilityData, maxSegments) => {
    if (behaviourProbabilityData.behavioural_segment_count !== null) {
      return behaviourProbabilityData.behavioural_segment_count;
    }
    if (
      behaviourProbabilityData.behavioural_probability[9] &&
      behaviourProbabilityData.behavioural_probability[9].probability < 0.2
    ) {
      for (let i = 8; i >= 0; i--) {
        if (
          behaviourProbabilityData.behavioural_probability[i] &&
          behaviourProbabilityData.behavioural_probability[i].probability >= 0.2
        ) {
          return i + 1;
        }
      }
      return 1;
    }
    return maxSegments > 10 ? 10 : maxSegments;
  };

  useEffect(() => {
    if (Object.keys(behaviourProbabilityData).length === 0) {
      return;
    }

    if (behaviourProbabilityData.behavioural_probability === null) {
      return;
    }

    const maxSegments = behaviourProbabilityData.behavioural_probability.length;
    const initialValue = getInitialValue(behaviourProbabilityData, maxSegments);
    setSliderValue(initialValue);
    handleSliderChange(initialValue);
  }, [behaviourProbabilityData]);

  useEffect(() => {
    setBehaviourSegmentCount(sliderValue);
  }, [sliderValue]);

  const handleSliderChange = (value) => {
    setBehaviourSegmentCount(value);
    setSliderValue(value);
    const segments = behaviourProbabilityData.behavioural_probability;
    let adjustedSegments = segments.slice(0, value);
    setAdjustedAudienceData(adjustedSegments);
  };

  const calculateAverageProbability = (data) => {
    const totalProbability = data.reduce((sum, segment) => sum + segment.probability, 0);
    return totalProbability / data.length;
  };

  const totalPopulation = behaviourProbabilityData.base_size;
  const totalAverageProbability = behaviourProbabilityData.average_behavioural_probability;

  const adjustedAudienceSize =
    adjustedAudienceData.length > 0
      ? adjustedAudienceData[adjustedAudienceData.length - 1].cumulative_num_people_in_clusters
      : 0;
  const adjustedPercentage = ((adjustedAudienceSize / totalPopulation) * 100).toFixed(2);
  const adjustedAverageProbability = calculateAverageProbability(adjustedAudienceData);
  const likelihood = (adjustedAverageProbability / totalAverageProbability).toFixed(2);

  const lastSegmentProbability =
    adjustedAudienceData.length > 0 ? adjustedAudienceData[adjustedAudienceData.length - 1].probability : null;
  const showLowPrecisionMessage = lastSegmentProbability !== null && lastSegmentProbability <= 0.2;

  const chartOptions = {
    title: {
      text: 'Selected E1 Segments',
      textStyle: {
        color: '#FFFFFF',
      },
    },
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: ['Probability'],
      textStyle: {
        color: '#FFFFFF',
      },
    },
    xAxis: {
      type: 'category',
      data: adjustedAudienceData.map((segment) => segment.e1_segment),
      axisLine: {
        lineStyle: {
          color: '#FFFFFF',
        },
      },
      axisLabel: {
        color: '#FFFFFF',
      },
    },
    yAxis: {
      type: 'value',
      axisLine: {
        lineStyle: {
          color: '#FFFFFF',
        },
      },
      axisLabel: {
        color: '#FFFFFF',
      },
    },
    series: [
      {
        name: 'Probability',
        type: 'line',
        data: adjustedAudienceData.map((segment) => segment.probability),
        itemStyle: {
          color: '#FF5733',
        },
        lineStyle: {
          color: '#FF5733',
        },
      },
    ],
  };

  return {
    adjustedAudienceData,
    sliderValue,
    handleSliderChange,
    adjustedAudienceSize,
    adjustedPercentage,
    likelihood,
    showLowPrecisionMessage,
    chartOptions,
  };
};

export default useBehaviourProbabilityHook;
