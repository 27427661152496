import { css } from '@linaria/core';

const form = css`
  .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600;
    color: gray;
  }

  .ant-input-outlined {
    background: none !important;
    border: 1px solid var(--color-60-black) !important;
    border-radius: 0px !important;

    .ant-input-data-count {
      color: var(--color-30-gray);
    }
  }
`;

const headingText = css`
  margin-bottom: 1.5rem;
  font-size: 16px;
  font-weight: 600;
`;

const chatListItem = css`
  display: flex;
  font-size: 15px;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  border-radius: 0px
  cursor: pointer;
  transition: all 0.2s ease-in;
  .anticon svg {
    display: inline-block;
  }
  div {
    .anticon svg {
      display: inline-block;
    }
  }
  &:hover {
    .anticon svg {
      display: inline-block;
    }
    background: var(--color-charcoal-surface-2);
  }
`;

const deleteFileCard = css`
  width: 100%;
  border-radius: 8px;
  margin-bottom: 10px;
  display: block;
  justify-content: space-between;
  align-items: center;

  .ant-modal-body {
    padding: 0px !important;
  }

  .ant-card {
    background: black;
    border: none !important;
    border-radius: 0px !important;
  }

  .ant-card-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
    padding: 0px !important;

    .anticon-delete {
      padding: 10px;
    }
  }
`;

const tabsStyle = css`
  .ant-tabs-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .ant-tabs-tab {
    flex: 1;
    text-align: center;
    min-width: 204px; /* Ensures each tab occupies 50% width */
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    margin: 0 !important;

    @media (max-width: 768px) {
      min-width: unset !important;
    }
  }

  .ant-tabs-nav-list {
    margin: 0 !important;
    width: 100%;
  }

  .ant-tabs-tab .ant-tabs-tab-btn {
    width: 100%;
    text-align: center;
  }

  .deleteFileCard {
    margin-top: 10px;
  }

  .anticon-delete {
    padding: 10px;
    color: white;
    font-size: 18px;
    cursor: pointer;
  }

  .ant-modal-content {
    min-height: 60vh !important;
  }

  .ant-tabs-tab-btn > div {
    width: auto !important;
  }

  .ant-tabs-content-holder {
    min-height: 42vh;
  }

  .trash-icon {
    color: white;
    font-size: 18px;
    cursor: pointer;
    transition:
      transform 0.2s ease-in-out,
      color 0.2s ease-in-out;
  }

  .trash-icon:hover {
    color: darkred;
    transform: rotate(-10deg);
  }
`;

const uploadFileSection = css`
  span.anticon.anticon-paper-clip {
    color: white !important;
    font-size: 14px !important;
  }

  .ant-upload-list-item-actions button {
    opacity: unset !important;

    .anticon-delete {
      color: white !important;
    }

    .anticon-delete:hover {
      color: #dc5a4b !important;
    }
  }
`;

const deleteFileIcon = css`
  color: white;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    color: #dc5a4b;
  }
`;

export default {
  form,
  headingText,
  chatListItem,
  deleteFileCard,
  tabsStyle,
  uploadFileSection,
  deleteFileIcon,
};
