import React, { useEffect } from 'react';
import { notification } from 'antd'; // Assuming you're using Ant Design for notifications
import CustomGptApiServices from '../services/customGptApiServices'; // Adjust the import path as needed

export function useToolCardHooks(id, item, initialCapabilityValues, metaData) {
  const [isActive, setIsActive] = React.useState(initialCapabilityValues?.is_active || false);
  const [loading, setIsLoading] = React.useState(false);
  const capabilityConfig = metaData?.capability_config?.find((ele) => ele.capability_name === item?.capability_name);

  useEffect(() => {
    setIsActive(initialCapabilityValues?.is_active || false);
  }, [initialCapabilityValues]);

  const connection = async (connectionFlag) => {
    try {
      setIsLoading(true);
      const payload = {
        capability_name: item?.capability_name,
        is_active: connectionFlag,
      };
      await CustomGptApiServices.capabilityConnection(id, payload);
      notification.success({
        duration: 5,
        message: `${item.capability_display_name} ${connectionFlag ? 'connected' : 'disconnected'} successfully.`,
        placement: 'bottomRight',
      });
      setIsActive(connectionFlag);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return {
    isActive,
    setIsActive,
    loading,
    setIsLoading,
    capabilityConfig,
    connection,
  };
}
