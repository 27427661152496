import axios from 'src/http/index';
import ApiUrls from 'src/constants/apiUrls';

export default class CustomGptApiServices {
  static async getMetaData() {
    const res = await axios.get(ApiUrls.GET_CUSTOM_APP_META_DATA);
    return res.data;
  }
  x;

  static async createCustomGpt(payload) {
    const res = await axios.post(ApiUrls.CREATE_CUSTOM_APP, payload);
    return res.data;
  }

  static async getCustomAppData(mAiApp) {
    const res = await axios.get(ApiUrls.GET_CONVERSATION_STARTERS, {
      headers: {
        mAiApp: mAiApp,
      },
    });
    return res.data;
  }

  static async editCustomGpt(payload, id) {
    const res = await axios.put(ApiUrls.EDIT_CUSTOM_GPT, payload, {
      headers: {
        mAiApp: id,
      },
    });
    return res.data;
  }

  static async getSqlConnection(mAiApp, clientSpaceId) {
    const res = await axios.get(ApiUrls.SQL_CONNECTION_STRING(clientSpaceId), {
      headers: {
        mAiApp: mAiApp,
      },
    });
    return res.data;
  }

  static async configureCapabilities(mAiApp, payload) {
    const res = await axios.post(ApiUrls.CONFIGURE_CAPABILITIES, payload, {
      headers: {
        mAiApp: mAiApp,
      },
    });
    return res.data;
  }
  static async capabilityConnection(mAiApp, payload) {
    const res = await axios.post(ApiUrls.CAPABILITY_ACTIVATION, payload, {
      headers: {
        mAiApp: mAiApp,
      },
    });
    return res.data;
  }

  static async getUsersList(mAiApp) {
    const res = await axios.get(ApiUrls.GET_LIST_USERS, {
      headers: {
        mAiApp: mAiApp,
      },
    });
    return res.data;
  }

  static async changeRole(mAiApp, payload) {
    const res = await axios.post(ApiUrls.CHANGE_ROLE, payload, {
      headers: {
        mAiApp: mAiApp,
      },
    });
    return res.data;
  }

  static async getGDriveList(payload) {
    const res = await axios.post(ApiUrls.GOOGLE_DRIVE_LIST, payload);
    return res.data;
  }

  static async getSchema(payload, mAiApp) {
    const res = await axios.post(ApiUrls.GET_GOOGLE_SHEET_SCHEMA, payload, {
      headers: {
        mAiApp: mAiApp,
      },
    });
    return res.data;
  }

  static async getFeedbackList(mAiApp, payload, pageNo) {
    const res = await axios.post(ApiUrls.FEEDBACK_LIST(pageNo), payload, {
      headers: {
        mAiApp: mAiApp,
      },
    });
    return res.data;
  }

  static async resolveFeedback(mAiApp, payload) {
    const res = await axios.put(ApiUrls.RESOLVE_FEEDBACK, payload, {
      headers: {
        mAiApp: mAiApp,
      },
    });
    return res.data;
  }

  static async getFeedbackByChatId(mAiApp, chatId, prev, next) {
    const res = await axios.get(ApiUrls.GET_FEEDBACK_SESSION(chatId, prev, next), {
      headers: {
        mAiApp: mAiApp,
      },
    });
    return res.data;
  }

  static async postStrategyCapability(mAiApp, payload) {
    const res = await axios.post(ApiUrls.GET_STRATEGY_CAPABILITY, payload, {
      headers: {
        mAiApp: mAiApp,
      },
    });
    return res.data;
  }

  static async archive(mAiApp) {
    const res = await axios.get(ApiUrls.ARCHIVE_APP, {
      headers: {
        mAiApp: mAiApp,
      },
    });
    return res.data;
  }
}
