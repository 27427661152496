import { css } from '@linaria/core';

const form = css``;

const card = css`
  &.ant-card-bordered {
    border-radius: 10px;
    .ant-card-body {
      max-height: calc(100vh - 238px);
      overflow-y: auto;
    }
  }
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

const label = css`
  color: gray;
`;

const disabled = css`
  opacity: 0.5;
`;

const clusterNumberContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .ant-form-item {
    margin-bottom: 0.8rem;
  }
  .ant-slider-rail {
    height: 9px;
  }
  .ant-slider-track {
    height: 9px;
  }
  .ant-slider-handle {
    width: 18px;
    height: 18px;
  }
  .ant-slider-dot {
    width: 14px;
    height: 14px;
  }
  .ant-input-number {
    padding: 8px 16px !important;
  }
`;

const progressBar = css`
  width: 98%;
  &.ant-progress-success-bg,
  .ant-progress-bg {
    height: 25px !important;
  }
  .ant-progress-status-success .ant-progress-text {
    color: white;
  }
  .ant-progress-success-bg,
  .ant-progress-bg {
    border-radius: 0;
  }
  .ant-progress-inner {
    border-radius: 0;
    background-color: var(--color-full-black);
  }
`;

const featureContainer = css`
  overflow-y: auto;
  margin-top: 1rem;
  overflow-x: hidden;
  margin-left: 1rem;
`;

const polarityLegend = css`
  padding: 4px 14px;
  background-color: #91dd91;
`;

const disabledFeatureContainer = css`
  opacity: 0.7;
  pointer-events: none;
  cursor: not-allowed;
`;

export const algorithmSelectedFeaturesContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 16px 24px 24px 24px;
  border-radius: 8px;
  border: 0.5px solid var(--color-border);
`;

export const featureBox = css`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-radius: 8px;
  background: var(--color-70-black);
  box-shadow: 0 2px 5px var(--color-box-shadow);
`;

export const modelQualityGaugeCard = css`
  background: transparent;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  border-radius: 8px;
  border: 0.5px solid var(--color-border);
`;

export const gaugeRow = css`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 32px;
`;

export const equalHeightCol = css`
  display: flex;
  flexdirection: column;
  justifycontent: stretch;
`;

export default {
  form,
  card,
  label,
  disabled,
  clusterNumberContainer,
  progressBar,
  featureContainer,
  polarityLegend,
  disabledFeatureContainer,
  algorithmSelectedFeaturesContainer,
  featureBox,
  modelQualityGaugeCard,
  gaugeRow,
  equalHeightCol,
};
