import { css } from '@linaria/core';

const customCard = css`
  &.ant-card-bordered {
    border-radius: 10px;
    .ant-card-body {
      padding: 24px 14px;
      max-height: calc(100vh - 238px);
      overflow-y: auto;
    }
  }
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 8px;
  text-align: center;
  height: 200px;
  width: 200px;
  cursor: pointer;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  word-wrap: break-word; /* Ensures text wraps to the next line if it's too long */

  &.card-primary {
    background-color: var(--color-primary) !important;
  }

  &.card-gray {
    background-color: var(--color-full-black) !important;
  }

  &.card-hover {
    &:hover {
      transform: scale(1.05);
    }
  }

  &.card-disabled {
    background-color: var(--color-full-black) !important;
    color: #aaa !important;
    cursor: not-allowed;

    .ant-card-meta-title,
    .ant-card-meta-description {
      color: #aaa !important;
    }
  }

  .ant-card-meta-title {
    width: 180px !important;
    font-size: 18px;
    font-weight: bold;
    color: white !important;
    white-space: pre-wrap !important;
  }

  .ant-card-meta-description {
    font-size: 14px;
    color: white !important;
    word-wrap: break-word;
  }
  .ant-radio-inner {
    background-color: transparent;
  }
`;

const formContainer = css`
  .ant-form-item-label > label {
    width: 100% !important;
  }
  flex-grow: 1;
`;

const columnLabel = css`
  color: gray;
  margin-bottom: 8px;
`;

const dataSourceLoadingSpinner = css`
  position: absolute;
  height: auto;
  top: 40px;
  right: 20px;
`;

const dataSourceContainer = css`
  position: relative;
`;

const breadCrumb = css`
  margin-bottom: 16px !important;
`;

const audienceSize = css`
  margin-top: -10px;
  margin-bottom: 24px;
`;

const clusterTypeContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
`;

const clusterTypeHeader = css`
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
`;

const rowSpacer = css`
  margin-bottom: 16px;
`;

const editIcon = css`
  color: var(--color-primary);
  margin-left: 8px;
`;

const clusterTypeTitle = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const radioPositioning = css`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const featureLabel = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const featureExtraText = css`
  margin-bottom: 10px;
`;

const targetValueDisplay = css`
  margin-bottom: 10px;
`;

const tooltipOverlay = css`
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 5px;
  background-color: #000;
  max-width: 70%;
`;

const sliderContainer = css`
  width: 300px;
  max-width: 300px;
`;

const sectionHeader = css`
  color: var(--color-gale-gray);
  font-weight: 400;
`;

export default {
  columnLabel,
  customCard,
  formContainer,
  dataSourceContainer,
  dataSourceLoadingSpinner,
  breadCrumb,
  audienceSize,
  clusterTypeContainer,
  clusterTypeHeader,
  rowSpacer,
  editIcon,
  clusterTypeTitle,
  radioPositioning,
  featureLabel,
  featureExtraText,
  targetValueDisplay,
  tooltipOverlay,
  sliderContainer,
  sectionHeader,
};
