import { css } from '@linaria/core';

export const taskDetailContainer = css`
  max-width: 1440px;
  width: 80%;
  margin: 0 auto;
  padding: 32px 0;
`;

export const taskDetailTitle = css`
  color: var(--color-white);
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 600;
`;

export const taskDetailSection = css`
  margin-bottom: 24px;
  background-color: var(--color-90-black);
  border-radius: 8px;
  padding: 24px;
`;

export const taskDetailSectionTitle = css`
  color: var(--color-white);
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
`;

export const taskDetailInfoGrid = css`
  display: grid;
  grid-template-columns: minmax(150px, auto) 1fr;
  gap: 12px 24px;
  align-items: baseline;

  .ant-typography {
    color: var(--color-white);
    font-size: 14px;
  }

  .ant-typography strong {
    color: var(--color-30-gray);
  }
`;

export const taskDetailBackButton = css`
  display: inline-block;
  cursor: pointer;
  color: var(--color-link);
  margin-bottom: 16px;

  .ant-btn-link {
    color: var(--color-link);
    padding-left: 0;

    &:hover {
      color: var(--color-primary-tint-90);
    }
  }
`;

export const taskDetailNoData = css`
  color: var(--color-white);
  font-size: 18px;
  text-align: center;
  margin-top: 50px;
`;

export const errorRow = css`
  color: var(--feedback-dislike);
  &:hover {
    .ant-table-cell.ant-table-cell,
    td {
      color: var(--feedback-dislike) !important;
    }
  }
`;

export const tooltipError = css`
  .ant-tooltip-inner {
    width: max-content;
    max-width: 1000px;
  }
`;
