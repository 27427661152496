import { InfoCircleOutlined, ProfileOutlined, ToolOutlined } from '@ant-design/icons';
import { Steps, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ConditionalRender from 'src/components/conditionalRender';
import LoadingSpinner from 'src/components/loadingSpinner';
import NcBreadCrumb from 'src/modules/newsAndCulture/components/NcBreadCrumb';
import CreateMediaPlanForm from '../CreateMediaPlan/CreateMediaPlanForm';
import MediaplanOutput from '../mediaPlanOutput/MediaplanOutput';
import ScenarioConstraint from '../scenarioConstraint/ScenarioConstraint';
import Semprocess from '../semProcess/Semprocess';
import './mediaPlanCreateLayout.less';
import useMediaplanCreateLayoutHook from './useMediaplanCreateLayoutHook';

const MediaPlanCreateLayout = () => {
  const {
    loading,
    step,
    metaData,
    mediaPlanData,
    setStep,
    setMediaPlanData,
    mediaplanCreated,
    editScenario,
    getTitle,
    reviewScreen,
  } = useMediaplanCreateLayoutHook();
  const { t } = useTranslation();
  const renderStepContent = () => {
    switch (step) {
      case 0:
        return (
          <CreateMediaPlanForm
            metaData={metaData}
            mediaPlanData={mediaPlanData}
            setStep={setStep}
            setMediaPlanData={setMediaPlanData}
            mediaplanCreated={mediaplanCreated}
            editScenario={editScenario}
            reviewScreen={reviewScreen}
          />
        );
      case 1:
        return (
          <ScenarioConstraint
            metaData={metaData}
            mediaPlanData={mediaPlanData}
            setStep={setStep}
            setMediaPlanData={setMediaPlanData}
            reviewScreen={reviewScreen}
          />
        );
      case 2:
        return <Semprocess mediaPlanData={mediaPlanData} setStep={setStep} reviewScreen={reviewScreen} />;
      case 3:
        return <MediaplanOutput />;
      default:
        return null;
    }
  };
  if (loading) {
    return <LoadingSpinner centered />;
  }
  return (
    <>
      <div className="mediaplanCreateLayout">
        <NcBreadCrumb />
        <Typography.Title className="title" level={2}>
          {getTitle()}
        </Typography.Title>
        <Steps className="steps" current={step} direction="horizontal">
          <Steps.Step
            title={
              <span className={step >= 0 ? 'step-title-active' : 'step-title'}>
                {t('mediaplan.configure')}
                <ConditionalRender shouldRender={reviewScreen && step === 0}>
                  <div style={{ fontSize: '0.9rem', margin: 0, marginTop: '-1rem' }}>(View only)</div>
                </ConditionalRender>
              </span>
            }
            icon={<ToolOutlined className={step >= 0 ? 'step-icon-active' : 'step-icon'} />}
            style={{ cursor: reviewScreen ? 'pointer' : 'default' }}
            onClick={() => {
              if (reviewScreen) {
                setStep(0);
              }
            }}
          />
          <Steps.Step
            title={
              <span className={step >= 1 ? 'step-title-active' : 'step-title'}>
                {t('mediaplan.constraint')}
                <ConditionalRender shouldRender={reviewScreen && step === 1}>
                  <div style={{ fontSize: '0.9rem', margin: 0, marginTop: '-1rem' }}>(View only)</div>
                </ConditionalRender>
              </span>
            }
            icon={<InfoCircleOutlined className={step >= 1 ? 'step-icon-active' : 'step-icon'} />}
            style={{ cursor: reviewScreen ? 'pointer' : 'default' }}
            onClick={() => {
              if (reviewScreen) {
                setStep(1);
              }
            }}
          />
          <Steps.Step
            title={
              <span className={step >= 2 ? 'step-title-active' : 'step-title'}>
                {t('mediaplan.sem_process')}
                <ConditionalRender shouldRender={reviewScreen && step === 2}>
                  <div style={{ fontSize: '0.9rem', margin: 0, marginTop: '-1rem' }}>(View only)</div>
                </ConditionalRender>
              </span>
            }
            icon={<ProfileOutlined className={step >= 2 ? 'step-icon-active' : 'step-icon'} />}
            style={{ cursor: reviewScreen ? 'pointer' : 'default' }}
            onClick={() => {
              if (reviewScreen) {
                setStep(2);
              }
            }}
          />
          <Steps.Step
            title={
              <span className={step >= 3 ? 'step-title-active' : 'step-title'}>{t('mediaplan.review_full_plan')}</span>
            }
            icon={<ProfileOutlined className={step >= 2 ? 'step-icon-active' : 'step-icon'} />}
            style={{ cursor: reviewScreen ? 'pointer' : 'default' }}
            onClick={() => {
              if (reviewScreen) {
                setStep(3);
              }
            }}
          />
        </Steps>
        <div style={{ marginTop: '1rem' }}>
          <div>{renderStepContent()}</div>
        </div>
      </div>
    </>
  );
};

export default MediaPlanCreateLayout;
