import { Button, Input, Modal, Select, Radio } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Assets from 'src/assets';
import {
  RULE_TYPE_READABLE_NAMES,
  RULE_TYPE_READABLE_NAMES_WITH_FIRST_PARTY,
} from '../../../constants/queryBuilderConstants';
import { SecondaryButton } from 'src/components/basic';

const { TextArea } = Input;
const { Option } = Select;

const renderRules = (rules, t) => {
  return rules.map((rule, index) => (
    <li key={index}>
      <strong>{rule.field_readable_name && `${rule.field_readable_name}: `}</strong>
      {rule.reasoning}
      <ul>
        {rule.operator && (
          <li>
            <strong>{t('audience.operator')}:</strong> {rule.operator}
          </li>
        )}
        {rule.value && (
          <li>
            <strong>{t('audience.value')}: </strong>
            {Array.isArray(rule.value)
              ? rule.value.map((item, index) => (
                  <span key={index}>
                    {item.value_description}
                    {index < rule.value.length - 1 ? ', ' : ''}
                  </span>
                ))
              : typeof rule.value === 'object'
                ? rule.value.value_description
                : rule.value}
          </li>
        )}
      </ul>
      {rule.rules && <ul>{renderRules(rule.rules, t)}</ul>}
    </li>
  ));
};

const RulesRecommendationModal = ({
  isModalVisible,
  loading,
  aiPrompt,
  rulesRecommendations,
  handleAskRecommendation,
  handleApplyRecommendations,
  handleAiPromptChange,
  handleCancel,
  availableRuleTypes,
  setAvailableRuleTypes,
  setWithReflection,
  withReflection,
  fields,
}) => {
  const { t } = useTranslation();

  const handleReflectionChange = (e) => {
    setWithReflection(e.target.value);
  };

  const handleRuleTypeChange = (selectedTypes) => {
    setAvailableRuleTypes(selectedTypes);
  };

  return (
    <Modal
      title="AI Recommendations"
      visible={isModalVisible}
      onOk={handleAskRecommendation}
      footer={[
        loading ? (
          <span key="loading" style={{ alignItems: 'center' }}>
            Analyzing...{' '}
            <img
              src={Assets.getImage('AiLoader')}
              alt="AI"
              style={{ width: '20px', height: '20px', marginLeft: '10px' }}
            />{' '}
          </span>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: '10px' }}>
            <Button type="primary" onClick={handleCancel} style={{ width: '48%', height: '40px' }}>
              Cancel
            </Button>
            <SecondaryButton
              onClick={
                Object.keys(rulesRecommendations).length === 0 ? handleAskRecommendation : handleApplyRecommendations
              }
              style={{ width: '48%', height: '40px' }}
            >
              {Object.keys(rulesRecommendations).length === 0 ? 'Recommend' : 'Apply'}
            </SecondaryButton>
          </div>
        ),
      ]}
      onCancel={handleCancel}
      width={600}
      centered
    >
      {Object.keys(rulesRecommendations).length > 0 ? (
        <div style={{ maxHeight: '450px', overflowY: 'auto' }}>
          <h3 style={{ color: '#fff' }}>Recommended Rules:</h3>
          <p>{rulesRecommendations.reasoning}</p>
          <ul>{renderRules(rulesRecommendations.rules, t)}</ul>
        </div>
      ) : (
        <>
          <TextArea
            rows={4}
            value={aiPrompt}
            onChange={handleAiPromptChange}
            placeholder="Enter your prompt for AI"
            style={{ marginBottom: '20px' }}
          />
          <div style={{ marginBottom: '20px' }}>
            <strong>{t('audience.advanced_settings')}:</strong>
            <Radio.Group
              value={withReflection}
              onChange={handleReflectionChange}
              style={{ display: 'block', marginTop: '10px' }}
            >
              <Radio value={false}>{t('audience.quick_recommendations')}</Radio>
              <Radio value={true}>{t('audience.advanced_recommendations')}</Radio>
            </Radio.Group>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <strong>Select data sources to include:</strong>
            <Select
              mode="multiple"
              style={{ width: '100%', marginTop: '10px' }}
              value={availableRuleTypes}
              onChange={handleRuleTypeChange}
            >
              {Object.keys(
                fields && fields.first_party_features && fields.first_party_features.length > 0
                  ? RULE_TYPE_READABLE_NAMES_WITH_FIRST_PARTY
                  : RULE_TYPE_READABLE_NAMES
              ).map((type) => (
                <Option key={type} value={type}>
                  {fields && fields.first_party_features && fields.first_party_features.length > 0
                    ? RULE_TYPE_READABLE_NAMES_WITH_FIRST_PARTY[type]
                    : RULE_TYPE_READABLE_NAMES[type]}
                </Option>
              ))}
            </Select>
          </div>
        </>
      )}
    </Modal>
  );
};

export default RulesRecommendationModal;
