import { Card, Checkbox, Col, Modal, Progress, Row, Tabs, Tooltip, Typography } from 'antd';
import React from 'react';
import { PrimaryButton, SecondaryButton } from 'src/components/basic';
import ConditionalRender from 'src/components/conditionalRender';
import LoadingSpinner from 'src/components/loadingSpinner';
import { AUDIENCES_CREATED, VIEWER } from 'src/modules/audienceTool/audienceManager/constants/constants';
import Style from './clusterFeature.style';
import CorrelationChart from './CorrelationChart';
import useClusterFeatureHook from './useClusterFeatureHook';
import { useTranslation } from 'react-i18next';
import ReactSpeedometer from 'react-d3-speedometer';
const { TabPane } = Tabs;
const SalientFeatures = ({ salientFeatures, onFeatureSelect, topCorrelation, viewMode, t }) => (
  <div>
    {/* Header Row */}
    <Row style={{ marginTop: '1.5rem', fontWeight: 'bold', paddingBottom: '0.5rem', borderBottom: '1px solid #ddd' }}>
      <Col span={10}>Features</Col>
      <Col span={8}>
        <Row justify="space-between">
          <Col>{t('audience.relative_importance')}</Col>
          <Col>
            <span className={Style.progressBar}>
              <span className={Style.polarityLegend}></span>&nbsp; Positive&nbsp;&nbsp;
              <span style={{ background: '#d97979' }} className={Style.polarityLegend}></span>&nbsp; Negative
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </Col>
        </Row>
      </Col>
      <Col span={6}>Top Correlations</Col>
    </Row>

    {/* Feature List */}
    <div className={Style.featureContainer}>
      {salientFeatures?.map((item, idx) => {
        // Filter only top_correlations for this feature
        const filteredTopCorrelations = topCorrelation
          .filter((correlation) => correlation.feature_name === item.feature_name)
          .flatMap((correlation) => correlation.top_correlations || []); // Extract only `top_correlations`

        return (
          <Row key={idx} style={{ padding: '0.75rem 0', borderBottom: '1px solid #eee' }} align="middle">
            {/* Feature Name with Checkbox */}
            <Col span={10}>
              <Checkbox
                checked={item.is_user_selected}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => onFeatureSelect(idx, e.target.checked)}
                disabled={viewMode}
              >
                <Typography.Text strong>{item.feature_readable_name}</Typography.Text>
              </Checkbox>
            </Col>

            {/* Importance Score */}
            <Col span={8}>
              <div className={Style.progressBar} style={{ width: '90%' }}>
                <Progress
                  percent={Math.round(item.score_normalized)} // Rounded for cleaner UI
                  strokeColor={item.polarity === 'negative' ? '#d97979' : '#91dd91'}
                  showInfo={false}
                />
              </div>
            </Col>

            {/* Top Correlations - Wrapped & Scrollable */}
            <Col span={6}>
              <div style={{ maxHeight: '80px', paddingRight: '5px' }}>
                {filteredTopCorrelations.length > 0 ? (
                  filteredTopCorrelations.map((topCorr, correlationIdx) => (
                    <Tooltip key={correlationIdx} title={topCorr.feature_readable_name}>
                      <Typography.Text style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            flex: 1, // Ensures it takes up available space
                          }}
                        >
                          {topCorr.feature_readable_name}
                        </span>
                        : <b>{topCorr.correlation_value.toFixed(2)}</b>
                      </Typography.Text>
                    </Tooltip>
                  ))
                ) : (
                  <Typography.Text type="secondary">No correlations</Typography.Text>
                )}
              </div>
            </Col>
          </Row>
        );
      })}
    </div>
  </div>
);

const AlgorithmSelectedFeatures = ({ algorithmSelectedFeatures, salientFeatures, onFeatureSelect, viewMode }) => (
  <div className={Style.algorithmSelectedFeaturesContainer}>
    <h2>Alchemy AI Recommended Features</h2>
    <h4>
      Considering both the standalone and combined predictive power of each feature, while also removing redundant
      features due to high correlation with other predictions, Achemy AI recommends using the following features to
      segment contacts into clusters. To remove any of the selected features from the final model, deselect them now:
    </h4>
    <Row gutter={[16, 16]}>
      {algorithmSelectedFeatures?.map((feature, idx) => {
        const salientFeatureIndex = salientFeatures.findIndex(
          (f) => f.feature_readable_name === feature.feature_readable_name
        );
        const isSelected = salientFeatureIndex !== -1 && salientFeatures[salientFeatureIndex].is_user_selected;
        return (
          <Col key={idx} span={8}>
            <div className={Style.featureBox}>
              <Checkbox
                checked={isSelected}
                onChange={(e) => onFeatureSelect(salientFeatureIndex, e.target.checked)}
                disabled={viewMode}
              />
              <h4 style={{ marginLeft: '0.5rem' }}>{feature.feature_readable_name}</h4>
            </div>
          </Col>
        );
      })}
    </Row>
  </div>
);

const ModelQualityGauge = ({ modelQuality, getGaugeValue }) => {
  return (
    <Card className={Style.modelQualityGaugeCard}>
      <h2>Model Performance</h2>
      <Row className={Style.gaugeRow}>
        <Col>
          <ReactSpeedometer
            value={getGaugeValue(modelQuality)}
            minValue={0}
            maxValue={3}
            needleTransitionDuration={4000}
            startColor="red"
            segments={3}
            endColor="green"
            height={200}
            width={200}
            currentValueText={modelQuality}
            labelFontSize="0"
          />
        </Col>
      </Row>
    </Card>
  );
};

export default function ClusterFeature({ history, match, typeConfigId, setCurrentStep }) {
  const {
    loading,
    configureLoading,
    initialValues,
    generateCluster,
    onFeatureSelect,
    goBack,
    viewMode,
    audienceRole,
    clusterConfigId,
    getGaugeValue,
    topCorrelation,
    editMode,
    goNext,
  } = useClusterFeatureHook({ history, match, typeConfigId, setCurrentStep });

  const { t } = useTranslation();

  const handleSave = () => {
    if (editMode && initialValues?.state === AUDIENCES_CREATED) {
      Modal.confirm({
        title: 'Warning',
        content: t('audience.cluster_warning'),
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          generateCluster();
        },
      });
    } else {
      generateCluster();
    }
  };

  if (loading) {
    return <LoadingSpinner centered />;
  }

  return (
    <ConditionalRender shouldRender={!loading}>
      <Row justify="space-between">
        <Col>
          <h1>Feature Importance</h1>
        </Col>
        <Col>
          <Row gutter={[20, 0]}>
            <Col>
              <PrimaryButton className="small" onClick={goBack}>
                Back
              </PrimaryButton>
            </Col>
            <ConditionalRender shouldRender={editMode && initialValues?.state === AUDIENCES_CREATED}>
              <Col>
                <PrimaryButton className="small" onClick={goNext}>
                  Next
                </PrimaryButton>
              </Col>
            </ConditionalRender>
            <Col>
              <SecondaryButton
                disabled={viewMode || audienceRole === VIEWER}
                loading={configureLoading}
                className="small"
                onClick={handleSave}
              >
                Save and continue
              </SecondaryButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={[20, 0]} justify="space-between" align="middle" style={{ alignItems: 'stretch' }}>
        <Col xs={24} sm={24} md={19} className={Style.equalHeightCol}>
          <AlgorithmSelectedFeatures
            algorithmSelectedFeatures={initialValues?.algorithm_selected_features}
            salientFeatures={initialValues?.salient_features}
            onFeatureSelect={onFeatureSelect}
            viewMode={viewMode}
          />
        </Col>
        <Col xs={24} sm={24} md={5} className={Style.equalHeightCol}>
          <ModelQualityGauge modelQuality={initialValues?.model_quality} getGaugeValue={getGaugeValue} />
        </Col>
      </Row>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Feature Importance" key="1">
          <h4>
            The chart below provides more insight into each evaluated feature&apos;s power to predict{' '}
            {initialValues?.target_feature} as well as other features one is strongly correlated to. You can better
            understand the drivers behind the final features recommended for the clustering model. You may add
            additional features from the list below by checking the box beside the feature name. Adding or removing
            features from the model may make the model less strong at separating segments in ways that also predict{' '}
            {initialValues?.target_feature}.
          </h4>
          <SalientFeatures
            salientFeatures={initialValues?.salient_features}
            onFeatureSelect={onFeatureSelect}
            topCorrelation={topCorrelation}
            viewMode={viewMode}
            t={t}
          />
          <h3 style={{ marginTop: '1rem' }}>
            <i>
              You have selected <b>{initialValues?.salient_features.filter((f) => f.is_user_selected).length}</b>{' '}
              features for clustering
            </i>
          </h3>
        </TabPane>
        <TabPane tab="View Correlation" key="2">
          <CorrelationChart getFieldValue={() => initialValues?.salient_features} clusterConfigId={clusterConfigId} />
        </TabPane>
      </Tabs>
    </ConditionalRender>
  );
}
