import { Row, Tabs } from 'antd';
import ReactECharts from 'echarts-for-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/basic';
import LoadingSpinner from 'src/components/loadingSpinner';
import MediaPlanForecast from '../audienceForecast/MediaPlanForecast';
import MediaPlanSemKeywords from '../mediaPlanSemKeyword/MediaPlanSemKeyword';
import MediaPlanFlighting from './MediaPlanFlighting';
import './MediaplanOutput.less';
import useMediaplanOutput from './useMediaplanOutput';

const MediaplanOutput = () => {
  const {
    loading,
    gantChartData,
    chanelDataOptions,
    audienceSpend,
    funnelStage,
    spendByWeek,
    goBackToListing,
    rawForecastData,
    semData,
  } = useMediaplanOutput();
  const { t } = useTranslation();

  if (loading) {
    return <LoadingSpinner centered />;
  }
  return (
    <>
      <Row justify="end" style={{ marginBottom: '1rem' }}>
        <PrimaryButton className="small" comfy onClick={goBackToListing}>
          {t('mediaplan.back_to_listing')}
        </PrimaryButton>
      </Row>
      <Tabs>
        <Tabs.TabPane tab="Overview" key="1" className="media-plan-overview-container">
          <div className="charts-row second-row">
            <div className="media-plan-chart-container">
              <ReactECharts option={audienceSpend} style={{ height: '400px', width: '100%' }} />
            </div>
            <div className="media-plan-chart-container">
              <ReactECharts option={funnelStage} style={{ height: '400px', width: '100%' }} />
            </div>
          </div>
          <div className="charts-row first-row" style={{ marginTop: '1rem' }}>
            <div className="media-plan-chart-container">
              <ReactECharts option={chanelDataOptions} style={{ height: '500px', width: '100%' }} />
            </div>
          </div>
          <div className="charts-row first-row" style={{ marginTop: '1rem' }}>
            <div className="media-plan-chart-container">
              <ReactECharts option={spendByWeek} style={{ height: '400px', width: '100%' }} />
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Flighting" key="2">
          <MediaPlanFlighting data={gantChartData} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Forecast KPIs" key="3">
          <MediaPlanForecast data={rawForecastData} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="SEM Keywords" key="4">
          <MediaPlanSemKeywords data={semData} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default MediaplanOutput;
