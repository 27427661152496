import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Checkbox, Col, Drawer, Form, Input, List, Row, Select } from 'antd';
import React from 'react';
import { RoundedOutlinedButton } from 'src/components/basic';
import ConditionalRender from 'src/components/conditionalRender';
import ExploreDatabase from '../exploreDatabase.jsx/ExploreDatabase';
import CommonStyle from '../newSqlTool.style';
import SqlColumn from './SqlColumn';
import SqlQuery from './SqlQuery';
import Style from './sqlToolTables.style';
import useSqlToolTablesHook from './useSqlToolTablesHook';

export default function SqlToolTables({
  selectedConnection,
  clientId,
  sqlData,
  mAiApp,
  setSqlData,
  virtualTable = false,
}) {
  const {
    exploreDataBaseDrawer,
    setExploreDataBaseDrawer,
    formRef,
    addConnectionlevelTable,
    loading,
    unsavedChanges,
    setUnsavedChanges,
  } = useSqlToolTablesHook({
    mAiApp,
    selectedConnection,
    setSqlData,
    virtualTable,
  });
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 200px)' }}>
      <div style={{ flexGrow: '1' }}>
        <Form
          ref={formRef}
          initialValues={sqlData}
          onFinish={(val) => {
            addConnectionlevelTable(val);
          }}
          onValuesChange={() => {
            if (!unsavedChanges) {
              setUnsavedChanges(true);
            }
          }}
        >
          <Form.List name={virtualTable ? 'virtual_tables' : 'tables'}>
            {(fields, { add, remove }) => {
              return (
                <Form.Item
                  shouldUpdate={(prevValues, curValues) => {
                    return prevValues.additional !== curValues.additional;
                  }}
                  noStyle
                >
                  {({ getFieldsValue, setFieldsValue }) => {
                    return (
                      <>
                        <ConditionalRender shouldRender={unsavedChanges}>
                          <Row justify="end">
                            <Col className={Style.unsavedChanges}>Unsaved changes</Col>
                          </Row>
                        </ConditionalRender>
                        <Row></Row>
                        <Row gutter={[30, 0]} justify={virtualTable ? 'end' : 'space-between'}>
                          <ConditionalRender shouldRender={!virtualTable}>
                            <Col>
                              <RoundedOutlinedButton
                                className={CommonStyle.button}
                                onClick={() => setExploreDataBaseDrawer(true)}
                              >
                                Explore database
                              </RoundedOutlinedButton>
                            </Col>
                          </ConditionalRender>
                          <Col>
                            <RoundedOutlinedButton
                              className={CommonStyle.button}
                              onClick={() => add({ is_active: true })}
                            >
                              <PlusOutlined />
                              &nbsp;{virtualTable ? 'Add Virtual Table' : 'Add Table'}
                            </RoundedOutlinedButton>
                          </Col>
                        </Row>
                        <Drawer
                          maskClosable={false}
                          onClose={() => setExploreDataBaseDrawer(false)}
                          open={exploreDataBaseDrawer}
                          destroyOnClose
                          width="80%"
                          footer={null}
                          title="Explore Database"
                        >
                          <ExploreDatabase
                            selectedConnection={selectedConnection}
                            clientId={clientId}
                            formRef={formRef}
                            setExploreDataBaseDrawer={setExploreDataBaseDrawer}
                          />
                        </Drawer>
                        <List
                          dataSource={fields}
                          header={
                            <div className={Style.listHeader}>
                              <div style={{ width: '3%' }}></div>
                              <ConditionalRender shouldRender={!virtualTable}>
                                <div style={{ width: '18%' }}>Schema name</div>
                              </ConditionalRender>
                              <div style={{ width: '18%' }}>Name</div>
                              <div style={{ width: '33%' }}>Description</div>
                              <div style={{ width: '21%' }}>Synonyms</div>
                              <ConditionalRender shouldRender={virtualTable}>
                                <div style={{ width: '18%' }}>SQL Query</div>
                              </ConditionalRender>
                              <div style={{ width: '7%' }}>Action</div>
                            </div>
                          }
                          renderItem={(field, idx) => {
                            const tables = getFieldsValue([virtualTable ? 'virtual_tables' : 'tables']);
                            let tableName = '';
                            if (virtualTable) {
                              tableName = tables?.virtual_tables?.[field.name]?.table_name;
                            } else {
                              tableName = tables?.tables?.[field.name]?.table_name;
                            }

                            return (
                              <div className={Style.listItem} style={{ marginTop: idx === 0 ? '1.7rem' : '' }}>
                                <div style={{ width: '3%' }}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'is_active']}
                                    valuePropName="checked"
                                    fieldKey={[field.fieldKey, 'is_active']}
                                  >
                                    <Checkbox />
                                  </Form.Item>
                                </div>
                                <ConditionalRender shouldRender={!virtualTable}>
                                  <div style={{ width: '18%' }}>
                                    <Form.Item
                                      {...field}
                                      name={[field.name, 'schema_name']}
                                      fieldKey={[field.fieldKey, 'schema_name']}
                                      rules={[{ required: true, message: 'Schema Name is required' }]}
                                    >
                                      <Input style={{ width: '95%' }} placeholder="Enter schema name" />
                                    </Form.Item>
                                  </div>
                                </ConditionalRender>

                                <div style={{ width: '18%' }}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'table_name']}
                                    fieldKey={[field.fieldKey, 'table_name']}
                                    rules={[{ required: true, message: 'Table Name is required' }]}
                                  >
                                    <Input placeholder="Enter table name" />
                                  </Form.Item>
                                </div>
                                <div style={{ width: '33%' }}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'description']}
                                    fieldKey={[field.fieldKey, 'description']}
                                  >
                                    <Input.TextArea
                                      style={{ width: '95%' }}
                                      autoSize={{ maxRows: 3 }}
                                      placeholder="Enter table description"
                                    />
                                  </Form.Item>
                                </div>
                                <div style={{ width: '21%' }}>
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'synonyms']}
                                    fieldKey={[field.fieldKey, 'synonyms']}
                                    rules={[
                                      {
                                        validator: (_, value) => {
                                          if (value) {
                                            if (value.length === 0) {
                                              return Promise.resolve();
                                            } else {
                                              let error = false;
                                              for (let i = 0; i < value.length; i++) {
                                                if (value[i].trim().length < 2) {
                                                  error = true;
                                                  break;
                                                }
                                              }
                                              if (error) {
                                                return Promise.reject(
                                                  new Error('Synonyms should be atleast 2 characters long')
                                                );
                                              } else {
                                                return Promise.resolve();
                                              }
                                            }
                                          }
                                          return Promise.resolve();
                                        },
                                      },
                                    ]}
                                  >
                                    <Select
                                      mode="tags"
                                      style={{ width: '95%' }}
                                      maxTagCount="responsive"
                                      placeholder="Enter synonyms"
                                    ></Select>
                                  </Form.Item>
                                </div>
                                <ConditionalRender shouldRender={virtualTable}>
                                  <div style={{ width: '18%' }}>
                                    <Form.Item
                                      name={[field.name, 'virtual_sql_query']}
                                      rules={[
                                        {
                                          validator: (_, value) => {
                                            if (value) {
                                              if (value.trim().length === 0) {
                                                return Promise.reject(new Error('SQL query is required'));
                                              }
                                              return Promise.resolve();
                                            }
                                            return Promise.reject('SQL query is required');
                                          },
                                        },
                                      ]}
                                    >
                                      <SqlQuery
                                        tableName={tableName}
                                        selectedConnection={selectedConnection}
                                        mAiApp={mAiApp}
                                        setFieldValue={setFieldsValue}
                                        fieldName={field.name}
                                        getFieldsValue={getFieldsValue}
                                        setUnsavedChanges={setUnsavedChanges}
                                      />
                                    </Form.Item>
                                  </div>
                                </ConditionalRender>
                                <div style={{ width: '7%' }}>
                                  <div className={Style.columnContainer}>
                                    <div>
                                      <Form.Item
                                        name={[field.name, 'columns']}
                                        rules={[
                                          {
                                            validator: (_, value) => {
                                              if (value) {
                                                if (value.length === 0) {
                                                  return Promise.reject(new Error('Column is required'));
                                                }
                                                return Promise.resolve();
                                              }
                                              return Promise.reject('Column is required');
                                            },
                                          },
                                        ]}
                                      >
                                        <SqlColumn
                                          tableName={tableName}
                                          virtualTable={virtualTable}
                                          setUnsavedChanges={setUnsavedChanges}
                                        />
                                      </Form.Item>
                                    </div>
                                    <div>
                                      <DeleteOutlined
                                        style={{ color: 'red', marginTop: '9px' }}
                                        onClick={() => remove(field.name)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }}
                        />
                      </>
                    );
                  }}
                </Form.Item>
              );
            }}
          </Form.List>
        </Form>
      </div>
      <Row justify="end" style={{ paddingBottom: '1rem' }}>
        <RoundedOutlinedButton
          loading={loading}
          className={CommonStyle.button}
          onClick={() => formRef.current.submit()}
        >
          Save
        </RoundedOutlinedButton>
      </Row>
    </div>
  );
}
