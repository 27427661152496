import ApiUrls from 'src/constants/apiUrls';
import axios from 'src/http/index';

import { AUDIENCES_CREATED } from '../constants/constants';
import { DataFeatureType, defaultBehaviourValues } from '../constants/queryBuilderConstants';

export default class createAudienceService {
  static async getAudienceRecommendation(prompt) {
    const url = ApiUrls.GET_AUDIENCE_RECOMMENDATIONS;
    const response = await axios.post(url, { query: prompt });
    return response.data;
  }

  static async getAudienceFeatureMetadata(clientId, includeInRuleBuilder, isProfileable, includeInClustering = false) {
    const url = ApiUrls.GET_AUDIENCE_FEATURE_META_DATA(
      clientId,
      includeInRuleBuilder,
      isProfileable,
      includeInClustering
    );
    const response = await axios.get(url);
    return response.data;
  }

  static async getAudienceRecommendations(clientId, aiPrompt, audienceType, withReflection, availableRuleTypes) {
    const url = ApiUrls.GET_AUDIENCE_RULE_RECOMMENDATIONS;
    const payload = {
      client_space_id: clientId,
      user_query: aiPrompt,
      audience_type: audienceType,
      with_reflections: withReflection,
      available_rule_types: availableRuleTypes,
    };
    const response = await axios.post(url, payload);
    return response.data;
  }

  static async getClusterList(clientId) {
    const url = ApiUrls.GET_CLUSTER_LISTING(clientId, 1, '', 1000, AUDIENCES_CREATED);
    const response = await axios.get(url);
    return response.data;
  }

  static async createAudience(payload, profiling, value) {
    const url = ApiUrls.CREATE_AUDIENCE;
    const requestData = {
      ...payload,
      trigger_profiling: profiling,
    };

    if (value > 0) {
      requestData['behavioural_segment_count'] = value;
    }

    const response = await axios.put(url, requestData);
    return response.data;
  }

  static async getAudienceFeature(clientId, searchKey) {
    const url = ApiUrls.GET_AUDIENCE_SEARCH_FEATURE(clientId, searchKey);
    const response = await axios.get(url);
    return response.data;
  }

  static async getAudienceSize(clientId, payload) {
    const url = ApiUrls.GET_CREATE_AUDIENCE_SIZE(clientId);
    const response = await axios.post(url, payload);
    return response.data;
  }

  static async getAudienceBehaviorProbability(audienceId) {
    const url = ApiUrls.GET_AUDIENCE_BEHAVIOUR_PROBABILITY(audienceId);
    const response = await axios.get(url);
    return response.data;
  }

  static async getAudienceBehaviouralDirectory(payload) {
    const url = ApiUrls.GET_AUDIENCE_BEHAVIOURAL_DIRECTORY;
    const response = await axios.post(url, payload);
    return response.data;
  }

  static async getAudienceMetadata(clientId) {
    const url = ApiUrls.GET_AUDIENCE_META_DATA(clientId);
    const response = await axios.get(url);
    return response.data;
  }

  static async validateAudienceName(clientId, audienceName) {
    const url = ApiUrls.VALIDATE_AUDIENCE_PROJECT_NAME;
    const response = await axios.post(url, { client_space_id: clientId, audience_project_name: audienceName });
    return response.data;
  }

  static transformColumnsToFields(columns, category) {
    return columns.map((column) => {
      const field = {
        name: column.feature_name,
        label: column.feature_readable_name,
        category: category,
        featureCategory: column.feature_category,
        secondLevelCategory: column.second_level_category,
        thirdLevelCategory: column.third_level_category,
        source: column.source,
        format: column.format,
        min: column.min,
        max: column.max,
      };

      if (column.feature_values.length > 0) {
        field.valueEditorType = 'select';
        field.values = column.feature_values.map((value) => ({
          value: value.value,
          value_description: value.value_description,
        }));
      } else if (column.feature_type === DataFeatureType.NUMERIC) {
        field.valueEditorType = 'number';
      } else if (column.feature_type === DataFeatureType.DATE) {
        field.valueEditorType = 'date';
      } else if (column.feature_type === DataFeatureType.BOOLEAN) {
        field.valueEditorType = 'boolean';
        field.values = defaultBehaviourValues;
      } else {
        field.valueEditorType = 'text';
      }

      return field;
    });
  }
}
