import { Tooltip } from 'antd';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { toSentenceCase } from 'src/modules/newsAndCulture/utils';

/**
 *
 * @function
 * @see https://stackoverflow.com/a/46181
 * @param {String} email
 * @return {Boolean} true if value is email, false otherwise
 */
export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

/**
 *
 * @param {String} isoDateStr Date string in ISO 8601
 * @param {String} formatStr format as per data-fns docs
 * @returns
 */
export function formatISO(isoDateStr, formatStr = 'dd-MMM-yyyy p') {
  try {
    return format(parseISO(isoDateStr), formatStr);
  } catch (e) {
    console.error(e);
  }
  return '';
}

/**
 * Formats number as per the browser's internationalization API.
 * @param {Number} number
 * @returns
 */
export function justFormatNumber(number, defaultVal = '', formatType = 'en-US') {
  if (!number) {
    return defaultVal;
  }
  const res = new Intl.NumberFormat(formatType).format(number);
  return res;
}

/**
 * Formats number as per the browser's internationalization API with Decimal.
 * @param {Number} number
 * @returns
 */
export function formatNumber(number, defaultVal = '', decimalPlaces = 2, formatType = 'en-IN') {
  if (!number) {
    return defaultVal;
  }
  return new Intl.NumberFormat(formatType, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(number);
}

/**
 * Formats number as per the browser's internationalization API and Formats 2 Decimals
 * @param {Number} number
 * @returns
 */
export function formatNumberWithCurrencyByDecimal(number, fraction = 2, currency = 'INR') {
  if (!number) {
    number = 0;
  } // eslint-disable-line
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  }).format(number);
}

/**
 * Formats number as per the browser's internationalization API and Formats 2 Decimals with Percentage Symbols.
 * @param {Number} number
 * @returns
 */
export function formatNumberWithPercentage(number, defaultVal = '', fraction = 2) {
  if (!number) {
    return defaultVal;
  }
  // return new Intl.NumberFormat('en-IN', {
  //   style: 'percent',
  //   minimumFractionDigits: 2,
  //   maximumFractionDigits: 2,
  // }).format(number);
  return new Intl.NumberFormat('en-IN', {
    style: 'percent',
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  }).format(number / 100);
}

/**
 * Formats number as per the browser's internationalization API.
 * @param {Number} number
 * @returns
 */
export function formatNumberWithCurrency(number = 0, currency = '₹') {
  if (!number) {
    return '';
  }
  return `${currency}${new Intl.NumberFormat().format(number)}`;
}

/**
 *
 * @param {Object} axiosRejectEx Exception object
 * @param {Object} [axiosRejectEx.response] axios response
 * @param {Object} [axiosRejectEx.response.data]
 * @param {String} [axiosRejectEx.response.data.message] message
 * @param {String} [axiosRejectEx.response.data.exception_name] name of the exception
 * @param {String} [axiosRejectEx.response.data.error_config] name of the exception
 * @param {String} [axiosRejectEx.response.data.error_msg] name of the exception
 * @param {String} [axiosRejectEx.message]
 * @returns {{name: String, details: String, msg: String }|{msg: String}} Details of the exception
 */
export function getErrorMessageFromResponse(axiosRejectEx) {
  if (!axiosRejectEx) {
    return { msg: 'Unknown error' };
  }

  if (axiosRejectEx.response && axiosRejectEx.response.data) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // console.error(axiosRejectEx.response.data);
    // console.error(axiosRejectEx.response.status);
    // console.error(axiosRejectEx.response.headers);

    const errorDetails = axiosRejectEx.response.data.error_config?.errors
      ? typeof axiosRejectEx.response.data.error_config.errors === 'string'
        ? axiosRejectEx.response.data.error_config.errors
        : Object.entries(axiosRejectEx.response.data.error_config.errors)
            .map(([key, value]) => toSentenceCase(`${key}:${value.join('\n')}`))
            .join('\n')
      : '';

    return {
      name: axiosRejectEx.response.data.exception_name || '',
      details: axiosRejectEx.response.data.error_config || '',
      msg: axiosRejectEx.response.data.error_msg || axiosRejectEx.response.data.message || '',
      status: axiosRejectEx.response?.status,
      error_message: errorDetails,
    };
  }

  // if (axiosRejectEx.request) {
  // The request was made but no response was received
  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  // http.ClientRequest in node.js
  // console.error(axiosRejectEx.request);
  // return '';
  // }

  // Something happened in setting up the request that triggered an Error
  return {
    msg: axiosRejectEx.message || '',
  };
}

/**
 * Debounces the given function with the timeout
 * @param {Function} func to be debounced
 * @param {Number} timeout Timeout in milliseconds
 * @returns
 */
export function debounce(func, timeout = 300) {
  /**
   * @type {NodeJS.Timeout}
   */
  let timer;

  /**
   *
   * @param  {...any} args
   */
  const debouncedFn = (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };

  return debouncedFn;
}

/**
 * Returns Date object containing the expiry date present in token.
 * @param {String} token
 * @returns
 */
export function getExpiryDateFromToken(token) {
  if (!token) return false;
  // Decode the token and extract the expiry date
  const expiryDate = new Date(JSON.parse(window.atob(token.split('.')[1])).exp * 1000);
  return expiryDate;
}

/**
 * Returns true if token is valid based on the expiry date present in the token.
 * @param {String} token
 * @returns {Boolean}
 */
export function isTokenValid(token) {
  const expiryDateInToken = getExpiryDateFromToken(token);
  const currentDate = new Date();

  if (currentDate > expiryDateInToken) {
    return false;
  }

  return true;
}

/**
 *
 * @param {String} parentPath Path of the parent page/route
 * @param {String} url
 * @param {String} [search] Search query with ?
 * @returns
 */
export const getFullUrl = (parentPath, url, search = '') => `${parentPath}/${url}${search}`.replaceAll('//', '/');

export const getFullUrlWithSearch = (parentPath, url, search = window.location.search) =>
  getFullUrl(parentPath, url, search);

export const getUrlWithSearch = (url, search = window.location.search) => `${url}${search}`;

export const navigateTo = (history, parentPath, url = '') => {
  history.push(`${getFullUrl(parentPath, url)}${history.location.search}`);
};

/**
 *
 * @param {String} text
 * @returns {Boolean}
 */
export const isUrl = (text) => {
  const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,20}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
  return regex.test(text);
};

/**
 *
 * // TODO: When es2020, Intl.Number formatter `compact` is implemeneted, replace this with that function
 * @param {Number} newNum
 * @returns
 */
export function numFormatter(num, decimalPlaces = 2) {
  const newNum = +num;

  if (!newNum) {
    return 0;
  }

  if (newNum > 999 && newNum < 100000) {
    return `${(newNum / 1000).toFixed(decimalPlaces)}K`.replace(/\.00?$/, ''); // convert to K for number from > 1000 < 1 million
  }

  if (newNum >= 100000 && newNum < 10000000) {
    return `${(newNum / 100000).toFixed(decimalPlaces)}L`.replace(/\.00?$/, ''); // convert to M for number from > 1 million
  }

  if (newNum >= 10000000) {
    return `${(newNum / 10000000).toFixed(decimalPlaces)}Cr`.replace(/\.00?$/, ''); // convert to M for number from > 1 million
  }

  if (newNum <= 1000) {
    return newNum.toFixed(decimalPlaces).replace(/\.00?$/, '');
  }

  return '< 1K';
}

export function numFormatterForMoreThan10K(num, decimalPlaces = 2) {
  if (!num) {
    return 0;
  }

  if (num > 999 && num < 100000) {
    return `${(num / 1000).toFixed(decimalPlaces)}K`.replace(/\.00?$/, ''); // convert to K for number from > 1000 < 1 million
  }

  if (num >= 100000 && num < 10000000) {
    return `${(num / 100000).toFixed(decimalPlaces)}L`.replace(/\.00?$/, ''); // convert to M for number from > 1 million
  }

  if (num >= 10000000) {
    return `${(num / 10000000).toFixed(decimalPlaces)}Cr`.replace(/\.00?$/, ''); // convert to M for number from > 1 million
  }

  return num.toFixed(decimalPlaces).replace(/\.00?$/, '');
}

/**
 *
 * @param {String} str
 * @param {Number} maxLength
 * @returns
 */
export function truncateString(str = '', maxLength = 30) {
  if (!str) return '';
  if (str.length > maxLength) {
    return str.substring(0, maxLength).concat('...');
  }

  return str;
}

/**
 *
 * @param {String} str
 * @returns
 */
export function formatString(str = '') {
  const str1 = (str || '').replaceAll('_', ' ');
  return str1.charAt(0).toUpperCase() + str1.slice(1).toLowerCase();
}

/**
 *
 * @param {String} str
 * @returns
 */
export function formatURLOrString(str = '') {
  try {
    let newStr = str;
    if (isUrl(newStr) && !newStr.includes('://')) {
      newStr = `http://${newStr}`;
    }
    const url = new URL(newStr);
    return url.href.replace(`${url.protocol}//`, '').replace(/\/$/, '');
  } catch (ex) {
    /** ignored */
  }

  return formatString(str);
}

/**
 *
 * @param {String} str
 * @returns
 */
export function formatStringWithCapitalizeFirstLetter(str = '') {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 *
 * @param {String} str
 * @returns
 */
export function formatStringWithCapitalizeAllLetters(str = '') {
  return str.toUpperCase().replaceAll(' ', '_');
}

/**
 *
 * @param {String} str
 * @returns
 */
export function capitalizeFirstLetterOfString(str = '') {
  const str1 = (str || '').split('_');
  return str1.map((s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase()).join(' ');
}

export function compareName(a, b) {
  // converting to uppercase to have case-insensitive comparison
  const value1 = a.value.toUpperCase();
  const value2 = b.value.toUpperCase();

  let comparison = 0;

  if (value1 > value2) {
    comparison = 1;
  } else if (value1 < value2) {
    comparison = -1;
  }
  return comparison;
}

/*
 * @param {String} url
 * @return {undefined|String} url without protocol in front
 */
export function getUrlWithoutProtocol(url) {
  if (!url) {
    return url;
  }

  const val = new URL(url);
  return url.replace(`${val.protocol}//`, '');
}

/*
 * @param {String} url
 * @return {undefined|String} protocol from url
 */
export function getUrlProtocol(url) {
  if (!url) {
    return url;
  }

  const val = new URL(url);
  return val.protocol.replace(':', '');
}

/**
 *
 * @param {String} protocol
 * @param {String} url
 * @return {String} returns url with protocol
 */
export function addProtocolIfNotExists(protocol, url) {
  if (!url || !protocol) {
    return url;
  }
  const splitedUrl = url.trim().split('://');
  if (splitedUrl.length > 1) {
    return url;
  }

  return `${protocol}://${splitedUrl[0]}`;
}

// TODO: Duplicated in campaign lib

export function openExternalLink(link) {
  window.open(link, '_blank', 'noopener,noreferrer');
}

export function splitAndCapitalizeFirstLetterOfEachWord(str = '') {
  const str1 = (str || ' ').replace('_', ' ').split(' ');
  return str1.map((s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase()).join(' ');
}

export const shortenStr = (str, size = 12) => {
  if (!str) return '';
  if (str.length > size) {
    const newStr = truncateString(str, size);
    return <Tooltip title={str}>{newStr}</Tooltip>;
  }

  return str;
};

export const combineObjects = (obj1, obj2) => {
  const combined = { ...obj1 };

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (combined.hasOwnProperty(key)) {
        combined[key] = [...combined[key], ...obj2[key]];
      } else {
        combined[key] = obj2[key];
      }
    }
  }

  return combined;
};

export function hashCode(str) {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    const char = str?.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }
  return hash;
}

export function consistentHashing(str, n) {
  if (str) {
    const hash = hashCode(str);
    return ((hash % n) + n) % n;
  }
}

export function saveToClipboard(text) {
  const textarea = document.createElement('textarea');
  textarea.value = text.replace(/\*\*(.*?)\*\*/g, '$1')?.replace(/\n/g, '\n');
  textarea.style.position = 'fixed';
  textarea.style.opacity = 0;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
}

export const cleanText = (text) => {
  return text
    ?.replace(/::/g, ':') // Replaces double colons with single colon
    ?.replace(/<br>\s*<br>/g, '<br>') // Reduces multiple line breaks to a single break
    ?.replace(/\n\s*\n/g, '\n') // Removes extra space between paragraphs
    ?.replace(/\*\*/g, '') // Removes double asterisks
    ?.replace(/#/g, ''); // Removes hash symbols
};

export function numberWithCommas(x) {
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export function formatNumberWithPercentageAndCeil(number, defaultVal = '', fraction = 2) {
  if (number == null || isNaN(number)) {
    return defaultVal;
  }

  // Apply Math.ceil if the number is less than 1
  const adjustedNumber = number < 1 ? Math.ceil(number * 100) / 100 : number;

  return new Intl.NumberFormat('en-IN', {
    style: 'percent',
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  }).format(adjustedNumber / 100);
}
export function formatNumberWithCeil(number, defaultVal = '', decimalPlaces = 2, formatType = 'en-IN') {
  if (!number) {
    return defaultVal;
  }
  // Apply Math.ceil if the number is less than 1
  const adjustedNumber = number < 1 ? Math.ceil(number * 100) / 100 : number;
  return new Intl.NumberFormat(formatType, {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(adjustedNumber);
}

export const removeUnderscores = (text) => {
  return text.replace(/_/g, ' ');
};

export const checkBuildNumber = async () => {
  try {
    const response = await fetch('/build_file.txt');
    const buildNumber = await response.text();
    // Extract the part of the string after "BUILD_NUMBER="
    const matchResult = buildNumber?.match(/BUILD_NUMBER=(.*)/);
    const currentBuildNumber = matchResult ? matchResult[1] : null;
    if (currentBuildNumber && BUILD_NUMBER) {
      const trimmedCurrentBuildNumber = currentBuildNumber.trim();
      const numericCurrentBuildNumber = parseInt(trimmedCurrentBuildNumber, 10);
      const numericBuildNumber = parseInt(BUILD_NUMBER, 10);
      if (numericCurrentBuildNumber > numericBuildNumber) {
        window.location.reload(); // Force a hard refresh
      }
    }
  } catch (error) {
    console.error('Error checking build number:', error);
  }
};
