import { css } from '@linaria/core';

export const reviewContainer = css`
  max-width: 100%;
  margin: 0 auto;
  border-radius: 8px;
`;

export const reviewTitle = css`
  color: var(--color-white);
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 600;
`;

export const section = css`
  margin-bottom: 24px;
  padding: 20px;
  background-color: var(--color-90-black);
  border-radius: 8px;
`;

export const sectionTitle = css`
  color: var(--color-white);
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
`;

export const infoGrid = css`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px 16px;
  align-items: baseline;

  .ant-typography {
    color: var(--color-white);
    font-size: 14px;
  }

  .ant-typography strong {
    color: var(--color-30-gray);
  }
`;

export const sectionHeader = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const previewButton = css`
  height: 28px !important;
  width: 35px !important;
  font-size: 12px !important;
  border-radius: 4px !important;
`;

export const loadingOverlay = css`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
`;

export const emptyPreview = css`
  text-align: center;
  padding: 32px;
  color: var(--color-60-gray);
`;

export const infoIcon = css`
  color: var(--color-60-gray);
  font-size: 14px;
  cursor: help;
  margin-left: 8px;
  transition: color 0.2s ease;

  &:hover {
    color: var(--color-link);
  }
`;

export const errorRow = css`
  color: var(--feedback-dislike);
  &:hover {
    .ant-table-cell.ant-table-cell,
    td {
      color: var(--feedback-dislike) !important;
    }
  }
`;

export const tooltipError = css`
  .ant-tooltip-inner {
    width: max-content;
    max-width: 1000px;
  }
`;

export const numRowsInput = css`
  &.ant-input-number {
    margin-left: 10px;
    padding: 0 10px !important;
    width: 60px !important;
  }
`;
