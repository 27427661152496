import { Form, notification, Modal } from 'antd';
import NcApiServices from '../../services/NcApiServices';
import { useMemo, useState } from 'react';

const useAnalysisSettingsHooks = ({
  analysisData = null,
  setAnalysisData = () => {},
  appConfig = null,
  setAppConfig = () => {},
  isClientSpace,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const initialValues = useMemo(() => {
    if (analysisData) {
      return {
        exclude_nodes: analysisData?.overall_default_filters?.exclude_nodes,
        exclude_handles: analysisData?.overall_default_filters?.exclude_handles,
        apply_at_client_level: analysisData?.overall_default_filters?.apply_at_client_level,
      };
    } else {
      return {
        exclude_nodes: appConfig?.default_filters?.exclude_nodes,
        exclude_handles: appConfig?.default_filters?.exclude_handles,
      };
    }
  }, [analysisData, appConfig]);

  const updateDefaultFilters = async (values) => {
    if (isClientSpace) {
      return updateDefaultFiltersSubmitForClient(values);
    }
    if (values.apply_at_client_level) {
      Modal.confirm({
        title: 'Warning',
        content: 'This will apply the filters at the client level. Do you want to proceed?',
        okText: 'Yes',
        cancelText: 'No',
        onOk: async () => {
          await updateDefaultFiltersSubmit(values);
        },
      });
    } else {
      await updateDefaultFiltersSubmit(values);
    }
  };

  const updateDefaultFiltersSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await NcApiServices.updateDefaultFilters({
        analysisID: analysisData.id,
        clientSpaceID: analysisData.client_space_id,
        defaultFilters: {
          exclude_nodes: values.exclude_nodes?.map((item) => item.trim()) || null,
          exclude_handles: values.exclude_handles?.map((item) => item.trim().replace(/^@/, '')) || null,
        },
        apply_at_client_level: values.apply_at_client_level,
      });
      if (response.success) {
        setAnalysisData(analysisData);
        setLoading(false);
        notification.success({
          message: 'Updated the filters',
          placement: 'bottomRight',
        });
      } else {
        setLoading(false);
        notification.error({
          message: '1Failed to update filters',
          placement: 'bottomRight',
        });
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: 'Failed to update filters',
        placement: 'bottomRight',
      });
    }
  };

  const updateDefaultFiltersSubmitForClient = async (values) => {
    setLoading(true);
    setAppConfig({
      ...(appConfig || {}),
      default_filters: {
        ...(appConfig?.overall_default_filters || {}),
        exclude_nodes: values.exclude_nodes?.map((item) => item.trim()) || null,
        exclude_handles: values.exclude_handles?.map((item) => item.trim().replace(/^@/, '')) || null,
      },
    });
    setLoading(false);
  };

  return {
    form,
    updateDefaultFilters,
    initialValues,
    loading,
  };
};

export default useAnalysisSettingsHooks;
