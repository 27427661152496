import { InfoCircleOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Image, Spin, Table, Tooltip, Typography, Modal, InputNumber, Row, Form } from 'antd';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATA_SOURCE_TYPE } from '../../constants';
import * as LabelerStyles from '../../css/labeler';
import LabelerApiService from '../../services/api';
import * as Styles from './styles';
import { SecondaryButton } from 'src/components/basic';

const { Title, Text } = Typography;

const ReviewForm = ({ formData, clientSpaceId, setAllowRun }) => {
  const { t } = useTranslation();
  const [previewData, setPreviewData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const formRef = useRef(null);

  const { name, description, dataSourceType, databaseConnection, googleSheetUrl, inputColumns, outputColumns } =
    formData;

  const inputColumnsData =
    inputColumns?.map((col, index) => ({
      key: index,
      name: col.name,
      type: col.type,
      description: col.description || '-',
    })) || [];

  const outputColumnsData =
    outputColumns?.map((col, index) => ({
      key: index,
      name: col.name,
      description: col.description || '-',
    })) || [];

  const columnsTableColumns = [
    { title: 'Column Name', dataIndex: 'name', key: 'name' },
    { title: 'Type', dataIndex: 'type', key: 'type' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
  ];

  const formatPreviewData = (values) => ({
    name: values.name,
    description: values.description,
    data_source: {
      type: values.dataSourceType,
      ...(values.dataSourceType === DATA_SOURCE_TYPE.GOOGLE_SHEETS
        ? { sheet_url: values.googleSheetUrl }
        : { sql_connection: parseInt(values.databaseConnection) }),
    },
    input_columns: values.inputColumns,
    output_columns: values.outputColumns,
  });

  const fetchPreview = async (rows = 5) => {
    try {
      setLoading(true);
      setError('');
      setPreviewData(null); // Clear previous data
      const formattedData = formatPreviewData(formData);
      formattedData.dry_run_length = rows;
      const response = await LabelerApiService.getLabelingTaskPreview(clientSpaceId, formattedData);
      if (response.success) {
        setPreviewData(response.data);
        setAllowRun(true);
      } else {
        setError(response.message || 'Failed to generate preview. Please check your data source and configurations.');
      }
    } catch (err) {
      console.error('Error while performing labeler dry run:', err);
      setError('An error occurred while generating the preview. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const renderCellContent = (text, columnKey) => {
    // Find the column configuration from input columns
    const columnConfig = inputColumns?.find((col) => col.name === columnKey);

    if (!columnConfig || text === undefined || text === null) return text;

    switch (columnConfig.type) {
      case 'IMAGE':
        if (text?.includes('drive.google.com')) {
          return (
            <Typography.Link href={text} target="_blank" rel="noopener noreferrer">
              {t('labeler.view_image')}
            </Typography.Link>
          );
        }
        return <Image src={text} alt={columnKey} width={100} />;

      case 'URL':
        return (
          <Typography.Link href={text} target="_blank" rel="noopener noreferrer">
            {text}
          </Typography.Link>
        );

      case 'DATE':
        return moment(text).format('YYYY-MM-DD');

      case 'NUMBER':
        return Number(text).toLocaleString();

      default:
        return text;
    }
  };

  const WrapWithTooltip = ({ children, record }) => {
    if (record.aai_labeler_error) {
      return (
        <Tooltip
          title={`Error: ${record.aai_labeler_error}`}
          placement="topLeft"
          overlayClassName={Styles.tooltipError}
          color="var(--feedback-dislike)"
        >
          {children}
        </Tooltip>
      );
    }
    return children;
  };

  const handleDryRunClick = () => {
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleModalOk = (values) => {
    setIsModalVisible(false);
    fetchPreview(values.numRows);
  };

  const DryRunModal = () => (
    <Modal title={t('labeler.dry_run_configuration')} open={isModalVisible} onCancel={handleModalCancel} footer={null}>
      <div style={{ marginBottom: '1rem' }}>
        <Form ref={formRef} layout="vertical" initialValues={{ numRows: 5 }} onFinish={handleModalOk}>
          <Form.Item
            label={t('labeler.number_of_rows')}
            name="numRows"
            rules={[
              { required: true, message: t('labeler.number_of_rows_required') },
              { type: 'number', min: 1, max: 15, message: t('labeler.number_of_rows_max') },
            ]}
            layout="horizontal"
          >
            <InputNumber className={Styles.numRowsInput} />
          </Form.Item>
        </Form>
      </div>
      <Row justify="space-between">
        <SecondaryButton className="small" onClick={() => handleModalCancel()}>
          {t('labeler.cancel')}
        </SecondaryButton>
        <SecondaryButton className="small" onClick={() => formRef.current.submit()}>
          {t('labeler.run')}
        </SecondaryButton>
      </Row>
    </Modal>
  );

  const previewColumns = previewData
    ? [
        {
          title: t('labeler.success'),
          key: 'success',
          render: (text, record) =>
            record.aai_labeler_error ? (
              <CloseCircleOutlined style={{ color: 'red' }} />
            ) : (
              <CheckCircleOutlined style={{ color: 'green' }} />
            ),
        },
        ...Object.keys(previewData[0])
          .filter((key) => !['aai_labeler_error'].includes(key))
          .map((key) => ({
            title: key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase()),
            dataIndex: key,
            key: key,
            render: (text) => renderCellContent(text, key),
          })),
      ]
    : [];

  return (
    <div className={Styles.reviewContainer}>
      <Title level={3} className={Styles.reviewTitle}>
        {t('labeler.review')}
      </Title>

      <div className={Styles.section}>
        <Title level={5} className={Styles.sectionTitle}>
          {t('labeler.basic_information')}
        </Title>
        <div className={Styles.infoGrid}>
          <Text strong>{t('labeler.analysis_name')}</Text>
          <Text>{name || t('not_specified')}</Text>
          <Text strong>{t('description')}</Text>
          <Text>{description || t('not_specified')}</Text>
        </div>
      </div>

      <div className={Styles.section}>
        <Title level={5} className={Styles.sectionTitle}>
          {t('labeler.data_source')}
        </Title>
        <div className={Styles.infoGrid}>
          {dataSourceType === DATA_SOURCE_TYPE.GOOGLE_SHEETS && (
            <>
              <Text strong>{t('labeler.google_sheet_url')}:</Text>
              {googleSheetUrl ? (
                <Typography.Link href={googleSheetUrl} target="_blank" rel="noopener noreferrer">
                  {googleSheetUrl}
                </Typography.Link>
              ) : (
                <Text>{t('labeler.no_url_provided')}</Text>
              )}
            </>
          )}
          {dataSourceType === DATA_SOURCE_TYPE.DATABASE && (
            <>
              <Text strong>{t('labeler.database_connection')}</Text>
              <Text>{databaseConnection || 'Not specified'}</Text>
            </>
          )}
        </div>
      </div>

      <div className={Styles.section}>
        <Title level={5} className={Styles.sectionTitle}>
          {t('labeler.input_columns')}
        </Title>
        <Table
          dataSource={inputColumnsData}
          columns={columnsTableColumns}
          pagination={{ pageSize: 5 }}
          scroll={{ x: true }}
          className={LabelerStyles.table}
          size="small"
        />
      </div>

      <div className={Styles.section}>
        <Title level={5} className={Styles.sectionTitle}>
          {t('labeler.output_columns')}
        </Title>
        <Table
          dataSource={outputColumnsData}
          columns={columnsTableColumns.filter((col) => col.key !== 'type')}
          pagination={{ pageSize: 5 }}
          scroll={{ x: true }}
          className={LabelerStyles.table}
          size="small"
        />
      </div>

      <div className={Styles.section}>
        <div className={Styles.sectionHeader}>
          <Title level={5} className={Styles.sectionTitle}>
            {t('labeler.preview_results')}
            <Tooltip title={t('labeler.dry_run_title')}>
              <InfoCircleOutlined className={Styles.infoIcon} />
            </Tooltip>
          </Title>
          <Button
            type="primary"
            size="small"
            onClick={handleDryRunClick}
            loading={loading}
            className={Styles.previewButton}
            aria-label="Generate preview results"
          >
            {t('labeler.dry_run')}
          </Button>
        </div>
        {loading ? (
          <div className={Styles.loadingOverlay}>
            <Spin tip={t('labeler.generating_preview')} />
          </div>
        ) : error ? (
          <Alert message="Error" description={error} type="error" showIcon className="low-precision-alert" />
        ) : previewData ? (
          <Table
            dataSource={previewData}
            columns={previewColumns}
            pagination={{ pageSize: 10 }}
            scroll={{ x: 'max-content' }}
            className={LabelerStyles.table}
            size="small"
            rowClassName={(record) => (record.aai_labeler_error ? `${Styles.errorRow}` : '')}
            components={{
              body: {
                row: (props) => (
                  <WrapWithTooltip record={props.children[0].props.record}>
                    <tr {...props} />
                  </WrapWithTooltip>
                ),
              },
            }}
          />
        ) : (
          <div className={Styles.emptyPreview}>
            <Text>
              {t('labeler.no_preview_data_available_click')} <strong>{t('labeler.dry_run')}</strong>{' '}
              {t('labeler.to_generate_a_preview')}
            </Text>
          </div>
        )}
      </div>
      <DryRunModal />
    </div>
  );
};

export default ReviewForm;
