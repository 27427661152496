import { Col, Form, Input, Row, Select, TimePicker } from 'antd';
import React from 'react';
import { PrimaryButton, SecondaryButton } from 'src/components/basic';
import LoadingSpinner from 'src/components/loadingSpinner';
import { formatString } from 'src/lib/utils';
import useTaskCreateEditForm from './useTaskCreateEditForm';
import { useTranslation } from 'react-i18next';

const TaskCreateEditForm = ({ data, taskCreateModal, updateTask, setTaskCreateModal }) => {
  const { loading, formRef, configureLoading, onFinish, scheduleOption, initialValues, configureLoadingNow, weekDays } =
    useTaskCreateEditForm({
      data,
      taskCreateModal,
      updateTask,
      setTaskCreateModal,
    });
  const { t } = useTranslation();

  if (loading) {
    return (
      <div style={{ height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <LoadingSpinner centered />
      </div>
    );
  }
  const handleSubmit = (extraField) => {
    formRef.current.validateFields().then((values) => {
      console.log('Form Values:', values);
      // If extraField is provided, add a new field
      if (extraField) {
        onFinish({ ...values, run_now: true });
      } else {
        onFinish(values);
      }
    });
  };

  return (
    <div>
      <Row justify="end" style={{ marginBottom: '1rem' }} gutter={[20, 20]}>
        <Col>
          <SecondaryButton
            className="small"
            comfy
            loading={configureLoading}
            disabled={configureLoadingNow}
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </SecondaryButton>
        </Col>
        <Col>
          <PrimaryButton
            className="small"
            comfy
            disabled={configureLoading}
            loading={configureLoadingNow}
            onClick={() => {
              handleSubmit(true);
            }}
          >
            Save & Run now
          </PrimaryButton>
        </Col>
      </Row>
      <Form ref={formRef} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="task_name"
              rules={[{ required: true, message: t('client_space.enter-task-name-message') }]}
            >
              <Input placeholder={t('client_space.enter-task-name-placeholder')} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Instruction"
              name="task_description"
              rules={[{ required: true, message: t('client_space.enter-taskinstruction-message') }]}
            >
              <Input.TextArea placeholder={t('client_space.add-task-details-placeholder')}></Input.TextArea>
            </Form.Item>
          </Col>
          <Col span={12} xs={24}>
            <Form.Item
              name="period"
              label="Schedule"
              rules={[{ required: true, message: t('client_space.select-task-period-message') }]}
            >
              <Select placeholder={t('client_space.select-Task-period-placeholder')} style={{ width: '100%' }}>
                {scheduleOption.map((item) => (
                  <Select.Option key={item} value={item}>
                    {formatString(item)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} xs={24} style={{ marginTop: window.innerWidth <= 768 ? '0' : '2.2rem' }}>
            <Form.Item name="time" rules={[{ required: true, message: t('client_space.select-time-message') }]}>
              <TimePicker
                showNow={false}
                needConfirm={false}
                minuteStep={15}
                allowClear={false}
                use12Hours
                format="h:mm A"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                if (getFieldValue('period') === 'MONTHLY') {
                  return (
                    <Form.Item
                      name="day_of_month"
                      label="Select Date"
                      rules={[{ required: true, message: t('client_space.select-a-date-message') }]}
                    >
                      <Select placeholder="Select Date">
                        {Array.from({ length: 31 }, (_, i) => i + 1)?.map((item) => {
                          return (
                            <Select.Option key={item} value={item}>
                              {item}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  );
                } else if (getFieldValue('period') === 'WEEKLY') {
                  return (
                    <Form.Item
                      name="day_of_week"
                      label="Select Week"
                      rules={[{ required: true, message: 'Please select a week' }]}
                    >
                      <Select placeholder="Select Week">
                        {weekDays?.map((item) => {
                          return (
                            <Select.Option key={item.label} value={item.value}>
                              {item.label}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  );
                } else {
                  return null;
                }
              }}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default TaskCreateEditForm;
