import ReactECharts from 'echarts-for-react';
import React from 'react';
import { CLUSTER_COLORS } from '../../constants/constants';

const ShowClusterGraph = ({ data, clusterType }) => {
  const colors = CLUSTER_COLORS;

  // Sort data alphabetically by cluster_size
  const graphData = data;
  const pieOption = {
    tooltip: {
      trigger: 'item',
      formatter: (params) => {
        const { name, percent } = params;
        return `
          <div style="text-align: left;">
            <strong>${name}</strong>: (${percent}%)
          </div>
        `;
      },
    },
    legend: {
      orient: 'vertical',
      right: 10,
      data: data.map((cluster) => cluster.cluster_name),
      textStyle: {
        color: '#ffffff',
      },
    },
    series: [
      {
        name: 'Cluster Size',
        type: 'pie',
        radius: ['40%', '70%'],
        center: ['40%', '50%'],
        avoidLabelOverlap: false,
        color: colors,
        label: {
          show: true,
          position: 'inside',
          formatter: '{d}%',
          color: '#fff',
          fontSize: 12,
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '14',
            fontWeight: 'bold',
            textStyle: {
              color: '#ffffff',
            },
          },
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        labelLine: {
          show: false,
        },
        data: graphData.map((cluster) => ({
          value: cluster.cluster_size,
          name: cluster.cluster_name,
        })),
      },
    ],
  };

  const totalClusterSize = graphData.reduce((acc, cur) => acc + cur.cluster_size, 0);

  const barOption = {
    tooltip: {
      show: false,
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      orient: 'vertical',
      right: 0,
      data: data.map((cluster) => cluster.cluster_name),
      textStyle: {
        color: '#ffffff',
      },
    },
    grid: {
      left: '1%',
      right: '40%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: ['Number of Customers', 'Total Revenue'],
      axisLabel: {
        color: '#ffffff',
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#ffffff',
        formatter: '{value} %',
      },
      splitLine: {
        show: false,
      },
    },
    series: graphData.map((cluster, index) => ({
      name: cluster.cluster_name,
      type: 'bar',
      stack: 'total',
      barWidth: '40%',
      label: {
        show: true,
        position: 'inside',
        formatter: (params) => `${params.value}%`,
      },
      data: [((cluster.cluster_size / totalClusterSize) * 100).toFixed(1), cluster.revenue_percentage?.toFixed(1)],
      itemStyle: {
        color: colors[index % colors.length],
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '14',
          fontWeight: 'bold',
          textStyle: {
            color: '#ffffff',
          },
        },
        itemStyle: {
          shadowBlur: 20,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.7)',
        },
      },
    })),
    color: colors,
  };

  const option = clusterType.toLowerCase().includes('acquisition') ? pieOption : barOption;

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <h4 style={{ fontWeight: 'bold' }}>Clusters sorted by importance</h4>
      </div>
      <ReactECharts option={option} style={{ height: '400px', width: '100%' }} />
    </>
  );
};

export default ShowClusterGraph;
