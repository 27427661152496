import { DeleteOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, Input, Select, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BEHAVIOUR,
  COMBINATOR_AND,
  COMBINATOR_ANY,
  COMBINATOR_OR,
  DEMOGRAPHIC,
  FIRST_PARTY,
  LABEL_BEHAVIOUR_GROUP,
  LABEL_DEMOGRAPHIC_GROUP,
  LABEL_FIRST_PARTY_GROUP,
} from '../../../constants/queryBuilderConstants';
import BrowseBehaviourFeaturesComponent from './BrowseFeatures/BrowseBehaviourFeaturesComponent';
import './dataSourceTabLayout.style.less';
import useQueryBuilderHook from './useQueryBuilderHook';
import queryBuilderService from './../../../services/queryBuilderService';

const { Option } = Select;

const QueryBuilder = ({
  conditions,
  setConditions,
  fields,
  isReadOnly = false,
  errors = {},
  behaviourDirectory = {},
  addFeaturesToFields,
}) => {
  const { t } = useTranslation();
  const {
    showBrowseDrawer,
    applyRules,
    fieldDirectory,
    ruleType,
    searchQuery,
    browsedSelectedItems,
    setSearchQuery,
    setBrowsedSelectedItems,
    handleCancel,
    handleBrowseConfirm,
    addRule,
    addGroup,
    addFeatureGroup,
    removeRuleOrGroup,
    handleFieldChange,
    handleCombinatorChange,
    handleNumberChange,
    renderValueEditor,
  } = useQueryBuilderHook({ conditions, setConditions, fields, behaviourDirectory, isReadOnly, addFeaturesToFields });

  const renderConditions = (condition, parentIndex = '') => {
    return condition.rules.map((rule, index) => {
      const currentIndex = `${parentIndex}-${index}`;

      if (rule.rules) {
        return (
          <div key={index} className="group">
            <div className="group-header">
              <div className="combinator-container">
                <Select
                  value={rule.combinator}
                  onChange={(value) => handleCombinatorChange(rule, value)}
                  style={{ width: 100, marginBottom: 10 }}
                  disabled={isReadOnly}
                >
                  <Option value={COMBINATOR_AND}>{t('audience.and')}</Option>
                  <Option value={COMBINATOR_OR}>{t('audience.or')}</Option>
                  <Option value={COMBINATOR_ANY}>{t('audience.any')}</Option>
                </Select>
                {rule.combinator.toUpperCase() === 'ANY' && (
                  <>
                    <Input
                      type="number"
                      value={rule.number}
                      onChange={(e) => handleNumberChange(rule, e.target.value)}
                      style={{ width: 100, marginBottom: 10 }}
                      min={1}
                      disabled={isReadOnly}
                    />
                    <h4>{t('audience.of_the')}</h4>
                  </>
                )}
              </div>
              {!isReadOnly && (
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => removeRuleOrGroup(condition, index)}
                  className="delete-button"
                />
              )}
            </div>
            {renderConditions(rule, currentIndex)}
            {!isReadOnly && (
              <div className="add-buttons">
                <Button onClick={() => addRule(rule)} className="add-rule-button" icon={<PlusOutlined />}>
                  {rule.rule_type === BEHAVIOUR
                    ? t('audience.enrichment_rule')
                    : rule.rule_type === DEMOGRAPHIC
                      ? t('audience.demographic_rule')
                      : t('audience.first_party_rule')}
                </Button>
                {rule.combinator.toUpperCase() !== 'ANY' && (
                  <Button onClick={() => addGroup(rule)} className="add-group-button" icon={<PlusOutlined />}>
                    {t('audience.group')}
                  </Button>
                )}
              </div>
            )}
          </div>
        );
      } else {
        const allFields = [
          ...fields.demographic_features,
          ...fields.behaviour_features,
          ...fields.first_party_features,
        ];
        const field = allFields.find((f) => f.name === rule.field) ?? {};
        const hasPredefinedValues = field.values ?? [];
        const availableOperators =
          field.category === BEHAVIOUR
            ? [{ value: 'IS', label: 'Is' }]
            : queryBuilderService.getOperatorsByValueEditorType(field.valueEditorType, field.name);

        return (
          <div key={index} className="expression">
            <Select
              value={rule.field}
              onChange={(value) => handleFieldChange(rule, 'field', value)}
              placeholder={t('audience.field')}
              style={{ width: '40%', marginRight: 10 }}
              showSearch
              filterOption={(input, option) => {
                const optionText = option.key || option.value;
                return optionText.toLowerCase().includes(input.toLowerCase());
              }}
              disabled={isReadOnly}
            >
              {condition.rule_type === DEMOGRAPHIC ? (
                <Select.OptGroup label="Demographic Features">
                  {fields.demographic_features.map((field) => (
                    <Select.Option key={field.label} value={field.name}>
                      <Tooltip title={field.label}>{field.label}</Tooltip>
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ) : condition.rule_type === BEHAVIOUR ? (
                <Select.OptGroup label="Behaviour Features">
                  {fields.behaviour_features.map((field) => (
                    <Select.Option key={field.label} value={field.name}>
                      <Tooltip title={field.label}>{field.label}</Tooltip>
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ) : (
                <Select.OptGroup label="First Party Features">
                  {fields.first_party_features.map((field) => (
                    <Select.Option key={field.label} value={field.name}>
                      <Tooltip title={field.label}>{field.label}</Tooltip>
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              )}
            </Select>
            {rule.field === '' && Object.keys(errors).length !== 0 && (
              <span className="error">{t('audience.field_cannot_be_empty')}</span>
            )}

            <Select
              value={rule.operator}
              onChange={(value) => handleFieldChange(rule, 'operator', value)}
              style={{ width: '15%', marginRight: 10 }}
              disabled={isReadOnly}
            >
              {availableOperators.map((operator) => (
                <Select.Option key={operator.value} value={operator.value}>
                  {operator.label}
                </Select.Option>
              ))}
            </Select>
            {rule.operator === '' && Object.keys(errors).length !== 0 && (
              <span className="error">{t('audience.operator_cannot_be_empty')}</span>
            )}

            {renderValueEditor(rule, hasPredefinedValues, field)}
            {rule.value === '' && Object.keys(errors).length !== 0 && (
              <span className="error">{t('audience.value_cannot_be_empty')}</span>
            )}

            {rule.reasoning && (
              <Tooltip title={rule.reasoning} className="delete-button">
                <InfoCircleOutlined className="info-icon" />
              </Tooltip>
            )}
            {!isReadOnly && (
              <Button
                type="text"
                icon={<DeleteOutlined />}
                onClick={() => removeRuleOrGroup(condition, index)}
                className="delete-button"
              />
            )}
          </div>
        );
      }
    });
  };

  return (
    <>
      <div className="group">
        <div className="group-header">
          <div className="combinator-container">
            <Select
              value={conditions.combinator}
              onChange={(value) => handleCombinatorChange(conditions, value)}
              style={{ width: 100, marginBottom: 10 }}
              disabled={isReadOnly}
            >
              <Option value={COMBINATOR_AND}>{t('audience.and')}</Option>
              <Option value={COMBINATOR_OR}>{t('audience.or')}</Option>
            </Select>
            {conditions.combinator === COMBINATOR_ANY && (
              <>
                <Input
                  type="number"
                  value={conditions.number}
                  onChange={(e) => handleNumberChange(conditions, e.target.value)}
                  style={{ width: 100, marginBottom: 10 }}
                  min={1}
                  disabled={isReadOnly}
                />
                <h4>{t('audience.of_the')}</h4>
              </>
            )}
          </div>
        </div>
        {renderConditions(conditions)}
        {!isReadOnly && (
          <div className="add-buttons">
            <Button onClick={() => addFeatureGroup(conditions, DEMOGRAPHIC)} icon={<PlusOutlined />}>
              {LABEL_DEMOGRAPHIC_GROUP}
            </Button>
            <Button onClick={() => addFeatureGroup(conditions, BEHAVIOUR)} icon={<PlusOutlined />}>
              {LABEL_BEHAVIOUR_GROUP}
            </Button>
            {fields && fields.first_party_features && fields.first_party_features.length > 0 && (
              <Button onClick={() => addFeatureGroup(conditions, FIRST_PARTY)} icon={<PlusOutlined />}>
                {LABEL_FIRST_PARTY_GROUP}
              </Button>
            )}
          </div>
        )}
      </div>
      {showBrowseDrawer && (
        <Drawer
          title={t('audience.features')}
          placement="right"
          onClose={handleCancel}
          visible={showBrowseDrawer}
          width="60%"
          footer={
            <div className="drawer-footer">
              <Button onClick={handleCancel}>{t('cancel')}</Button>
              <Button onClick={handleBrowseConfirm} type="primary" loading={applyRules} disabled={applyRules}>
                {t('apply')}
              </Button>
            </div>
          }
        >
          <BrowseBehaviourFeaturesComponent
            fieldDirectory={fieldDirectory}
            browsedSelectedItems={browsedSelectedItems}
            setBrowsedSelectedItems={setBrowsedSelectedItems}
            ruleType={ruleType}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </Drawer>
      )}
    </>
  );
};

export default QueryBuilder;
