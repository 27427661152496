import { notification } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import mediaPlanService from '../../../services/mediaPlanService';

const useScenarioConstraint = ({ mediaPlanData, metaData, setStep, setMediaPlanData }) => {
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState('channel');
  const [configureLoading, setConfigureLoading] = useState(false);
  const [channelConstraint, setChannelConstraint] = useState([]);
  const [audienceConstraint, setAudienceConstraint] = useState([]);
  const formRef = useRef(null);
  const { clientId } = useParams();

  const fetchData = async () => {
    try {
      setLoading(true);
      let selectedChannelConstraints = {};
      let tempChannelConstraint = [];
      let tempAudienceConstraint = [];
      if (mediaPlanData?.state !== 'DRAFT') {
        mediaPlanData?.constraints?.channel_constraints?.forEach((channel) => {
          selectedChannelConstraints[channel?.channel_tactic] = true;
          tempChannelConstraint.push({ ...channel, channel_name: channel?.channel_tactic, selected: true });
        });
        mediaPlanData?.audience_mapping?.forEach((audience) => {
          tempAudienceConstraint.push({ ...audience });
        });
        metaData?.channel_tactics?.forEach((channel) => {
          if (!selectedChannelConstraints[channel]) {
            tempChannelConstraint.push({
              channel_name: channel,
              min_spend: 0,
              max_spend: mediaPlanData?.budget,
              selected: false,
            });
          }
        });
      }
      if (mediaPlanData?.state === 'DRAFT') {
        if (mediaPlanData?.constraints?.channel_constraints) {
          mediaPlanData?.constraints?.channel_constraints?.forEach((channel) => {
            selectedChannelConstraints[channel?.channel_tactic] = true;
            tempChannelConstraint.push({ ...channel, channel_name: channel?.channel_tactic, selected: true });
          });
          metaData?.channel_tactics?.forEach((channel) => {
            if (!selectedChannelConstraints[channel]) {
              tempChannelConstraint.push({
                channel_name: channel,
                min_spend: 0,
                max_spend: mediaPlanData?.budget,
                selected: false,
              });
            }
          });
        } else {
          metaData?.channel_tactics?.forEach((channel) => {
            tempChannelConstraint.push({
              channel_name: channel,
              min_spend: 0,
              max_spend: mediaPlanData?.budget,
              selected: true,
            });
          });
        }
        mediaPlanData?.audience_mapping.forEach((audience) => {
          if (audience.min_spend || audience.max_spend) {
            tempAudienceConstraint.push({ ...audience });
          } else {
            tempAudienceConstraint.push({ ...audience, min_spend: 0, max_spend: mediaPlanData?.budget });
          }
        });
      }
      setChannelConstraint(tempChannelConstraint);
      setAudienceConstraint(tempAudienceConstraint);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onChangeChannelConstraint = (type, idx, value) => {
    if (type === 'checked') {
      let tempChannelConstraint = [...channelConstraint];
      tempChannelConstraint[idx].selected = value;
      setChannelConstraint(tempChannelConstraint);
    } else if (type === 'range') {
      let tempChannelConstraint = [...channelConstraint];
      if (value[0] < value[1]) {
        tempChannelConstraint[idx].min_spend = value[0];
        tempChannelConstraint[idx].max_spend = value[1];
        setChannelConstraint(tempChannelConstraint);
      }
    } else if (type === 'min') {
      if (value < channelConstraint[idx].max_spend) {
        let tempChannelConstraint = [...channelConstraint];
        tempChannelConstraint[idx].min_spend = value;
        setChannelConstraint(tempChannelConstraint);
      }
    } else if (type === 'max') {
      if (value > channelConstraint[idx].min_spend) {
        let tempChannelConstraint = [...channelConstraint];
        tempChannelConstraint[idx].max_spend = value;
        setChannelConstraint(tempChannelConstraint);
      }
    }
  };

  const onChangeAudienceConstraint = (type, idx, value) => {
    if (type === 'range') {
      if (value[0] < value[1]) {
        let tempAudienceConstraint = [...audienceConstraint];
        tempAudienceConstraint[idx].min_spend = value[0];
        tempAudienceConstraint[idx].max_spend = value[1];
        setAudienceConstraint(tempAudienceConstraint);
      }
    } else if (type === 'min') {
      if (value < audienceConstraint[idx].max_spend) {
        let tempAudienceConstraint = [...audienceConstraint];
        tempAudienceConstraint[idx].min_spend = value;
        setAudienceConstraint(tempAudienceConstraint);
      }
    } else if (type === 'max') {
      if (value > audienceConstraint[idx].min_spend) {
        let tempAudienceConstraint = [...audienceConstraint];
        tempAudienceConstraint[idx].max_spend = value;
        setAudienceConstraint(tempAudienceConstraint);
      }
    }
  };

  const triggerResponse = async () => {
    try {
      setConfigureLoading(true);
      let audienceMapping = [];
      let channelMapping = [];
      channelConstraint.forEach((channel) => {
        if (channel.selected) {
          channelMapping.push({
            channel_tactic: channel.channel_name,
            min_spend: channel.min_spend,
            max_spend: channel.max_spend,
          });
        }
      });
      audienceConstraint.forEach((audience) => {
        audienceMapping.push({
          audience_id: audience.audience_id,
          min_spend: audience.min_spend,
          max_spend: audience.max_spend,
        });
      });
      const payload = {
        client_space_id: clientId,
        mediaplan_id: mediaPlanData?.mediaplan_id,
        scenario_id: mediaPlanData?.scenario_id,
        constraints: { channel_constraints: channelMapping },
        audience_mapping: audienceMapping,
      };
      let res = await mediaPlanService.createMediaplanRecommendation(payload);
      setMediaPlanData(res.data);
      setStep(2);
      setConfigureLoading(false);
    } catch (error) {
      setConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    tab,
    setTab,
    formRef,
    channelConstraint,
    audienceConstraint,
    loading,
    onChangeChannelConstraint,
    onChangeAudienceConstraint,
    triggerResponse,
    configureLoading,
  };
};

export default useScenarioConstraint;
