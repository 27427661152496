import { notification } from 'antd';
import { useState } from 'react';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { AiAppApiServices } from 'src/modules/aiApps/services/aiAppApiServices';
import dayjs from 'dayjs';

export default function useConversationResponseHook({ mAiApp, chatId }) {
  const [viewAnalysis, setViewAnalysis] = useState(null);
  const [analysisLoading, setAnalysisLoading] = useState(true);
  const [editPopOverVisible, setEditPopOverVisible] = useState(false);

  const getAnalysis = async () => {
    try {
      setAnalysisLoading(true);
      const res = await AiAppApiServices.getAnalysis(mAiApp, chatId);
      setViewAnalysis(res?.data?.intermediate_steps);
      setAnalysisLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 4,
        placement: 'bottomRight',
      });
    }
  };

  const openAnalysisAccordian = async (val) => {
    if (val.includes('analysis')) {
      if (!viewAnalysis) {
        await getAnalysis();
      }
    }
  };

  const getFormattedTaskTime = (task) => {
    if (!task) return 'N/A';

    const utcTimeString = task.time;
    const todayUtc = dayjs.utc().format('YYYY-MM-DD');
    const utcDateTime = dayjs.utc(`${todayUtc}T${utcTimeString}Z`);
    const localDateTime = utcDateTime.local();
    let formattedTime = localDateTime.format('h:mm A');

    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const getOrdinal = (num) => {
      if (num > 3 && num < 21) return `${num}th`;
      const lastDigit = num % 10;
      return `${num}${['th', 'st', 'nd', 'rd'][lastDigit] || 'th'}`;
    };

    if (task.period === 'DAILY') {
      return `Daily at ${formattedTime}`;
    } else if (task.period === 'WEEKLY' && task.day_of_week !== null) {
      return `Weekly on ${daysOfWeek[task.day_of_week]} at ${formattedTime}`;
    } else if (task.period === 'MONTHLY' && task.day_of_month) {
      return `Monthly on ${getOrdinal(task.day_of_month)} at ${formattedTime}`;
    }

    return `Scheduled at ${formattedTime}`;
  };

  return {
    analysisLoading,
    viewAnalysis,
    openAnalysisAccordian,
    editPopOverVisible,
    setEditPopOverVisible,
    getFormattedTaskTime,
  };
}
