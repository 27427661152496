import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  InfoCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import { Collapse, Modal, Popover, Radio } from 'antd';
import React, { useContext } from 'react';
import Assets from 'src/assets';
import ConditionalRender from 'src/components/conditionalRender';
import appUrls from 'src/constants/appUrls';
import { PageLayoutContext } from 'src/context/pageLayoutContext';
import { shortenStr } from 'src/lib/utils';
import AssistantAppIcon from 'src/modules/assistantAppIcon/assistantAppIcon';
import Disclaimer from 'src/modules/disclaimer/Disclaimer';
import useSideNavBarHook from '../../hooks/useSideNavBarHook';
import SessionRenameModal from '../SessionRenameModal';
import Style from './sideNavBar.style';
import Cookies from 'js-cookie';

export default function SideNavBar({
  data,
  deleteChatHIstory,
  sessionToggle,
  singleChatSessions,
  multipleChatSessions,
  setSessionToggle,
  history,
  sessionId,
  setStreamingChatID,
  setGeneratingResponse,
  cancellationStreamingRef,
  setUserMessage,
  generatingResponse,
  createNewSessionRef,
  sessionLoading,
  scrollSingleSesion,
  renameSession,
  cancelApiCall,
  mode,
  clientId,
  setTaskCreateModal,
  task,
  taskLoading,
  updateTask,
  scrollTaskSesion,
}) {
  const {
    toggleSession,
    toggleSingleChat,
    handleScroll,
    renameModal,
    closeRenameModal,
    openRenameModal,
    renameData,
    disclaimerModal,
    setDisclaimerModal,
    handleTaskContainerScroll,
    pauseTask,
    visiblePopoverId,
    setVisiblePopoverId,
  } = useSideNavBarHook({
    history,
    multipleChatSessions,
    setSessionToggle,
    data,
    setStreamingChatID,
    setGeneratingResponse,
    cancellationStreamingRef,
    setUserMessage,
    generatingResponse,
    createNewSessionRef,
    scrollSingleSesion,
    sessionLoading,
    singleChatSessions,
    cancelApiCall,
    mode,
    clientId,
    scrollTaskSesion,
    updateTask,
    sessionId,
  });
  const { isNavOpen, setIsNavOpen, inDesktop } = useContext(PageLayoutContext);

  const popOverContent = (item, idx) => {
    return (
      <div>
        <div className={Style.userContainer} onClick={() => openRenameModal(item, idx)}>
          <EditOutlined />
          &nbsp;&nbsp;Rename
        </div>
        <div
          className={Style.userContainer}
          style={{ marginBottom: '0' }}
          onClick={() => deleteChatHIstory(item?.id, idx)}
        >
          <DeleteOutlined />
          &nbsp;&nbsp;Delete Session
        </div>
      </div>
    );
  };

  const popOverTaskContent = (item, idx) => {
    return (
      <div>
        <div
          className={Style.userContainer}
          onClick={() => {
            setTaskCreateModal({ visible: true, taskId: item?.task_id });
            setVisiblePopoverId(null);
          }}
        >
          <EditOutlined />
          &nbsp;&nbsp;Edit
        </div>

        {item?.task_status === 'PAUSED' && (
          <div
            className={Style.userContainer}
            style={{ marginBottom: '0' }}
            onClick={() => {
              pauseTask(item?.task_id, idx, 'SCHEDULED');
              setVisiblePopoverId(null);
            }}
          >
            <PlayCircleOutlined />
            &nbsp;&nbsp;Resume Task
          </div>
        )}
        {item?.task_status !== 'PAUSED' && (
          <div
            className={Style.userContainer}
            onClick={() => {
              pauseTask(item?.task_id, idx, 'PAUSED');
              setVisiblePopoverId(null);
            }}
          >
            <PauseOutlined />
            &nbsp;&nbsp;Pause Task
          </div>
        )}
        <div
          className={Style.userContainer}
          style={{ marginBottom: '0' }}
          onClick={() => {
            pauseTask(item?.task_id, idx, 'ARCHIVED');
            setVisiblePopoverId(null);
          }}
        >
          <DeleteOutlined />
          &nbsp;&nbsp;Delete Task
        </div>
      </div>
    );
  };
  const isMobile = Cookies.get('source') === 'mobile_app';
  const renderGroupChatOptions = () => {
    return (
      <>
        <div className={Style.groupChatContainer}>
          <ConditionalRender shouldRender={multipleChatSessions?.length > 0}>
            <Radio.Group
              className={Style.toggleButton}
              value={sessionToggle}
              buttonStyle="solid"
              onChange={(val) => toggleSession(val.target.value)}
            >
              <Radio.Button value="MY_CHAT">My chat</Radio.Button>
              <Radio.Button value="MULTI_CHAT">Group chat</Radio.Button>
            </Radio.Group>
          </ConditionalRender>
          <ConditionalRender shouldRender={data?.role === 'ADMIN' && !isMobile}>
            <div className={Style.editButtonParent}>
              <span
                onClick={() => history.push(appUrls.EDIT_CUSTOM_GPT(data?.client_space?.id, data?.id))}
                style={{ cursor: 'pointer', marginRight: '10px', padding: '0px 2px' }}
              >
                <EditOutlined style={{ marginRight: '5px', fontSize: '15px' }} />
                <span style={{ fontSize: '14px' }}>Edit settings</span>
              </span>
            </div>
          </ConditionalRender>
        </div>
        <ConditionalRender shouldRender={sessionToggle === 'MULTI_CHAT'}>
          <div>
            <ConditionalRender shouldRender={data?.role === 'ADMIN'}>
              <span className={Style.deleteGroupChat} style={{ marginRight: '10px', padding: '0px 2px' }}>
                <span style={{ cursor: 'pointer' }} onClick={() => deleteChatHIstory(multipleChatSessions?.[0]?.id)}>
                  <DeleteOutlined />
                  &nbsp;&nbsp;Clear chat
                </span>
              </span>
            </ConditionalRender>
          </div>

          <div className={Style.groupChatContent}>
            <b>Note:</b> All messages sent in this chat window is visible to everyone who has access to this Assistant
          </div>
        </ConditionalRender>

        <div className={Style.myChatGroup}>
          <Collapse
            className={Style.sideNavCollapse}
            defaultActiveKey={['1', '2']}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          >
            {sessionToggle === 'MY_CHAT' && (
              <>
                <Collapse.Panel
                  key="1"
                  header={
                    <div style={{ marginBottom: 4 }}>
                      <img alt="speech bubble icon" src={Assets.getImage('SpeechBubbleIcon')} />
                      <span style={{ marginLeft: '6px' }}>Conversations</span>
                    </div>
                  }
                >
                  <div className={Style.chatListContainer} onScroll={handleScroll}>
                    <div
                      className={`${Style.chatListItem} ${!sessionId && Style.activeListItem}`}
                      onClick={() => {
                        if (sessionId) {
                          toggleSingleChat();
                        }
                      }}
                    >
                      <div>Start new chat</div>
                      <img alt="plus icon" src={Assets.getImage('CirclePlusIcon')} />
                    </div>
                    {singleChatSessions.map((item, idx) => {
                      return (
                        <div
                          key={`chat-list-item-${item.id}`}
                          className={`${Style.chatListItem} ${sessionId === item?.id && Style.activeListItem}`}
                        >
                          <span
                            onClick={() => {
                              if (sessionId !== item?.id) toggleSingleChat(item?.id);
                            }}
                            style={{ flexGrow: 1 }}
                          >
                            {shortenStr(item?.name, 16)}
                          </span>
                          <Popover
                            overlayClassName={Style.popOverContainer}
                            placement="bottomRight"
                            content={() => popOverContent(item, idx)}
                            trigger={['click']}
                          >
                            <EllipsisOutlined
                              style={{ width: 24, height: 24, display: 'flex', justifyContent: 'center' }}
                            />
                          </Popover>
                        </div>
                      );
                    })}
                    {sessionLoading && (
                      <div className={Style.sessionLoadingLoader}>
                        <img alt="ai loader img" height={30} width={30} src={Assets.getImage('AiLoader')} />
                      </div>
                    )}
                  </div>
                </Collapse.Panel>
                <Collapse.Panel
                  key="2"
                  header={
                    <div style={{ marginBottom: 4 }}>
                      <img alt="speech bubble icon" src={Assets.getImage('SpeechBubbleIcon')} />
                      <span style={{ marginLeft: '6px' }}>Tasks</span>
                    </div>
                  }
                >
                  <div className={Style.chatListContainer} onScroll={handleTaskContainerScroll}>
                    <div
                      className={`${Style.chatListItem} ${!sessionId && Style.activeListItem}`}
                      onClick={() => {
                        setTaskCreateModal({ visible: true, taskId: null });
                      }}
                    >
                      <div>Create new task</div>
                      <img alt="plus icon" src={Assets.getImage('CirclePlusIcon')} />
                    </div>
                    {task?.data?.map((item, idx) => {
                      return (
                        <div
                          key={`chat-list-item-${item.id}`}
                          className={`${Style.chatListItem} ${sessionId === item?.id && Style.activeListItem}`}
                        >
                          <span
                            onClick={() => {
                              if (sessionId !== item?.id)
                                toggleSingleChat(item?.id, true, item?.task_status || item?.status);
                            }}
                            style={{ flexGrow: 1 }}
                          >
                            {shortenStr(item?.name, 16)}
                          </span>
                          <Popover
                            overlayClassName={Style.popOverContainer}
                            placement="bottomRight"
                            content={() => popOverTaskContent(item, idx)}
                            trigger={['click']}
                            visible={visiblePopoverId === item.id}
                            onVisibleChange={(visible) => setVisiblePopoverId(visible ? item.id : null)}
                          >
                            <EllipsisOutlined
                              onClick={(e) => {
                                e.stopPropagation();
                                setVisiblePopoverId(item.id);
                              }}
                              style={{ width: 24, height: 24, display: 'flex', justifyContent: 'center' }}
                            />
                          </Popover>
                        </div>
                      );
                    })}
                    {taskLoading && (
                      <div className={Style.sessionLoadingLoader}>
                        <img alt="ai loader img" height={30} width={30} src={Assets.getImage('AiLoader')} />
                      </div>
                    )}
                  </div>
                </Collapse.Panel>
              </>
            )}
          </Collapse>
        </div>
      </>
    );
  };

  const handleClose = () => {
    setDisclaimerModal(false);
  };

  return (
    <>
      <ConditionalRender shouldRender={!inDesktop && isNavOpen}>
        <div
          className={Style.overlay}
          onClick={() => {
            setIsNavOpen(false);
          }}
        ></div>
      </ConditionalRender>
      <div className={Style.sideNav + `${isNavOpen ? ' show' : ''}`} style={{ zIndex: inDesktop ? 100 : 1002 }}>
        <div className={Style.navBarContainerWrapper}>
          <div className={Style.navBarContainer}>
            <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
              <div className={Style.navBarLogo}>
                <AssistantAppIcon data={data} size={50} />
              </div>
              <div className={Style.appDescription}>
                <p>{data?.description}</p>
              </div>
            </div>
            {renderGroupChatOptions()}
          </div>
          <div className={Style.disclaimer} onClick={() => setDisclaimerModal(true)}>
            <b>
              <InfoCircleOutlined />
              &nbsp;&nbsp;Disclaimer{' '}
            </b>
          </div>
          <Modal
            maskClosable={false}
            destroyOnClose
            open={renameModal}
            onCancel={closeRenameModal}
            footer={null}
            className={Style.feedbackModal}
          >
            <SessionRenameModal
              renameData={renameData}
              renameSession={renameSession}
              closeRenameModal={closeRenameModal}
              data={data}
            />
          </Modal>
          <Disclaimer modalOpen={disclaimerModal} closeModal={handleClose} />
        </div>
      </div>
    </>
  );
}
