import React from 'react';
import ForecastTable from './ForecastTable';
import useMediaPlanForecast from './useMediaPlanForecast';

const MediaPlanForecast = ({ data }) => {
  const { tab, tabData } = useMediaPlanForecast({ data });
  return <>{tab && <ForecastTable tab={tab} tabData={tabData} />}</>;
};

export default MediaPlanForecast;
