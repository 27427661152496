export const PIE_CHART_COLORS = [
  '#5470C6', // Blue
  '#87CEEB', // Light Sky Blue
  '#E6E6FA', // Lavender
  '#6495ED', // Cornflower Blue
  '#BA55D3', // Medium Orchid
  '#6A5ACD', // Slate Blue
  '#B0C4DE', // Light Steel Blue
  '#F4A460', // Sandy Brown
  '#D2691E', // Chocolate
  '#FF8C00', // Dark Orange
];

export const DEFAULT_CHART_GREY = '#C6C6C6';
