import React, { useState, useRef, useEffect } from 'react';

const TreeChartHooks = () => {
  const [tooltipData, setTooltipData] = useState(null);
  const [treeTranslate, setTreeTranslate] = useState({ x: 0, y: 0 });
  const [showModal, setShowModal] = useState(false);
  const shouldRecenterTreeRef = useRef(true);
  const treeContainerRef = useRef(null);
  const chartRef = useRef(null);

  const handleNodeMouseOver = (nodeData) => {
    setTooltipData(nodeData);
  };
  const handleNodeMouseDown = (nodeData) => {
    setTooltipData(nodeData);
    setShowModal(true);
  };

  const renderCustomNode = ({ nodeDatum }) => {
    return (
      <g
        onClick={() => handleNodeMouseDown(nodeDatum)}
        // onClickCapture={(evt) => handleNodeMouseDown(nodeDatum, evt)}
        // onMouseDownCapture={(evt) => handleNodeMouseDown(nodeDatum, evt)}
        onMouseOver={() => handleNodeMouseOver(nodeDatum)}
      >
        <foreignObject width="150" height="70" x="-75" y="-35">
          <div
            style={{
              background: '#262626',
              border: `2px solid ${getNodeColor(nodeDatum.value)}`,
              borderRadius: '5px',
              padding: '5px',
              textAlign: 'center',
            }}
          >
            <h4>{nodeDatum.name}</h4>
            <h5>Score: {Math.round(nodeDatum.value * 1000) / 1000}</h5>
          </div>
        </foreignObject>
      </g>
    );
  };

  const getNodeColor = (value) => {
    if (value > 0.75) {
      return '#2E8B57'; // SeaGreen
    } else if (value > 0.5) {
      return '#98FB98'; // PaleGreen
    } else if (value > 0.25) {
      return '#FFB6C1'; // LightPink
    } else {
      return '#CD5C5C'; // IndianRed
    }
  };

  const formatData = (nodes) => {
    return nodes.map((node) => {
      node.itemStyle = { borderColor: getNodeColor(node.value) };
      if (node.children) {
        node.children = formatData(node.children);
      }
      return node;
    });
  };

  const getDynamicPathClass = ({ source, target }) => {
    if (source.data.is_solved && target.data.is_solved) {
      return 'link__to-solved';
    }

    return 'link__to';
  };

  useEffect(() => {
    if (treeContainerRef.current && shouldRecenterTreeRef.current) {
      shouldRecenterTreeRef.current = false;
      const dimensions = treeContainerRef.current.getBoundingClientRect();

      setTreeTranslate({
        x: dimensions.width / 2,
        y: 100,
      });
    }
    const handleScroll = () => {
      const rect = treeContainerRef.current?.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect === undefined || rect.top === 0 || rect.bottom <= 0 || rect.top >= windowHeight) {
        setTooltipData(null);
      }
    };
    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  return {
    tooltipData,
    treeTranslate,
    treeContainerRef,
    chartRef,
    renderCustomNode,
    formatData,
    getDynamicPathClass,
    showModal,
    setShowModal,
    setTooltipData,
  };
};

export default TreeChartHooks;
