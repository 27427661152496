import { Col, Collapse, Form, Input, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import useSqlGlobalSettingsHook from './useSqlGlobalSettingsHook';
import SqlAgentConfig from './SqlAgentConfig';
import Style from './sqlGlobalSettings.style';
import SqlInjectionType from './SqlInjectionType';
import SqlLlmConfig from './SqlLlmConfig';
import { SecondaryButton } from 'src/components/basic';
import ConditionalRender from 'src/components/conditionalRender';
import { CustomGptService } from 'src/modules/CustomGpt/services/customGptServices';
import CommonStyle from '../newSqlTool.style';

export default function SqlGlobalSettings({ metaData, aiAppData, mAiApp, updateCustomApp }) {
  const {
    loading,
    formRef,
    initialValues,
    configData,
    configureCapability,
    cascaderOptions,
    selectedValue,
    setSelectedValue,
    chosenLLM,
    setChosenLLM,
  } = useSqlGlobalSettingsHook({ metaData, aiAppData, mAiApp, updateCustomApp });
  return (
    <>
      <ConditionalRender shouldRender={!loading}>
        <div className={Style.container}>
          <div style={{ flexGrow: '1' }}>
            <Form
              ref={formRef}
              initialValues={initialValues}
              layout="vertical"
              requiredMark={false}
              onFinish={(val) => {
                configureCapability(val);
              }}
            >
              <Row gutter={[20, 0]}>
                <Col span={24}>
                  <Form.Item
                    name="sql_assistant_name"
                    label="Sql assistant name*"
                    rules={CustomGptService.validations(
                      { required: true, max_length_string: true },
                      'Name',
                      configData?.config?.sql_assistant_name
                    )}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="sql_assistant_description_for_system_prompt"
                    label="Sql assistant description for system prompt*"
                    rules={CustomGptService.validations(
                      { required: true, max_length_string: true },
                      'SQL ssistant description for system prompt',
                      configData?.config?.sql_assistant_description_for_system_prompt
                    )}
                  >
                    <TextArea autoSize={{ minRows: 5, maxRows: 7 }} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="sql_assistant_tool_description"
                    label="Sql assistant tool description*"
                    rules={CustomGptService.validations(
                      { required: true, max_length_string: true },
                      'SQL assistant tool description',
                      configData?.config?.sql_assistant_tool_description
                    )}
                  >
                    <TextArea autoSize={{ minRows: 5, maxRows: 7 }} />
                  </Form.Item>
                </Col>
              </Row>
              <Collapse className={'drawerCollapse'}>
                <Collapse.Panel header={'Llm configuration'} forceRender={true}>
                  <Form.Item noStyle name={['sql_assistant_llm_config']}>
                    <SqlLlmConfig
                      configData={configData}
                      cascaderOptions={cascaderOptions}
                      selectedValue={selectedValue}
                      setSelectedValue={setSelectedValue}
                      chosenLLM={chosenLLM}
                      setChosenLLM={setChosenLLM}
                    />
                  </Form.Item>
                </Collapse.Panel>
              </Collapse>
              <Collapse className={'drawerCollapse'}>
                <Collapse.Panel header={'Agent configuration'} forceRender={true}>
                  <Form.Item noStyle name={['sql_assistant_llm_config']}>
                    <SqlAgentConfig configData={configData} />
                  </Form.Item>
                </Collapse.Panel>
              </Collapse>
              <Collapse className={'drawerCollapse'}>
                <Collapse.Panel header={'Injection type'} forceRender={true}>
                  <SqlInjectionType configData={configData} />
                </Collapse.Panel>
              </Collapse>
            </Form>
          </div>
          <Row className={CommonStyle.topSaveBar} onClick={() => formRef.current.submit()} justify={'end'}>
            <SecondaryButton>Done</SecondaryButton>
          </Row>
        </div>
      </ConditionalRender>
    </>
  );
}
