import { SettingOutlined } from '@ant-design/icons';
import { Col, Drawer, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Assets from 'src/assets';
import { PrimaryButton, SecondaryButton } from 'src/components/basic';
import ConditionalRender from 'src/components/conditionalRender';
import LoadingSpinner from 'src/components/loadingSpinner';
import { numberWithCommas } from 'src/lib/utils';
import { SEM_NOT_ENABLED_STATUS, SEM_STATUS } from '../../../constants/constants';
import AdGroupCollapse from '../adGroupCollapse/AdGroupCollapse';
import ConfigureSem from '../configureSem/ConfigureSem';
import './semprocess.less';
import useSemProcess from './useSemprocess';

const SemProcess = ({ mediaPlanData, setStep, reviewScreen }) => {
  const { t } = useTranslation();
  const {
    loading,
    data,
    keywordData,
    setKeywordData,
    setSelectedData,
    selectedData,
    keywordUpdate,
    setForecastData,
    forecastData,
    triggerScenario,
    configureLoading,
    skipSem,
    skipSemLoading,
    semConfigurationModal,
    setSemConfigurationModal,
    fetchSemData,
  } = useSemProcess({
    mediaPlanData,
  });
  if (loading) {
    return <LoadingSpinner centered />;
  }

  return (
    <div className="semProcessContainer">
      <Row gutter={[20, 20]} justify="end" align="middle">
        <Col>
          <SecondaryButton
            className="small"
            onClick={() => {
              setSemConfigurationModal(true);
            }}
            disabled={SEM_STATUS.includes(data?.status) || data?.status === 'ERROR' || skipSemLoading}
          >
            Configure <SettingOutlined />
          </SecondaryButton>
        </Col>
        <Col>
          <SecondaryButton onClick={() => setStep(1)} className="small">
            {t('back')}
          </SecondaryButton>
        </Col>
        <ConditionalRender shouldRender={!reviewScreen}>
          <Col>
            <PrimaryButton
              loading={skipSemLoading}
              disabled={configureLoading}
              className="small"
              onClick={() => skipSem()}
            >
              {t('mediaplan.skip_sem')}
            </PrimaryButton>
          </Col>
        </ConditionalRender>
        <Col>
          <PrimaryButton
            onClick={() => {
              if (reviewScreen) {
                setStep(3);
              } else {
                if (selectedData.select.length > 0 || selectedData.unselect.length > 0 || forecastData) {
                  keywordUpdate();
                } else {
                  triggerScenario();
                }
              }
            }}
            loading={configureLoading}
            disabled={SEM_STATUS.includes(data?.status) || data?.status === 'ERROR' || skipSemLoading}
            className="small"
          >
            {selectedData.select.length > 0 || selectedData.unselect.length > 0 || forecastData
              ? t('mediaplan.forecast_and_next')
              : t('next')}
          </PrimaryButton>
        </Col>
      </Row>
      {data?.status === 'ERROR' && (
        <div className="semContainerHeader">
          <h3>{t('mediaplan.sem_error_message')}</h3>
        </div>
      )}
      {SEM_STATUS.includes(data?.status) && (
        <div className="semContainerHeader">
          <p>
            {t('mediaplan.sem_creating_message')} &nbsp;
            <img alt="ai loader img" height={30} width={30} src={Assets.getImage('AiLoader')} />
          </p>
        </div>
      )}
      {SEM_NOT_ENABLED_STATUS.includes(data?.status) && (
        <div className="semContainerHeader">
          <p>{t('mediaplan.sem_not_enabled')}</p>
        </div>
      )}
      {data?.status === 'CREATED' && (
        <>
          <div className="semContainerHeader">
            <p>
              {/* {t('mediaplan.sem_header', {
                minClick: numberWithCommas(Math.round(data?.min_clicks)),
                maxClick: numberWithCommas(Math.round(data?.max_clicks)),
                minBudget: numberWithCommas(Math.round(data?.min_budget)),
                maxBudget: numberWithCommas(Math.round(data?.max_budget)),
              })} */}
              {t('mediaplan.sem_header1')} <strong>{numberWithCommas(Math.round(data?.min_clicks))}</strong>{' '}
              {t('mediaplan.sem_header2')} <strong>{numberWithCommas(Math.round(data?.max_clicks))}</strong>{' '}
              {t('mediaplan.sem_header3')}
              <strong>${numberWithCommas(Math.round(data?.min_budget))}</strong> {t('mediaplan.sem_header4')}
              <strong>${numberWithCommas(Math.round(data?.max_budget))}</strong> {t('mediaplan.sem_header5')}
            </p>
          </div>
          {data?.ad_group_data?.map((adGroup, idx) => {
            return (
              <AdGroupCollapse
                adGroup={adGroup}
                idx={idx}
                key={idx}
                keywordData={keywordData}
                setkeywordData={setKeywordData}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                setForecastData={setForecastData}
                reviewScreen={reviewScreen}
              />
            );
          })}
        </>
      )}
      <Drawer
        open={semConfigurationModal}
        onClose={() => {
          setSemConfigurationModal(false);
        }}
        title="SEM Configuration"
        width="80%"
        destroyOnClose
      >
        <ConfigureSem data={data} fetchSemData={fetchSemData} setSemConfigurationModal={setSemConfigurationModal} />
      </Drawer>
    </div>
  );
};

export default SemProcess;
