import { css } from '@linaria/core';

const inputContainer = css`
  margin: 6px 16px;
  position relative;
  z-index: 2;
  .ant-input {
    height: 3.5rem;
    border-radius: 26px !important;
  } 
  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
  @media screen and (min-width: 1200px) {
    margin:0 15%;
  }

  .ant-input:placeholder-shown {
    font-size: 14px;
    ::placeholder{
      font-weight: 600
    }
`;

const inputContainerTextArea = css`
  border-radius: 64px !important;
  background: var(--color-charcoal-surface-3) !important;
  border: 1px solid transparent !important;
  font-size: 16px !important;
  font-weight: 400;
  padding: 16px 11px 16px 20px !important;
  color: var(--color-30-gray);
  transition: all 0.2s ease-in;

  &.ant-input-disabled,
  &.ant-input-disabled:hover {
    color: var(--color-30-gray) !important;
  }

  &.upload-feature {
    padding-left: 52px !important;
  }
  &:hover {
    background: var(--color-70-black) !important;
  }
  &:focus {
    border: 1px solid var(--color-60-gray) !important;
  }
`;

const inputFileUploadContainer = css`
  display: flex;
  gap: 0.6rem;
  align-items: center;
`;

const inputLoaderImage = css`
  position: absolute;
  top: 5%;
  right: 4px;
  padding: 4px 7px;
`;

const stopButton = css`
  position: absolute;
  top: 0;
  right: 4px;
  width: 50px;
  height: 50px;
  padding: 4px 7px;
  border: none;
  background: none;
  color: #dcdcdc;
  img {
    width: 100%;
    height: 100%;
  }
`;

const submitBtn = css`
  position: absolute;
  right: 4px;
  top: 0;
  cursor: pointer;
  background: transparent;
  border: none;
  width: 50px;
  height: 50px;
  transition: all 0.2s ease-in;
  img {
    width: 100%;
    height: 100%;
  }
  &:disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }
`;

const fileUploadIcon = css`
  position: absolute;
  cursor: pointer;
  top: 20%;
  padding: 4px 7px;
  margin-left: 12px;
  @media screen and (max-width: 768px) {
  }
`;

export default {
  inputContainer,
  inputContainerTextArea,
  inputFileUploadContainer,
  inputLoaderImage,
  stopButton,
  submitBtn,
  fileUploadIcon,
};
