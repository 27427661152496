import { useState, useEffect, useRef } from 'react';
import CreateClientApiServices from '../services/createClientSpaceApiServices';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { notification } from 'antd';
import AppUrls from 'src/constants/appUrls';

const useClientSpaceAndBasicInfoHook = ({ history, clientId, addUpdateClient }) => {
  const [loading, setLoading] = useState(true);
  const [basicInfo, setBasicInfo] = useState({});
  const [configureLoading, seConfigureLoading] = useState(false);
  const formRef = useRef(null);

  const createClisentSpace = async (values) => {
    try {
      seConfigureLoading(true);
      let payload = {
        ...values,
      };
      if (values?.black_white_icon) {
        payload = {
          ...payload,
          icon: values?.black_white_icon,
        };
      }
      if (clientId) {
        payload = {
          ...payload,
          id: clientId,
          client_space_id: clientId,
        };
      }
      const res = await CreateClientApiServices.createClientSpace(payload);
      addUpdateClient(res.data);
      if (!clientId) {
        history.push(AppUrls.CLIENT_HOME(res.data.id));
      } else {
        notification.success({
          duration: 5,
          placement: 'bottomRight',
          message: 'Client Space updated successfully',
        });
      }
      seConfigureLoading(false);
    } catch (error) {
      seConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        placement: 'bottomRight',
        message: msg,
      });
    }
  };

  const getClientData = async () => {
    try {
      setLoading(true);
      const res = await CreateClientApiServices.getClientSpaceDetails(clientId);
      setBasicInfo(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        placement: 'bottomRight',
        message: msg,
      });
    }
  };

  useEffect(() => {
    if (clientId) {
      getClientData();
    } else {
      setLoading(false);
    }
  }, []);
  return {
    basicInfo,
    formRef,
    createClisentSpace,
    configureLoading,
    loading,
  };
};

export default useClientSpaceAndBasicInfoHook;
