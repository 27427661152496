export const COLUMNS_TYPE = ['string', 'int', 'boolean', 'date', 'datetime', 'float'];

export const COLUMNS_TYPE_GOOGLE_SHEET = ['string', 'integer', 'boolean', 'date', 'datetime', 'float'];

export const USER_ROLE = ['ADMIN', 'APP_USER'];

export const NAVIGATOR_CLIENT_ID = '59a83550-babf-46b5-80e5-d25ecb0ccd48';

export const AVAILABLE_CAPABILITY = [
  'SqlCapability',
  'InternetCapability',
  'ChartingCapability',
  'GoogleDriveCapability',
  'BackboneCapability',
  'DashCapability',
  'GoogleSheetCapability',
  'CodeInterpreterCapability',
  'SqlCapabilityV2',
  'ImageGenerationCapability',
  'CsvGenerationCapability',
  'GithubCapability',
];

export const ICON_CONFIG = {
  UPLOAD_INFO: 'Please upload images with a dimension of 150*150',
  TITLE: 'Icons',
  BUTTON: 'Upload',
};

export const CREATE_FORM = {
  ASSISTANT_DESC: 'Assistant Description*',
  ASSISTANT_DESC_PLACEHOLDER:
    "Provide a user-friendly description of your assistant's capabilities and how it can aid the end user. This description is intended for users who will interact with the assistant.",
  INSTRUCTION_ASSISTANT: 'Instruction for the Assistant*',
  INSTRUCTION_ASSISTANT_PLACEHOLDER:
    'Enter a directive that shapes how the assistant behaves. This prompt should detail the specific actions, responses, and operating procedures your assistant will follow.',
};

export const FEEDBACK_TYPE = [
  { value: 'LIKED', label: 'Liked' },
  { value: 'DISLIKED', label: 'Disliked' },
];

export const FEEDBACK_CATEGORY = [
  { value: 'INCORRECT', label: 'Incorrect' },
  { value: 'UNETHICAL', label: 'Unethical' },
  { value: 'BIASED', label: 'Biased' },
  { value: 'SLOW_RESPONSE', label: 'Slow Response' },
  { value: 'OTHERS', label: 'Others' },
];

export const RESOLVED_BY = [
  { value: 'RESOLVED', label: 'Resolved' },
  { value: 'UNRESOLVED', label: 'Unresolved' },
];

export const JIRA_STATUS = [
  { value: 'backlog', label: 'BACKLOG' },
  { value: 'selected_for_development', label: 'SELECTED FOR DEVELOPMENT' },
  { value: 'in_progress', label: 'IN PROGRESS' },
  { value: 'invalidate/_duplicate', label: 'INVALIDATE/DUPLICATE' },
  { value: 'blocked', label: 'BLOCKED' },
  { value: 'reopened', label: 'REOPENED' },
  { value: 'done', label: 'DONE' },
];

export const VIEW_BY_CONVO = [
  { value: 'By Feedback', label: 'View by Feedback' },
  { value: 'By Session', label: 'View by Session' },
];

export const AGENT_VALUE_TO_TEXT = {
  BASE_OPENAI_AGENT: {
    text: 'Standard OpenAI Agent',
    description:
      "Leverage OpenAI's inherent reasoning abilities. Provides faster results, sometimes at cost of accuracy",
  },
  BASE_REACT_AGENT: {
    text: 'ReAct Agent',
    description: "Reasoning-Action (Re-Act) Agent is capable of increasing the LLM's ability to reason.",
  },
  BASE_PLANNER_AGENT: {
    text: 'Planner Agent',
    description: 'Planner Agent devises a plan first then proceeds with execution. Helps improve accuracy',
  },
  BASE_PLANNER_REPLANNER_AGENT: {
    text: 'Planner - Replanner Agent',
    description:
      'Planner - Replanner Agent devises a plan first then proceeds with execution. Upon execution, it checks if the result is satisfactory, if not, it creates an update plan of action. Helps improve accuracy at the cost of execution time and tokens',
  },
  BASE_LATS_AGENT: {
    text: 'Tree-Of-Thought Agent',
    description:
      'Tree-Of-Thought Agent tries out many possible solutions, step by step, then finds the most appropriate answer. This improves accuracy, at a significant cost to execution time and tokens',
  },
};
