import { css } from '@linaria/core';

const form = css``;

const formCheckbox = css`
  width: 100%;
  color: var(--color-gale-gray) !important;
  .ant-form-item-control-input-content {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
  }
`;

const uploadFileContainer = css`
  .ant-upload-drag {
    background-color: #1e1e1e;
    border: 2px dashed #3a3a3a;
    border-radius: 12px;
    color: #b0b0b0;
    transition: all 0.3s ease;

    &:hover {
      border-color: #666;
    }

    .ant-upload-text {
      font-size: 14px !important;
      color: #dcdcdc;
    }
  }
  }
`;

const uploadedFileCard = css`
  width: 100%;
  border-radius: 12px;
  padding: 0;

  .ant-card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 24px !important;
  }
`;

const uploadGuideContainer = css`
  color: #d0d0d0;
  font-size: 12px;
  padding: 15px 0px;
  p {
    margin-bottom: 0 !important;
  }
`;

const guideLink = css`
  color: #60aaff;
  text-decoration: none;
  font-weight: 500;
  transition:
    color 0.3s ease,
    transform 0.3s ease;

  &:hover {
    text-decoration: underline;
    color: #1a8fff;
    transform: translateY(-2px);
  }
`;

const fileName = css`
  font-size: 14px;
  color: black;
  font-weight: 500;
  flex-grow: 1;
  text-align: left;
  word-break: break-all;
`;

const deleteIcon = css`
  color: red;
  font-size: 18px;
  cursor: pointer;
`;

const uploadIcon = css`
  font-size: 32px !important;
  color: #dcdcdc;
`;

const analyzingText = css`
  text-align: center;
  color: #888;
`;

const textAreaStyle = css`
  ::placeholder {
    color: gray !important; /* Change this color as needed */
    opacity: 1;
  }
`;

const selectedFileTitle = css`{
  font-size: 14px;
  font-weight: 600;
  color: var(--color-gale-gray);
  margin-bottom: 6px;
  display: block;
}`;

export default {
  form,
  formCheckbox,
  uploadFileContainer,
  uploadedFileCard,
  fileName,
  deleteIcon,
  uploadIcon,
  analyzingText,
  uploadGuideContainer,
  guideLink,
  textAreaStyle,
  selectedFileTitle,
};
