export const CLUSTERING_TYPE = (acceptAll) => {
  if (acceptAll) {
    return {
      ACQUISITION_CRM_LOOKALIKES: 'Acquisition CRM Lookalikes',
      ACQUISITION_NET_NEW_STRATEGIC_TARGET: 'Acquisition Net New Strategic Target',
      CRM_PRIORITIZE_REACH: 'CRM Prioritize Reach',
      CRM_PRIORITIZE_360_INSIGHTS: 'CRM Prioritize 360 Insights',
    };
  } else {
    return {
      ACQUISITION_NET_NEW_STRATEGIC_TARGET: 'Acquisition Net New Strategic Target',
    };
  }
};
export const ACQUISITION_CRM_LOOKALIKES = 'ACQUISITION_CRM_LOOKALIKES';
export const TRANSUNION = 'TRANSUNION';
export const E1 = 'E1';
export const FIRST_PARTY = 'FIRST_PARTY';
export const EXCLUDED_E1_FEATURES = ['demographics'];
export const LOOKALIKE = 'LOOKALIKE';
export const EXCLUDED_DEMOGRAPHIC_FEATURES = [
  'age_ranges',
  'exact_age',
  'gender_female',
  'education',
  'bp_ranges',
  'hhi_range',
  'net_asset_value',
  'est_household_income',
];

export const CLUSTER_COLORS = [
  '#5470C6',
  '#91CC75',
  '#FAC858',
  '#EE6666',
  '#73C0DE',
  '#3BA272',
  '#FC8452',
  '#9A60B4',
  '#EA7CCC',
  '#3EAF7C',
  '#E57373',
  '#81C784',
  '#64B5F6',
  '#FFB74D',
  '#4DB6AC',
  '#BA68C8',
  '#7986CB',
  '#F06292',
  '#FFD54F',
  '#4FC3F7',
];

export const BLUE_SHADES = [
  '#CCE5FF', // Light Blue
  '#B3D8FF',
  '#99CCFF',
  '#80BFFF',
  '#66B3FF',
  '#4DA6FF',
  '#3399FF',
  '#1A8CFF',
  '#007FFF',
  '#0072E5',
  '#0066CC',
  '#0059B3',
  '#004D99',
  '#004080',
  '#003366',
  '#00264D',
  '#001A33',
  '#000D1A',
  '#000814',
  '#000311', // Dark Blue
];
export const FOGO_ACQUISTION =
  "We are developing customer-centric clusters for Fogo De Chao, a Brazilian Steakhouse, with the goal of identifying high-value customers. These clusters should capture distinct groups of customers based on their dining habits, spending patterns, demographics, and any other relevant behaviours. Once these high-value customer clusters are identified, we aim to find similar 'look-alike' audiences within the broader US population. The final output should include detailed cluster descriptions and personas that highlight the unique characteristics, preferences, and motivations of these customers, along with strategies for targeting these look-alike groups.";

export const FOGO_CRM =
  'We are creating customer-centric clusters for Fogo De Chao, a Brazilian Steakhouse, with the goal of identifying and profiling both high-value customers and those with the potential to become high-value customers. The objective is to retain high-value customers and develop strategies to nurture and elevate customers who show potential to increase their value. Analyze the clusters, focusing on their frequency of visits, spending patterns, and any other relevant behavioural or demographic patterns. Provide detailed descriptions and personas that capture the unique characteristics, preferences, and motivations of these two customer segments. Additionally, offer insights on actionable strategies to effectively engage, retain, and grow these groups.';

export const CHILIS_ACQUISTION =
  "We are developing customer-centric clusters for Chili's, an American casual dining restaurant, with the goal of identifying high-value customers. These clusters should capture distinct groups of customers based on their dining habits, spending patterns, demographics, and any other relevant behaviours. Once these high-value customer clusters are identified, we aim to find similar 'look-alike' audiences within the broader US population. The final output should include detailed cluster descriptions and personas that highlight the unique characteristics, preferences, and motivations of these customers, along with strategies for targeting these look-alike groups.";

export const CHILIS_CRM =
  "We are creating customer-centric clusters for Chili's, an American casual dining restaurant, with the goal of identifying and profiling both high-value customers and those with the potential to become high-value customers. The objective is to retain high-value customers and develop strategies to nurture and elevate customers who show potential to increase their value. Analyze the clusters, focusing on their frequency of visits, spending patterns, and any other relevant behavioural or demographic patterns. Provide detailed descriptions and personas that capture the unique characteristics, preferences, and motivations of these two customer segments. Additionally, offer insights on actionable strategies to effectively engage, retain, and grow these groups.";

export const DEFAULT_AUDIENCE_NAME = {
  ACQUISITION_CRM_LOOKALIKES: 'Entire TU Audience (272M)',
  ACQUISITION_NET_NEW_STRATEGIC_TARGET: 'Entire TU Audience (272M)',
  CRM_PRIORITIZE_REACH: 'CRM Audience only (XM)',
  CRM_PRIORITIZE_360_INSIGHTS: 'CRM Audience mapped to TU (XXM)',
};

export const CLUSTER_TOOL_TIPS = {
  ACQUISITION_NET_NEW_STRATEGIC_TARGET:
    'Use this method to find subsegments of the population who are likely to align with a 3rd party behavioral or attitude trait. For instance, subsegmenting those who self-report that they buy a certain product category, by a range of attitudes about how they like to be advertised to. (Clusters people in the TransUnion contact list who may or may not be in your client’s CRM list. Allows you to cluster on traits from 1P, TransUnion demographics, and 3P sources. Target is a 3P trait.)',
  ACQUISITION_CRM_LOOKALIKES:
    'Use this method to find subsegments of the population who look like your current customer base. For instance, segmenting consumers who look like your High Frequency customers, into subsegments based on their current preferred competitors. (Clusters people in the TransUnion contact list who may or may not be in your client’s CRM list. Allows you to cluster on traits from 1P, TransUnion demographics, and 3P sources. Target is a 3P trait.)',
  CRM_PRIORITIZE_REACH:
    'Use this method to find subsegments of client’s customer base, using only traits from the CRM data set. Focusing clustering on only this data set ensures that every single CRM contact is placed in a cluster, so they can be targeted or suppressed. (Clusters the people in your CRM list, not the rest of the TransUnion contact list. Allows only traits from 1P data source.)',
  CRM_PRIORITIZE_360_INSIGHTS:
    'Use this method to find subsegments of the overall consumer population, when you want to blend 1P traits with traits from 3P sources in your model. For instance, if you want to subsegment high LTV customers based on their attitudes toward competitor brands. CRM contacts who do not match the TransUnion ID graph will be excluded from the resulting clusters and any activation of them. (Clusters the people in your CRM list, not the rest of the TransUnion contact list. Allows traits from 1P, TransUnion Demographics, and 3P sources.)',
};
