import { Col, DatePicker, Form, Input, Popover, Row, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/basic';
import { InputNo } from 'src/components/basic/Input';
import LoadingSpinner from 'src/components/loadingSpinner';
import { formatString } from 'src/lib/utils';
import { MEDIA_PLAN_GRANULARITY } from '../../../constants/constants';
import './CreateMediaPlanForm.less';
import useCreateMediaPlan from './useCreateMediaPlan';

const { RangePicker } = DatePicker;

const CreateMediaPlanForm = ({
  mediaPlanData,
  metaData,
  setStep,
  setMediaPlanData,
  mediaplanCreated,
  editScenario,
  reviewScreen,
}) => {
  const { configureLoading, loading, form, onFinish, audienceOptions } = useCreateMediaPlan({
    mediaPlanData,
    setStep,
    setMediaPlanData,
  });
  const { t } = useTranslation();

  if (loading) {
    return <LoadingSpinner centered />;
  }
  return (
    <div className="createMediaPlanContainer">
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item>
          <div
            style={{
              flexDirection: 'row',
              justifyContent: 'end',
              display: 'flex',
            }}
          >
            <PrimaryButton
              onClick={() => {
                if (reviewScreen) {
                  setStep(1);
                } else {
                  form.submit();
                }
              }}
              className="small"
              loading={configureLoading}
            >
              {t('next')}
            </PrimaryButton>
          </div>
        </Form.Item>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="mediaplan_name"
              label={t('mediaplan.media_plan_name')}
              rules={[{ required: true, message: 'Media Plan Name is required' }]}
              extra={t('mediaplan.media_plan_name_extra')}
            >
              <Input disabled={editScenario || mediaplanCreated || reviewScreen} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="audience"
              label={t('mediaplan.audience')}
              rules={[{ required: true, message: 'Please select the audience' }]}
              extra={t('mediaplan.audience_extra')}
            >
              <Select
                mode="multiple"
                showArrow
                disabled={editScenario || mediaplanCreated || reviewScreen}
                maxTagCount="responsive"
                maxTagPlaceholder={(omittedValues) => (
                  <Popover
                    color="black"
                    overlayClassName="tagPopover"
                    content={
                      <div className="tagWrapper">
                        {omittedValues.map(({ label }, id) => (
                          <div className={`tagText ${mediaplanCreated && 'tagTextDisabled'}`} key={id}>
                            {label}&nbsp;&nbsp;&nbsp;
                          </div>
                        ))}
                      </div>
                    }
                  >
                    <span>+{omittedValues.length}</span>
                  </Popover>
                )}
              >
                {audienceOptions?.map((audience, idx) => {
                  let audienceType = audience?.audience_config?.[0]?.audience_type;
                  if (audienceType === 'CLUSTER_BASED') {
                    const clusterProject = audience?.audience_config?.[0];
                    return (
                      <Select.OptGroup
                        label={
                          <div style={{ fontSize: '14px', color: 'gray', fontWeight: 'bold' }}>
                            <div>{clusterProject?.name}</div>
                          </div>
                        }
                        key={idx}
                      >
                        {clusterProject?.audience_profiles?.map((profile) => {
                          return (
                            <Select.Option value={profile?.id} key={profile?.id}>
                              {profile?.persona_name}
                            </Select.Option>
                          );
                        })}
                      </Select.OptGroup>
                    );
                  } else {
                    return (
                      <Select.OptGroup
                        label={
                          <div style={{ fontSize: '14px', color: 'gray', fontWeight: 'bold' }}>
                            <div>{audience?.name}</div>
                          </div>
                        }
                        key={idx}
                      >
                        {audience?.audience_config?.map((config) => {
                          const profileId = config?.audience_profiles[0]?.id;
                          return (
                            <Select.Option value={profileId} key={profileId}>
                              &nbsp;&nbsp;&nbsp;{config?.name}
                            </Select.Option>
                          );
                        })}
                      </Select.OptGroup>
                    );
                  }
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="scenario_name"
              label={t('mediaplan.scenario_name')}
              rules={[{ required: true, message: 'Media Plan Name is required' }]}
              extra={t('mediaplan.scenario_name_extra')}
            >
              <Input disabled={reviewScreen} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="dateRange"
              label={t('mediaplan.start_end_date')}
              extra={t('mediaplan.start_end_date_extra')}
              rules={[{ required: true, message: 'Date Range is required' }]}
            >
              <RangePicker style={{ width: '100%' }} disabled={editScenario || mediaplanCreated || reviewScreen} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="kpis"
              label={t('mediaplan.primary_goal')}
              rules={[{ required: true, message: 'Please select the primary goal' }]}
              extra={t('mediaplan.primary_goal_extra')}
            >
              <Select showArrow disabled={editScenario || mediaplanCreated}>
                {metaData?.kpis?.map((goal) => {
                  return (
                    <Select.Option value={goal} key={goal}>
                      {goal}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="budget"
              label={t('mediaplan.specify_your_budget')}
              extra={t('mediaplan.specify_your_budget_extra')}
              rules={[{ required: true, message: 'Budget is required' }]}
            >
              <InputNo
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} // Format to USD with commas
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')} // Remove dollar sign and commas for parsing
                min={0}
                disabled={reviewScreen}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="granularity"
              label={t('mediaplan.granularity')}
              extra={t('mediaplan.granularity_extra')}
              rules={[{ required: true, message: 'Optimisation Granularity is required' }]}
            >
              <Select disabled={reviewScreen}>
                {MEDIA_PLAN_GRANULARITY.map((item) => {
                  return (
                    <Select.Option key={item} value={item}>
                      {formatString(item)}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CreateMediaPlanForm;
