import { css } from '@linaria/core';

const layoutContainer = css`
  position: relative;
`;

const title = css`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0.1rem;
  color: black;
`;

const hamburgerContainer = css`
  padding: 0.8rem;
  height: 50px;
  display: flex;
  justify-content: space-between;
  background-color: black;
  z-index: 1000;
  align-items: center;
  position: sticky;
  left: 0;
  top: 0;
`;

const chatContainer = css`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
  margin-left: 0;
  background-color: var(--color-charcoal-surface-2);
  @media screen and (min-width: 768px) {
    width: calc(100vw - 320px);
    margin-left: 320px;
  }
`;

const socialInsightsChatContainer = css`
  margin-top: 0px !important;
  padding-top: 32px !important;
`;

const messageContainer = css`
  flex: 1;
  overflow-y: scroll;
  padding: 1rem;
  padding-left: 1.5rem;
  margin-top: 0px;

  @media screen and (min-width: 768px) {
    margin-top: 5px;
    padding-bottom: 32px;
  }
  @media screen and (min-width: 1200px) {
    padding: 0 15%;
    padding-bottom: 32px;
  }
`;

const mainContainer = css`
  position: fixed;
  top: 60px;
  width: 100vw;
  overflow: auto;
  display: flex;
  height: calc(100vh - 50px);
  height: calc(100dvh - 50px);
  flex-direction: column;
  transition: transform 0.2s ease-in;
  background: var(--color-charcoal-surface-2);
  @media screen and (min-width: 768px) {
    top: 0px;
    height: 100vh;
  }
`;

const helpImage = css`
  display: flex;
  color: var(--color-white);
  justify-content: center;
  margin-top: 1rem;
  font-size: 1.429rem;
  font-weight: 700;
`;

const helpContainer = css`
  margin: 7vh 0;
  @media screen and (max-width: 768px) {
    margin: 1rem 0;
  }
`;

const chatLabel = css`
  font-size: 14px;
  font-weight: 600;
  margin-top: 0.3rem;
`;

const chatDesc = css`
  color: var(--color-white);
  padding-left: 32px;
  font-weight: 400;
  width: calc(100% - 3.4rem);
  margin-bottom: 0.5rem;
`;

const chatMessage = css``;

const inputLoader = css`
  right: 16%;
  position: absolute;
  top: 30%;
  padding: 4px 7px;
  border-radius: 0.5rem;
  background: black;
  @media screen and (max-width: 768px) {
    right: 7%;
  }
`;

const accountIcon = css`
  padding: 6px 9px;
  background: #788f9c;
  border-radius: 26px;
`;

const noMessageContainer = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const conversationStarterContainer = css`
  flex-grow: 1;
  display: flex;
  flex-direction: column-reverse;
`;

const chatMessageContainer = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const messageLabel = css`
  font-weight: 600;
  color: black;
  margin-bottom: 0.2rem;
  font-size: 16px;
`;

const reactionContainer = css`
  margin-top: 0.2rem;
  font-size: 1rem;
  & > * {
    opacity: 0.7;
    transition: transform 0.2s;
    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }
  }
`;

const reactionIcon = css`
  cursor: pointer;
  padding-right: 3px;
`;

const copiedIcon = css`
  padding-right: 3px;
`;

const markdown = css`
  display: contents;
  flex-direction: column;
  color: #2c2c2c;
  font-weight: 400;

  table {
    border-collapse: collapse;
    border-radius: 5px;
    overflow: hidden;
  }
  th {
    padding: 6px 13px;
    background: black;
    color: white;
    font-weight: 600;
  }
  td {
    background: #eeeeee;
    padding: 6px 13px;
    color: black;
    font-weight: 400;
  }
  p {
    font-size: 1.143rem !important;
    margin-bottom: 0.3rem;
  }
`;

const reactionContainerDisabled = css`
  opacity: 0.75;
  pointer-events: none;
`;

const clearChatButton = css`
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
`;

const streamingResponseAccordianContainer = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 6px;
`;

const streamingResponseAccordianActionText = css`
  font-size: 1rem;
  font-weight: 600;
  color: gray;
  overflow: auto;
`;

const actionParserContainer = css`
  font-weight: 700;
  color: gray;
  white-space: pre;
  overflow: auto;
  max-height: 350px;
`;

const outputLabel = css`
  font-weight: 600;
  font-size: 0.85rem;
  margin-top: 0.5rem;
`;

const inputLabel = css`
  font-weight: 600;
  font-size: 0.85rem;
`;

const actionContainer = css`
  background: #f9f9f9;
  padding: 5px 10px;
  border-radius: 5px;
`;

const userContainer = css`
  font-size: 1.1rem;
  margin-bottom: 1rem;
  font-weight: 600;
  cursor: pointer;
`;

const aclhemyAiLogoContainer = css`
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  margin-right: 12px;
  span {
    font-size: 1rem;
    font-weight: 500;
  }
`;

const footer = css`
  color: white;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: 500;
  width: 100%;
  padding: 1rem;
  display: flex;
  background-color: black;
  z-index: 1000;
  align-items: center;
  position: absolute;
  bottom: 0;
`;

const sideNav = css`
  position: fixed;
  left: 0;
  top: 0;
  padding: 16px;
  width: 320px;
  height: 100vh;
  color: black;
  background: #212121;
  z-index: 2;
  transition: all 0.2s ease-in;
`;

const questionTabsContainer = css`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  z-index: 100;
  transition: all 0.2s ease-in;

  .ant-card-body {
    background: var(--color-charcoal-surface-1);
    border-bottom: 1px solid var(--color-charcoal-black);
  }
  .ant-card-bordered {
    width: 100%;
    border-radius: 0;
    border: none;
  }
  .ant-tabs-tab:hover {
    color: var(--color-white);
  }
  .ant-tabs-nav::before {
    border-bottom: 1px solid var(--color-60-gray) !important;
  }
  .ant-tabs {
    padding-bottom: 8px;
    color: var(--color-white);
    h3 {
      color: var(--color-white);
    }
    font-size: 13px;
    font-weight: 400;
  }

  .ant-tabs-content-holder {
    max-height: 0;
    overflow: hidden;
    transition: all 0.4s ease-in;
  }

  .ant-btn-link {
    display: flex;
    align-items: center;
    font-size: 0.857rem;
    color: var(--color-40-black);
    padding: 0px;
    &:hover {
      color: var(--color-white);
    }
    .more-info-icon {
      margin-right: 4px;
    }
  }

  &.expanded {
    .ant-tabs-content-holder {
      max-height: 1000px;
    }
  }

  @media screen and (min-width: 768px) {
    width: calc(100% - 320px);
    margin-left: 320px;
    .ant-card-body {
      padding: 50px 15% 24px;
    }
  }
`;

const questionTabExpandBtn = css`
  cursor: pointer;
  position: absolute;
  bottom: 0px;
  right: 16px;
  background: transparent;
  border: none;
  width: 40px;
  height: 40px;
  z-index: 1;
  padding: 0;
  transform: rotate(0deg);
  transition: all 0.2s ease-in;
  color: var(--color-white);

  &:hover {
    color: var(--color-primary);
  }

  &.expanded {
    transform: rotate(180deg);
  }
  @media screen and (min-width: 768px) {
    right: 14%;
  }
`;

const openNavBarIcon = css`
  position: absolute;
  top: 50%;
`;

const navBarContainer = css`
  display: flex;
  flex-direction: column;
`;

const navBarContainerWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 102px);
  overflow-y: auto;
`;

const fileListHeader = css`
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
`;

const fileContainer = css`
  padding-bottom: 0.3rem;
  display: flex;
`;

const file = css`
  display: flex;
  flex-direction: column;
  padding: 0.7rem 1rem;
  border-radius: 6px;
  color: white;
  background: #484545;
`;

const previewFileLoader = css`
  top: 64%;
`;

const previewFileUploader = css`
  top: 61%;
`;

const streamingResponseContainer = css`
  width: 100%;
  max-height: 30rem;
  overflow-y: auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
`;

const loadingDeleteContainer = css`
  opacity: 0.8;
  pointer-events: none;
  cursor: not-allowed;
`;

const editButton = css`
  background: #db5a4b !important;
  color: white !important;
  border: 0 !important;
  font-weight: 600 !important;
  height: 2.2rem !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
`;

const hiddenReactions = css`
  & > * {
    visibility: hidden;
  }
`;

const timestampTime = css`
  float: right;
  visibility: unset;
  color: #1c1c1c;
  font-size: 0.7rem;
`;

const timeTooltip = css`
  border-radius: 0.5rem;
  padding-bottom: 0.5rem !important;
  .ant-tooltip-content {
    border-radius: 0.5rem;
    font-size: 10px;
    .ant-tooltip-inner {
      border-radius: 0.5rem;
      margin: 5px;
      display: flex;
      align-items: center;
    }
  }
`;

const skeleton = css`
  .ant-skeleton-title {
    display: none;
  }
  .ant-skeleton-paragraph {
    margin-top: 0;
  }
  li {
    height: 10px !important;
  }
  .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-top: 1rem;
  }
`;

const groupChatContainer = css`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const chatLoader = css`
  width: 70%;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  align-self: center;
  .ant-skeleton {
    padding: 11px;
    .ant-skeleton-content {
      .ant-skeleton-title {
        width: 20% !important;
      }
    }
  }
`;

const sessionLoader = css`
  height: 90%;
  margin-left: 320px;

  @media (max-width: 768px) {
    margin-left: 0px !important;
  }
`;

const disclaimer = css`
  font-size: 1rem;
  padding: 0px 10px;
`;

const toggleButton = css`
  &.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #171717 !important;
  }
  .ant-radio-button-wrapper {
    border: none;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: none;
  }
  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: none !important;
  }
  .ant-radio-button-wrapper:first-child {
    border-radius: 5px 0 0 5px;
  }
  .ant-radio-button-wrapper:last-child {
    border-radius: 0 5px 5px 0;
  }
`;

const navBarLogo = css`
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0px 10px;
`;

const chatListItem = css`
  display: flex;
  font-size: 15px;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border-radius: 4px
  cursor: pointer;
  .anticon svg {
    display: inline-block;
  }
  div {
    .anticon svg {
      display: inline-block;
    }
  }
  &:hover {
    .anticon svg {
      display: inline-block;
    }
    background: #e0e0e0;
  }
`;

const activeListItem = css`
  background: #e0e0e0;
`;

const sessionLoadingLoader = css`
  display: flex;
  justify-content: center;
`;

const popOverContainer = css`
  .ant-popover-inner {
    width: fit-content;
    border-radius: 5px;
  }
`;

const clientIcon = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  #hoverImage {
    display: none;
  }
  :hover {
    #normalImage {
      display: none;
    }
    #hoverImage {
      display: inline-block;
    }
  }
`;

const llmModelSelect = css`
  position: relative;
  top: 10px;
  left: 350px;
  z-index: 999999 !important;
  overflow: auto !important;
  max-width: 200px;
`;

const customDropdownOverlay = css`
  .ant-dropdown-menu {
    background-color: var(--color-charcoal-surface-1) !important;
    color: var(--color-white) !important;
  }
  .ant-dropdown-menu-submenu-arrow-icon {
    color: inherit !important;
  }

  &.ant-dropdown-menu-submenu-popup {
    inset: 44px auto auto 480px !important;
    border-radius: 8px !important;

    @media (max-width: 768px) {
      inset: 104px auto auto 160px !important;
    }

    ul li {
      margin-bottom: 0px !important;
    }
  }
`;

const taskCreateContainer = css`
  @media screen and (max-width: 768px) {
    width: 100% !important;
    .ant-modal-body {
      padding: 16px 0px !important;
    }
  }
  @media screen and (max-width: 390px) {
    .ant-row {
      display: block !important;
    }
    .ant-col {
      margin-bottom: 24px;
      max-width: 100% !important;
    }
    .ant-col:last-child {
      margin-bottom: 0;
    }
  }
`;

export default {
  title,
  chatContainer,
  hamburgerContainer,
  messageContainer,
  helpContainer,
  helpImage,
  chatLabel,
  chatDesc,
  chatMessage,
  inputLoader,
  accountIcon,
  disclaimer,
  noMessageContainer,
  conversationStarterContainer,
  chatMessageContainer,
  messageLabel,
  reactionContainer,
  reactionIcon,
  copiedIcon,
  markdown,
  reactionContainerDisabled,
  clearChatButton,
  streamingResponseAccordianContainer,
  streamingResponseAccordianActionText,
  actionParserContainer,
  outputLabel,
  inputLabel,
  chatLoader,
  actionContainer,
  userContainer,
  mainContainer,
  aclhemyAiLogoContainer,
  footer,
  sideNav,
  openNavBarIcon,
  layoutContainer,
  navBarContainer,
  fileListHeader,
  fileContainer,
  file,
  previewFileLoader,
  streamingResponseContainer,
  previewFileUploader,
  loadingDeleteContainer,
  editButton,
  hiddenReactions,
  timestampTime,
  timeTooltip,
  skeleton,
  groupChatContainer,
  sessionLoader,
  toggleButton,
  navBarContainerWrapper,
  navBarLogo,
  chatListItem,
  activeListItem,
  sessionLoadingLoader,
  popOverContainer,
  clientIcon,
  questionTabsContainer,
  questionTabExpandBtn,
  socialInsightsChatContainer,
  llmModelSelect,
  customDropdownOverlay,
  taskCreateContainer,
};
