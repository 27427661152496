import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import appUrls from 'src/constants/appUrls';
import { getErrorMessageFromResponse, shortenStr } from 'src/lib/utils';
import { ClusterToolApiServices } from '../../services/ClusterToolApiServices';

export default function useClusterFeatureHook({ match, history, typeConfigId, setCurrentStep }) {
  const clientSpaceId = match?.params?.clientId;
  const clusterConfigId = match?.params?.clusterId || typeConfigId;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const audienceConfigId = queryParams.get('audience-config-id');
  const [topCorrelation, setTopCorrelation] = useState([]);
  const [loading, setLoading] = useState(true);
  const [configureLoading, setConfigureLoading] = useState(false);
  const [initialValues, setInitialValues] = useState();
  const [viewMode, setViewMode] = useState(false);
  const [audienceRole, setAudienceRole] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const getGaugeValue = (value) => {
    if (value === 'Do Not Proceed') return 0.5;
    if (value === 'Proceed With Caution') return 1.5;
    if (value === 'Proceed') return 2.5;
  };

  const getClusterConfig = async () => {
    try {
      setLoading(true);
      const res = await ClusterToolApiServices.getClusterConfig(clusterConfigId);
      const state = res?.data?.state;
      const path = location.pathname;
      let checkbox_disable = false;
      if (path.includes('review-audience')) {
        checkbox_disable = true; // Remove 'let' to modify the outer variable
      }
      if (state === 'CREATING_AUDIENCES' || checkbox_disable) {
        setViewMode(true);
      } else if (path.includes('edit-audience')) {
        setEditMode(true);
      }
      const topCorrelation = await ClusterToolApiServices.getTopCorrelation(clusterConfigId);
      setTopCorrelation(topCorrelation.data);
      const salientFeatures = res?.data?.salient_features.map((item) => ({
        ...item,
        is_user_selected:
          ['CREATING_AUDIENCES', 'AUDIENCES_CREATED'].includes(state) && !res?.data?.auto_select_salient_features
            ? item.is_user_selected
            : item.is_algorithm_recommended,
      }));

      const modelQualityDetails = res?.data?.model_quality_details.reduce((acc, item) => {
        acc[item.metric] = item.score;
        return acc;
      }, {});

      setInitialValues({
        salient_features: salientFeatures,
        algorithm_selected_features: salientFeatures.filter((item) => item.is_algorithm_recommended),
        model_quality: modelQualityDetails['Model Quality'],
        cross_validation_auc: modelQualityDetails['AUC'],
        target_feature: res?.data?.target_feature,
        state: res?.data?.state,
      });
      setAudienceRole(res?.data?.role);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: shortenStr(msg, 100),
        placement: 'bottomRight',
      });
    }
  };

  const generateCluster = async () => {
    try {
      setConfigureLoading(true);
      const payload = {
        ...initialValues,
        audience_config_id: audienceConfigId,
        trigger_sample_cluster_creation: true,
      };
      await ClusterToolApiServices.createClusterConfig(payload);
      setConfigureLoading(false);
      history.push(
        match.path.includes('audience-tool')
          ? appUrls.AUDIENCE_TOOL(clientSpaceId)
          : appUrls.CLUSTER_TOOL(clientSpaceId)
      );
    } catch (error) {
      setConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: shortenStr(msg, 100),
        placement: 'bottomRight',
      });
    }
  };

  const onFeatureSelect = (index, isSelected) => {
    const updatedFeatures = [...initialValues.salient_features];
    updatedFeatures[index].is_user_selected = isSelected;
    setInitialValues({ ...initialValues, salient_features: updatedFeatures });
  };

  const goBack = () => {
    match.path.includes('audience-tool')
      ? setCurrentStep(1)
      : history.push(appUrls.EDIT_CLUSTER(clientSpaceId, clusterConfigId, audienceConfigId));
  };

  const goNext = () => {
    setCurrentStep(3);
  };

  useEffect(() => {
    getClusterConfig();
  }, []);

  return {
    loading,
    configureLoading,
    initialValues,
    generateCluster,
    onFeatureSelect,
    goBack,
    viewMode,
    audienceRole,
    clusterConfigId,
    topCorrelation,
    getGaugeValue,
    editMode,
    goNext,
  };
}
