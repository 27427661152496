export class CustomGptService {
  static formatInitialValues(data) {
    let obj = {};
    let llmObj = {};
    let agentObj = {};
    const agentConfigArr = Object.keys(data?.agent_config);
    const llm_config = Object.keys(data?.llm_config);
    agentConfigArr.forEach((item) => {
      agentObj[item] = data?.agent_config?.[item]?.default_value;
    });
    llm_config.forEach((item) => {
      llmObj[item] = data?.llm_config?.[item]?.default_value;
    });
    obj = {
      agent_config: agentObj,
      llm_config: llmObj,
    };
    return obj;
  }

  static validations(type, name, data) {
    const rules = [];
    if (type?.required) {
      rules.push({
        required: true,
        message: `${name} is required.`,
      });
    }
    if (type?.max_length_number) {
      rules.push({
        type: 'number',
        min: data?.min_value,
        max: data?.max_value,
        message: `Value should be between ${data?.min_value} and ${data?.max_value}`,
      });
    }
    if (type?.max_length_string) {
      rules.push({
        type: 'string',
        min: data?.min_length,
        max: data?.max_length,
        message: `${name} should be between ${data?.min_length} to ${data?.max_length} character.`,
      });
    }
    if (type?.bot_name) {
      rules.push({
        pattern: /^(?!.* {2})[\w\s]+$/,
        message: 'Remove special charachters and trailing spaces',
      });
    }
    return rules;
  }

  static formatCreateGptPayload(data) {
    let obj = {};
    for (let key in data) {
      if (data[key]) {
        obj[key] = data[key];
      }
    }
    // Ensure only the model name (not provider) is sent to the API
    if (obj.llm_config?.model_name && Array.isArray(obj.llm_config.model_name)) {
      obj.llm_config.model_name = obj.llm_config.model_name[1]; // Extract only model_name
    }
    return obj;
  }

  static formatSqlToolInitialValues(capabilityData, configData) {
    let obj = {};
    if (capabilityData?.fields_config) {
      obj = { ...capabilityData?.fields_config };
    } else {
      for (let key in configData?.config) {
        if (key !== 'tables') {
          obj[key] = configData?.config?.[key]?.default_value;
        }
      }
    }
    return obj;
  }

  static formatGdriveFiles(data) {
    let obj = {};
    data.forEach((item) => {
      obj[item.file_id] = item;
    });
    return obj;
  }

  static formatBulkUploadValues(data) {
    let obj = {};
    data?.data?.forEach((ele) => {
      if (obj[ele.Connection]) {
        obj[ele.Connection].push(ele);
      } else {
        obj[ele.Connection] = [ele];
      }
    });
    let payload = {
      tables: [],
    };
    Object.keys(obj).forEach((ele) => {
      let connections = obj[ele];
      let tables = {};
      connections?.forEach((item) => {
        if (tables[item?.['Table name']]) {
          tables[item?.['Table name']].push(item);
        } else {
          tables[item?.['Table name']] = [item];
        }
      });
      Object.keys(tables).forEach((tableEle) => {
        let is_active = false;
        let tablearr = tables[tableEle];
        if (tablearr[0]?.['Is active']?.toUpperCase() === 'TRUE' || tablearr[0]?.['Is active']?.toString() === '1') {
          is_active = true;
        }

        let tempObj = {
          name: tablearr[0]?.['Table name'],
          schema: tablearr[0]?.['Schema'],
          connection_id: tablearr[0]?.['Connection'],
          description: tablearr[0]?.['Description'],
          columns: [],
          is_active: is_active,
        };
        tablearr.forEach((tableItem) => {
          let is_active = false;
          if (
            tableItem?.['Column is active']?.toUpperCase() === 'TRUE' ||
            tableItem?.['Column is active']?.toString() === '1'
          ) {
            is_active = true;
          }
          tempObj.columns.push({
            name: tableItem['Column name'],
            description: tableItem['Column description'],
            type: tableItem['Column type'],
            is_active: is_active,
          });
        });
        payload.tables.push(tempObj);
      });
    });
    return payload;
  }

  static dateConverter = (value) => {
    const date = new Date(value);
    const options = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    return date.toLocaleDateString('en-US', options);
  };

  static formatCategories = (categories) => {
    if (categories && categories.length) {
      return categories.map((category) => category.replace(/_/g, ' ')).join(', ');
    }
    return '';
  };
}
