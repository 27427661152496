import React from 'react';
import SemKeywordsTable from './SemKeywordsTable';
import useMediaPlanSemKeyword from './useMediaPlanSemKeyword';

const MediaPlanSemKeyword = ({ data }) => {
  const { tab, tabData } = useMediaPlanSemKeyword({ data });
  return <>{tab && <SemKeywordsTable tabData={tabData} />}</>;
};

export default MediaPlanSemKeyword;
