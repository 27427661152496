import { Checkbox } from 'antd';
import React from 'react';
import { numberWithCommas } from 'src/lib/utils';

const useAdGroupCollapse = ({ keywordData, setkeywordData, adGroup, setSelectedData, selectedData, reviewScreen }) => {
  const columns = [
    {
      title: '',
      dataIndex: 'selected',
      key: 'selected',
      render: (text, record) => {
        return (
          <Checkbox
            checked={record.selected}
            disabled={reviewScreen}
            onChange={(e) => {
              let temp = [...keywordData[adGroup?.name]];
              let tempIdx = temp.findIndex((item) => item.id === record.id);
              console.log(text);
              temp[tempIdx].selected = e.target.checked;
              if (e.target.checked) {
                if (selectedData.unselect.includes(record.id)) {
                  const array = selectedData.unselect;
                  const index = array.indexOf(record.id);
                  if (index > -1) {
                    array.splice(index, 1);
                  }
                  setSelectedData({
                    select: [...selectedData.select],
                    unselect: array,
                  });
                } else {
                  setSelectedData({
                    select: [...selectedData.select, record.id],
                    unselect: selectedData.unselect,
                  });
                }
              } else {
                if (selectedData.select.includes(record.id)) {
                  const array = selectedData.select;
                  const index = array.indexOf(record.id);
                  if (index > -1) {
                    array.splice(index, 1);
                  }
                  setSelectedData({
                    select: array,
                    unselect: [...selectedData.unselect],
                  });
                } else {
                  setSelectedData({
                    select: selectedData.select,
                    unselect: [...selectedData.unselect, record.id],
                  });
                }
              }

              setkeywordData({
                ...keywordData,
                [adGroup?.name]: temp,
              });
            }}
          />
        );
      },
      width: 30,
    },
    {
      title: 'Keyword',
      dataIndex: 'keyword',
      key: 'keyword',
      render: (record) => record,
      width: 150,
    },
    {
      title: 'Avg. Monthly Searches',
      dataIndex: 'avg_monthly_searches',
      key: 'avg_monthly_searches',
      render: (record) => numberWithCommas(Math.round(record)),
      width: 150,
    },
    {
      title: 'Competition',
      dataIndex: 'competition',
      key: 'competition',
      render: (record) => record,
      width: 150,
    },
    {
      title: 'Search Volume Percentage',
      dataIndex: 'search_volume_percentage',
      key: 'search_volume_percentage',
      render: (record) => `${Number.isInteger(record) ? record : parseFloat(record.toFixed(2))}%`,
      width: 150,
    },
    {
      title: 'Low Bid Range',
      dataIndex: 'low_top_of_page_bid',
      key: 'low_top_of_page_bid',
      render: (record) => `$${numberWithCommas(Math.round(record))}`,
      width: 150,
    },
    {
      title: 'High Bid Range',
      dataIndex: 'high_top_of_page_bid',
      key: 'high_top_of_page_bid',
      render: (record) => `$${numberWithCommas(Math.round(record))}`,
      width: 150,
    },
    {
      title: 'Avg. CPC',
      dataIndex: 'average_cpc',
      key: 'average_cpc',
      render: (record) => `$${numberWithCommas(Math.round(record))}`,
      width: 150,
    },
  ];

  return {
    columns,
  };
};

export default useAdGroupCollapse;
