import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import assets from 'src/assets';
import AppUrls from 'src/constants/appUrls';
import { PageLayoutContext } from 'src/context/pageLayoutContext';
import AudienceDetailsComponent from '../audienceManager/components/audienceListing/audienceDetails/AudienceDetailsComponent';
import AudienceListing from '../audienceManager/components/audienceListing/AudienceListing';
import CreateAudience from '../audienceManager/components/createAudience/CreateAudience';
import ClusterFeature from '../clusterTool/components/clusterFeature/ClusterFeature';
import CreateCluster from '../clusterTool/components/createCluster/CreateCluster';
import SelectFeatures from '../clusterTool/components/selectFeatures/SelectFeatures';
import ShowCluster from '../clusterTool/components/ShowCluster/ShowCluster';
import Style from './audienceToolLayout.style';

export default function AudienceToolLayout() {
  const { isNavOpen, inDesktop } = useContext(PageLayoutContext);

  return (
    <>
      <div className={Style.container}>
        <div
          className={Style.layout}
          style={{
            transform: !inDesktop && isNavOpen ? 'translateX(256px)' : 'translateX(0)',
          }}
        >
          <Switch>
            <Route exact path={AppUrls.CREATE_AUDIENCE(':clientId')} component={CreateAudience} />
            <Route exact path={AppUrls.EDIT_AUDIENCE(':clientId', ':audienceId')} component={CreateAudience} />
            <Route exact path={AppUrls.REVIEW_AUDIENCE(':clientId', ':audienceId')} component={CreateAudience} />
            <Route
              exact
              path={AppUrls.AUDIENCE_DETAIL(':clientId', ':audienceId')}
              component={AudienceDetailsComponent}
            />
            <Route exact path={[AppUrls.EDIT_CLUSTER_TOOL(':clientId', ':clusterId')]} component={CreateCluster} />
            <Route exact path={AppUrls.SELECT_FEATURES_TOOL(':clientId', ':clusterId')} component={SelectFeatures} />
            <Route exact path={AppUrls.SHOW_CLUSTER_TOOL(':clientId', ':clusterId')} component={ShowCluster} />
            <Route
              exact
              path={AppUrls.CLUSTER_CONFIG_FEATURES_TOOL(':clientId', ':clusterId')}
              component={ClusterFeature}
            />
            <Route path={AppUrls.AUDIENCE_TOOL(':clientId')} component={AudienceListing} />
            <Redirect to={AppUrls.AUDIENCE_TOOL(':clientId')} />
          </Switch>
        </div>
      </div>
      <div className={Style.decorativeBackgroundImage}>
        <img className="select-disable" alt="decorative element" src={assets.getImage('ClientSpaceWave')} />
      </div>
    </>
  );
}
