import { Modal, notification } from 'antd';
import { useEffect, useState } from 'react';
import assets from 'src/assets';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import CreateClientApiServices from '../services/createClientSpaceApiServices';

const useClientSpaceSelectAppsHook = ({ clientId, getClientData }) => {
  const [loading, setLoading] = useState(true);
  const [addUserModal, setAddUserModal] = useState({
    visible: false,
    data: {},
  });
  const [configureLoading, seConfigureLoading] = useState(false);
  const [appConfigTab, setAppConfigTab] = useState({
    visible: false,
    data: {},
  });
  const [data, setData] = useState({});
  const [appSettingsDrawer, setAppSettingsDrawer] = useState({
    visible: false,
    data: {},
  });

  const microApps = [
    'REPORTING_APP',
    'CLUSTERING_APP',
    'LABELER',
    'MEDIA_PLAN_GENERATOR',
    'AUDIENCE_APP',
    'NEWS_AND_CULTURE_APP',
  ];

  const Images = {
    REPORTING_APP: {
      icon: assets.getImage('MicroappDashboardIcon'),
    },
    SOCIAL_INSIGHTS_APP: {
      icon: assets.getImage('MicroappSocialInsightsIcon'),
    },
    MEDIA_PLANNER_APP: {
      icon: assets.getImage('MicroappMediaPlanIcon'),
    },
    CLUSTERING_APP: {
      icon: assets.getImage('MicroappClusteringIcon'),
    },
    NEWS_AND_CULTURE_APP: {
      icon: assets.getImage('MicroappNewsCultureIcon'),
    },
    INTERACTIVE_PERSONAL_APP: {
      icon: assets.getImage('MicroappInteractivePersonaIcon'),
    },
    PRIVATE_WORLD_DATA_CHAT_APP: {
      icon: assets.getImage('MicroappPrivateWorldDataChatIcon'),
    },
    MEDIA_PLANNER_TOOL: {
      icon: assets.getImage('MicroappMediaPlanIcon'),
    },
    AUDIENCE_APP: {
      icon: assets.getImage('MicroAppAudienceIcon'),
    },
    MEDIA_PLAN_GENERATOR: {
      icon: assets.getImage('MicroappMediaPlanIcon'),
    },
    LABELER: {
      icon: assets.getImage('MicroappLabelerIcon'),
    },
  };

  const CardTitle = {
    REPORTING_APP: 'Dashboards',
    CLUSTERING_APP: 'Clustering Tool',
    MEDIA_PLANNER_TOOL: 'Media Planner Tool',
    NEWS_AND_CULTURE_APP: 'Sensing Culture',
    AUDIENCE_APP: 'Audience Tool',
    MEDIA_PLAN_GENERATOR: 'Media Plan Generator',
    LABELER: 'Labeler',
  };

  const getClientAppsData = async () => {
    try {
      setLoading(true);
      const res = await CreateClientApiServices.getClientAppDetails(clientId);
      let microAppObj = {};
      res.data.forEach((item) => {
        microAppObj[item?.app_type] = item;
      });
      setData(microAppObj);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        placement: 'bottomRight',
        message: msg,
      });
    }
  };

  const updateApps = async (appType, type, value) => {
    try {
      seConfigureLoading(true);
      let obj = {
        app_type: appType,
        id: data?.[appType]?.id,
      };
      if (type === 'enabled') {
        obj = {
          ...obj,
          is_active: value,
        };
      }
      if (type === 'assignAll') {
        obj = {
          ...obj,
          assign_to_all_users: value,
        };
      }
      if (type === 'config') {
        obj = {
          ...obj,
          app_config: value,
        };
      }
      let payload = {
        client_space_id: clientId,
        micro_apps: [obj],
      };
      const res = await CreateClientApiServices.updateClientApps(payload);
      let resObj = res.data[0];
      setData({ ...data, [appType]: resObj });
      notification.success({
        duration: 5,
        placement: 'bottomRight',
        message: `${CardTitle[appType]} updated successfully`,
      });
      getClientData(clientId);
      seConfigureLoading(false);
      if (type === 'enabled') {
        if (value) {
          setAppSettingsDrawer({
            visible: true,
            data: {
              appType: appType,
              data: resObj,
            },
          });
        }
      }
    } catch (error) {
      seConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        placement: 'bottomRight',
        message: msg,
      });
    }
  };

  const addUpdateUserToMicroApp = async (appType, microAppId, value, type) => {
    try {
      seConfigureLoading(true);
      let payload = {
        micro_app_id: microAppId,
        access: [value],
        client_space_id: clientId,
      };
      const res = await CreateClientApiServices.addUpdateUserToMicroApp(payload);
      setData({ ...data, [appType]: { ...data[appType], access: res.data.access } });
      seConfigureLoading(false);
      notification.success({
        duration: 5,
        placement: 'bottomRight',
        message: `${type === 'update' ? 'User role updated successfully' : 'User added successfully'}`,
      });
    } catch (error) {
      seConfigureLoading(false);
      const { msg, error_message } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        placement: 'bottomRight',
        message: error_message || msg,
      });
    }
  };

  const deleteUserFromMicroApp = async (id, appType) => {
    Modal.warn({
      title: 'Are you sure you want to remove this user?',
      okText: 'Okay',
      cancelText: 'Cancel',
      okCancel: true,
      onOk: async () => {
        try {
          seConfigureLoading(true);
          await CreateClientApiServices.deleteUserFromMicroApp(id, clientId);
          const accessArr = data[appType]?.access.filter((item) => item?.id !== id);
          setData({ ...data, [appType]: { ...data[appType], access: accessArr } });
          seConfigureLoading(false);
          notification.success({
            duration: 5,
            placement: 'bottomRight',
            message: 'User removed successfully',
          });
        } catch (error) {
          seConfigureLoading(false);
          const { msg } = getErrorMessageFromResponse(error);
          notification.error({
            duration: 5,
            placement: 'bottomRight',
            message: msg,
          });
        }
      },
    });
  };

  useEffect(() => {
    if (clientId) {
      getClientAppsData();
    } else {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    microApps,
    Images,
    CardTitle,
    data,
    appConfigTab,
    setAppConfigTab,
    updateApps,
    configureLoading,
    addUserModal,
    setAddUserModal,
    appSettingsDrawer,
    setAppSettingsDrawer,
    addUpdateUserToMicroApp,
    deleteUserFromMicroApp,
  };
};

export default useClientSpaceSelectAppsHook;
