import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Editor from '@monaco-editor/react';
import { Drawer, Form, Row } from 'antd';
import React from 'react';
import { format } from 'sql-formatter';
import { SecondaryButton } from 'src/components/basic';
import CommonStyle from '../newSqlTool.style';
import Style from './sqlQuery.style';
import useSqlQueryHook from './useSqlQueryHook';

export default function SqlQuery({
  tableName,
  value = '',
  onChange,
  mAiApp,
  selectedConnection,
  setFieldValue,
  fieldName,
  getFieldsValue,
  setUnsavedChanges,
}) {
  const { loading, drawer, setDrawer, formRef, validateVirtualTable, setLoading } = useSqlQueryHook({
    mAiApp,
    selectedConnection,
    onChange,
    setFieldValue,
    fieldName,
    getFieldsValue,
    setUnsavedChanges,
  });
  return (
    <>
      <span
        onClick={() => {
          setDrawer(true);
        }}
        className={Style.viewQueryButton}
      >
        View
      </span>
      <Drawer
        title={`Add sql query ${tableName ? `for ${tableName}` : 'new table'}`}
        maskClosable={true}
        placement="right"
        onClose={() => {
          setDrawer(false);
          setLoading(false);
        }}
        open={drawer}
        destroyOnClose
        size="large"
        width={'80%'}
      >
        <Form
          ref={formRef}
          initialValues={{ virtual_sql_query: format(value, { language: 'snowflake' }) }}
          onFinish={(val) => validateVirtualTable(val)}
        >
          <Form.Item shouldUpdate noStyle>
            {({ setFieldValue, getFieldValue }) => {
              return (
                <div>
                  <div className={Style.editorTopBar}>
                    <span
                      onClick={() => {
                        const sql = getFieldValue('virtual_sql_query');
                        const res = format(sql, { language: 'snowflake' });
                        setFieldValue('virtual_sql_query', res);
                      }}
                      className={Style.formatter}
                    >
                      <LeftOutlined />/<RightOutlined />
                    </span>
                  </div>
                  <Form.Item
                    name="virtual_sql_query"
                    rules={[{ required: true, message: 'Please enter a valid sql query' }]}
                  >
                    <Editor
                      height="calc(100vh - 250px)"
                      className={Style.editor}
                      defaultLanguage="sql"
                      theme="vs-light"
                      options={{
                        minimap: { enabled: false },
                        lineNumbers: 'on',
                        automaticLayout: true,
                      }}
                    />
                  </Form.Item>
                </div>
              );
            }}
          </Form.Item>
          <Row className={CommonStyle.topSaveBar} justify="end">
            <SecondaryButton loading={loading} htmlType="submit">
              Validate Query
            </SecondaryButton>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
