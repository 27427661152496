import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EyeOutlined,
  FileOutlined,
  PlayCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Progress, Space, Table, Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import * as TableStyle from './styles';
import LabelerApiService from '../../services/api';
import { notification } from 'antd';

function ListingTable({ clientId, tableDataSource, onPageChange, total, currentPage, loading }) {
  const PAGE_SIZE = 10;

  const statusConfig = {
    DRAFT: {
      icon: <FileOutlined />,
      color: 'rgba(245, 121, 0, 0.8)',
      text: 'Draft',
    },
    SCHEDULED: {
      icon: <ClockCircleOutlined />,
      color: 'rgba(0, 172, 193, 0.8)',
      text: 'Scheduled',
    },
    RUNNING: {
      icon: <SyncOutlined spin />,
      color: 'rgba(0, 156, 255, 0.9)',
      text: 'Running',
    },
    COMPLETED: {
      icon: <CheckOutlined />,
      color: 'rgba(50, 205, 50, 0.8)',
      text: 'Completed',
    },
    FAILED: {
      icon: <CloseOutlined />,
      color: 'rgba(220, 20, 60, 0.8)',
      text: 'Failed',
    },
    CANCEL_TRIGGERED: {
      icon: <CloseOutlined />,
      color: 'rgba(220, 137, 20, 0.8)',
      text: 'Cancel Triggered',
    },
    CANCELLED: {
      icon: <CloseOutlined />,
      color: 'rgba(220, 137, 20, 0.8)',
      text: 'Cancelled',
    },
  };

  const handleTriggerTask = async (record) => {
    if (['RUNNING', 'CANCEL_TRIGGERED'].includes(record.status)) {
      return;
    }
    const response = await LabelerApiService.triggerLabelingTask(clientId, record.id);
    if (response.success) {
      notification.success({
        duration: 5,
        placement: 'bottomRight',
        message: 'Task triggered successfully',
      });
    } else {
      notification.error({
        duration: 5,
        placement: 'bottomRight',
        message: 'Failed to trigger task',
      });
    }
  };

  const handleCancelTask = async (record) => {
    if (record.status !== 'RUNNING') {
      return;
    }
    const response = await LabelerApiService.cancelLabelingTask(clientId, record.id);
    if (response.success) {
      notification.success({
        duration: 5,
        placement: 'bottomRight',
        message: 'Task cancel triggered',
      });
    } else {
      notification.error({
        duration: 5,
        placement: 'bottomRight',
        message: 'Failed to cancel task',
      });
    }
  };

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${hrs > 0 ? `${hrs}h ` : ''}${mins > 0 ? `${mins}m ` : ''}${secs}s`;
  };

  const TABLE_COLUMNS = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className={TableStyle.nameSection}>
          <Tooltip title={text}>
            <Link to={`/client/${clientId}/labeler/${record.id}`} className={TableStyle.viewDetailsLink}>
              <span className={TableStyle.taskName}>{text}</span>
            </Link>
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Progress',
      dataIndex: 'progress',
      key: 'progress',
      render: (record, data) => {
        const { icon, text, color } = statusConfig[data.status];
        return (
          <Tooltip title={text}>
            <div className={TableStyle.progressSection}>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                <div className={TableStyle.metaInfo}>{data.progress}%</div>
                {data.estimated_time_to_complete_in_sec > 0 ? (
                  <div className={TableStyle.metaInfo}>ETA: {formatTime(data.estimated_time_to_complete_in_sec)}</div>
                ) : (
                  ['RUNNING', 'CANCEL_TRIGGERED'].includes(data.status) && <div style={{ color: color }}>{icon}</div>
                )}
              </div>
              <Progress
                percent={record}
                strokeColor={color}
                trailColor="rgba(255,255,255,0.1)"
                status={data.status === 'RUNNING' ? 'active' : 'normal'}
                showInfo={false}
              />
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: 'Source',
      dataIndex: 'data_source',
      key: 'data_source',
      render: (record) => (
        <div className={TableStyle.dataSourceSection}>
          <span className={TableStyle.metaInfo}>{record.type}</span>
        </div>
      ),
    },
    {
      title: 'Created On',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (text) => (
        <div className={TableStyle.dateSection}>
          <span className={TableStyle.metaInfo}>{text}</span>
        </div>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <div className={TableStyle.actionSection}>
          <Space size={16}>
            <Link to={`/client/${clientId}/labeler/${record.id}`} className={TableStyle.viewDetailsLink}>
              <EyeOutlined /> View
            </Link>
            <Link
              onClick={() => handleTriggerTask(record)}
              className={TableStyle.viewDetailsLink}
              style={{
                cursor: ['RUNNING', 'CANCEL_TRIGGERED'].includes(record.status) ? 'not-allowed' : 'pointer',
                opacity: ['RUNNING', 'CANCEL_TRIGGERED'].includes(record.status) ? 0.5 : 1,
              }}
            >
              {record.status === 'RUNNING' ? <SyncOutlined spin /> : <PlayCircleOutlined />} Run
            </Link>
            <Link
              onClick={() => handleCancelTask(record)}
              className={TableStyle.viewDetailsLink}
              style={{
                cursor: record.status !== 'RUNNING' ? 'not-allowed' : 'pointer',
                opacity: record.status !== 'RUNNING' ? 0.5 : 1,
              }}
            >
              {record.status === 'CANCEL_TRIGGERED' ? (
                <>
                  <SyncOutlined spin /> Cancelling
                </>
              ) : (
                <>
                  <CloseCircleOutlined /> Cancel
                </>
              )}
            </Link>
          </Space>
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={TABLE_COLUMNS}
      dataSource={tableDataSource}
      className="dark-table"
      style={{ backgroundColor: '#333', color: '#fff', marginBottom: '20px' }}
      pagination={{
        pageSize: PAGE_SIZE,
        total: total,
        showSizeChanger: false,
        current: currentPage,
        position: ['bottomCenter'],
        onChange: (page) => onPageChange(page),
      }}
      loading={loading}
    />
  );
}

export default ListingTable;
