import { DeleteOutlined, InfoCircleOutlined, PlusOutlined, CopyOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Tooltip, List, Typography } from 'antd';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Styles from './styles';
import { SERVICE_ACCOUNT_EMAIL } from 'src/modules/labeler/constants';
const { Title } = Typography;

const InputColumnsForm = ({ columns }) => {
  const [hoveredRow, setHoveredRow] = useState(null);
  const [tooltipText, setTooltipText] = useState('Copy to clipboard');
  const { t } = useTranslation();
  const form = Form.useFormInstance();

  const validateInputColumns = useCallback(async (_, inputColumns) => {
    if (!inputColumns?.length) {
      return Promise.reject(new Error('At least one input column is required.'));
    }
  }, []);

  const columnOptions = React.useMemo(
    () =>
      columns.map((col) => (
        <Select.Option key={col.name} value={col.name} dataType={col.data_type.toUpperCase()}>
          {col.name}
        </Select.Option>
      )),
    [columns]
  );

  const typeOptions = React.useMemo(
    () => [
      {
        value: 'TEXT',
        label: 'Text',
        description: (
          <div className={Styles.toolTip}>
            <Title level={4}>{t('labeler.input_columns_definitions.text.description_title')}</Title>
            <List>
              <List.Item>{t('labeler.input_columns_definitions.text.example')}</List.Item>
              <List.Item>{t('labeler.input_columns_definitions.text.use_case')}</List.Item>
            </List>
          </div>
        ),
      },
      {
        value: 'IMAGE',
        label: 'Image',
        description: (
          <div className={Styles.toolTip}>
            <Title level={4}>{t('labeler.input_columns_definitions.image.description_title')}</Title>
            <List>
              <List.Item>
                {t('labeler.input_columns_definitions.example')}: &quot;https://example.com/image.jpg&quot;
              </List.Item>
              <List.Item>{t('labeler.input_columns_definitions.image.supports')}</List.Item>
              <List.Item>{t('labeler.input_columns_definitions.image.use_case')}</List.Item>
            </List>
          </div>
        ),
      },
      {
        value: 'VIDEO',
        label: 'Video',
        description: (
          <div className={Styles.toolTip}>
            <Title level={4}>{t('labeler.input_columns_definitions.video.description_title')}</Title>
            <List>
              <List.Item>
                {t('labeler.input_columns_definitions.example')}: &quot;https://example.com/video.mp4&quot;
              </List.Item>
              <List.Item>{t('labeler.input_columns_definitions.video.supports')}</List.Item>
              <List.Item>{t('labeler.input_columns_definitions.video.use_case')}</List.Item>
            </List>
          </div>
        ),
      },
      {
        value: 'URL',
        label: 'Webpage',
        description: (
          <div className={Styles.toolTip}>
            <Title level={4}>{t('labeler.input_columns_definitions.url.description_title')}</Title>
            <List>
              <List.Item>{t('labeler.input_columns_definitions.example')}: &quot;https://example.com&quot;</List.Item>
              <List.Item>{t('labeler.input_columns_definitions.url.use_case')}</List.Item>
            </List>
          </div>
        ),
      },
      {
        value: 'HTML5',
        label: 'HTML5',
        description: (
          <div className={Styles.toolTip}>
            <Title level={4}>{t('labeler.input_columns_definitions.html5.description_title')}</Title>
            <List>
              <List.Item>{t('labeler.input_columns_definitions.html5.example')}</List.Item>
              <List.Item>{t('labeler.input_columns_definitions.html5.use_case')}</List.Item>
            </List>
          </div>
        ),
      },
    ],
    []
  );

  const handleColumnChange = (value, option, fieldName) => {
    const dataType = option.dataType == 'STRING' ? 'TEXT' : option.dataType;
    if (typeOptions.filter((type) => type.value === dataType).length) {
      form.setFieldsValue({
        inputColumns: {
          [fieldName]: {
            type: dataType,
          },
        },
      });
    } else {
      form.setFieldsValue({
        inputColumns: {
          [fieldName]: {
            type: 'TEXT',
          },
        },
      });
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(SERVICE_ACCOUNT_EMAIL).then(() => {
      setTooltipText('Copied!');
      setTimeout(() => setTooltipText('Copy to clipboard'), 2000);
    });
  };

  return (
    <Form.List name="inputColumns" rules={[{ validator: validateInputColumns }]}>
      {(fields, { add, remove }, { errors }) => (
        <div className={Styles.formContainer}>
          <div className={Styles.formHeader}>
            <div className={Styles.titleContainer}>
              <h3 className={Styles.formTitle}>{t('labeler.configure_input_columns')}</h3>
            </div>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => add()} className={Styles.addColumnButton}>
              {t('labeler.add_column')}
            </Button>
          </div>
          <div className={Styles.inputOptionsContainer}>
            <div className={Styles.inputOptionsDescription}>{t('labeler.input_options.input_options_title')}</div>
            <List className={Styles.inputOptionsList}>
              <List.Item className={Styles.inputOptionItem}>
                - <strong>{t('labeler.input_options.text.label')}:</strong>{' '}
                {t('labeler.input_options.text.description')}
              </List.Item>
              <List.Item className={Styles.inputOptionItem}>
                - <strong>{t('labeler.input_options.images.label')}:</strong>{' '}
                {t('labeler.input_options.images.description')}
              </List.Item>
              <List.Item className={Styles.inputOptionItem}>
                - <strong>{t('labeler.input_options.videos.label')}:</strong>{' '}
                {t('labeler.input_options.videos.description')}
              </List.Item>
              <List.Item className={Styles.inputOptionItem}>
                - <strong>{t('labeler.input_options.webPages.label')}:</strong>{' '}
                {t('labeler.input_options.webPages.description')}
              </List.Item>
              <List.Item className={Styles.inputOptionItem}>
                - <strong>{t('labeler.input_options.html5.label')}:</strong>{' '}
                {t('labeler.input_options.html5.description')}
              </List.Item>
            </List>
            <div className={Styles.inputOptionsFooter}>
              {t('labeler.input_options.note')} <span className={Styles.serviceAccount}>{SERVICE_ACCOUNT_EMAIL}</span>{' '}
              <Tooltip title={tooltipText}>
                <CopyOutlined className={Styles.copyIcon} onClick={handleCopy} />
              </Tooltip>
            </div>
          </div>

          {fields.length === 0 ? (
            <div className={Styles.emptyState}>
              <p>{t('labeler.input_columns_added_yet')}</p>
            </div>
          ) : (
            <div className={Styles.columnsContainer}>
              <div className={Styles.columnHeaders}>
                <div className={Styles.headerColumn}>{t('labeler.column')}</div>
                <div className={Styles.headerColumn}>{t('description')}</div>
                <div className={Styles.headerColumn}>{t('labeler.type')}</div>
                <div className={Styles.headerAction}></div>
              </div>
              <div className={Styles.scrollableContainer}>
                {fields.map((field, index) => (
                  <div
                    className={Styles.inputColumnRow}
                    key={field.key}
                    onMouseEnter={() => setHoveredRow(index)}
                    onMouseLeave={() => setHoveredRow(null)}
                  >
                    <Form.Item
                      name={[field.name, 'name']}
                      rules={[{ required: true, message: t('labeler.name_error') }]}
                    >
                      <Select
                        className={Styles.columnSelect}
                        placeholder={t('labeler.input_column_placeholder')}
                        showSearch
                        optionFilterProp="children"
                        onChange={(value, option) => handleColumnChange(value, option, field.name)}
                      >
                        {columnOptions}
                      </Select>
                    </Form.Item>

                    <Form.Item name={[field.name, 'description']}>
                      <Input
                        className={Styles.columnDescription}
                        placeholder={t('labeler.add_a_description_optional')}
                      />
                    </Form.Item>

                    <Form.Item
                      name={[field.name, 'type']}
                      rules={[{ required: true, message: t('labeler.type_is_required') }]}
                    >
                      <Select className={Styles.columnTypeSelect} placeholder="Type" labelRender={({ title }) => title}>
                        {typeOptions.map(({ value, label, description }) => (
                          <Select.Option key={value} value={value} title={label}>
                            <Tooltip title={description} placement="right" color="white">
                              <div
                                style={{
                                  justifyContent: 'space-between',
                                  display: 'flex',
                                  width: '100%',
                                }}
                              >
                                {label} <InfoCircleOutlined style={{ opacity: 0.5, fontSize: '10px' }} />
                              </div>
                            </Tooltip>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Tooltip title={t('labeler.delete_column')}>
                      <DeleteOutlined
                        className={`${Styles.deleteIcon} ${hoveredRow === index ? Styles.deleteIconVisible : ''}`}
                        onClick={() => remove(field.name)}
                      />
                    </Tooltip>
                  </div>
                ))}
              </div>
            </div>
          )}

          {errors && <Form.ErrorList errors={errors} className={Styles.errorList} />}
        </div>
      )}
    </Form.List>
  );
};

export default React.memo(InputColumnsForm);
