import { notification } from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import CreateClientApiServices from '../../services/createClientSpaceApiServices';

const useKpiConfigType = ({ kpiConfigData, kpiDetails, getKPIDetails }) => {
  const { clientId } = useParams();
  const [isAdding, setIsAdding] = useState(false);
  const [editedItem, setEditedItem] = useState({});
  const [editMode, setEditMode] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [newKpi, setNewKpi] = useState({
    kpi_name: null,
    custom_name: null,
    definition: '',
    benchmark_value: null,
    b2c_percent: null,
    b2b_percent: null,
    kpi_id: '',
    client_space_id: clientId,
    kpi_config_type: 'CUSTOM',
  });

  const handleEdit = (index) => {
    setEditMode(index);
    setEditedItem(kpiConfigData[index]);
  };

  const validateAndCleanPlayload = (data) => {
    let dataToSave = data;

    if (!dataToSave.kpi_name) {
      notification.error({
        message: 'Validation Error',
        description: 'KPI Name Cannot be empty',
        duration: 5,
        placement: 'bottomRight',
      });
      return;
    }

    // Remove empty fields from payload
    Object.keys(dataToSave).forEach((key) => {
      if (dataToSave[key] === '') {
        delete dataToSave[key];
      }
    });
    return dataToSave;
  };

  const handleSave = async (index) => {
    try {
      setDataLoading(true);
      if (index === kpiConfigData.length) {
        newKpi.kpi_id = kpiDetails.find((kpi) => kpi.name === newKpi.kpi_name)?.id;
        let cleanedPayload = validateAndCleanPlayload(newKpi);
        newKpi.kpi_config_type = 'CUSTOM';
        if (!cleanedPayload) {
          setDataLoading(false);
          return;
        }
        let success = await CreateClientApiServices.saveKPIConfig(newKpi);
        if (!success) return;
        getKPIDetails();
        notification.success({
          message: 'Custom KPI added Successfully',
          duration: 5,
          placement: 'bottomRight',
        });

        setNewKpi({
          kpi_name: null,
          custom_name: null,
          definition: '',
          benchmark_value: null,
          b2c_percent: null,
          b2b_percent: null,
          kpi_id: '',
          client_space_id: clientId,
          kpi_config_type: 'CUSTOM',
        });
      } else {
        let cleanedPayload = validateAndCleanPlayload(editedItem);
        cleanedPayload.client_space_id = clientId;
        if (editedItem?.kpi_config_type === 'STANDARD') {
          editedItem.kpi_config_type = 'STANDARD_OVERRIDE';
        }
        if (!editedItem?.custom_name) {
          editedItem.custom_name = null;
        }
        if (!editedItem?.benchmark_value) {
          editedItem.benchmark_value = null;
        }
        if (!cleanedPayload) {
          setDataLoading(false);
          return;
        }
        let success = await CreateClientApiServices.saveKPIConfig(editedItem);
        if (!success) return;
        notification.success({
          message: 'KPI edited Successfully',
          duration: 5,
          placement: 'bottomRight',
        });
        getKPIDetails();
      }
      setEditMode(null);
      setIsAdding(false);
      setDataLoading(false);
    } catch (error) {
      setDataLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };

  const handleChange = (key, value) => {
    if (editMode !== null) {
      setEditedItem({ ...editedItem, [key]: value });
    } else {
      setNewKpi({ ...newKpi, [key]: value });
    }
  };

  const handleDelete = async (index) => {
    try {
      setDataLoading(true);
      await CreateClientApiServices.deleteKPIConfig(kpiConfigData[index].id, clientId);
      await getKPIDetails();
      notification.success({
        message: 'KPI deleted Successfully',
        duration: 5,
        placement: 'bottomRight',
      });
      setDataLoading(false);
    } catch (error) {
      setDataLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };

  const handleAdd = () => {
    setIsAdding(true);
    setEditMode(null);
  };

  const handleAddCancel = () => {
    setIsAdding(false);
    setNewKpi({
      kpi_name: null,
      custom_name: null,
      definition: '',
      benchmark_value: null,
      b2c_percent: null,
      b2b_percent: null,
      kpi_id: '',
      client_space_id: clientId,
    });
  };

  const handleCancelEdit = () => {
    setEditMode(null);
    setEditedItem({});
  };

  return {
    isAdding,
    newKpi,
    setNewKpi,
    editMode,
    editedItem,
    handleDelete,
    handleEdit,
    handleAdd,
    handleAddCancel,
    handleChange,
    handleCancelEdit,
    handleSave,
    dataLoading,
  };
};

export default useKpiConfigType;
