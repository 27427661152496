import { Form, notification } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom';
import appUrls from 'src/constants/appUrls';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import audienceListService from '../../../services/audienceListService';
import mediaPlanService from '../../../services/mediaPlanService';

const useCreateMediaPlan = ({ mediaPlanData, setStep, setMediaPlanData }) => {
  const history = useHistory();
  const { clientId, mediaPlanId, scenarioId } = useParams();
  const { state } = useLocation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [configureLoading, setConfigureLoading] = useState(false);
  const [audienceOptions, setAudienceOptions] = useState([]);

  const filterRef = {
    currentPage: 1,
    searchName: '',
    filterType: '',
    sortBy: 'created_on',
    isAscending: false,
    statusFilter: 'AUDIENCES_CREATED',
    clusterType: undefined,
    dataSourceExist: true,
  };

  const createMediaplanRecommendations = async (paylod) => {
    try {
      setConfigureLoading(true);
      const res = await mediaPlanService.createMediaplanRecommendation(paylod);
      if (scenarioId || mediaPlanData?.scenario_id) {
        notification.success({
          message: 'Scenario updated successfully',
          duration: 5,
          placement: 'bottomRight',
        });
      } else if (mediaPlanId || mediaPlanData?.mediaplan_id) {
        notification.success({
          message: 'Scenario created successfully',
          duration: 5,
          placement: 'bottomRight',
        });
      } else {
        notification.success({
          message: 'Media Plan created successfully',
          duration: 5,
          placement: 'bottomRight',
        });
      }
      setMediaPlanData(res.data);
      window.history.pushState({}, '', appUrls.EDIT_SCENARIO_MEDIA_PLAN(clientId, res.data.scenario_id));
      setConfigureLoading(false);
      setStep(1);
    } catch (error) {
      setConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };

  const onFinish = (values) => {
    const audiences = values.audience?.map((audience) => {
      return {
        audience_id: audience,
      };
    });
    const transformedValues = {
      client_space_id: clientId,
      mediaplan_name: values.mediaplan_name,
      budget: values.budget,
      scenario_name: values.scenario_name,
      audience_mapping: audiences,
      json_data: {
        kpis: [values.kpis],
      },
      start_date: values.dateRange ? dayjs(values.dateRange[0]).format('YYYY-MM-DD') : null,
      end_date: values.dateRange ? dayjs(values.dateRange[1]).format('YYYY-MM-DD') : null,
      granularity: values.granularity,
    };
    if (mediaPlanId || mediaPlanData?.mediaplan_id) {
      transformedValues.mediaplan_id = mediaPlanId || mediaPlanData?.mediaplan_id;
      delete transformedValues.json_data;
    }
    if (scenarioId || mediaPlanData?.scenario_id) {
      transformedValues.mediaplan_id = mediaPlanData?.mediaplan_id;
      transformedValues.scenario_id = scenarioId || mediaPlanData?.scenario_id;
      delete transformedValues.json_data;
    }
    createMediaplanRecommendations(transformedValues);
  };

  const getAudience = async () => {
    try {
      const res = await audienceListService.getAudienceList(
        clientId,
        filterRef.currentPage,
        100,
        filterRef.searchName,
        filterRef.filterType,
        filterRef.sortBy,
        filterRef.isAscending,
        filterRef.statusFilter,
        filterRef.clusterType,
        filterRef.dataSourceExist
      );
      setAudienceOptions(res.data.data);
      form.setFieldsValue({
        granularity: 'MONTHLY',
      });
      if (mediaPlanData?.mediaplan_id) {
        const audiences = mediaPlanData?.audience_mapping?.map((audience) => audience.audience_id);
        const transformedValues = {
          mediaplan_name: mediaPlanData.mediaplan_name,
          budget: mediaPlanData.budget,
          dateRange: [dayjs(mediaPlanData.start_date), dayjs(mediaPlanData.end_date)],
          kpis: mediaPlanData?.json_data?.kpis,
          audience: audiences,
        };
        if (mediaPlanData?.scenario_id) {
          transformedValues.scenario_name = mediaPlanData?.scenario_name;
          transformedValues.budget = mediaPlanData?.budget;
          transformedValues.granularity = mediaPlanData?.granularity;
        }
        form.setFieldsValue(transformedValues);
      }
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };

  const fetchData = async () => {
    setLoading(true);
    await getAudience();
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    configureLoading,
    loading,
    form,
    onFinish,
    state,
    history,
    audienceOptions,
    mediaPlanId,
    clientId,
  };
};

export default useCreateMediaPlan;
