import { notification } from 'antd';
import { useEffect, useState } from 'react';
import appUrls from 'src/constants/appUrls';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { AiAppApiServices } from '../services/aiAppApiServices';

export default function useAiAppContainerHook({ sessionId, history, appId, mode, clientId }) {
  const mAiApp = appId;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [singleChatSessions, setSingleChatSessions] = useState([]);
  const [singleChatSessionsDetails, setSingleChatSessionsDetails] = useState(null);
  const [multipleChatSessions, setMultipleChatSessions] = useState([]);
  const [starterConversation, setStarterConversation] = useState([]);
  const [sessionToggle, setSessionToggle] = useState('MY_CHAT');
  const [sessionLoading, setSessionLoading] = useState(false);
  const [task, setTask] = useState({ data: [] });
  const [taskLoading, setTaskLoading] = useState(false);

  const [singleChatLoaded, setSingleChatLoaded] = useState(false);
  const [multipleChatLoaded, setMultipleChatLoaded] = useState(false);
  const [starterConversationLoaded, setStarterConversationLoaded] = useState(false);

  const getSingleChatSessions = async () => {
    try {
      let type = 'SINGLE_USER';
      let page = 1;
      const res = await AiAppApiServices.getsessionList(mAiApp, type, page);
      if (!sessionId && res?.data?.data?.length > 0) {
        history.push(appUrls.APP_PAGE(clientId, mAiApp, res?.data?.data?.[0]?.id, mode));
      }
      setSingleChatSessions(res.data.data);
      setSingleChatSessionsDetails(res.data);
      setSingleChatLoaded(true);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const getMultipleChatSessions = async () => {
    try {
      let type = 'MULTI_USER';
      let page = 1;
      const res = await AiAppApiServices.getsessionList(mAiApp, type, page);
      if (sessionId) {
        res.data.data.forEach((item) => {
          if (item.id === sessionId) {
            setSessionToggle('MULTI_CHAT');
          }
        });
      }
      setMultipleChatSessions(res.data.data);
      setMultipleChatLoaded(true);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const getStarterConversation = async (sessionId) => {
    try {
      const res = await AiAppApiServices.getStarterConversation(mAiApp, sessionId);
      setData(res.data);
      setStarterConversation(res.data.conversation_starters);
      setStarterConversationLoaded(true);
    } catch (error) {
      const { msg, status } = getErrorMessageFromResponse(error);
      if (status === 403) {
        notification.error({
          message: 'You are not authorized to access this app',
          duration: 6,
          placement: 'bottomRight',
        });
        history.push(appUrls.HOMEPAGE);
        window.location.reload();
      }
      notification.error({
        message: msg,
        duration: 6,
        placement: 'bottomRight',
      });
    }
  };

  const addNewSession = async (data) => {
    let tempArr = singleChatSessions;
    if (singleChatSessions.length === 5) {
      tempArr = singleChatSessions.slice(0, singleChatSessions.length - 1);
    }
    setSingleChatSessions([data, ...tempArr]);
  };

  const renameSession = async (data, idx) => {
    let tempArr = singleChatSessions;
    tempArr[idx] = data;
    setSingleChatSessions([...tempArr]);
  };

  const scrollSingleSesion = async () => {
    try {
      if (singleChatSessionsDetails?.current_page < singleChatSessionsDetails?.total_pages) {
        setSessionLoading(true);
        let type = 'SINGLE_USER';
        let page = singleChatSessionsDetails?.current_page + 1;
        const res = await AiAppApiServices.getsessionList(mAiApp, type, page);
        setSingleChatSessionsDetails(res.data);
        setSingleChatSessions([...singleChatSessions, ...res.data.data]);
        setSessionLoading(false);
      }
    } catch (error) {
      setSessionLoading(false);
    }
  };

  const scrollTaskSesion = async () => {
    try {
      if (task?.current_page < task?.total_pages) {
        setTaskLoading(true);
        let type = 'SINGLE_USER';
        let page = task?.current_page + 1;
        const res = await AiAppApiServices.getsessionList(mAiApp, type, page, true);
        setTask({ ...res.data, data: [...task.data, ...res.data.data] });
        setTaskLoading(false);
      }
    } catch (error) {
      setTaskLoading(false);
    }
  };

  const getsessionHistory = async () => {
    try {
      await getStarterConversation();
      await getSingleChatSessions();
      await getTask();
      if (!data.is_private && appId !== '5a6907c5-5441-4643-8d8f-978c70dfe30f') {
        await getMultipleChatSessions();
      } else {
        setMultipleChatLoaded(true);
      }
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const getTask = async () => {
    try {
      let type = 'SINGLE_USER';
      let page = 1;
      const res = await AiAppApiServices.getsessionList(mAiApp, type, page, true);
      setTask(res?.data);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 4,
        placement: 'bottomRight',
      });
    }
  };

  const updateTask = async (value, mode, idx) => {
    if (mode === 'add') {
      let updatedFields = {
        ...value,
        task_id: value?.task_id,
        name: value.name || value.task_name || 'Unnamed Task',
        id: value?.task_session_id,
        task_status: value?.task_status,
      };

      setTask((prevTask) => ({
        ...prevTask,
        data: [updatedFields, ...prevTask.data],
      }));
    }

    if (mode === 'update') {
      let updatedFields = {
        ...value,
        task_id: value?.id,
        name: value?.task_name,
        id: value?.task_session_id,
        task_status: value?.task_status,
      };
      setTask((prevTask) => ({
        ...prevTask,
        data: prevTask.data.map((task) => (task.task_id === updatedFields.task_id ? updatedFields : task)),
      }));
    }

    if (mode === 'upsert') {
      let updatedFields = {
        ...value,
        task_id: value?.task_id,
        name: value.name || value?.task_name,
        id: value?.task_session_id,
        task_status: value?.task_status,
      };

      setTask((prevTask) => {
        const taskExists = prevTask.data.some((task) => task.task_id === updatedFields.task_id);
        const updatedData = taskExists
          ? prevTask.data.map((task) => (task.task_id === updatedFields.task_id ? updatedFields : task))
          : [updatedFields, ...prevTask.data];

        return {
          ...prevTask,
          data: updatedData,
        };
      });
    }

    if (mode === 'delete') {
      setTask((prevTask) => ({
        ...prevTask,
        data: prevTask.data.filter((_, index) => index !== idx),
      }));
    }
  };

  const deleteSingleSession = (idx) => {
    let tempArr = singleChatSessions;
    tempArr.splice(idx, 1);
    setSingleChatSessions([...tempArr]);
  };

  useEffect(() => {
    getsessionHistory();
  }, []);

  useEffect(() => {
    if (starterConversationLoaded && singleChatLoaded && multipleChatLoaded) {
      setLoading(false);
    }
  }, [starterConversationLoaded, singleChatLoaded, multipleChatLoaded]);

  return {
    loading,
    singleChatSessions,
    multipleChatSessions,
    starterConversation,
    sessionToggle,
    setSessionToggle,
    addNewSession,
    sessionLoading,
    scrollSingleSesion,
    renameSession,
    data,
    task,
    taskLoading,
    updateTask,
    scrollTaskSesion,
    deleteSingleSession,
  };
}
