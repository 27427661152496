import {
  ArrowDownOutlined,
  CaretRightOutlined,
  ClockCircleOutlined,
  EditOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { Collapse, Popover } from 'antd';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat'; // Enables better formatting
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import ReactECharts from 'echarts-for-react';
import React from 'react';
import { CSVLink } from 'react-csv';
import { ErrorBoundary } from 'react-error-boundary';
import ConditionalRender from 'src/components/conditionalRender';
import Style from '../../aiApp.style';
import MarkdownRender from '../../MarkdownRender';
import useConversationResponseHook from '../hooks/useConversationResponseHook';
import CsvTable from './CsvTable';
import StreamingResponseOutput from './StreamingResponseOutput';
import ViewAnalysis from './ViewAnalysis';

export default function ConversationResponse({
  data,
  analysisData,
  mAiApp,
  chatId,
  hasAnalysis,
  item,
  feedbackClass,
  setTaskCreateModal,
  feedbackScreen = false,
  theme = 'dark',
}) {
  const {
    analysisLoading,
    viewAnalysis,
    openAnalysisAccordian,
    editPopOverVisible,
    setEditPopOverVisible,
    getFormattedTaskTime,
  } = useConversationResponseHook({ mAiApp, chatId });
  let chartKeys = [];
  let jsonChartKeys = [];
  let imageKeys = [];

  if (data?.metadata?.charts) {
    chartKeys = Object.keys(data?.metadata?.charts);
  }
  if (data?.metadata?.generated_images) {
    imageKeys = data?.metadata?.generated_images;
  }

  if (data?.metadata?.json_charts) {
    jsonChartKeys = Object.keys(data?.metadata?.json_charts);
  }

  /* eslint-disable */
  let latestActionValue = '';
  let actions = 0;
  analysisData?.forEach((item) => {
    if (['ACTION', 'TREE'].includes(item.type)) {
      actions += 1;
      latestActionValue = item.value;
    }
  });

  const evalutedConfig = (config) => {
    try {
      const res = eval(`(${config})`);
      if (res?.title) {
        res.title = null;
      }
      if (res?.legend) {
        res.legend.textStyle = { color: '#6F7079' };
      }
      return res;
    } catch {
      return null;
    }
  };

  dayjs.extend(localizedFormat);
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const taskItem = item?.ai_output?.metadata?.created_task || {};

  const formattedTaskTime = getFormattedTaskTime(taskItem);

  let csvDownloads = data?.metadata?.download_as_csv_list || [];

  const listTaskSource = item?.ai_output?.metadata?.created_task;

  const popOverTaskContent = (item) => {
    return (
      <div>
        <div
          className={Style.userContainer}
          onClick={() => {
            setTaskCreateModal({
              visible: true,
              taskId: item?.ai_output?.metadata?.created_task?.id,
            });
            setEditPopOverVisible(false);
          }}
        >
          <EditOutlined onClick={() => setEditPopOverVisible(true)} />
          &nbsp;&nbsp;Edit
        </div>
      </div>
    );
  };

  return (
    <>
      <ConditionalRender shouldRender={!!analysisData && actions > 0}>
        <Collapse
          className={'analysisCollapse'}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        >
          <Collapse.Panel header={'View Analysis'}>
            <StreamingResponseOutput streamingData={analysisData} analysisData={true} />
          </Collapse.Panel>
        </Collapse>
        {listTaskSource && Object.keys(listTaskSource).length > 0 && (
          <div className={Style.taskPreviewContainer}>
            <div className="taskCard">
              <div className="taskDetails">
                <span className="taskName">{taskItem.task_name || 'ERROR'}</span>
                <div className="taskMeta">
                  <span className="taskTime">
                    <ClockCircleOutlined /> {formattedTaskTime}
                  </span>
                </div>
              </div>
              <Popover
                overlayClassName={Style.popOverContainer}
                placement="bottomRight"
                content={() => popOverTaskContent(item)}
                trigger={['click']}
                visible={editPopOverVisible}
                onVisibleChange={setEditPopOverVisible}
              >
                <MoreOutlined className="taskMenuIcon" />
              </Popover>
            </div>
          </div>
        )}
      </ConditionalRender>
      <ConditionalRender shouldRender={hasAnalysis}>
        <Collapse
          className={'analysisCollapse'}
          onChange={(val) => openAnalysisAccordian(val)}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        >
          <Collapse.Panel header={'View Analysis'} key="analysis">
            <ViewAnalysis
              analysisLoading={analysisLoading}
              viewAnalysis={viewAnalysis}
              feedbackScreen={feedbackScreen}
            />
          </Collapse.Panel>
        </Collapse>
        {listTaskSource && Object.keys(listTaskSource).length > 0 && (
          <div className={Style.taskPreviewContainer}>
            <div className="taskCard">
              {/* Left Section: Task Name */}
              <div className="taskDetails">
                <span className="taskName">{taskItem.task_name || 'ERROR'}</span>

                {/* Bottom Section: Time (Left) and Status (Right) */}
                <div className="taskMeta">
                  <span className="taskTime">
                    <ClockCircleOutlined /> {formattedTaskTime}
                  </span>
                </div>
              </div>

              {/* Right Section: Options Menu */}
              <Popover
                overlayClassName={Style.popOverContainer}
                placement="bottomRight"
                content={() => popOverTaskContent(item)}
                trigger={['click']}
                visible={editPopOverVisible}
                onVisibleChange={setEditPopOverVisible}
              >
                <MoreOutlined className="taskMenuIcon" />
              </Popover>
            </div>
          </div>
        )}
      </ConditionalRender>
      <ConditionalRender shouldRender={!!imageKeys && imageKeys.length > 0}>
        <img height="50%" width="50%" src={'data:image/png;base64,' + imageKeys[0]} />
      </ConditionalRender>
      <ConditionalRender shouldRender={jsonChartKeys.length > 0}>
        <div>
          {jsonChartKeys?.map((chartName) => {
            try {
              const chart = data?.metadata?.json_charts[chartName];
              const config = evalutedConfig(chart.config);
              if (config === null) return null;
              return (
                <ErrorBoundary fallback={<div style={{ marginBottom: '1rem' }}>Cannot render chart</div>}>
                  <div style={{ overflow: 'auto' }}>
                    <ReactECharts option={config} style={{ height: 500 }} />
                  </div>
                </ErrorBoundary>
              );
            } catch (error) {
              return null;
            }
          })}
        </div>
      </ConditionalRender>
      <ConditionalRender shouldRender={chartKeys.length > 0 && jsonChartKeys.length === 0}>
        {chartKeys.map((item) => {
          const object = data?.metadata?.charts[item];
          return (
            <div key={item} style={{ marginTop: '0.4rem' }}>
              <iframe style={{ border: '0' }} height="425px" width="100%" srcDoc={object} />
            </div>
          );
        })}
      </ConditionalRender>
      <div
        style={{ maxWidth: feedbackScreen ? '850px' : '' }}
        className={
          feedbackClass !== undefined
            ? feedbackClass === 'disliked'
              ? `${Style.markDownParent} ${Style.dislikedParent}`
              : `${Style.markDownParent} ${Style.likedParent}`
            : Style.markDownParent
        }
      >
        <MarkdownRender children={data?.final_answer} feedbackClass={feedbackClass} theme={theme} />
      </div>
      <ConditionalRender shouldRender={csvDownloads?.length > 0}>
        <ErrorBoundary fallback={<div style={{ marginBottom: '1rem' }}>Cannot render csv</div>}>
          <div style={{ marginBottom: '0.7rem' }}>
            {csvDownloads?.map((item) => {
              return (
                <>
                  <CSVLink data={item?.value} filename={item?.file_name}>
                    {item?.file_name} &nbsp;
                    <ArrowDownOutlined />
                  </CSVLink>
                  <div style={{ maxWidth: feedbackScreen ? '850px' : '' }}>
                    <CsvTable data={item?.value} />
                  </div>
                </>
              );
            })}
          </div>
        </ErrorBoundary>
      </ConditionalRender>
    </>
  );
}
