import { css } from '@linaria/core';

const hoverComponent = css`
  z-index: 0;
  background-color: var(--color-charcoal-surface-1);
  padding: 10px;
  border-radius: 4px;
  border: solid 0.5px #ffffff30;
  height: 30rem;
  width: 100%;
`;

const nodeContent = css`
  max-height: 24rem;
  overflow-y: auto;
  .ant-collapse-item {
    .ant-collapse-header {
      border-radius: 5px !important;
      background: var(--color-charcoal-surface-3) !important;
    }
    .ant-collapse-content {
      border: solid 3px var(--color-charcoal-surface-3) !important;
      border-top: 0 !important;
    }
  }
  .ant-collapse-item-active {
    .ant-collapse-header {
      border-radius: 5px !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      background: var(--color-charcoal-surface-3) !important;
    }
  }
`;

const node = css`
  display: flex;
  justify-content: space-between;
  .ant-btn.ant-btn-default,
  .ant-btn-background-ghost.ant-btn-primary {
    border: none;
    display: contents;
  }
`;

const nodeHeader = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const nodeActions = css`
  display: flex;
  justify-content: space-between;
  width: 42px;
  position: absolute;
  right: 23px;
  top: 23px;
  z-index: 2;
  .ant-btn.ant-btn-default,
  .ant-btn-background-ghost.ant-btn-primary {
    color: white !important;
    border: none;
    display: contents;
    background: transparent;
    padding: 0px 7px !important;
    &:hover {
      color: white !important;
      background: transparent;
    }
  }
`;

const tabs = css`
  .ant-tabs-content-holder {
    overflow-y: auto;
  }
  height: 100%;
  // width: 60%;
`;

const hoverStreamingContainer = css`
  max-height: 100%;
  overflow-y: auto;
`;

export default {
  hoverComponent,
  nodeContent,
  nodeHeader,
  nodeActions,
  tabs,
  hoverStreamingContainer,
  node,
};
