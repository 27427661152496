import { Input, InputNumber } from 'antd';
import React, { useState } from 'react';

import Cookie from 'src/lib/cookie';

import './index.less';

const FloatInput = (props) => {
  const [focus, setFocus] = useState(true);
  let { label, value, placeholder, type, required } = props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? 'label as-label' : 'label as-placeholder';

  const requiredMark = required ? <span className="text-danger">*</span> : null;

  React.useEffect(() => {
    if (!Cookie.get('token')) {
      setFocus(true);
    }
  }, []);
  return (
    <div className="float-label" onBlur={() => setFocus(true)} onFocus={() => setFocus(true)}>
      {type == 'password' ? (
        <Input.Password onChange={props.onChange} type={type} defaultValue={value} {...props} />
      ) : (
        <InputNumber onChange={props.onChange} type={type} defaultValue={value} {...props} />
      )}

      <label className={labelClass}>
        {isOccupied ? placeholder : placeholder} {requiredMark}
      </label>
    </div>
  );
};

export default FloatInput;
