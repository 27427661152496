import React from 'react';
import { SecondaryButton } from 'src/components/basic';
import Style from '../../NewsAndCulture.style';

export const LuceneQueryComponent = ({
  queryText,
  queryIndex,
  setDrawerVisible,
  query,
  setQueryData,
  removeQuery,
  sectionIndex,
  queryType,
  isViewOnly,
  sectionId,
  maxCount,
  setInstaFormData,
}) => {
  const setDefaultInstaFormData = () => {
    query?.json_query
      ? setInstaFormData({
          handle: query?.json_query?.handle,
          max_count: query?.max_count,
          max_comments: query?.max_comments,
          hashtag: query?.json_query?.hashtag,
        })
      : setInstaFormData({ handle: [], hashtag: [], max_count: '' });
  };

  return (
    <>
      <div className={Style.LuceneQueryContainer}>
        <div style={{ paddingLeft: '4%', fontWeight: 'bold', width: '10%' }}>Query {queryIndex + 1} :</div>

        <div className={Style.LuceneQueryQueryBoxStyle}>{queryText}</div>
        <div style={{ paddingLeft: '30px', fontWeight: 'bold', width: '10%' }}>Max Count :</div>
        <div className={Style.maxCountBoxStyle}>{maxCount || ''}</div>
        <SecondaryButton
          className={'small'}
          onClick={() => {
            setQueryData({ query: query, queryType: queryType, sectionIndex: sectionIndex, queryIndex: queryIndex });
            queryType === 'instagramQueries' && setDefaultInstaFormData();
            setDrawerVisible(true);
          }}
          disabled={isViewOnly}
        >
          Edit
        </SecondaryButton>
        <SecondaryButton
          onClick={() => {
            removeQuery(sectionIndex, sectionId, queryType, query);
          }}
          className={'small'}
          disabled={isViewOnly}
        >
          Delete
        </SecondaryButton>
      </div>
    </>
  );
};
