import { notification } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import mediaPlanService from '../../../services/mediaPlanService';

const useConfigureSem = ({ data, setSemConfigurationModal, fetchSemData }) => {
  const [loading, setLoading] = useState(true);
  const formRef = useRef();
  const [configureLoading, setConfigureLoading] = useState(false);

  const [semConfigData, setSemConfigData] = useState({
    keywords: [],
    brand_keyword: [],
    competitor_keyword: [],
    local_keyword: [],
    brand_max_aggressiveness: 3,
    nb_max_aggressiveness: 1,
    brand_ctr_factor: 1,
    nb_ctr_factor: 1,
    brand_cpc_factor: 10,
    nb_cpc_factor: 10,
    top_keyword_percentage: 80,
    kpi_factor: true,
    include_bing_value: true,
    forecast_by_adgroup: true,
  });

  const fetchData = () => {
    try {
      setLoading(true);
      if (data.json_data) {
        let tempSemConfig = {
          ...semConfigData,
          ...data.json_data,
          brand_cpc_factor: data.json_data.brand_cpc_factor ? data.json_data.brand_cpc_factor * 100 : 0,
          nb_cpc_factor: data.json_data.nb_cpc_factor ? data.json_data.nb_cpc_factor * 100 : 0,
          top_keyword_percentage: data.json_data.top_keyword_percentage
            ? data.json_data.top_keyword_percentage * 100
            : 0,
        };
        setSemConfigData(tempSemConfig);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 3,
        placement: 'bottomRight',
      });
    }
  };

  const saveSemConfig = async (val) => {
    try {
      setConfigureLoading(true);
      const payload = { ...val };
      payload.brand_cpc_factor = val.brand_cpc_factor / 100;
      payload.nb_cpc_factor = val.nb_cpc_factor / 100;
      payload.top_keyword_percentage = val.top_keyword_percentage / 100;
      let finalPayload = {
        scenario_id: data?.scenario_id,
        json_data: payload,
      };
      await mediaPlanService.updateSemConfig(finalPayload);
      setSemConfigurationModal(false);
      await fetchSemData();
      setConfigureLoading(false);
    } catch (error) {
      setConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 3,
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    loading,
    formRef,
    configureLoading,
    semConfigData,
    saveSemConfig,
  };
};

export default useConfigureSem;
