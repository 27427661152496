import ApiUrls from 'src/constants/apiUrls';
import axios from 'src/http/index';

export default class mediaPlanService {
  static async getMediaPlanList(pageNum, body) {
    const res = await axios.post(ApiUrls.GET_MEDIA_PLAN(pageNum), body);
    return res.data;
  }
  static async getMediaPlanDetails(mediaplanId) {
    const url = ApiUrls.GET_MEDIA_PLAN_DETAILS(mediaplanId);
    const response = await axios.get(url);
    return response.data;
  }
  static async getMediaPlanBasicInfo(scenarioId) {
    const url = ApiUrls.GET_MEDIA_PLAN_BASIC_INFO(scenarioId);
    const response = await axios.get(url);
    return response.data;
  }
  static async createMediaplanRecommendation(payload) {
    const url = ApiUrls.CREATE_MEDIAPLAN_RECOMMENDATION;
    const response = await axios.post(url, payload);
    return response.data;
  }
  static async deleteMediaPlan(mediaplanId) {
    const url = ApiUrls.DELETE_MEDIA_PLAN(mediaplanId);
    const response = await axios.delete(url);
    return response.data;
  }
  static async updateMediaPlanAudience(payload) {
    const url = ApiUrls.UPDATE_MEDIA_PLAN_AUDIENCE;
    const response = await axios.post(url, payload);
    return response.data;
  }

  static async triggerRecommendation(payload) {
    const url = ApiUrls.TRIGGER_RECOMMENDATION;
    const response = await axios.post(url, payload);
    return response.data;
  }

  static async getMediaPlanFlightingResponse(responseId) {
    const url = ApiUrls.GET_MEDIA_PLAN_FLIGHTING_RESPONSE(responseId);
    const response = await axios.get(url);
    return response.data;
  }
  static async getMetaData(clientId) {
    const url = ApiUrls.GET_MEDIA_PLAN_META_DATA(clientId);
    const response = await axios.get(url);
    return response.data;
  }

  static async postMediaPlanScenarioRecommendation(payload) {
    const url = ApiUrls.MEDIA_PLAN_RECOMMENDATION_TRIGGER;
    const response = await axios.post(url, payload);
    return response.data;
  }

  static async archiveScenario(scenarioId) {
    const url = ApiUrls.ARCHIVE_SCENARIO(scenarioId);
    const response = await axios.delete(url);
    return response.data;
  }

  static async cloneScenario(payload) {
    const url = ApiUrls.CLONE_SCENARIO;
    const response = await axios.post(url, payload);
    return response.data;
  }

  static async cloneMediaPlan(payload) {
    const url = ApiUrls.CLONE_MEDIA_PLAN;
    const response = await axios.post(url, payload);
    return response.data;
  }

  static async getMediaPlanAudienceInfo(mediaPlanId) {
    const url = ApiUrls.GET_MEDIA_PLAN_AUDIENCE_INFO(mediaPlanId);
    const response = await axios.get(url);
    return response.data;
  }

  static async deleteMediaPlanAudience(mediaPlanAudienceId) {
    const url = ApiUrls.DELETE_MEDIA_PLAN_AUDIENCE(mediaPlanAudienceId);
    const response = await axios.delete(url);
    return response.data;
  }

  static async getMediaPlanScenario(mediaPlanId) {
    const url = ApiUrls.GET_MEDIA_PLAN_SCENARIO(mediaPlanId);
    const response = await axios.get(url);
    return response.data;
  }

  static async getMediaPlanOutput(payload) {
    const url = ApiUrls.GET_MEDIA_PLAN_OUTPUT;
    const response = await axios.post(url, payload);
    return response.data;
  }
  static getUsersByClient = async (id) => {
    const res = await axios.get(ApiUrls.GET_USERS_BY_CLIENT(id));
    return res.data;
  };

  static async getMediaPlanUserList(mediaPlanId) {
    const url = ApiUrls.GET_MEDIA_PLAN_USER_LIST(mediaPlanId);
    const response = await axios.get(url);
    return response.data;
  }

  static async createAndUpdateMediaPlanUser(payload) {
    const url = ApiUrls.CREATE_UPDATE_MEDIA_PLAN_USER;
    const response = await axios.post(url, payload);
    return response.data;
  }

  static async deleteMediaPlanUser(mediaPlanId, userId) {
    const url = ApiUrls.DELETE_MEDIA_PLAN_USER(mediaPlanId, userId);
    const response = await axios.delete(url);
    return response.data;
  }

  static async getSemData(scenarioId) {
    const url = ApiUrls.GET_SEM_DATA(scenarioId);
    const response = await axios.get(url);
    return response.data;
  }

  static async getSemKeyword(adGroupId) {
    const url = ApiUrls.GET_SEM_KEYWORD(adGroupId);
    const response = await axios.get(url);
    return response.data;
  }

  static async updateSemKeyword(payload) {
    const url = ApiUrls.UPDATE_SEM_KEYWORD;
    const response = await axios.post(url, payload);
    return response.data;
  }

  static async semRetrigger(payload) {
    const url = ApiUrls.SEM_RETRIGGER;
    const response = await axios.post(url, payload);
    return response.data;
  }

  static async updateSemConfig(payload) {
    const url = ApiUrls.MEDIA_PLAN_SEM_CONFIG_UPDATE;
    const response = await axios.post(url, payload);
    return response.data;
  }
}
