import React, { useRef, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsVenn from 'highcharts/modules/venn';
import { Drawer, Table } from 'antd';
import { useVennDiagram } from './vennDiagram.hooks';
import Style from './vennDiagram.style';
import { toSentenceCase } from '../../utils';
import ArticleTableDrawer from '../ArticlesTableDrawer';
import { SecondaryButton } from 'src/components/basic';

highchartsVenn(Highcharts);

const VennDiagramComponent = ({ data, columnName, loading, analysisId }) => {
  const chartRef = useRef(null);
  const {
    chartOptions,
    isDrawerVisible,
    drawerContent,
    hoverInfo,
    handleSetClick,
    handleSetHover,
    handleSetMouseOut,
    handleDrawerClose,
    isViewArticles,
    setIsViewArticles,
  } = useVennDiagram(data);

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current.chart;

      chart.series[0].points.forEach((point) => {
        point?.graphic?.element?.addEventListener('click', () => handleSetClick(point));
        point?.graphic?.element?.addEventListener('mouseover', () => handleSetHover(point));
        point?.graphic?.element?.addEventListener('mouseout', handleSetMouseOut);
      });
    }
  }, [data, handleSetClick, handleSetHover, handleSetMouseOut]);

  return (
    <div className={Style.VennDiagramWrapper}>
      {!loading && data && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />}
      {hoverInfo && (
        <div className={Style.HoverTooltip}>
          <h3>
            Overlapping {toSentenceCase(columnName)}: {hoverInfo?.count}
          </h3>
          <Table
            className="dark-table"
            columns={[
              {
                title: toSentenceCase(columnName),
                dataIndex: 'entityValue',
                key: 'entityValue',
              },
              {
                title: 'No. of posts',
                dataIndex: 'postCount',
                key: 'postCount',
              },
            ]}
            dataSource={[
              ...(hoverInfo?.topics?.map(([entityValue, count]) => ({
                key: entityValue,
                entityValue: entityValue,
                postCount: count,
              })) || []),
            ].slice(0, 5)}
            pagination={false}
          />
        </div>
      )}
      <Drawer
        className={`${Style.overlapDrawer} ${Style.fullScreenDrawer}`}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '10px' }}>
            <span>Overlapping {toSentenceCase(columnName)}</span>
            <SecondaryButton className={'small'} onClick={() => setIsViewArticles(true)}>
              View Posts
            </SecondaryButton>
          </div>
        }
        placement="right"
        onClose={handleDrawerClose}
        visible={isDrawerVisible}
        width={500}
        // getContainer={false}
      >
        {drawerContent && (
          <>
            <Table
              className="dark-table"
              columns={[
                {
                  title: toSentenceCase(columnName),
                  dataIndex: 'entityValue',
                  key: 'entityValue',
                },
                {
                  title: 'No. of posts',
                  dataIndex: 'postCount',
                  key: 'postCount',
                },
              ]}
              dataSource={[
                ...Object.entries(drawerContent).map(([entityValue, count]) => ({
                  key: entityValue,
                  entityValue: entityValue,
                  postCount: count,
                })),
              ]}
              pagination={true}
              showSizeChanger={false}
            />
          </>
        )}
      </Drawer>

      <ArticleTableDrawer
        drawerContent={drawerContent}
        columnName={columnName}
        visible={isViewArticles}
        analysisId={analysisId}
        onClose={() => setIsViewArticles(false)}
      />
    </div>
  );
};

export default VennDiagramComponent;
