import { DatePicker, Input, Select } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import './dataSourceTabLayout.style.less';

import {
  BEHAVIOUR,
  COMBINATOR_AND,
  COMBINATOR_ANY,
  DEMOGRAPHIC,
  OPERATOR_BETWEEN,
  OPERATOR_CONTAINS,
  OPERATOR_ENDS_WITH,
  OPERATOR_EQUALS,
  OPERATOR_IN,
  OPERATOR_IS,
  OPERATOR_LIKE,
  OPERATOR_NOT_BETWEEN,
  OPERATOR_NOT_IN,
  OPERATOR_NOT_LIKE,
  OPERATOR_STARTS_WITH,
} from '../../../constants/queryBuilderConstants';

const useQueryBuilder = ({
  conditions,
  setConditions,
  fields,
  behaviourDirectory,
  isReadOnly,
  addFeaturesToFields,
}) => {
  const [showBrowseDrawer, setShowBrowseDrawer] = useState(false);
  const [browseParent, setBrowseParent] = useState(null);
  const [applyRules, setApplyRules] = useState(false);
  const [fieldDirectory, setFieldDirectory] = useState({});
  const [ruleType, setRuleType] = useState(BEHAVIOUR);
  const [searchQuery, setSearchQuery] = useState('');
  const [browsedSelectedItems, setBrowsedSelectedItems] = useState([]);

  const addBrowsedItemsToGroup = useCallback(
    (parent, selectedItems) => {
      if (isReadOnly) return;

      parent.rules = parent.rules.filter(
        (rule) => rule.field === undefined || selectedItems.includes(rule.field_readable_name)
      );

      selectedItems.forEach((item) => {
        if (!parent.rules.some((rule) => rule.field_readable_name === item.field_readable_name)) {
          if (parent.rule_type === BEHAVIOUR) {
            parent.rules.push({
              field: item.field_readable_name,
              field_readable_name: item.field_readable_name,
              behaviour_name: item.field_readable_name,
              value: { value: '1', value_description: 'True' },
              operator: OPERATOR_IS,
              rule_type: parent?.rule_type,
            });
          } else {
            const allFields = [
              ...fields.demographic_features,
              ...fields.behaviour_features,
              ...fields.first_party_features,
            ];
            const field = allFields.find((f) => f.label === item.field_readable_name) ?? {};
            parent.rules.push({
              field: field.name,
              field_readable_name: field.label,
              operator: item.operator,
              value: item.value,
              rule_type: parent?.rule_type,
            });
          }
        }
      });

      setConditions({ ...conditions });
    },
    [conditions, fields, isReadOnly, setConditions]
  );

  const handleCancel = useCallback(() => {
    setBrowsedSelectedItems([]);
    setBrowseParent(null);
    setShowBrowseDrawer(false);
    setSearchQuery('');
  }, []);

  const handleBrowseConfirm = useCallback(() => {
    setApplyRules(true);
    setSearchQuery('');
    if (browseParent?.rule_type === BEHAVIOUR) {
      addFeaturesToFields(browsedSelectedItems);
    }
    addBrowsedItemsToGroup(browseParent, browsedSelectedItems);
    setShowBrowseDrawer(false);
    setApplyRules(false);
  }, [addBrowsedItemsToGroup, addFeaturesToFields, browsedSelectedItems, browseParent]);

  const addRule = useCallback(
    (parent) => {
      if (isReadOnly) return;
      if (parent.rule_type === BEHAVIOUR) {
        setFieldDirectory(behaviourDirectory);
      } else if (parent.rule_type === DEMOGRAPHIC) {
        setFieldDirectory(fields.demographic_features);
      } else {
        setFieldDirectory(fields.first_party_features);
      }
      setRuleType(parent.rule_type);
      setBrowseParent(parent);
      if (parent.rules && Array.isArray(parent.rules)) {
        setBrowsedSelectedItems(parent.rules);
      } else {
        setBrowsedSelectedItems([]);
      }
      setShowBrowseDrawer(true);
    },
    [behaviourDirectory, fields, isReadOnly]
  );

  const addGroup = useCallback(
    (parent) => {
      if (isReadOnly) return;
      parent.rules.push({ combinator: COMBINATOR_AND, rules: [], rule_type: parent?.rule_type });
      setConditions({ ...conditions });
    },
    [conditions, isReadOnly, setConditions]
  );

  const addFeatureGroup = useCallback(
    (parent, feature) => {
      if (isReadOnly) return;
      parent.rules.push({ combinator: COMBINATOR_AND, rules: [], rule_type: feature });
      setConditions({ ...conditions });
    },
    [conditions, isReadOnly, setConditions]
  );

  const removeRuleOrGroup = useCallback(
    (parent, index) => {
      if (isReadOnly) return;
      parent.rules.splice(index, 1);
      setConditions({ ...conditions });
    },
    [conditions, isReadOnly, setConditions]
  );

  const formatValue = (value) => {
    if (Array.isArray(value)) {
      return value.map((val) =>
        typeof val === 'object'
          ? {
              value: val.value,
              value_description: val.value_description ? val.value_description : val.value,
            }
          : { value: val, value_description: val }
      );
    } else if (typeof value === 'object' && value !== null) {
      return {
        value: value.value,
        value_description: value.value_description ? value.value_description : value.value,
      };
    } else {
      return { value: value, value_description: value };
    }
  };

  const handleFieldChange = useCallback(
    (rule, field, value) => {
      if (isReadOnly) return;

      rule[field] = value;

      if (field === 'field') {
        updateFieldOperator(rule, value);
      }

      if (field === 'value') {
        updateFieldValue(rule, value);
      }

      setConditions({ ...conditions });
    },
    [conditions, fields, isReadOnly, setConditions]
  );

  const updateFieldOperator = (rule, value) => {
    const allFields = [...fields.demographic_features, ...fields.behaviour_features, ...fields.first_party_features];
    const selectedField = allFields.find((f) => f.name === value);
    if (selectedField && selectedField.category === BEHAVIOUR) {
      rule.operator = OPERATOR_IS;
      rule.behaviour_name = value;
    } else {
      rule.operator = OPERATOR_EQUALS;
    }
  };

  const updateFieldValue = (rule, value) => {
    if (rule.operator === OPERATOR_BETWEEN || rule.operator === OPERATOR_NOT_BETWEEN) {
      if (Array.isArray(value) && value.length === 2) {
        rule.value = formatValue(value);
      }
    } else if (
      typeof value === 'object' &&
      value !== null &&
      (rule.operator === OPERATOR_STARTS_WITH ||
        rule.operator === OPERATOR_ENDS_WITH ||
        rule.operator === OPERATOR_CONTAINS)
    ) {
      rule.value = { value: value.value, value_description: value.value };
    } else {
      rule.value = formatValue(value);
    }
  };
  const handleCombinatorChange = useCallback(
    (condition, value) => {
      if (isReadOnly) return;
      condition.combinator = value;
      if (value === COMBINATOR_ANY) {
        condition.number = 1;
      } else {
        delete condition.number;
      }
      setConditions({ ...conditions });
    },
    [conditions, isReadOnly, setConditions]
  );

  const handleNumberChange = useCallback(
    (condition, value) => {
      if (isReadOnly) return;
      condition.number = value;
      setConditions({ ...conditions });
    },
    [conditions, isReadOnly, setConditions]
  );

  const getSelectedValues = (value, hasPredefinedValues) => {
    if (Array.isArray(value)) {
      return value
        .map((val) => hasPredefinedValues.find((preVal) => preVal.value === val))
        .filter((val) => val !== undefined);
    } else {
      return hasPredefinedValues.find((val) => val.value === value);
    }
  };

  const filterOptions = (input, option) => {
    const optionText = option.children;
    return optionText.toLowerCase().includes(input.toLowerCase());
  };

  const renderSelect = (rule, hasPredefinedValues, handleFieldChange, isReadOnly, mode) => (
    <Select
      mode={mode}
      value={
        Array.isArray(rule.value) ? rule.value.filter((val) => val !== '') : rule.value !== '' ? rule.value : undefined
      }
      onChange={(value) => {
        const selectedVals = getSelectedValues(value, hasPredefinedValues);
        handleFieldChange(rule, 'value', selectedVals);
      }}
      placeholder="Select values"
      style={{ width: '30%', marginRight: 10 }}
      disabled={isReadOnly}
      showSearch
      filterOption={filterOptions}
    >
      {hasPredefinedValues
        .sort((a, b) => a.value_description.localeCompare(b.value_description))
        .map((val) => (
          <Select.Option key={val.value} value={val.value}>
            {val.value_description}
          </Select.Option>
        ))}
    </Select>
  );

  const renderInput = (rule, handleFieldChange, isReadOnly) => (
    <Input
      value={rule?.value?.value}
      onChange={(e) => handleFieldChange(rule, 'value', { value: e.target.value })}
      placeholder="Value"
      style={{ width: '30%', marginRight: 10 }}
      disabled={isReadOnly}
    />
  );

  const renderBetweenInputs = (rule, field, handleFieldChange, isReadOnly, format) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {field.valueEditorType === 'date' ? (
        <>
          <DatePicker
            value={rule?.value?.[0]?.value ? dayjs(rule.value[0].value, format) : null}
            onChange={(date) =>
              handleFieldChange(rule, 'value', [{ value: date ? dayjs(date).format(format) : null }, rule.value[1]])
            }
            placeholder="Start"
            style={{ width: '30%', marginRight: 10 }}
            disabled={isReadOnly}
          />
          <DatePicker
            value={rule?.value?.[1]?.value ? dayjs(rule.value[1].value, format) : null}
            onChange={(date) =>
              handleFieldChange(rule, 'value', [rule.value[0], { value: date ? dayjs(date).format(format) : null }])
            }
            placeholder="End"
            style={{ width: '30%', marginRight: 10 }}
            disabled={isReadOnly}
          />
        </>
      ) : (
        <>
          <Input
            type={field.valueEditorType === 'number' ? 'number' : 'text'}
            value={rule?.value?.[0]?.value}
            onChange={(e) => handleFieldChange(rule, 'value', [{ value: e.target.value }, rule.value[1]])}
            placeholder="Start"
            style={{ width: '30%', marginRight: 10 }}
            disabled={isReadOnly}
          />
          <Input
            type={field.valueEditorType === 'number' ? 'number' : 'text'}
            value={rule?.value?.[1]?.value}
            onChange={(e) => handleFieldChange(rule, 'value', [rule.value[0], { value: e.target.value }])}
            placeholder="End"
            style={{ width: '30%', marginRight: 10 }}
            disabled={isReadOnly}
          />
        </>
      )}
    </div>
  );

  const renderDefaultInput = (rule, field, handleFieldChange, isReadOnly) => {
    let errorMessage = `Value should be between ${field.min} and ${field.max}`;
    switch (field.valueEditorType) {
      case 'select':
      case 'boolean':
        return renderSelect(rule, field.values, handleFieldChange, isReadOnly, 'single');
      case 'number':
        return (
          <>
            <Input
              type="number"
              value={rule?.value?.value}
              onChange={(e) => {
                const value = e.target.value;
                handleFieldChange(rule, 'value', { value });
              }}
              placeholder="Value"
              style={{ width: '30%', marginRight: 10 }}
              disabled={isReadOnly}
              min={field.min !== null ? field.min : 0}
              max={field.max !== null ? field.max : undefined}
            />
            {rule?.value?.value > field.max ||
              (rule?.value?.value < field.min && <span className="error">{errorMessage}</span>)}
          </>
        );
      case 'date': {
        const dateValue = rule.value?.value ? dayjs(rule.value.value, field.format || 'YYYYMMDD') : null;
        return (
          <DatePicker
            value={dateValue}
            onChange={(date) =>
              handleFieldChange(rule, 'value', date ? dayjs(date).format(field.format || 'YYYYMMDD') : null)
            }
            placeholder="Value"
            showMonthYearPicker={field.format === 'YYYYMM'}
            disabled={isReadOnly}
            style={{ width: '30%', marginRight: 10 }}
          />
        );
      }
      default:
        return renderInput(rule, handleFieldChange, isReadOnly);
    }
  };

  const renderValueEditor = useCallback(
    (rule, hasPredefinedValues, field) => {
      const format = field.format || 'YYYYMMDD';

      switch (rule.operator) {
        case OPERATOR_IN:
        case OPERATOR_NOT_IN:
          return field.valueEditorType === 'select'
            ? renderSelect(rule, hasPredefinedValues, handleFieldChange, isReadOnly, 'multiple')
            : renderInput(rule, handleFieldChange, isReadOnly);
        case OPERATOR_BETWEEN:
        case OPERATOR_NOT_BETWEEN:
          return renderBetweenInputs(rule, field, handleFieldChange, isReadOnly, format);
        case OPERATOR_CONTAINS:
        case OPERATOR_STARTS_WITH:
        case OPERATOR_ENDS_WITH:
        case OPERATOR_LIKE:
        case OPERATOR_NOT_LIKE:
          return renderInput(rule, handleFieldChange, isReadOnly);
        default:
          return renderDefaultInput(rule, field, handleFieldChange, isReadOnly);
      }
    },
    [handleFieldChange, isReadOnly]
  );

  return {
    showBrowseDrawer,
    applyRules,
    fieldDirectory,
    ruleType,
    searchQuery,
    browsedSelectedItems,
    setSearchQuery,
    setBrowsedSelectedItems,
    handleCancel,
    handleBrowseConfirm,
    addRule,
    addGroup,
    addFeatureGroup,
    removeRuleOrGroup,
    handleFieldChange,
    handleCombinatorChange,
    handleNumberChange,
    renderValueEditor,
  };
};

export default useQueryBuilder;
