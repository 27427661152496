import { useState } from 'react';

const useMediaPlanFlighting = ({ data }) => {
  let initialObjective = '';
  let initialTabData = null;
  if (data?.flighting.length > 0) {
    initialObjective = data.flighting;
    initialTabData = data.flighting;
  }

  const [tab, setTab] = useState(initialObjective);
  const [tabData, setTabData] = useState(initialTabData);

  const onTabChange = (objective, data) => {
    setTab(objective);
    setTabData(data);
  };

  return {
    tab,
    setTab,
    tabData,
    setTabData,
    onTabChange,
  };
};

export default useMediaPlanFlighting;
