import { Modal, notification } from 'antd';
import { useRef, useState } from 'react';
import appUrls from 'src/constants/appUrls';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import Style from 'src/modules/aiApps/components/aiApp.style.js';
import { AiAppApiServices } from 'src/modules/aiApps/services/aiAppApiServices';

export default function useSideNavBarHook({
  history,
  multipleChatSessions,
  setSessionToggle,
  data,
  setStreamingChatID,
  setGeneratingResponse,
  cancellationStreamingRef,
  setUserMessage,
  generatingResponse,
  createNewSessionRef,
  scrollSingleSesion,
  sessionLoading,
  singleChatSessions,
  cancelApiCall,
  mode,
  clientId,
  scrollTaskSesion,
  updateTask,
  sessionId,
}) {
  const mAiApp = data?.id;
  const [renameModal, setRenameModal] = useState(false);
  const [disclaimerModal, setDisclaimerModal] = useState(false);
  const [renameData, setRenameData] = useState(null);
  const containerRef = useRef(null);
  const [visiblePopoverId, setVisiblePopoverId] = useState(null);

  const closeRenameModal = () => {
    setRenameModal(false);
  };
  const openRenameModal = (item, idx) => {
    setRenameData({
      data: item,
      idx: idx,
    });
    setRenameModal(true);
  };

  const toggleSession = (val) => {
    if (val === 'MY_CHAT') {
      setSessionToggle('MY_CHAT');
      createNewSessionRef.current.created = false;
      if (singleChatSessions?.length > 0) {
        cancelApiCall();
        history.push(appUrls.APP_PAGE(clientId, mAiApp, singleChatSessions[0]?.id, mode));
      } else {
        cancelApiCall();
        history.push(appUrls.APP_PAGE(clientId, mAiApp, undefined, mode));
      }
      setUserMessage('');
      if (generatingResponse) {
        cancellationStreamingRef.current.terminated = true;
      }
      setGeneratingResponse(false);
      setStreamingChatID(null);
    } else {
      cancelApiCall();
      const groupChatId = multipleChatSessions?.[0]?.id;
      setSessionToggle('MULTI_CHAT');
      createNewSessionRef.current.created = false;
      history.push(appUrls.APP_PAGE(clientId, mAiApp, groupChatId, mode));
      setUserMessage('');
      if (generatingResponse) {
        cancellationStreamingRef.current.terminated = true;
      }
      setGeneratingResponse(false);
      setStreamingChatID(null);
    }
  };

  const toggleSingleChat = (id, taskMode = false, taskStatus) => {
    cancelApiCall();
    history.push(appUrls.APP_PAGE(clientId, mAiApp, id, mode, taskMode, taskStatus));
    createNewSessionRef.current.created = false;
    setUserMessage('');
    if (generatingResponse) {
      cancellationStreamingRef.current.terminated = true;
    }
    setGeneratingResponse(false);
    setStreamingChatID(null);
  };

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight && !sessionLoading) {
      scrollSingleSesion();
    }
  };

  const handleTaskContainerScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight && !sessionLoading) {
      scrollTaskSesion();
    }
  };

  const pauseTask = async (id, idx, status) => {
    try {
      const payload = {
        task_id: id,
        status: status,
      };

      const res = await AiAppApiServices.createEditTask(payload, mAiApp);
      notification.success({
        message: `Task ${status === 'PAUSED' ? 'Paused' : status === 'SCHEDULED' ? 'Resumed' : 'Deleted'} Successfully`,
        duration: 3,
        placement: 'bottomRight',
      });

      if (status === 'ARCHIVED') {
        updateTask({}, 'delete', idx);
      } else {
        updateTask({ ...res.data, task_status: status }, 'update', idx);
      }
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const deleteTask = async (id, idx) => {
    Modal.warn({
      title: 'Are you sure you want to delete this task?',
      okText: 'Okay',
      cancelText: 'Cancel',
      okCancel: true,
      onOk: async () => {
        try {
          await AiAppApiServices.deleteChatHistory(mAiApp, id);
          if (id === sessionId) {
            history.push(appUrls.APP_PAGE(clientId, mAiApp, null, mode));
          }
          updateTask({}, 'delete', idx);
          notification.success({
            message: 'Task Deleted Successfully',
            duration: 3,
            placement: 'bottomRight',
          });
        } catch (error) {
          const { msg } = getErrorMessageFromResponse(error);
          notification.error({
            message: msg || 'Failed to delete the Session',
            duration: 6,
            placement: 'bottomRight',
          });
        }
      },
      className: Style.confirmModal,
    });
  };

  return {
    toggleSession,
    toggleSingleChat,
    containerRef,
    handleScroll,
    renameModal,
    closeRenameModal,
    openRenameModal,
    renameData,
    disclaimerModal,
    setDisclaimerModal,
    handleTaskContainerScroll,
    pauseTask,
    deleteTask,
    visiblePopoverId,
    setVisiblePopoverId,
  };
}
