import { MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Dropdown, Modal, Tabs, Typography } from 'antd';
import React from 'react';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from 'src/components/loadingSpinner';
import { formatNumberWithPercentageAndCeil, justFormatNumber } from 'src/lib/utils';
import NcBreadCrumb from 'src/modules/newsAndCulture/components/NcBreadCrumb';
import { CLUSTER_BASED } from '../../../constants/constants';
import AttitudinalOverview from './AttitudinalOverview';
import './audienceDetails.style.less';
import AudienceOverview from './AudienceOverview';
import BrandAffinities from './BrandAffinities';
import DemographicOverview from './DemographicOverview';
import DownloadManagerComponent from './DownloadManagerComponent';
import GeographicHeatMap from './GeographicHeatMap';
import MultiplierTabComponent from './MultiplierTabComponent';
import useAudienceDetailsHook from './useAudienceDetailsHook';
import queryBuilderService from '../../../services/queryBuilderService';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const AudienceDetailsComponent = ({ match }) => {
  const clientId = match.params.clientId;
  const audienceId = match.params.audienceId;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const audienceProfileId = queryParams.get('audience-profile-id');
  const {
    audienceLoading,
    audienceData,
    audienceFeatures,
    behaviourFeaturesCategories,
    brandAffinityCategories,
    handleSearch,
    searchFeatureLoading,
    audienceProfile,
    isModalVisible,
    setIsModalVisible,
    isDownloadModalVisible,
    setIsDownloadModalVisible,
    activeTab,
    setActiveTab,
    menuOverlay,
  } = useAudienceDetailsHook(audienceId, clientId, audienceProfileId);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDownloadModalOk = () => {
    setIsDownloadModalVisible(false);
  };

  const handleDownloadModalCancel = () => {
    setIsDownloadModalVisible(false);
  };

  if (audienceLoading) {
    return <LoadingSpinner centered />;
  }

  return (
    <div className="audience-details-container">
      <NcBreadCrumb className="breadCrumb" />
      <div className="header">
        <div className="header-content">
          <Title level={2} className="title">
            {audienceData?.audience_type === CLUSTER_BASED ? audienceProfile.cluster_name : audienceData?.name}
          </Title>
          <Dropdown overlay={menuOverlay} trigger={['click']}>
            <MenuUnfoldOutlined
              style={{ color: 'var(--color-white)', fontSize: '20px', cursor: 'pointer' }}
              onClick={(e) => e.stopPropagation()}
            />
          </Dropdown>
        </div>
        <Text className="description">
          {audienceData?.audience_type === CLUSTER_BASED
            ? audienceProfile.cluster_description
            : audienceData?.description}
        </Text>
        <Text className="audience-size">
          Audience Size: {justFormatNumber(audienceProfile?.audience_size)} (
          {formatNumberWithPercentageAndCeil((audienceProfile?.audience_size / audienceProfile?.base_size) * 100)} of
          total population)
        </Text>
      </div>

      <div className="audience-persona-section">
        <div className="persona-section">
          <div className="persona-description">
            <Text className="persona-description-text">
              <b>Persona Description:</b> {audienceProfile?.persona_description}
            </Text>
          </div>
        </div>
      </div>

      <Tabs activeKey={activeTab} className="tabs" onChange={handleTabChange}>
        <TabPane tab="Demographic Overview" key="1">
          <DemographicOverview audienceFeatures={audienceFeatures} audienceId={audienceProfileId} />
        </TabPane>
        <TabPane tab="Geographic Heatmap" key="2">
          <GeographicHeatMap audienceId={audienceProfileId} />
        </TabPane>
        <TabPane tab="Attitudinal Overview" key="3">
          <AttitudinalOverview
            audienceId={audienceProfileId}
            behaviourFeaturesCategories={behaviourFeaturesCategories}
            activeTab={activeTab}
          />
        </TabPane>
        <TabPane tab="Brand Affinities" key="4">
          <BrandAffinities
            audienceId={audienceProfileId}
            brandAffinityCategories={brandAffinityCategories}
            activeTab={activeTab}
          />
        </TabPane>
        <TabPane tab="All Features Ranked" key="5">
          <AudienceOverview
            audienceFeatures={audienceFeatures}
            handleSearch={handleSearch}
            searchFeatureLoading={searchFeatureLoading}
            audienceProfileId={audienceProfileId}
          />
        </TabPane>
        <TabPane tab="Media Attribution" key="6">
          <MultiplierTabComponent audienceProfileId={audienceProfileId} clientId={clientId} />
        </TabPane>
      </Tabs>
      <Modal
        title="Query"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            OK
          </Button>,
        ]}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Query Summary" key="1">
            <pre className="sql-query">
              {queryBuilderService.generateQuery(audienceData?.type_based_config?.json_data?.rules)}
            </pre>
          </TabPane>
          <TabPane tab="SQL Query" key="2">
            <pre className="sql-query">{audienceProfile?.sql_query}</pre>
          </TabPane>
        </Tabs>
      </Modal>
      <Modal
        title="Download Manager"
        visible={isDownloadModalVisible}
        onOk={handleDownloadModalOk}
        onCancel={handleDownloadModalCancel}
        footer={null}
      >
        <DownloadManagerComponent
          audienceProfileId={audienceProfileId}
          handleDownloadModalCancel={handleDownloadModalCancel}
        />
      </Modal>
    </div>
  );
};

export default AudienceDetailsComponent;
