import { PlusCircleOutlined } from '@ant-design/icons';
import { Form, Input, Row, Upload, Tabs } from 'antd';
import React from 'react';
import { RoundedOutlinedButtonPrimary, RoundedPrimaryButton } from 'src/components/basic';
import Style from './fileUpload.style';
import { Card } from 'antd';
import { DeleteOutlined, FileOutlined } from '@ant-design/icons';

export default function FileUploadForm({ uploadFile, sessionId, createSesionWithFile, fileList, deleteFile }) {
  const formRef = React.useRef();
  const [loading, setLoading] = React.useState(false);
  return (
    <Tabs
      className={Style.tabsStyle}
      defaultActiveKey="1"
      centered
      tabBarStyle={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }}
    >
      <Tabs.TabPane
        tab={
          <div
            style={{
              width: window.innerWidth <= 768 ? '50%' : '100%',
              textAlign: 'center',
              fontSize: window.innerWidth <= 768 ? '14px' : '16px',
            }}
          >
            Upload File
          </div>
        }
        key="1"
      >
        <div className={Style.headingText}></div>
        <Form
          ref={formRef}
          className={Style.form}
          requiredMark={false}
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 18,
          }}
          onFinish={async (val) => {
            setLoading(true);
            if (sessionId) {
              await uploadFile(val);
            } else {
              await createSesionWithFile(val);
            }
            setLoading(false);
          }}
        >
          <Form.Item
            label="Name"
            name="name"
            labelAlign="left"
            rules={[
              {
                validator: (_, value) => {
                  if (value) {
                    if (!value.trim().length) {
                      return Promise.reject(new Error('Name is required.'));
                    }
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              },
              {
                required: false,
                message: 'Name is required.',
              },
            ]}
          >
            <Input placeholder="Enter name of the file" style={{ borderRadius: '5px' }} maxLength={15} />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            labelAlign="left"
            rules={[
              {
                validator: (_, value) => {
                  if (value) {
                    if (!value.trim().length) {
                      return Promise.reject(new Error('Description is required.'));
                    }
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              },
              {
                required: false,
                message: 'Description is required.',
              },
            ]}
          >
            <Input.TextArea
              placeholder="Enter description about what is there in the file"
              showCount
              style={{ borderRadius: '5px' }}
              maxLength={60}
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
          <Form.Item
            name="file"
            labelAlign="left"
            label="File"
            rules={[
              {
                validator: (_, value) => {
                  if (value) {
                    if (value.status === 'removed') {
                      return Promise.reject(new Error('File is required.'));
                    }
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error('File is required.'));
                  }
                },
              },
            ]}
          >
            <Uploader />
          </Form.Item>
          <Row justify="end">
            <RoundedPrimaryButton htmlType="submit" loading={loading}>
              Done
            </RoundedPrimaryButton>
          </Row>
        </Form>
      </Tabs.TabPane>

      <Tabs.TabPane
        tab={
          <div
            style={{
              width: window.innerWidth <= 768 ? '50%' : '100%',
              textAlign: 'center',
              fontSize: window.innerWidth <= 768 ? '14px' : '16px',
            }}
          >
            Existing Files
          </div>
        }
        key="2"
      >
        {fileList && fileList.length > 0 ? (
          <div className={Style.deleteFileCard} style={{ marginTop: '20px' }}>
            {fileList.map((item) => (
              <Card key={item.id} className={Style.deleteFileCard} style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', paddingLeft: '10px' }}>
                  <span style={{ fontWeight: 'bold', color: '#FFFFFF' }}>{item.name}</span>
                </div>
                <DeleteOutlined onClick={() => deleteFile(item.id)} className={Style.deleteFileIcon} />
              </Card>
            ))}
          </div>
        ) : (
          <div
            style={{
              textAlign: 'center',
              marginTop: '20px',
              color: '#888',
              minHeight: '43vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FileOutlined className={Style.fileIcon} style={{ fontSize: '70px', marginBottom: '10px' }} />
            <p style={{ fontSize: '18px', fontWeight: 'bold' }}>No files uploaded yet.</p>
            <p>
              Go to <b>&quot;Upload File&quot;</b> tab to upload a new file.
            </p>
          </div>
        )}
      </Tabs.TabPane>
    </Tabs>
  );
}

function Uploader({ onChange }) {
  return (
    <Upload
      className={Style.uploadFileSection}
      accept=".xlsx, .csv, .pdf, .docx, .png, .jpeg, .jpg, .gif"
      showUploadList={true}
      maxCount={1}
      beforeUpload={(file) => {
        const reader = new FileReader();
        reader.readAsText(file);
        return false;
      }}
      onChange={(event) => {
        const file = event.file;
        onChange(file);
      }}
    >
      <RoundedOutlinedButtonPrimary>
        <PlusCircleOutlined />
        &nbsp;Upload
      </RoundedOutlinedButtonPrimary>
    </Upload>
  );
}
