import { LeftOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Button, Image, Skeleton, Table, Typography, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import * as LabelerStyles from '../css/labeler';
import LabelerApiService from '../services/api';
import { formatDate } from '../utils';
import * as Styles from './TaskDetailView.styles';
import { notification } from 'antd';

const { Title, Text } = Typography;

const TaskDetailSkeleton = () => (
  <div className={Styles.taskDetailContainer}>
    <div className={Styles.taskDetailSection}>
      <Skeleton active paragraph={{ rows: 4 }} />
    </div>
    <div className={Styles.taskDetailSection}>
      <Skeleton active paragraph={{ rows: 3 }} />
    </div>
    <div className={Styles.taskDetailSection}>
      <Skeleton active paragraph={{ rows: 3 }} />
    </div>
  </div>
);

const TaskDetailView = () => {
  const { t } = useTranslation();
  const { clientId, taskId } = useParams();
  const history = useHistory();
  const [taskDetails, setTaskDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTaskDetails = async () => {
      try {
        setLoading(true);
        const response = await LabelerApiService.getLabelingTaskDetails(clientId, taskId);
        if (response.success) {
          setTaskDetails(response.data);
        } else {
          notification.error({
            duration: 5,
            placement: 'bottomRight',
            message: 'Failed to fetch task details',
          });
        }
      } catch (error) {
        notification.error({
          duration: 5,
          placement: 'bottomRight',
          message: 'An error occurred while fetching task details',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchTaskDetails();
  }, [clientId, taskId]);

  const handleBackClick = () => {
    history.push(`/client/${clientId}/labeler`);
  };

  if (loading) {
    return <TaskDetailSkeleton />;
  }

  if (!taskDetails) {
    return <div className={Styles.taskDetailNoData}>{t('labeler.no_task_details_found')}</div>;
  }

  const inputColumnsData =
    taskDetails.input_columns?.map((col, index) => ({
      key: index,
      name: col.name,
      type: col.type,
      description: col.description || '-',
    })) || [];

  const outputColumnsData =
    taskDetails.output_columns?.map((col, index) => ({
      key: index + inputColumnsData.length,
      name: col.name,
      description: col.description || '-',
    })) || [];

  const outputData =
    taskDetails.output_data?.map((data, index) => ({
      key: index,
      ...data,
    })) || [];

  const renderCellContent = (text, columnKey) => {
    const column = inputColumnsData.find((col) => col.name === columnKey);

    if (!column || !text) return text;

    switch (column.type) {
      case 'IMAGE':
        if (text?.includes('drive.google.com')) {
          return (
            <Typography.Link href={text} target="_blank" rel="noopener noreferrer">
              {t('labeler.view_image')}
            </Typography.Link>
          );
        }
        return <Image src={text} alt={columnKey} width={100} />;

      case 'URL':
        return (
          <Typography.Link href={text} target="_blank" rel="noopener noreferrer">
            {text}
          </Typography.Link>
        );

      case 'VIDEO':
        if (text?.includes('drive.google.com')) {
          return (
            <Typography.Link href={text} target="_blank" rel="noopener noreferrer">
              {t('labeler.view_video')}
            </Typography.Link>
          );
        }
        return (
          <video width="150" controls>
            <source src={text} type="video/mp4" />
            {/* Fallback content for browsers that do not support the <video> element */}
            <Typography.Link href={text} target="_blank" rel="noopener noreferrer">
              {t('labeler.view_video')}
            </Typography.Link>
          </video>
        );

      default:
        return text;
    }
  };

  // Generate dynamic columns for the output data based on input and output columns
  const inputColumnNames = inputColumnsData.map((col) => col.name);
  const outputColumnNames = outputColumnsData.map((col) => col.name);

  const outputDataTableColumns = [
    {
      title: t('labeler.success'),
      key: 'success',
      render: (text, record) =>
        record.aai_labeler_error ? (
          <CloseCircleOutlined style={{ color: 'red' }} />
        ) : (
          <CheckCircleOutlined style={{ color: 'green' }} />
        ),
    },
    // Input columns first
    ...inputColumnNames.map((key) => ({
      title: key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase()),
      dataIndex: key,
      key: key,
      render: (text) => renderCellContent(text, key),
    })),
    // Output columns next
    ...outputColumnNames.map((key) => ({
      title: key.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase()),
      dataIndex: key,
      key: key,
    })),
  ];

  const WrapWithTooltip = ({ children, record }) => {
    if (record?.aai_labeler_error) {
      return (
        <Tooltip
          title={`Error: ${record.aai_labeler_error}`}
          placement="topLeft"
          overlayClassName={Styles.tooltipError}
          color="var(--feedback-dislike)"
        >
          {children}
        </Tooltip>
      );
    }
    return children;
  };

  return (
    <div className={Styles.taskDetailContainer}>
      <div className={Styles.taskDetailBackButton} onClick={handleBackClick}>
        <Button type="link" icon={<LeftOutlined />}>
          {t('back')}
        </Button>
      </div>

      <Title level={3} className={Styles.taskDetailTitle}>
        {taskDetails.name}
      </Title>

      <div className={Styles.taskDetailSection}>
        <Title level={5} className={Styles.taskDetailSectionTitle}>
          {t('labeler.task_details')}
        </Title>
        <div className={Styles.taskDetailInfoGrid}>
          <Text strong>{t('labeler.status')}:</Text>
          <Text>{taskDetails.status}</Text>
          <Text strong>{t('labeler.progress')}:</Text>
          <Text>{taskDetails.progress}%</Text>
          <Text strong>{t('labeler.created_on')}:</Text>
          <Text>{formatDate(taskDetails.created_on)}</Text>
          <Text strong>{t('labeler.data_source')}:</Text>
          <Text>{taskDetails.data_source?.type}</Text>
          <Text strong>{t('labeler.description')}:</Text>
          <Text>{taskDetails.description || 'Not specified'}</Text>
          <Text strong>{t('labeler.start_date')}:</Text>
          <Text>{taskDetails.start_date || 'Not specified'}</Text>
          <Text strong>{t('labeler.end_date')}:</Text>
          <Text>{taskDetails.start_time || 'Not specified'}</Text>
          <Text strong>{t('labeler.repeat_frequency')}:</Text>
          <Text>{taskDetails.repeat_frequency || 'Not specified'}</Text>
          <Text strong>{t('labeler.end_date')}:</Text>
          <Text>{taskDetails.end_date || 'Not specified'}</Text>
        </div>
      </div>

      <div className={Styles.taskDetailSection}>
        <Title level={5} className={Styles.taskDetailSectionTitle}>
          {t('labeler.input_columns')}
        </Title>
        <Table
          dataSource={inputColumnsData}
          columns={[
            { title: 'Column Name', dataIndex: 'name', key: 'name' },
            { title: 'Type', dataIndex: 'type', key: 'type' },
            {
              title: 'Description',
              dataIndex: 'description',
              key: 'description',
            },
          ]}
          pagination={{
            showSizeChanger: false,
          }}
          className={LabelerStyles.table}
        />
      </div>

      <div className={Styles.taskDetailSection}>
        <Title level={5} className={Styles.taskDetailSectionTitle}>
          {t('labeler.output_columns')}
        </Title>
        <Table
          dataSource={outputColumnsData}
          columns={[
            { title: 'Column Name', dataIndex: 'name', key: 'name' },
            {
              title: 'Description',
              dataIndex: 'description',
              key: 'description',
            },
            {
              title: 'Validations',
              dataIndex: 'validations',
              key: 'validations',
            },
          ]}
          pagination={{
            showSizeChanger: false,
          }}
          className={LabelerStyles.table}
        />
      </div>

      <div className={Styles.taskDetailSection}>
        <Title level={5} className={Styles.taskDetailSectionTitle}>
          {t('labeler.output_data')}
        </Title>
        <Table
          dataSource={outputData}
          columns={outputDataTableColumns}
          className={LabelerStyles.table}
          pagination={{
            showSizeChanger: false,
          }}
          scroll={{ x: 'max-content' }}
          rowClassName={(record) => (record.aai_labeler_error ? `${Styles.errorRow}` : '')}
          components={{
            body: {
              row: (props) => (
                <WrapWithTooltip record={props?.children?.[0]?.props?.record}>
                  <tr {...props} />
                </WrapWithTooltip>
              ),
            },
          }}
        />
      </div>
    </div>
  );
};

export default TaskDetailView;
