import {
  ADDITIONAL_CATEGORY_OPERATORS,
  CATEGORY_OPERATORS,
  DATE_OPERATORS,
  NUMERICAL_OPERATORS,
  OPERATOR_EQUALS,
  QUERY_OPERATORS,
  SPECIAL_FIELDS,
} from '../constants/queryBuilderConstants';
export default class queryBuilderService {
  static getOperatorsByValueEditorType(valueEditorType, name) {
    switch (valueEditorType) {
      case 'select':
        if (SPECIAL_FIELDS.includes(name)) {
          return [...CATEGORY_OPERATORS, ...ADDITIONAL_CATEGORY_OPERATORS];
        }
        return CATEGORY_OPERATORS;
      case 'number':
        return NUMERICAL_OPERATORS;
      case 'date':
        return DATE_OPERATORS;
      case 'text':
        return QUERY_OPERATORS;
      default:
        return [{ value: OPERATOR_EQUALS, label: 'Equals' }];
    }
  }

  static generateQueryRecursive(condition) {
    const combinator = condition.combinator.toUpperCase();
    const number = condition.number ? ` ${condition.number}` : '';
    const formattedCombinator = combinator === 'ANY' ? `${combinator}${number} of the` : combinator;

    const rules = condition.rules
      .map((rule) => {
        if (Array.isArray(rule.rules) && rule.rules.length > 0) {
          return this.generateQueryRecursive(rule);
        } else {
          if (Array.isArray(rule.value)) {
            const values = rule.value.map((val) => val.value_description).join(', ');
            return `${rule.field} ${rule.operator} (${values})`.trim();
          } else {
            const value = typeof rule.value === 'object' ? rule.value.value_description : rule.value;
            return `${rule.field} ${rule.operator} '${value}'`.trim();
          }
        }
      })
      .join(', ');

    return `${formattedCombinator} (${rules})`;
  }

  static generateQuery(condition) {
    if (condition === undefined) {
      return '';
    }
    return this.generateQueryRecursive(condition);
  }
}
