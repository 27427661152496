import { Checkbox, Col, Row, Slider } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FloatInput } from 'src/components/floatingLabelTextAndSelectBox';
import { numberWithCommas } from 'src/lib/utils';
import './channelConstraint.less';

const ChannelConstraint = ({ mediaPlanData, channelConstraint, onChangeChannelConstraint, reviewScreen }) => {
  const { t } = useTranslation();
  const formatter = (value) => `$${numberWithCommas(value)}`;
  return (
    <div className="channel-constraint">
      <Row className="constraint">
        <Col className="constraint-header" span={2}>
          {t('mediaplan.include')}
        </Col>
        <Col className="constraint-header" span={5}>
          {t('mediaplan.channel')}
        </Col>
        <Col className="constraint-header" span={17}>
          {t('mediaplan.budget_min_max')}
        </Col>
      </Row>
      {channelConstraint?.map((channel, idx) => {
        let range = [channel.min_spend, channel.max_spend];
        const disabled = !channel?.selected;
        return (
          <Row align="middle" style={{ marginLeft: '8px', marginBottom: '10px' }} key={idx}>
            <Col span={2}>
              <Checkbox
                checked={channel?.selected}
                disabled={reviewScreen}
                onChange={(e) => onChangeChannelConstraint('checked', idx, e.target.checked)}
              />
            </Col>
            <Col span={5}>{channel.channel_name}</Col>
            <Col span={17}>
              <div style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
                <FloatInput
                  onChange={(val) => onChangeChannelConstraint('min', idx, val)}
                  addonBefore="$"
                  formatter={(value) => `${numberWithCommas(value)}`}
                  placeholder="Min spend"
                  value={range[0]}
                  disabled={disabled || reviewScreen}
                />
                <Slider
                  style={{ width: '85%' }}
                  range
                  disabled={disabled || reviewScreen}
                  value={range}
                  min={0}
                  tooltip={{
                    formatter,
                  }}
                  max={mediaPlanData?.budget}
                  step={1}
                  onChange={(value) => {
                    if (value[0] < value[1]) {
                      onChangeChannelConstraint('range', idx, value);
                    }
                  }}
                  marks={{
                    [0]: `$${numberWithCommas(0)}`,
                    [mediaPlanData?.budget]: `$${numberWithCommas(mediaPlanData?.budget)}`,
                  }}
                />
                <FloatInput
                  onChange={(val) => {
                    onChangeChannelConstraint('max', idx, val);
                  }}
                  disabled={disabled || reviewScreen}
                  formatter={(value) => `${numberWithCommas(value)}`}
                  addonBefore="$"
                  placeholder="Max spend"
                  value={range[1]}
                />
              </div>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default ChannelConstraint;
