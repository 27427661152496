import { CloseOutlined, MenuUnfoldOutlined, PlusOutlined, SaveFilled } from '@ant-design/icons';
import { Dropdown, Empty, Input, InputNumber, List, Menu, Select, Typography } from 'antd';
import React from 'react';
import { PrimaryButton } from 'src/components/basic';
import ConditionalRender from 'src/components/conditionalRender';
import { KPI_TAB_MAPPING } from '../../constants';
import useKpiConfigType from './useKpiConfigType';
const { Text } = Typography;

const KpiConfigType = ({ name, action, kpiConfigData, kpiDetails, addMore, getKPIDetails }) => {
  const {
    isAdding,
    newKpi,
    editMode,
    editedItem,
    handleDelete,
    handleEdit,
    handleAdd,
    handleAddCancel,
    handleChange,
    handleCancelEdit,
    handleSave,
    dataLoading,
  } = useKpiConfigType({ kpiConfigData, kpiDetails, getKPIDetails });
  const { Option } = Select;
  const kpiTabData = KPI_TAB_MAPPING[name];

  const menu = (index) => (
    <Menu style={{ backgroundColor: '#333', color: '#fff' }}>
      <Menu.Item key="edit" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => handleEdit(index)}>
        Edit
      </Menu.Item>
      {name === 'custom_kpi' && (
        <Menu.Item key="delete" style={{ backgroundColor: '#333', color: '#fff' }} onClick={() => handleDelete(index)}>
          Delete
        </Menu.Item>
      )}
    </Menu>
  );

  const getEditComponent = (key) => {
    switch (key) {
      case 'kpi_name':
        return (
          <Select
            placeholder="Select KPI Name"
            value={editedItem?.kpi_name}
            onChange={(value) => handleChange('kpi_name', value)}
          >
            {kpiDetails?.map((kpi) => (
              <Option key={kpi.id} value={kpi.name}>
                {kpi.name}
              </Option>
            ))}
          </Select>
        );
      case 'custom_name':
        return (
          <Input
            value={editedItem?.custom_name}
            onChange={(e) => handleChange('custom_name', e.target.value)}
            placeholder="Enter Custom KPI"
          />
        );
      case 'definition':
        return (
          <Input
            value={editedItem?.definition}
            onChange={(e) => handleChange('definition', e.target.value)}
            placeholder="Enter Definition"
          />
        );
      case 'benchmark_value':
        return (
          <InputNumber
            style={{ width: '100%' }}
            value={editedItem?.benchmark_value}
            onChange={(e) => handleChange('benchmark_value', e)}
            placeholder="Enter Benchmark Value"
          />
        );
      case 'b2c_percent':
        return (
          <InputNumber
            style={{ width: '100%' }}
            value={editedItem?.b2c_percent}
            formatter={(value) => `${value}%`}
            max={100}
            min={0}
            onChange={(e) => {
              handleChange('b2c_percent', e);
            }}
            placeholder="Enter B2C %"
          />
        );
      case 'b2b_percent':
        return (
          <InputNumber
            style={{ width: '100%' }}
            value={editedItem?.b2b_percent}
            formatter={(value) => `${value}%`}
            max={100}
            min={0}
            onChange={(e) => handleChange('b2b_percent', e)}
            placeholder="Enter B2B %"
          />
        );
      default:
        return null;
    }
  };

  const renderItem = (item, index) => {
    return (
      <div className="kpi-details">
        {kpiTabData?.columns?.map((column, idx) => {
          return (
            <div className={column.className} key={idx}>
              {column?.editable ? (
                <>
                  {editMode === index ? (
                    getEditComponent(column?.key)
                  ) : (
                    <Text>
                      {item?.[column?.key]}
                      {column?.precision ? column?.precision : ''}
                    </Text>
                  )}
                </>
              ) : (
                <Text>
                  {item[column?.key]}
                  {column?.precision ? column?.precision : ''}
                </Text>
              )}
            </div>
          );
        })}
        <ConditionalRender shouldRender={action}>
          <div className="actions">
            {editMode === index ? (
              <>
                <SaveFilled style={{ cursor: 'pointer', color: 'white' }} onClick={() => handleSave(index)} />
                <CloseOutlined style={{ cursor: 'pointer', color: 'white' }} onClick={handleCancelEdit} />
              </>
            ) : (
              <Dropdown overlay={menu(index)} trigger={['click']}>
                <MenuUnfoldOutlined
                  style={{ cursor: 'pointer', color: 'white' }}
                  onClick={(e) => e.stopPropagation()}
                />
              </Dropdown>
            )}
          </div>
        </ConditionalRender>
      </div>
    );
  };
  return (
    <div>
      <ConditionalRender shouldRender={addMore}>
        <PrimaryButton
          className="small"
          onClick={() => {
            if (editMode !== null) handleCancelEdit();
            handleAdd();
          }}
          style={{ marginBottom: '10px', marginTop: '20px' }}
        >
          Add KPI
          <PlusOutlined />
        </PrimaryButton>
      </ConditionalRender>
      <List
        loading={dataLoading}
        header={
          <div className="kpi-header">
            {kpiTabData?.columns?.map((column, index) => {
              return (
                <Text key={index} className={column.className}>
                  {column.name}
                </Text>
              );
            })}
            {action && <Text className="actions">Action</Text>}
          </div>
        }
      >
        {kpiConfigData?.map((item, index) => (
          <List.Item key={index}>{renderItem(item, index)}</List.Item>
        ))}
        {isAdding && action && (
          <List.Item>
            <div className="kpi-details">
              <div className="custom-name">
                <Input
                  value={newKpi?.custom_name}
                  onChange={(e) => handleChange('custom_name', e.target.value)}
                  placeholder="Enter Custom Name"
                />
              </div>
              <div className="kpi-name">
                <Select
                  placeholder="Select Standard KPI"
                  value={newKpi?.kpi_name}
                  onChange={(value) => handleChange('kpi_name', value)}
                >
                  {kpiDetails.map((kpi) => (
                    <Option key={kpi.id} value={kpi.name}>
                      {kpi.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="definition">
                <Input
                  value={newKpi?.definition}
                  onChange={(e) => handleChange('definition', e.target.value)}
                  placeholder="Enter Definition"
                />
              </div>
              <div className="benchmark-value">
                <InputNumber
                  style={{ width: '100%' }}
                  value={newKpi?.benchmark_value}
                  onChange={(e) => handleChange('benchmark_value', e)}
                  placeholder="Enter Benchmark"
                />
              </div>
              <div className="b2c-percent">
                <InputNumber
                  style={{ width: '100%' }}
                  max={100}
                  min={0}
                  value={newKpi?.b2c_percent}
                  formatter={(value) => `${value}%`}
                  onChange={(e) => handleChange('b2c_percent', e)}
                  placeholder="Enter B2C %"
                />
              </div>
              <div className="b2b-percent">
                <InputNumber
                  style={{ width: '100%' }}
                  value={newKpi?.b2b_percent}
                  max={100}
                  min={0}
                  onChange={(e) => handleChange('b2b_percent', e)}
                  formatter={(value) => `${value}%`}
                  placeholder="Enter B2B %"
                />
              </div>
              <div className="actions">
                <SaveFilled
                  style={{ cursor: 'pointer', color: 'white' }}
                  onClick={() => handleSave(kpiConfigData.length)}
                />
                <CloseOutlined style={{ cursor: 'pointer', color: 'white' }} onClick={handleAddCancel} />
              </div>
            </div>
          </List.Item>
        )}
      </List>
      <ConditionalRender shouldRender={kpiConfigData?.length === 0 && !isAdding}>
        <Empty description="No KPIs found" />
      </ConditionalRender>
    </div>
  );
};

export default KpiConfigType;
