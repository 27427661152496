import { Checkbox, Col, Drawer, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/basic';
import ConditionalRender from 'src/components/conditionalRender';
import ConfigurationIcon from 'src/components/ConfigurationIcon';
import { AVAILABLE_CAPABILITY, NAVIGATOR_CLIENT_ID } from '../constants/constants';
import Style from './capabilities.style';
import CapabilityDrawer from './CapabilityDrawer';
import { useToolCardHooks } from './useToolCardHooks';
import useCustomGptHook from '../hooks/useCustomGptHook';
import LoadingSpinner from 'src/components/loadingSpinner';

export default function Capabilities({
  metaData,
  initialValues,
  id,
  updateCustomApp,
  history,
  clientId,
  backToChatAppHandler,
}) {
  const { t } = useTranslation();
  const {
    capabilities,
    loading,
    toolModal,
    setToolModal,
    toolData,
    setToolData,
    configData,
    setConfigData,
    onClose,
    reload,
  } = useCustomGptHook({ id, history, mode: 'Any', clientId });

  // Check if there is any capability with capability_name 'SqlCapability'
  const hasSqlCapability = capabilities.some((capability) => capability.capability_name === 'SqlCapability');
  return (
    <div>
      <ConditionalRender shouldRender={loading}>
        <LoadingSpinner centered />
      </ConditionalRender>
      <ConditionalRender shouldRender={!loading}>
        <Row justify="end" style={{ margin: '24px 0', gap: '8px' }}>
          <ConditionalRender shouldRender={id}>
            <PrimaryButton className="small" onClick={backToChatAppHandler}>
              {t('client_space.go_to_assistant')}
            </PrimaryButton>
          </ConditionalRender>
        </Row>
        <Row gutter={[8, 8]}>
          {metaData?.capability_config?.map((item, index) => {
            if (AVAILABLE_CAPABILITY.includes(item?.capability_name)) {
              const initialCapabilityValues = initialValues?.capabilities?.find(
                (ele) => ele.capability_name === item.capability_name
              );
              if (item.capability_name === 'BackboneCapability' && clientId === NAVIGATOR_CLIENT_ID) {
                return null;
              }

              // Use hasSqlCapability in the condition
              if (item.capability_name === 'SqlCapability' && !hasSqlCapability) {
                return null;
              }

              return (
                <Col xs={24} md={12} xl={8} key={`capability-config-${index}`}>
                  <ToolCard
                    setConfigData={setConfigData}
                    item={item}
                    setToolModal={setToolModal}
                    initialCapabilityValues={initialCapabilityValues}
                    metaData={metaData}
                    setToolData={setToolData}
                    id={id}
                    history={history}
                    clientId={clientId}
                  />
                </Col>
              );
            }
          })}
        </Row>
        <Drawer
          title={configData?.capability_display_name}
          maskClosable={true}
          placement="right"
          onClose={onClose}
          open={toolModal}
          destroyOnClose
          size="large"
          width={'88%'}
        >
          <CapabilityDrawer
            toolData={toolData}
            configData={configData}
            id={id}
            aiAppData={initialValues}
            updateCustomApp={updateCustomApp}
            onClose={onClose}
            reload={reload}
            clientId={clientId}
          />
        </Drawer>
      </ConditionalRender>
    </div>
  );
}

function ToolCard({ id, item, setToolModal, metaData, setToolData, initialCapabilityValues, setConfigData }) {
  const { t } = useTranslation();
  const { isActive, loading, capabilityConfig, connection } = useToolCardHooks(
    id,
    item,
    initialCapabilityValues,
    metaData
  );

  return (
    <div className={Style.toolCardContainer}>
      <div className={Style.toolCardName}>
        <Checkbox
          checked={isActive}
          disabled={loading}
          onChange={(val) => {
            if (!initialCapabilityValues) {
              setToolData(initialCapabilityValues);
              setConfigData(capabilityConfig);
              setToolModal(true);
            } else {
              connection(val.target.checked);
            }
          }}
        />
        <div>{item.capability_display_name}</div>
      </div>
      <Row justify="end">
        <button
          onClick={() => {
            setToolData(initialCapabilityValues);
            setConfigData(capabilityConfig);
            setToolModal(true);
          }}
          className={Style.toolCardButton}
        >
          {t('client_space.configure')}
          <ConfigurationIcon size={24} />
        </button>
      </Row>
    </div>
  );
}
