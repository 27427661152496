import React from 'react'; // Import React at the top of the file
import { numberWithCommas } from 'src/lib/utils';

const useForecastTable = () => {
  const getColumns = (data) => {
    let columns = [
      {
        title: () => <span style={{ fontWeight: '700' }}>Channel</span>,
        dataIndex: 'channel',
        key: 'channel',
        fixed: 'left',
        width: 200,
        render: (text) => <div style={{ minWidth: '200px', maxWidth: '200px' }}>{text}</div>,
      },
    ];

    if (data.length > 0) {
      const sampleChannelValue = data[0].channel_value[0];
      Object.keys(sampleChannelValue).forEach((key) => {
        if (key !== 'channel') {
          columns.push({
            title: () => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: '700',
                  minWidth: '200px',
                  maxWidth: '200px',
                }}
              >
                <span>{key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}</span>
              </div>
            ),
            dataIndex: key,
            key: key,
            render: (text) => <div>${numberWithCommas(Math.round(text))}</div>,
          });
        }
      });
    }

    return columns;
  };

  const addTotalRow = (data, totalName = 'Total') => {
    if (data.length === 0) return [];
    const numericKeys = Object.keys(data[0]).filter((key) => key !== 'channel');
    const total = data.reduce(
      (acc, curr) => {
        numericKeys.forEach((key) => {
          acc[key] = (acc[key] || 0) + Number(curr[key]);
        });
        return acc;
      },
      { channel: totalName }
    );

    return [total, ...data];
  };

  const formatData = (data) => {
    let formatData = data.map((data) => {
      const channelData = data.channel_value[0];

      const roundedChannelData = {};

      for (const key in channelData) {
        if (Object.prototype.hasOwnProperty.call(channelData, key)) {
          const value = channelData[key];
          if (typeof value === 'number') {
            // Check if the number has a decimal part
            roundedChannelData[key] = value % 1 !== 0 ? parseFloat(value).toFixed(2) : value;
          } else {
            roundedChannelData[key] = value;
          }
        }
      }

      return {
        channel: data.channel,
        ...roundedChannelData,
      };
    });
    let totalData = addTotalRow(formatData);
    return totalData;
  };

  return {
    getColumns,
    formatData,
  };
};

export default useForecastTable;
