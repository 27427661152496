import { notification } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import appUrls from 'src/constants/appUrls';
import AppContext from 'src/context/appContext';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import CreateClientApiServices from 'src/modules/addNewClientSpace/services/createClientSpaceApiServices';
import { DEMOGRAPHIC } from 'src/modules/audienceTool/audienceManager/constants/queryBuilderConstants';
import audienceListService from 'src/modules/audienceTool/audienceManager/services/audienceListService';
import createAudienceService from 'src/modules/audienceTool/audienceManager/services/createAudienceService';
import { ClusterToolApiServices } from 'src/modules/audienceTool/clusterTool/services/ClusterToolApiServices';
import {
  ACQUISITION_CRM_LOOKALIKES,
  CLUSTERING_TYPE,
  FIRST_PARTY,
  LOOKALIKE,
  TRANSUNION,
} from '../../constants/constants';
import { ClusterToolServices } from '../../services/ClusterToolServices';
import queryBuilderService from 'src/modules/audienceTool/audienceManager/services/queryBuilderService';

export default function useCreateClusterHook({ match, history, fromAudience, typeConfigId }) {
  const id = match?.params?.clientId;
  const clusterId = match?.params?.clusterId || typeConfigId;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const audienceConfigId = queryParams.get('audience-config-id');
  const [loading, setLoading] = useState(true);
  const [configureLoading, setConfigureLoading] = useState(false);
  const [audienceCount, setAudienceCount] = useState(null);
  const [audienceListLoading, setAudienceListLoading] = useState(false);
  const formRef = useRef();
  const [initialValues, setInitialValues] = useState({});
  const appContext = useContext(AppContext.Context);
  const clientSpaceName = appContext?.clientSpaceData?.client_space?.name;
  const clusteringApp = appContext?.clientSpaceData?.micro_apps?.find((app) => app.app_type === 'CLUSTERING_APP');
  const clusteringConfig = clusteringApp?.app_config;
  const [editDescription, setEditDescription] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [nextButton, setNextButton] = useState(false);
  const [savedChanges, setSavedChanges] = useState(false);
  const [audienceRole, setAudienceRole] = useState(null);
  const [clusterConfig, setClusterConfig] = useState(null);
  const [clusterType, setClusterType] = useState(null);
  const [drawerState, setDrawerState] = useState({
    target_values: false,
    features_to_exclude: false,
  });
  const [selectedItems, setSelectedItems] = useState({
    target_values: [],
    features_to_exclude: [],
  });
  const [audienceListOptions, setAudienceListOptions] = useState([]);
  const [clientInfo, setClientInfo] = useState(null);
  const [targetSource, setTargetSource] = useState(null);
  const [fields, setFields] = useState({ demographic_features: [], behaviour_features: [], first_party_features: [] });
  const [containsFirstParty, setContainsFirstParty] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [targetRule, setTargetRule] = useState(null);
  const [editClusterType, setEditClusterType] = useState(false);
  const path = location.pathname;

  const updateSelectedItems = (type, items) => {
    setSelectedItems((prevState) => {
      let updatedItems = { ...prevState, [type]: items };
      if (type === 'target_values') {
        setTargetSource(items[0]?.source);
        const rules = clusterConfig['feature_exclusion_rules'][clusterType];
        if (
          (items[0]?.source === FIRST_PARTY && rules.BEHAVIOURAL.FIRST_PARTY) ||
          (items[0]?.source === TRANSUNION && rules.DEMOGRAPHIC.TRANSUNION)
        ) {
          // Merge without duplicates
          const newFeaturesToExclude = [...new Set([...prevState.features_to_exclude, ...items])];
          updatedItems.features_to_exclude = newFeaturesToExclude;
        }
      }
      return updatedItems;
    });
    setFormChanged(true);
    closeDrawer(type);
  };

  const handleClusterTypeChange = (value) => {
    if (formRef?.current) {
      setFormChanged(true);
      formRef.current.setFieldsValue({
        input_audience_config_id: null,
        clusters_type: value,
      });
    }
    setSelectedItems({
      target_values: [],
      features_to_exclude: [],
    });
    setTargetRule(null);
    setAudienceCount(null);
    setClusterType(value);
  };

  const showDrawer = (type) => {
    setDrawerState({ ...drawerState, [type]: true });
  };

  const closeDrawer = (type) => {
    setDrawerState({ ...drawerState, [type]: false });
  };

  const createClusterConfig = async (val) => {
    try {
      setConfigureLoading(true);
      let formattedPayload = ClusterToolServices.formatCreateClusterConfigPayload(val, id, targetRule, clusterType);
      if (clusterId) {
        formattedPayload = {
          ...formattedPayload,
          cluster_config_id: clusterId,
          audience_config_id: audienceConfigId,
        };
      }
      await ClusterToolApiServices.createClusterConfig(formattedPayload);
      if (fromAudience) {
        history.push(appUrls.AUDIENCE_TOOL(id));
      } else {
        history.push(appUrls.CLUSTER_TOOL(id));
      }
      setConfigureLoading(false);
    } catch (error) {
      setConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const getAudienceSize = async (configId) => {
    if (configId === undefined || configId === null) {
      setAudienceCount(null);
    }
    if (audienceListOptions?.length > 0) {
      const audienceConfig = audienceListOptions.find((audience) =>
        audience?.audience_config?.some((config) => config.id === configId)
      );
      if (audienceConfig) {
        const config = audienceConfig.audience_config.find((config) => config.id === configId);
        setAudienceCount(config.audience_profiles[0]?.audience_size || null);
      } else {
        setAudienceCount(null);
      }
    }
  };

  const getAudienceList = async (clientId) => {
    try {
      setAudienceListLoading(true);
      const res = await audienceListService.getAudienceList(
        clientId,
        1,
        1000,
        '',
        '',
        'created_on',
        false,
        '',
        clusterType
      );
      setAudienceListOptions(res.data.data);
      setAudienceListLoading(false);
    } catch (error) {
      setAudienceListLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const getClientInfo = async (clientId) => {
    try {
      const res = await CreateClientApiServices.getClientSpaceDetails(clientId);
      setClientInfo(res.data);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const getClusterRule = async () => {
    try {
      const res = await ClusterToolApiServices.getClusterRule();
      const rule = res.data;
      setClusterConfig(rule);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const getData = async (clientId) => {
    try {
      setLoading(true);
      await getClientInfo(clientId);
      const metaDataRes = await createAudienceService.getAudienceFeatureMetadata(clientId, false, false, true);
      let demographicColumns = metaDataRes.data.TRANSUNION;
      let firstPartyColumns = metaDataRes.data.FIRST_PARTY;
      if (firstPartyColumns.length > 0) {
        setContainsFirstParty(true);
      }
      const demographicFields = createAudienceService.transformColumnsToFields(
        demographicColumns,
        DEMOGRAPHIC.toUpperCase()
      );
      const firstPartyFields = createAudienceService.transformColumnsToFields(
        firstPartyColumns,
        FIRST_PARTY.toUpperCase()
      );

      setFields({
        demographic_features: demographicFields,
        behaviour_features: [],
        first_party_features: firstPartyFields,
      });
      let tempObj = {};
      if (clusteringConfig) {
        tempObj = {
          description: clusteringConfig?.[CLUSTERING_TYPE(true)[0]],
        };
        setInitialValues(tempObj);
      }
      if (clusterId) {
        const clusterConfig = await ClusterToolApiServices.getClusterConfig(clusterId);
        setNextButton(true);
        if (
          clusterConfig?.data?.state === 'FINDING_RELEVANT_FEATURES' ||
          clusterConfig?.data?.state === 'CREATING_AUDIENCES' ||
          path.includes('review-audience')
        ) {
          setViewMode(true);
        } else {
          setEditMode(true);
        }

        let targetValues = [];
        if (clusterConfig.data.target_feature !== null && clusterConfig.data.target_feature !== LOOKALIKE) {
          targetValues = [
            {
              source: clusterConfig.data.target_source,
              feature_name: clusterConfig.data.target_feature,
              category: clusterConfig.data.target_category,
              name:
                clusterConfig.data.target_source === FIRST_PARTY.toUpperCase()
                  ? firstPartyColumns.find((col) => col.feature_name === clusterConfig.data.target_feature)
                      ?.feature_readable_name
                  : clusterConfig.data.target_feature,
            },
          ];
        }
        if (clusterConfig.data.target_feature_query_data && clusterConfig.data.target_feature_query_data !== null) {
          setTargetRule(clusterConfig.data.target_feature_query_data);
        }
        let featuresToExclude = clusterConfig.data.features_to_exclude.map((feature) => ({
          source: feature.source,
          feature_name: feature.feature_name,
          category: feature.category,
          name: feature.feature_readable_name,
        }));
        let clusterData = {
          clusters_type: clusterConfig.data.clusters_type,
          name: clusterConfig.data.name,
          description: clusterConfig.data.description,
          number_of_clusters: clusterConfig.data.number_of_clusters,
          target_feature: clusterConfig.data.target_feature,
          target_source: clusterConfig.data.target_source,
          target_category: clusterConfig.data.target_category,
          features_to_exclude: featuresToExclude,
          input_audience_config_id: clusterConfig.data.input_audience_config_id,
          state: clusterConfig.data.state,
        };
        setSelectedItems({
          target_values: targetValues,
          features_to_exclude: featuresToExclude,
        });
        setClusterType(clusterConfig.data.clusters_type);
        setAudienceRole(clusterConfig.data.role);
        setInitialValues(clusterData);
      } else {
        tempObj = {
          ...tempObj,
          clusters_type: CLUSTERING_TYPE(true)[0],
        };
        setInitialValues(tempObj);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const getTargetValuesDisplay = () => {
    // Check if input_audience_config_id is set and cluster type is ACQUISITION_CRM_LOOKALIKES
    if (
      formRef?.current?.getFieldValue('input_audience_config_id') &&
      clusterType === ACQUISITION_CRM_LOOKALIKES &&
      targetRule === null
    ) {
      for (const audience of audienceListOptions) {
        const config = audience.audience_config.find(
          (config) => config.id === formRef?.current?.getFieldValue('input_audience_config_id')
        );
        if (config) {
          return (
            LOOKALIKE + ' of segment: ' + queryBuilderService.generateQuery(config?.type_based_config?.json_data?.rules)
          );
        }
      }
    } else if (clusterType === ACQUISITION_CRM_LOOKALIKES && targetRule === null) {
      return LOOKALIKE;
    }

    // Default logic for target values
    return selectedItems.target_values
      .map((item) => {
        const featureName = item.name || item.feature_name;
        if (targetRule && targetRule !== null) {
          const operator = targetRule.operator;
          const valueDescription = Array.isArray(targetRule.value)
            ? targetRule.value.map((v) => v.value_description).join(', ')
            : targetRule.value?.value_description;

          return `${featureName} ${operator} ${valueDescription}`;
        }
        return featureName;
      })
      .join(', ');
  };

  useEffect(() => {
    getClusterRule();
    getData(id);
  }, []);

  useEffect(() => {
    if (clusterType !== null) {
      getAudienceList(id);
    }
  }, [clusterType]);

  useEffect(() => {
    if (initialValues?.input_audience_config_id && clusterType === initialValues?.clusters_type) {
      getAudienceSize(initialValues?.input_audience_config_id);
    }
  }, [initialValues, audienceListOptions]);

  useEffect(() => {
    formRef?.current?.setFieldsValue({
      target_values: selectedItems.target_values,
      features_to_exclude: selectedItems.features_to_exclude,
    });
  }, [selectedItems]);
  return {
    loading,
    formRef,
    createClusterConfig,
    configureLoading,
    getAudienceSize,
    audienceCount,
    audienceListLoading,
    initialValues,
    clientSpaceName,
    editDescription,
    setEditDescription,
    clusteringConfig,
    editMode,
    viewMode,
    nextButton,
    id,
    clusterId,
    savedChanges,
    setSavedChanges,
    audienceConfigId,
    audienceRole,
    clusterConfig,
    clusterType,
    setClusterType,
    drawerState,
    selectedItems,
    updateSelectedItems,
    showDrawer,
    closeDrawer,
    audienceListOptions,
    clientInfo,
    targetSource,
    fields,
    setFields,
    setSelectedItems,
    setAudienceCount,
    containsFirstParty,
    setFormChanged,
    formChanged,
    targetRule,
    setTargetRule,
    getTargetValuesDisplay,
    handleClusterTypeChange,
    editClusterType,
    setEditClusterType,
  };
}
