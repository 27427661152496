import { DownloadOutlined, EyeOutlined, ProfileOutlined, WechatWorkOutlined } from '@ant-design/icons';
import { Menu, notification } from 'antd';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import AppUrls from 'src/constants/appUrls';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { RULE_BASED } from '../../../constants/constants';
import audienceListService from '../../../services/audienceListService';
import createAudienceService from '../../../services/createAudienceService';
import { useHistory, useLocation } from 'react-router-dom';

export default function useAudienceDetailsHook(audienceId, clientId, audienceProfileId) {
  const [audienceLoading, setAudienceLoading] = useState(false);
  const [audienceData, setAudienceData] = useState(null);
  const [audienceFeatures, setAudienceFeatures] = useState({
    demographic_features: [],
    behaviour_features: [],
    first_party_features: [],
  });

  const [behaviourFeaturesCategories, setBehaviourFeaturesCategories] = useState([]);
  const [brandAffinityCategories, setBrandAffinityCategories] = useState([]);
  const [searchFeatureLoading, setSearchFeatureLoading] = useState(false);
  const [audienceProfile, setAudienceProfile] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  // Extract clientId and audienceId from URL
  const location = useLocation();
  const history = useHistory();

  const handleChatWithPersona = () => {
    if (audienceProfile?.ai_app_id !== null) {
      window.open(AppUrls.APP_PAGE(clientId, audienceProfile?.ai_app_id), '_blank');
    }
  };

  const extractAllNodes = (data, path = []) => {
    let allNodes = [];

    for (const [key, value] of Object.entries(data)) {
      const levelName = path.length === 0 ? 'parent_folder' : `sub_folder${path.length}`;
      const currentPath = [...path, { directory_level: levelName, level_value: key }];

      // Add the current node to the list
      allNodes.push({
        leaf_node: key,
        directory_path: currentPath,
      });

      if (typeof value === 'object' && value !== null && Object.keys(value).length > 0) {
        // If the value is a non-empty object, recurse into it
        allNodes = allNodes.concat(extractAllNodes(value, currentPath));
      }
    }

    return allNodes;
  };

  const extractBrandAffinityFeatures = (data, path = []) => {
    let allNodes = [];
    for (const [key, value] of Object.entries(data)) {
      const levelName = path.length === 0 ? 'parent_folder' : `sub_folder${path.length}`;
      const currentPath = [...path, { directory_level: levelName, level_value: key }];
      if (key !== 'Product Potential') {
        continue;
      }
      // Add the current node to the list
      if (key !== 'Product Potential') {
        allNodes.push({
          leaf_node: key,
          directory_path: currentPath,
        });
      }

      if (typeof value === 'object' && value !== null && Object.keys(value).length > 0) {
        // If the value is a non-empty object, recurse into it
        allNodes = allNodes.concat(extractAllNodes(value, currentPath));
      }
    }

    return allNodes;
  };

  const getAudienceBehaviouralDirectoryPayload = (clientId) => {
    return {
      client_space_id: clientId,
      with_features: false,
    };
  };

  const addSearchBehaviourFields = (fields, behaviourFeatureNames = []) => {
    const existingFieldNames = new Set(fields.behaviour_features.map((field) => field.name));
    const behaviourTransformedFields = behaviourFeatureNames
      .filter((behaviour) => !existingFieldNames.has(behaviour))
      .map((behaviour) => ({
        feature_name: behaviour,
        feature_readable_name: behaviour,
      }));

    return {
      ...fields,
      behaviour_features: [...fields.behaviour_features, ...behaviourTransformedFields],
    };
  };

  const handleSearch = useCallback(
    debounce(async (value) => {
      setSearchFeatureLoading(true);
      try {
        const res = await createAudienceService.getAudienceFeature(clientId, value);
        if (res.data?.behaviour_features && res.data.behaviour_features.length > 0) {
          const newUpdatedFields = addSearchBehaviourFields(audienceFeatures, res.data.behaviour_features);
          setAudienceFeatures(newUpdatedFields);
        }
        setSearchFeatureLoading(false);
      } catch (error) {
        setSearchFeatureLoading(false);
        console.error('Error fetching search results:', error);
      }
    }, 800),
    [clientId, audienceFeatures]
  );

  const menuOverlay = (
    <Menu style={{ backgroundColor: 'var(--color-charcoal-black)', color: 'var(--color-white)' }}>
      {audienceProfile?.sql_query &&
        audienceProfile?.sql_query !== null &&
        audienceData?.audience_type === RULE_BASED && (
          <Menu.Item
            key="showQuery"
            style={{ backgroundColor: 'var(--color-charcoal-black)', color: 'var(--color-white)' }}
            onClick={() => setIsModalVisible(true)}
          >
            <EyeOutlined /> Show Query
          </Menu.Item>
        )}
      <Menu.Item
        key="details"
        style={{ backgroundColor: 'var(--color-charcoal-black)', color: 'var(--color-white)' }}
        onClick={() => {
          if (audienceData?.audience_type === RULE_BASED && !audienceData.is_mapped_to_media_plans) {
            handleEditAudience(audienceData);
          } else {
            reviewClusterDetails(audienceData);
          }
        }}
      >
        <ProfileOutlined />{' '}
        {audienceData?.audience_type === RULE_BASED && !audienceData.is_mapped_to_media_plans ? 'Edit' : 'Review'}
      </Menu.Item>
      <Menu.Item
        key="chatWithPersona"
        style={{ backgroundColor: 'var(--color-charcoal-black)', color: 'var(--color-white)' }}
        onClick={handleChatWithPersona}
      >
        <WechatWorkOutlined /> Chat with Persona
      </Menu.Item>
      <Menu.Item
        key="downloadCSV"
        style={{ backgroundColor: 'var(--color-charcoal-black)', color: 'var(--color-white)' }}
        onClick={() => setIsDownloadModalVisible(true)}
      >
        <DownloadOutlined /> Download Report
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    async function fetchData() {
      try {
        setAudienceLoading(true);
        const audience = await audienceListService.getAudienceConfigById(audienceId);
        const metaDataRes = await createAudienceService.getAudienceFeatureMetadata(clientId, false, true);
        const filteredDemographicData = metaDataRes.data.TRANSUNION.filter(
          (feature) => feature.feature_values.length > 0
        );
        const filteredFirstPartyData = metaDataRes.data.FIRST_PARTY.filter(
          (feature) => feature.feature_values.length > 0
        );
        const behaviouralDirectoryPayload = getAudienceBehaviouralDirectoryPayload(clientId);
        const audienceBehaviouralFeatures =
          await createAudienceService.getAudienceBehaviouralDirectory(behaviouralDirectoryPayload);
        const audienceBehaviourFolders = extractAllNodes(audienceBehaviouralFeatures.data);
        const brandAffinityFolder = extractBrandAffinityFeatures(audienceBehaviouralFeatures.data);
        setBehaviourFeaturesCategories(audienceBehaviourFolders);
        setBrandAffinityCategories(brandAffinityFolder);
        setAudienceFeatures({
          demographic_features: filteredDemographicData,
          behaviour_features: [],
          first_party_features: filteredFirstPartyData,
        });
        const audienceProfile = audience?.data?.audience_profiles.find((profile) => profile.id === audienceProfileId);
        setAudienceProfile(audienceProfile);
        setAudienceData(audience.data);
        setAudienceLoading(false);
      } catch (error) {
        setAudienceLoading(false);
        const { details, msg } = getErrorMessageFromResponse(error);
        const errorMessage = details?.error || msg || 'Failed to get audience';
        notification.error({
          key: details?.resource_name || 'Error',
          message: errorMessage,
          duration: 5,
          placement: 'bottomRight',
        });
      }
    }
    fetchData();
    setActiveTab('1');
  }, [audienceId]);

  const queryParams = new URLSearchParams(location.search);
  const reviewAudienceId = queryParams.get('audience-config-id');

  const reviewClusterDetails = (audience) => {
    history.push(AppUrls.REVIEW_AUDIENCE(clientId, reviewAudienceId, audience.id));
  };

  const handleEditAudience = (audience) => {
    history.push(AppUrls.EDIT_AUDIENCE(clientId, reviewAudienceId, audience.id));
  };

  return {
    audienceLoading,
    audienceData,
    handleChatWithPersona,
    audienceFeatures,
    behaviourFeaturesCategories,
    brandAffinityCategories,
    handleSearch,
    searchFeatureLoading,
    audienceProfile,
    isModalVisible,
    setIsModalVisible,
    isDownloadModalVisible,
    setIsDownloadModalVisible,
    activeTab,
    setActiveTab,
    menuOverlay,
  };
}
