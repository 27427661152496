import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, List } from 'antd';
import React from 'react';
import OperatorValueSelector from './OperatorValueSelector';
import useBrowseFeatureHook from './useBrowseFeatureHook';
import './browseBehaviourFeatures.style.less';
import {
  BEHAVIOUR,
  DEMOGRAPHIC,
  FIRST_PARTY,
} from 'src/modules/audienceTool/audienceManager/constants/queryBuilderConstants';
import queryBuilderService from 'src/modules/audienceTool/audienceManager/services/queryBuilderService';

const BrowseBehaviourFeaturesComponent = ({
  fieldDirectory,
  browsedSelectedItems,
  setBrowsedSelectedItems,
  ruleType,
  searchQuery,
  setSearchQuery,
}) => {
  const {
    handleRemoveItem,
    handleSearchChange,
    filteredTree,
    filteredDemographicFields,
    filteredGroupedByCategory,
    renderTree,
    renderFieldDirectoryList,
    renderFolderStructure,
    selectedField,
    modalVisible,
    setModalVisible,
    editingItem,
    setEditingItem,
    handleEditItem,
  } = useBrowseFeatureHook({
    fieldDirectory,
    browsedSelectedItems,
    setBrowsedSelectedItems,
    ruleType,
    searchQuery,
    setSearchQuery,
  });

  return (
    <>
      <Input
        className="dark-theme-input"
        placeholder="Search..."
        prefix={<SearchOutlined />}
        value={searchQuery}
        onChange={handleSearchChange}
        style={{ marginBottom: '16px' }}
      />
      <div className="drawer-content">
        {ruleType === BEHAVIOUR && <div className="tree-container">{renderTree(filteredTree)}</div>}
        {ruleType === DEMOGRAPHIC && (
          <div className="tree-container">{renderFieldDirectoryList(filteredDemographicFields)}</div>
        )}
        {ruleType.toLowerCase() === FIRST_PARTY && (
          <div className="tree-container">{renderFolderStructure(filteredGroupedByCategory)}</div>
        )}
        <div className="selected-items">
          <h3>Selected Items</h3>
          <List
            dataSource={browsedSelectedItems}
            key={browsedSelectedItems.length}
            renderItem={(item) => (
              <List.Item
                key={item.field.name}
                actions={[
                  ruleType !== BEHAVIOUR && (
                    <Button
                      key={`${item.field}-edit`}
                      type="text"
                      onClick={() => handleEditItem(item)}
                      icon={<EditOutlined />}
                      style={{ marginRight: '8px' }}
                    />
                  ),
                  <Button
                    key={`${item.field}-remove`}
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => handleRemoveItem(item.field)}
                  />,
                ].filter(Boolean)}
              >
                {ruleType !== BEHAVIOUR
                  ? `${item.field_readable_name} ${item.operator} ${Array.isArray(item.value) ? item.value.map((v) => v.value_description).join(', ') : item.value.value_description}`
                  : item.field_readable_name}
              </List.Item>
            )}
          />
        </div>
      </div>

      {selectedField && (
        <OperatorValueSelector
          key={selectedField.name + String(modalVisible)}
          visible={modalVisible}
          onClose={() => {
            setEditingItem(null);
            setModalVisible(false);
          }}
          onConfirm={(operator, value) => {
            setBrowsedSelectedItems((prev) => {
              const fieldToUpdate = selectedField;
              const updatedItem = {
                field: fieldToUpdate.name,
                operator,
                value,
                field_readable_name: fieldToUpdate.label,
                behaviour_name: fieldToUpdate.name,
                rule_type: ruleType,
              };

              const itemExists = prev.some((item) => item.field === fieldToUpdate.name);

              if (itemExists) {
                return prev.map((item) => (item.field === fieldToUpdate.name ? updatedItem : item));
              } else {
                return [...prev, updatedItem];
              }
            });
            setEditingItem(null);
            setModalVisible(false);
          }}
          field={selectedField}
          operators={queryBuilderService.getOperatorsByValueEditorType(
            selectedField.valueEditorType,
            selectedField.name
          )}
          initialOperator={editingItem !== null ? editingItem.operator : null}
          initialValue={
            editingItem !== null
              ? Array.isArray(editingItem.value)
                ? editingItem.value.map((v) => v.value)
                : editingItem.value.value
              : null
          }
          showClear={false}
        />
      )}
    </>
  );
};

export default BrowseBehaviourFeaturesComponent;
