import { Table, Tabs } from 'antd';
import React from 'react';
import './MediaplanOutput.less';
import useFlightingTable from './useFlightingTable';

const FlightingTable = ({ tabData }) => {
  const { getColumns, formatData } = useFlightingTable();
  return (
    <div className="media-plan-overview-container">
      <Tabs defaultActiveKey="0" className="tableAudienceContainer">
        {tabData?.map((audience, id) => {
          const audienceName = audience?.audience_name;
          const audienceBudget = audience?.max_budget;
          const columns = audience?.channels?.length > 0 ? getColumns(audience?.channels[0]) : [];
          const formattedData = audience?.channels?.length > 0 ? formatData(audience?.channels, audienceBudget) : [];
          return (
            <Tabs.TabPane tab={audienceName} key={id}>
              <div className="tableContainer">
                <Table
                  className="dark-table"
                  columns={columns}
                  dataSource={formattedData}
                  pagination={false}
                  rowKey="id"
                  scroll={{
                    x: 'max-content',
                    y: 400,
                  }}
                />
              </div>
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default FlightingTable;
