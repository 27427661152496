import { useOktaAuth } from '@okta/okta-react';
import { notification } from 'antd';
import { useContext, useRef, useState } from 'react';
import AppContext from 'src/context/appContext';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import Services from 'src/modules/authentication/services/authenticationApiServices.js';

export default function useNewLoginHook() {
  const { oktaAuth } = useOktaAuth();
  const [tab, setTab] = useState('email');
  const emailRef = useRef();
  const passwordRef = useRef();
  const [data, setData] = useState({});
  const appContext = useContext(AppContext.Context);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);

  async function fetchOktaLink() {
    try {
      setLoading(true);
      oktaAuth.signInWithRedirect({ originalUri: '/login/' });
    } catch (error) {
      console.error(error);
    }
  }

  const navigateToPassword = (body) => {
    let { email } = body;
    if (email.includes('@galepartners.com')) {
      fetchOktaLink();
    } else {
      setData({ ...body });
      setTab('password');
    }
  };

  const navigateToEmail = () => {
    setTab('email');
  };

  const doLogin = async (body) => {
    try {
      setLoading(true);
      const payload = { ...data, ...body };
      // await AuthenticationService.login(payload, appContext);
      const res = await Services.login(payload);
      if (res.data.user.forced_password_change_required) {
        setToken(res.data.token);
        setTab('resetPassword');
      } else {
        await appContext.loginUserWithUserData(res.data, true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const resetPassword = async (payload) => {
    try {
      setLoading(true);
      await Services.passwordReset(payload, token);
      notification.success({
        duration: 3,
        message: 'Password reset successful',
        placement: 'bottomRight',
      });
      const tokenData = {
        token: token,
      };
      await appContext.loginUserWithUserData(tokenData, true);
      setToken(null);
    } catch (error) {
      setLoading(false);
      notification.error({
        duration: 5,
        message: 'Failed to reset the password',
        placement: 'bottomRight',
      });
    }
  };

  return {
    emailRef,
    passwordRef,
    data,
    tab,
    navigateToPassword,
    navigateToEmail,
    doLogin,
    loading,
    resetPassword,
  };
}
