import { Select } from 'antd';
import ReactECharts from 'echarts-for-react';
import React from 'react';
import LoadingSpinner from 'src/components/loadingSpinner';
import './demographicOverview.style.less';
import useDemographicHook from './useDemographicHook';

const DemographicOverview = ({ audienceFeatures, audienceId }) => {
  const {
    loading,
    audienceChart,
    selectedFeature,
    onFeatureChange,
    genderData,
    ageData,
    pageLoading,
    householdData,
    educationData,
    maritalStatusData,
    numberOfChildrenData,
    houseHoldCompositionData,
    homeOwnerData,
    homeValueData,
    urbanicityData,
    regionalDistributionData,
    netAssetData,
    buyingPowerScoreData,
  } = useDemographicHook({ audienceFeatures, audienceId });

  if (pageLoading) {
    return <LoadingSpinner centered />;
  }
  return (
    <div className="demographic-overview-section">
      <div className="core-demographics-container">
        <h2 className="section-title__demographic">Core Demographics</h2>
        <div className="core-charts-row">
          <div className="core-chart-container gender-container">
            <h3 className="section-title">Gender Distribution</h3>
            <div className="gender-icons">
              <div className="gender-icon">
                <svg width="59" height="250" viewBox="0 0 33 66" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <linearGradient id="male_0" x1="0%" y1="100%" x2="0%" y2="0%">
                      <stop
                        offset={`${genderData?.male ? genderData?.male : 0}%`}
                        stopColor="#5470c6"
                        stopOpacity="1"
                      ></stop>
                      <stop offset="0%" stopColor="white" stopOpacity="0"></stop>
                    </linearGradient>
                  </defs>
                  <path
                    fill="url(#male_0)"
                    d="M5.84038 37.3283C6.87203 35.057 9.03188 27.1518 9.41461 25.7386C9.44962 25.6094 9.56632 25.5216 9.70026 25.5216C9.82569 25.5216 9.92738 25.6009 9.92749 25.7263C9.92994 28.5318 9.95609 59.2775 9.92731 62.4673C9.89715 65.8101 15.8644 65.7866 15.8306 62.4673V38.1756C15.8306 37.7824 17.355 37.7824 17.355 38.1756C17.355 38.1756 17.3714 59.2563 17.42 62.531C17.4686 65.8058 23.3774 65.8399 23.3234 62.531V25.5216C23.3234 25.2944 23.7775 25.2945 23.7775 25.5216C23.7775 25.5216 26.4864 34.7893 27.4103 37.3282C28.3341 39.8671 32.8595 38.6905 32.1784 35.7389C32.1784 35.7389 26.5021 17.1206 25.8209 15.5313C25.1398 13.9419 21.2799 13.0337 16.7389 13.0337C12.1978 13.0337 8.11089 13.7148 7.42974 15.5313C6.74858 17.3477 1.75241 33.0143 1.07229 35.7389C0.39217 38.4634 4.706 39.8257 5.84038 37.3283Z M16.5127 1H16.7397 M16.5127 11.8984H16.7397 M16.7398 11.8984C23.7794 11.8984 24.0066 0.999963 16.1111 0.999963 M16.5127 1C9.47304 1 9.24697 11.8985 16.9999 11.8985"
                    stroke="white"
                    strokeWidth="0.5"
                  ></path>
                </svg>
                <h4 style={{ color: 'var(--color-30-black)' }}>{genderData?.male}% Male</h4>
              </div>
              <div className="gender-icon">
                <svg width="59" height="250" viewBox="0 0 33 66" xmlns="http://www.w3.org/2000/svg">
                  <defs>
                    <linearGradient id="female_0" x1="0%" y1="100%" x2="0%" y2="0%">
                      <stop offset={`${genderData?.female}%`} stopColor="#5470c6" stopOpacity="1"></stop>
                      <stop offset="0%" stopColor="white" stopOpacity="0"></stop>
                    </linearGradient>
                  </defs>
                  <path
                    fill="url(#female_0)"
                    d="M16.5127 1H16.7397 M16.5127 11.8984H16.7397 M5.84038 37.3283C6.87203 35.057 9.03188 27.1518 9.41461 25.7386C9.44962 25.6094 9.56632 25.5216 9.70026 25.5216C9.81802 25.5216 9.90663 25.6289 9.88434 25.7445L6.3294 44.1901C6.30732 44.3047 6.32364 44.4233 6.37581 44.5277C6.46566 44.7074 6.64931 44.8209 6.85021 44.8209H9.93943C9.94229 52.9626 9.9412 60.9271 9.92731 62.4673C9.89715 65.8101 15.8644 65.7866 15.8306 62.4673V44.8209C15.8306 44.4277 17.4209 44.4277 17.4209 44.8209C17.4209 44.8209 17.3714 59.2563 17.42 62.531C17.4686 65.8058 23.3774 65.8399 23.3234 62.531V44.8209H26.4022C26.6031 44.8209 26.7867 44.7074 26.8766 44.5277C26.9288 44.4233 26.9451 44.3047 26.923 44.1901L23.3234 25.5216C23.3234 25.2944 23.7775 25.2945 23.7775 25.5216C23.7775 25.5216 26.4864 34.7893 27.4103 37.3282C28.3341 39.8671 32.8595 38.6905 32.1784 35.7389C32.1784 35.7389 26.5021 17.1206 25.8209 15.5313C25.1398 13.9419 21.2799 13.0337 16.7389 13.0337C12.1978 13.0337 8.11089 13.7148 7.42974 15.5313C6.74858 17.3477 1.75241 33.0143 1.07229 35.7389C0.39217 38.4634 4.706 39.8257 5.84038 37.3283Z M16.5127 1C9.47304 1 9.24697 11.8985 16.5139 11.8985 M16.7398 11.8984C23.7794 11.8984 24.0066 0.999963 16.1111 0.999963 M16.7398 11.8984C23.7794 11.8984 24.0066 0.999963 16.9999 0.999963"
                    stroke="white"
                    strokeWidth="0.5"
                  ></path>
                </svg>
                <h4 style={{ color: 'var(--color-30-black)' }}>{genderData?.female}% Female</h4>
              </div>
            </div>
          </div>
          <div className="core-chart-container">
            <ReactECharts option={ageData} className="core-chart" />
          </div>
        </div>
      </div>
      <div className="core-demographics-container">
        <h2 className="section-title__demographic">Family Demographics</h2>
        <div className="core-charts-row">
          <div className="core-chart-container family-container">
            <ReactECharts option={maritalStatusData} className="core-chart" />
          </div>
          <div className="core-chart-container family-container">
            <ReactECharts option={houseHoldCompositionData} className="core-chart" />
          </div>
        </div>
        <div className="core-charts-row one-chart-row">
          <div className="core-chart-container family-container">
            <ReactECharts option={numberOfChildrenData} className="core-chart" />
          </div>
        </div>
      </div>
      <div className="core-demographics-container">
        <h2 className="section-title__demographic">Home Demographics</h2>
        <div className="core-charts-row">
          <div className="core-chart-container family-container">
            <ReactECharts option={homeOwnerData} className="core-chart" />
          </div>
          <div className="core-chart-container family-container">
            <ReactECharts option={homeValueData} className="core-chart" />
          </div>
        </div>
        <div className="core-charts-row one-chart-row">
          <div className="core-chart-container family-container">
            <ReactECharts option={urbanicityData} className="core-chart" />
          </div>
        </div>
        <div className="core-charts-row one-chart-row">
          <div className="core-chart-container family-container">
            <ReactECharts option={regionalDistributionData} className="core-chart" />
          </div>
        </div>
      </div>
      <div className="core-demographics-container">
        <h2 className="section-title__demographic">Financial & Socioeconomic</h2>
        <div className="core-charts-row one-chart-row">
          <div className="core-chart-container family-container">
            <ReactECharts option={householdData} className="core-chart" />
          </div>
        </div>
        <div className="core-charts-row">
          <div className="core-chart-container family-container">
            <ReactECharts option={netAssetData} className="core-chart" />
          </div>
          <div className="core-chart-container family-container">
            <ReactECharts option={educationData} className="core-chart" />
          </div>
        </div>
        <div className="core-charts-row one-chart-row">
          <div className="core-chart-container family-container">
            <ReactECharts option={buyingPowerScoreData} className="core-chart" />
          </div>
        </div>
      </div>
      <div className="core-demographics-container family-container">
        <h2 className="section-title__demographic">Feature Distribution</h2>
        <div className="core-charts-row">
          <div className="core-chart-container">
            <div className="core-chart-select">
              <Select
                value={selectedFeature}
                onChange={onFeatureChange}
                style={{ width: '300px' }}
                showSearch
                disabled={loading}
              >
                {audienceFeatures.demographic_features?.map((item, idx) => {
                  return (
                    <Select.Option key={`${item?.feature_name}-${idx}`} value={item?.feature_name}>
                      {item?.feature_readable_name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            {loading ? <LoadingSpinner centered /> : <ReactECharts option={audienceChart} className="core-chart" />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemographicOverview;
