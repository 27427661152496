export const AUDIENCE_STATE_COLOR = {
  RELEVANT_FEATURES_FOUND: 'YELLOW',
  CREATING_AUDIENCES: 'BLUE',
  AUDIENCES_CREATED: 'GREEN',
  ERROR: 'RED',
  ARCHIVED: 'GRAY',
  HIDDEN: 'GRAY',
  DONE: 'GREEN',
  SELECTING_FEATURES: 'YELLOW',
  CREATING_CLUSTERS: 'YELLOW',
  SELECTED_FEATURES: 'ORANGE',
  FINDING_RELEVANT_FEATURES: 'YELLOW',
  CREATING_SAMPLE_CLUSTERS: 'YELLOW',
  CREATING_FINAL_CLUSTERS: 'YELLOW',
  CREATED_FINAL_CLUSTERS: 'ORANGE',
  PROJECT_BRIEF_WRITTEN: 'YELLOW',
  DRAFT: 'YELLOW',
  PROFILING: 'BLUE',
  PROFILED: 'GREEN',
  NOT_PROFILEABLE: 'ORANGE',
};

export const OUTPUT_TABLE_COLUMNS = [
  {
    title: 'Channel',
    dataIndex: 'channel',
    key: 'channel',
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform',
  },
  {
    title: 'Audience',
    dataIndex: 'audience_name',
    key: 'audience_name',
  },
  {
    title: 'Tactic',
    dataIndex: 'tactic',
    key: 'tactic',
  },
  {
    title: 'Funnel Stage',
    dataIndex: 'funnel_stage',
    key: 'funnel_stage',
  },
  {
    title: 'Start Date',
    dataIndex: 'week_start_date',
    key: 'week_start_date',
  },
  {
    title: 'End Date',
    dataIndex: 'week_end_date',
    key: 'week_end_date',
  },
  {
    title: 'Budget',
    dataIndex: 'budget',
    key: 'budget',
    render: (budget) =>
      `${budget.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}`,
  },
  {
    title: 'Reasoning',
    dataIndex: 'reasoning',
    key: 'reasoning',
  },
];

export const AUDIENCE_STATUS_OPTIONS = [
  { value: '', label: 'All' },
  { value: 'PROJECT_BRIEF_WRITTEN', label: 'Project Brief Written' },
  { value: 'FINDING_RELEVANT_FEATURES', label: 'Finding Relevant Features' },
  { value: 'RELEVANT_FEATURES_FOUND', label: 'Relevant Features Found' },
  { value: 'CREATING_AUDIENCES', label: 'Creating Audiences' },
  { value: 'AUDIENCES_CREATED', label: 'Audiences Created' },
  { value: 'DONE', label: 'Done' },
  { value: 'ERROR', label: 'Error' },
  { value: 'ARCHIVED', label: 'Archived' },
  { value: 'HIDDEN', label: 'Hidden' },
];

export const MEDIA_PLAN_PAGE = {
  MEDIA_HEADER: 'Media Plans',
  MEDIA_DESCRIPTION: 'Manage and view all your media plans in one place. Easily track progress and compare strategies.',
  CREATE_MEDIA_PLAN: 'Create',
  MEDIA_BTN_DESCRIPTION: 'Click to start a new media Plan',
  FILTER_BY_NAME: 'Filter By Name',
  FILTER_BY_OWNER: 'Filter By Owner',
  FILTER_BY_SEARCH: 'Search',
};

// Transform the data to be compatible with the table
export const transformData = (data) => {
  let transformedData = [];

  data?.media_plan?.forEach((plan, planIndex) => {
    plan?.weekly_plan?.forEach((week, weekIndex) => {
      transformedData.push({
        key: `${planIndex}-${weekIndex}`, // Unique key for each row
        channel: plan.channel,
        platform: plan.platform,
        audience_name: plan.audience_name,
        tactic: plan.tactic,
        funnel_stage: plan.funnel_stage,
        week_start_date: week.week_start_date,
        week_end_date: week.week_end_date,
        budget: week.budget,
        reasoning: week.reasoning,
      });
    });
  });

  return transformedData;
};

export const ASK_AI = 'AI Recommendation';
export const AUDIENCES_CREATED = 'AUDIENCES_CREATED';
export const PROFILED = 'PROFILED';
export const CREATING_AUDIENCES = 'CREATING_AUDIENCES';
export const RULE_BASED = 'RULE_BASED';
export const CLUSTER = 'CLUSTER';
export const CLUSTER_BASED = 'CLUSTER_BASED';
export const PROPENSITY_TIERS = 'PROPENSITY_TIERS';
export const FILTER_OPTIONS = [
  { label: 'All', value: '' },
  { label: 'RULE_BASED', value: 'RULE_BASED' },
  { label: 'CLUSTER_BASED', value: 'CLUSTER_BASED' },
];

export const SORT_OPTIONS = [
  { label: 'Name', value: 'name' },
  { label: 'Created On', value: 'created_on' },
];

// Color mapping for channel-platform combinations
export const COLOR_MAP = {
  'Social Media-Instagram': '#FF5733',
  'Social Media-Facebook': '#33FF57',
  'Display-YouTube': '#3375FF',
  'Video-YouTube': '#FF33A1',
  'Video-Tiktok': '#FF33FF',
  'TV-TV': '#FFFF33',
};

// Channel Enum
export const CHANNELS = [
  'Affiliates',
  'CTV/OTT',
  'Display',
  'Linear TV',
  'Native',
  'OLV',
  'Organic Social',
  'Paid Search',
  'Paid Social',
  'Programmatic Video',
  'Publisher Partnership',
  'Sponsorships',
];

// Platform Enum
export const PLATFORMS = [
  'No Platform',
  'DV360',
  'The Trade Desk',
  'TTD',
  'Peacock',
  'ESPN',
  'Financial Times',
  'Forbes',
  'Fortune',
  'New York Times',
  'WSJDN',
  'Youtube',
  'Facebook',
  'TikTok',
  'adMarketplace',
  'Apple',
  'Bing Search',
  'Google Ads',
  'Boosted Social',
  'LinkedIn',
  'Next Door',
  'Pinterest',
  'Reddit',
  'Snapchat',
  'Instagram',
  'Twitch',
  'Twitter',
  'Yelp',
  'Apple News',
  'MIT',
  'Morning Brew',
];

// Tactic Enum
export const TACTICS = [
  'Prospecting',
  'Retargeting',
  'Retention',
  'Upsell',
  'Brand',
  'Non Brand',
  'Competitive',
  'PMAX',
];

// Funnel Stage Enum
export const FUNNEL_STAGES = ['Conversions', 'Awareness', 'Consideration', 'Decide', 'Leads', 'Nurture'];

// Media Plan dashboard chart Filter Options
export const CHART_FILTER_OPTIONS = [
  { value: 'channel', label: 'Channel' },
  { value: 'platform', label: 'Platform' },
  { value: 'tactic', label: 'Tactic' },
  { value: 'funnel_stage', label: 'Funnel Stage' },
  { value: 'audience_name', label: 'Audience' },
];

export const CRM = 'crm';
export const DIGITAL_MARKETING = 'digital_marketing';
export function formatText(text) {
  return text
    .split('_') // Split the string into an array by underscore
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Join the array back into a single string with spaces
}

export default function formatMediaData(data) {
  return data?.data?.data;
}

export const transformedData = (data) => {
  const transformedData = data?.map((plan) => ({
    label: plan.name,
    value: plan.name,
  }));
  return transformedData;
};

export const transformedDataOwner = (data) => {
  const seenOwners = new Set(); // Track unique owners
  const transformedData = data?.reduce((acc, plan) => {
    // Handle missing first_name or last_name gracefully
    const firstName = plan?.created_by?.first_name || '';
    const lastName = plan?.created_by?.last_name || '';
    const ownerName = `${firstName} ${lastName}`.trim() || plan?.created_by?.email; // Concatenate first_name and last_name, fallback to email if both are missing
    const ownerValue = plan?.created_by?.email;

    if (!seenOwners.has(ownerValue)) {
      seenOwners.add(ownerValue); // Use the email (unique identifier) to track
      acc.push({
        label: ownerName, // Either full name or email if name is not available
        value: ownerValue, // Use the email as the value
      });
    }

    return acc;
  }, []);

  return transformedData;
};

export const AUDIENCE_USER_ROLE_OPTIONS = ['OWNER', 'ADMIN', 'VIEWER'];

export const VIEWER = 'VIEWER';

export const SEM_STATUS = ['DRAFT', 'CREATING', 'GENERATING_KEYWORD', 'FORECASTING'];

export const SEM_NOT_ENABLED_STATUS = ['NOT_ENABLED', 'NOT_CREATED'];

export const MEDIA_PLAN_GRANULARITY = ['WEEKLY', 'MONTHLY', 'QUARTERLY', 'ANNUALLY'];
