import { formatString, numberWithCommas } from 'src/lib/utils';

const useSemKeywordsTable = () => {
  const transformData = (jsonData) => {
    if (!jsonData?.data?.ad_group_data) {
      console.error('Invalid data structure', jsonData);
      return [];
    }

    return jsonData.data.ad_group_data.map((adGroupData) => ({
      ad_group_data: adGroupData.name,
      seasonality_max_monthly_search_adjusted: formatNumber(adGroupData.seasonality_max_monthly_search_adjusted),
      weighted_competition: `${Math.round(adGroupData.weighted_percentage)}%`,
      top_keyword: adGroupData.top_keyword || '-',
      max_spend: formatNumber(adGroupData.max_spend, 'currency'),
      max_impression_share: formatNumber(adGroupData.max_impression_share, 'percent'),
      max_clicks: formatNumber(adGroupData.max_clicks),
      max_cpc: formatBudget(adGroupData.max_cpc),
      min_spend: formatNumber(adGroupData.min_spend, 'currency'),
      min_impression_share: formatNumber(adGroupData.min_impression_share, 'percent'),
      min_clicks: formatNumber(adGroupData.min_clicks),
      min_cpc: formatBudget(adGroupData.min_cpc),
    }));
  };

  const formatBudget = (value) => {
    return `$${Number.isInteger(value) ? numberWithCommas(value) : numberWithCommas(parseFloat(value.toFixed(2)))}`;
  };

  const formatNumber = (num, type = 'number') => {
    if (num === undefined || num === null) return '-';

    const parsedNum = parseFloat(num);
    if (isNaN(parsedNum)) return '-';

    if (type === 'currency') {
      return `$${parsedNum.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
    }

    if (type === 'percent') {
      return `${(parsedNum * 100).toLocaleString(undefined, { maximumFractionDigits: 0 })}%`;
    }
    return parsedNum % 1 === 0
      ? parsedNum.toLocaleString()
      : parsedNum.toLocaleString(undefined, { maximumFractionDigits: 0 });
  };

  const getDynamicColumns = () => {
    return [
      {
        title: 'Ad Group',
        dataIndex: 'ad_group_data',
        key: 'ad_group_data',
        fixed: 'left',
        width: 130,
        render: (data) => formatString(data),
      },
      {
        title: 'Potential Search (Monthly)',
        dataIndex: 'seasonality_max_monthly_search_adjusted',
        key: 'seasonality_max_monthly_search_adjusted',
        width: 150,
      },
      { title: 'Weighted Competition', dataIndex: 'weighted_competition', key: 'weighted_competition', width: 130 },
      { title: 'Top Keyword', dataIndex: 'top_keyword', key: 'top_keyword', width: 130 },
      { title: 'Max Spend', dataIndex: 'max_spend', key: 'max_spend', width: 100 },
      { title: 'Max IS', dataIndex: 'max_impression_share', key: 'max_impression_share', width: 80 },
      { title: 'Max Clicks', dataIndex: 'max_clicks', key: 'max_clicks', width: 100 },
      { title: 'Max CPC', dataIndex: 'max_cpc', key: 'max_cpc', width: 100 },
      { title: 'Min Spend', dataIndex: 'min_spend', key: 'min_spend', width: 100 },
      { title: 'Min IS', dataIndex: 'min_impression_share', key: 'min_impression_share', width: 80 },
      { title: 'Min Clicks', dataIndex: 'min_clicks', key: 'min_clicks', width: 100 },
      { title: 'Min CPC', dataIndex: 'min_cpc', key: 'min_cpc', width: 100 },
    ];
  };

  return {
    transformData,
    getDynamicColumns,
  };
};

export default useSemKeywordsTable;
