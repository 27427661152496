import { notification } from 'antd';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect, useRef, useState } from 'react';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { AiAppApiServices } from '../../services/aiAppApiServices';

const scheduleOption = ['DAILY', 'WEEKLY', 'MONTHLY'];
const weekDays = [
  { value: 0, label: 'Monday' },
  { value: 1, label: 'Tuesday' },
  { value: 2, label: 'Wednesday' },
  { value: 3, label: 'Thursday' },
  { value: 4, label: 'Friday' },
  { value: 5, label: 'Saturday' },
  { value: 6, label: 'Sunday' },
];

const useTaskCreateEditForm = ({ data, taskCreateModal, updateTask, setTaskCreateModal }) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const mAiApp = data?.id;
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [configureLoading, setConfigureLoading] = useState(false);
  const [configureLoadingNow, setConfigureLoadingNow] = useState(false);
  const formRef = useRef(null);

  const onFinish = async (val) => {
    try {
      val?.run_now ? setConfigureLoadingNow(true) : setConfigureLoading(true);
      const payload = {
        ...val,
        time: dayjs(val.time).utc().format('HH:mm'),
      };
      if (taskCreateModal?.taskId) {
        payload.task_id = taskCreateModal.taskId;
      }
      const res = await AiAppApiServices.createEditTask(payload, mAiApp);
      if (!taskCreateModal?.taskId) {
        notification.success({
          message: 'Task Created Successfully',
          duration: 3,
          placement: 'bottomRight',
        });
        const newTask = {
          ...res.data,
          task_id: res.data.id,
        };

        updateTask(newTask, 'add');
      }
      setTaskCreateModal({ visible: false, taskId: null });
      val?.run_now ? setConfigureLoadingNow(false) : setConfigureLoading(false);
    } catch (error) {
      val?.run_now ? setConfigureLoadingNow(false) : setConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 3,
        placement: 'bottomRight',
      });
    }
  };

  const fetchData = async () => {
    try {
      const res = await AiAppApiServices.getTask(taskCreateModal.taskId, mAiApp);

      if (res?.data?.status === 'ARCHIVED') {
        notification.error({
          message: 'Task is deleted',
          description: 'This task has been deleted hence cannot be edited.',
          duration: 3,
          placement: 'bottomRight',
        });
        setTaskCreateModal({ visible: false, taskId: null }); // Close the modal
        setLoading(false);
        return; // Stop further processing
      }

      const utcTimeString = res?.data?.time;
      const todayUtc = dayjs.utc().format('YYYY-MM-DD');
      const utcDateTime = dayjs.utc(`${todayUtc}T${utcTimeString}Z`);
      const localTime = utcDateTime.local().format('YYYY-MM-DD HH:mm:ss');

      setInitialValues({
        ...res.data,
        time: dayjs(localTime),
      });
      setLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 3,
        placement: 'bottomRight',
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    if (taskCreateModal?.taskId) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    formRef,
    configureLoading,
    onFinish,
    scheduleOption,
    initialValues,
    configureLoadingNow,
    weekDays,
  };
};

export default useTaskCreateEditForm;
