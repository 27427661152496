import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { formatNumber, getErrorMessageFromResponse } from 'src/lib/utils';
import audienceListService from '../../../services/audienceListService';
import { DEFAULT_CHART_GREY, PIE_CHART_COLORS } from 'src/constants/constants';

const useDemographicHook = ({ audienceFeatures, audienceId }) => {
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [audienceChart, setAudienceChart] = useState({});
  const [selectedFeature, setSelectedFeature] = useState(audienceFeatures?.demographic_features?.[0]?.feature_name);
  const [selectedFeatureReadableName, setSelectedFeatureReadableName] = useState(
    audienceFeatures?.demographic_features?.[0]?.feature_readable_name
  );
  const [genderData, setGenderData] = useState({});
  const [ageData, setAgeData] = useState({});
  const [householdData, setHouseHoldData] = useState({});
  const [educationData, setEducationData] = useState({});
  const [maritalStatusData, setMaritalStatusData] = useState({});
  const [numberOfChildrenData, setNumberOfChildrenData] = useState({});
  const [houseHoldCompositionData, setHouseHoldCompositionData] = useState({});
  const [homeOwnerData, setHomeOwnerData] = useState({});
  const [homeValueData, setHomeValueData] = useState({});
  const [urbanicityData, setUrbanicityData] = useState({});
  const [netAssetData, setNetAssetData] = useState({});
  const [buyingPowerScoreData, setBuyingPowerScoreData] = useState({});
  const [regionalDistributionData, setRegionalDistributionData] = useState({});
  const lightGreyColor = DEFAULT_CHART_GREY;
  const fetchData = async (featureName, params = {}) => {
    return await audienceListService.getAudienceFeatureProfiles(
      audienceId,
      '',
      featureName,
      1,
      '',
      undefined,
      100,
      params
    );
  };

  const formatBarChartOptions = (title, data, sortByvalue) => {
    const sortedData = sortByvalue
      ? data.sort((a, b) => b.audience_feature_percentage - a.audience_feature_percentage)
      : data;

    return {
      darkMode: true,
      title: {
        text: title,
        textStyle: {
          fontWeight: 500,
          fontSize: '1.17em',
          color: lightGreyColor,
        },
        left: 10,
      },
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          const { name, value, indexValue } = params[0].data;
          return `<b>${name}</b><br>Audience: ${value}%<br>Index: ${indexValue}`;
        },
        axisPointer: {
          type: 'none',
        },
      },
      toolbox: {
        right: 20,
        feature: {
          saveAsImage: { show: true, iconStyle: { normal: { borderColor: lightGreyColor } } },
          dataView: { show: true, iconStyle: { normal: { borderColor: lightGreyColor } } },
        },
      },
      grid: {
        left: '5%',
        right: '5%',
        containLabel: true, // Ensures labels fit inside the chart
      },
      xAxis: {
        type: 'value',
        name: 'Audience(%)',
        nameLocation: 'center',
        nameTextStyle: {
          color: lightGreyColor,
          padding: 20,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#444444',
          },
        },
        axisLabel: {
          color: lightGreyColor,
        },
      },
      yAxis: {
        type: 'category',
        data: sortedData.map((item) =>
          item.feature_readable_value.length > 0 ? item.feature_readable_value : 'Unknown'
        ),
        axisLabel: {
          interval: 0,
          color: lightGreyColor,
          width: 150, // Set a fixed width to prevent truncation
          overflow: 'break', // Ensures text wraps if needed
        },
      },
      series: [
        {
          type: 'bar',
          data: sortedData.map((item) => ({
            name: item.feature_readable_value.length > 0 ? item.feature_readable_value : 'Unknown',
            indexValue: formatNumber(item.index_value, '0.0', 1),
            value: formatNumber(item.audience_feature_percentage, '0.0', 1),
          })),
          label: {
            show: true,
            position: 'outside',
            formatter: ({ data }) => `${data.value}%`,
            distance: 5,
          },
          barMaxWidth: 50,
        },
      ],
    };
  };

  const formatPieChartOptions = (title, data) => ({
    darkMode: true,
    title: {
      text: title,
      textStyle: {
        fontWeight: 500,
        fontSize: '1.17em',
        color: lightGreyColor,
      },
      left: 10,
    },
    tooltip: {
      trigger: 'item',
      formatter: (params) => {
        // Show both audience percentage and index value in the tooltip
        return `<b>${params.name}</b><br>Audience: ${params.value}%<br>Index: ${params.data.indexValue}`;
      },
    },
    toolbox: {
      right: 20,
      top: 36,
      feature: {
        saveAsImage: { show: true, iconStyle: { normal: { borderColor: lightGreyColor } } },
        dataView: { show: true, iconStyle: { normal: { borderColor: lightGreyColor } } },
      },
    },
    legend: {
      type: 'scroll',
      width: '80%',
      textStyle: {
        color: lightGreyColor,
      },
      left: 10,
      top: 40,
      pageTextStyle: {
        color: lightGreyColor,
      },
      pageIconColor: lightGreyColor,
      pageIconInactiveColor: '#444444',
    },
    series: [
      {
        name: title,
        type: 'pie',
        radius: '70%',
        data: data.map((item) => ({
          value: formatNumber(item.audience_feature_percentage, '0.0', 1),
          name: item.feature_readable_value.length > 0 ? item.feature_readable_value : 'Unknown',
          indexValue: formatNumber(item.index_value, '0.0', 1), // Store index value for label
        })),
        top: 60,
        label: {
          show: true,
          formatter: ({ data }) => {
            const { name, value, indexValue } = data;
            return `{bold|${name}}\n${value}% (Index: ${indexValue})`;
          },
          color: lightGreyColor,
          rich: {
            bold: {
              fontWeight: 'bold',
              fontSize: 12,
              color: lightGreyColor,
            },
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        color: PIE_CHART_COLORS,
      },
    ],
  });

  const formatVerticalBarChartOptions = (title, data, sortVyValue, showDataZoom) => {
    const sortedData = sortVyValue
      ? data.sort((a, b) => b.audience_feature_percentage - a.audience_feature_percentage)
      : data;
    return {
      darkMode: true,
      title: {
        text: title,
        textStyle: {
          fontWeight: 500,
          fontSize: '1.17em',
          color: lightGreyColor,
        },
        left: 10,
      },
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          const { name, value, indexValue } = params[0].data;
          return `<b>${name}</b>:<br>Audience: ${value}%<br>Index: ${indexValue}`;
        },
        axisPointer: {
          type: 'none',
        },
      },
      toolbox: {
        right: 20,
        feature: {
          saveAsImage: { show: true, iconStyle: { normal: { borderColor: lightGreyColor } } },
          dataView: { show: true, iconStyle: { normal: { borderColor: lightGreyColor } } },
        },
      },
      dataZoom: showDataZoom
        ? [
            {
              type: 'slider',
              start: 0,
              end: 100,
              height: 20,
              bottom: 10,
            },
          ]
        : [],
      xAxis: {
        type: 'category',
        data: sortedData.map((item) =>
          item.feature_readable_value.length > 0 ? item.feature_readable_value : 'Unknown'
        ),
        axisLabel: {
          color: lightGreyColor,
          interval: 0,
          hideOverlap: true,
          formatter: (value) => {
            return ' ' + value + ' ';
          },
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        type: 'value',
        name: 'Audience(%)',
        nameTextStyle: {
          color: lightGreyColor,
          padding: 20,
        },
        nameLocation: 'center',
        splitLine: {
          show: true,
          lineStyle: {
            color: '#444444',
          },
        },
        axisLabel: {
          color: lightGreyColor,
        },
      },
      series: [
        {
          type: 'bar',
          data: sortedData.map((item) => ({
            name: item.feature_readable_value.length > 0 ? item.feature_readable_value : 'Unknown',
            indexValue: formatNumber(item.index_value, '0.0', 1),
            value: formatNumber(item.audience_feature_percentage, '0.0', 1),
          })),
          label: {
            show: true,
            position: 'top',
            formatter: ({ data }) => `${data.value}%`,
            color: '#ffffff',
            distance: 5,
          },
          emphasis: {
            label: {
              show: true,
              position: 'top',
              formatter: ({ data }) => `${data.value}%`,
              color: '#ffffff',
            },
          },
          barMaxWidth: 50,
        },
      ],
    };
  };

  const defaultLoad = async () => {
    try {
      setPageLoading(true);
      const gender = await fetchData('GENDER');
      const age = await fetchData('age_ranges');
      const household = await fetchData('hhi_range');
      const education = await fetchData('education');
      const maritalStatus = await fetchData('marital_status_married');
      const numberOfChildren = await fetchData('number_of_children_in_living_unit_rollup');
      const houseHoldComposition = await fetchData('hh_composition');
      const homeOwner = await fetchData('homeowner');
      const homeValue = await fetchData('home_business');
      const urbanicity = await fetchData('census_rural_urban_county_size_code_rollup');
      const netAsset = await fetchData('net_asset_value_rollup');
      const butingPowerScore = await fetchData('bp_ranges');
      const regionalDistribution = await fetchData('regional_distribution');

      if (gender?.data?.data?.length > 0) {
        const male = gender.data.data.find((item) => item.feature_value === '0');
        const female = gender.data.data.find((item) => item.feature_value === '1');
        setGenderData({
          male: formatNumber(male.audience_feature_percentage, '0.0', 1),
          female: formatNumber(female.audience_feature_percentage, '0.0', 1),
        });
      }

      setAgeData(formatBarChartOptions('Age Distribution', age.data.data, false));
      setMaritalStatusData(formatPieChartOptions('Marital Status Distribution', maritalStatus.data.data));
      setNumberOfChildrenData(
        formatBarChartOptions('Number of Children Distribution', numberOfChildren.data.data, false)
      );
      setHouseHoldCompositionData(
        formatPieChartOptions('Household Composition Distribution', houseHoldComposition.data.data)
      );
      setHomeOwnerData(formatPieChartOptions('Home Owner/Renter Status Distribution', homeOwner.data.data));
      setHomeValueData(formatPieChartOptions('Home Business Distribution', homeValue.data.data));
      setUrbanicityData(formatBarChartOptions('Urbanicity', urbanicity.data.data, true));
      setHouseHoldData(formatVerticalBarChartOptions('Household Income', household.data.data, false, true));
      setNetAssetData(formatPieChartOptions('Net Asset Value', netAsset.data.data));
      setBuyingPowerScoreData(
        formatVerticalBarChartOptions('Buying Power Score', butingPowerScore.data.data, false, false)
      );
      setEducationData(formatPieChartOptions('Education', education.data.data));
      setRegionalDistributionData(
        formatVerticalBarChartOptions('Regional Distribution', regionalDistribution.data.data, true, false)
      );

      if (audienceFeatures?.demographic_features.length > 0) {
        await getChartData(audienceFeatures.demographic_features[0].feature_name);
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const formatStackedBarChart = (data) => {
    const audienceData = data.map((item) => ({
      value: formatNumber(item.audience_feature_percentage, '0.0', 1),
      name: item.feature_readable_value.length > 0 ? item.feature_readable_value : 'Unknown',
      indexValue: formatNumber(item.index_value, '0.0', 1),
    }));

    const baselineData = data.map((item) => ({
      value: formatNumber(item.base_feature_percentage, '0.0', 1),
      name: item.feature_readable_value,
    }));

    return { audienceData, baselineData };
  };

  const getChartData = async (featureName) => {
    try {
      setLoading(true);
      const audienceChartData = await fetchData(featureName);
      const { audienceData, baselineData } = formatStackedBarChart(audienceChartData.data.data);

      const stackedBarChartOption = {
        darkMode: true,
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: (params) =>
            params
              .map((item) => {
                if (typeof item.data === 'object') {
                  return `Index Value: ${item.data.indexValue}<br/>${item.seriesName}: ${item.data.value}%`;
                }
                return `${item.seriesName}: ${item.data}%`;
              })
              .join('<br/>'),
        },
        toolbox: {
          right: 20,
          feature: {
            saveAsImage: { show: true, iconStyle: { normal: { borderColor: lightGreyColor } } },
            dataView: { show: true, iconStyle: { normal: { borderColor: lightGreyColor } } },
          },
        },
        dataZoom: [
          {
            type: 'slider',
            start: 0,
            end: 100,
            height: 20,
            bottom: 10,
          },
        ],
        legend: {
          data: ['Audience Percentage', 'Total Population Percentage'],
          textStyle: {
            color: lightGreyColor,
          },
        },
        xAxis: {
          type: 'category',
          data: audienceData.map((item) => item.name),
          splitLine: {
            lineStyle: {
              color: '#444444',
            },
          },
          axisLabel: {
            color: lightGreyColor,
          },
        },
        yAxis: {
          type: 'value',
          splitLine: {
            lineStyle: {
              color: '#444444',
            },
          },
          axisLabel: {
            color: lightGreyColor,
          },
        },
        series: [
          {
            name: 'Audience Percentage',
            type: 'bar',
            data: audienceData.map((item) => ({ value: item.value, indexValue: item.indexValue })),
            barMaxWidth: 50,
          },
          {
            name: 'Total Population Percentage',
            type: 'line',
            data: baselineData.map((item) => item.value),
            color: '#73C0DE',
          },
        ],
      };
      setAudienceChart(stackedBarChartOption);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const onFeatureChange = (featureName) => {
    const obj = audienceFeatures.demographic_features?.find((item) => item.feature_name === featureName);
    if (obj) {
      setSelectedFeatureReadableName(obj.feature_readable_name);
    }
    setSelectedFeature(featureName);
    getChartData(featureName);
  };

  useEffect(() => {
    defaultLoad();
  }, []);

  return {
    pageLoading,
    loading,
    audienceChart,
    selectedFeature,
    onFeatureChange,
    selectedFeatureReadableName,
    genderData,
    ageData,
    householdData,
    educationData,
    maritalStatusData,
    numberOfChildrenData,
    houseHoldCompositionData,
    homeOwnerData,
    homeValueData,
    urbanicityData,
    regionalDistributionData,
    netAssetData,
    buyingPowerScoreData,
  };
};

export default useDemographicHook;
