import React from 'react';
import { Modal, Select, Button } from 'antd';
import useOperatorValueSelectorHook from './useOperatorValueSelectorHook';

const { Option } = Select;

const OperatorValueSelector = ({
  visible,
  onClose,
  onConfirm,
  field,
  operators,
  initialOperator,
  initialValue,
  showClear = true,
}) => {
  const { selectedOperator, setSelectedOperator, renderValueInput, handleConfirm, handleClear } =
    useOperatorValueSelectorHook(initialOperator, initialValue, field, onConfirm, onClose);

  return (
    <Modal title={`${field.label}`} visible={visible} onCancel={onClose} destroyOnClose footer={null}>
      <div style={{ marginBottom: 16 }}>
        <label>Operator:</label>
        <Select style={{ width: '100%' }} value={selectedOperator} onChange={(val) => setSelectedOperator(val)}>
          {operators.map((op) => (
            <Option key={op.value} value={op.value}>
              {op.label}
            </Option>
          ))}
        </Select>
      </div>
      <div>
        <label>Value:</label>
        {renderValueInput()}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
        {showClear && (
          <Button key="clear" type="primary" style={{ width: '48%' }} onClick={handleClear}>
            Clear
          </Button>
        )}
        <Button key="apply" style={{ width: showClear ? '48%' : '100%' }} onClick={() => handleConfirm()}>
          Apply
        </Button>
      </div>
    </Modal>
  );
};

export default OperatorValueSelector;
