export const KPI_TAB_MAPPING = {
  standard_kpi: {
    columns: [
      {
        name: 'Std KPI Name',
        key: 'kpi_name',
        editable: false,
        className: 'kpi-name',
      },
      {
        name: 'Custom KPI Name',
        key: 'custom_name',
        editable: true,
        className: 'custom-name',
      },
      {
        name: 'Description',
        key: 'definition',
        editable: true,
        className: 'definition',
      },
      {
        name: 'Benchmark Value',
        key: 'benchmark_value',
        editable: true,
        className: 'benchmark-value',
      },
      {
        name: '% B2C',
        key: 'b2c_percent',
        editable: true,
        className: 'b2c-percent',
        precision: '%',
      },
      {
        name: '% B2B',
        key: 'b2b_percent',
        editable: true,
        className: 'b2b-percent',
        precision: '%',
      },
    ],
  },
  client_specific_kpi: {
    columns: [
      {
        name: 'KPI Name',
        key: 'kpi_name',
        editable: false,
        className: 'custom-name-without-edit',
      },
      {
        name: 'Description',
        key: 'definition',
        editable: false,
        className: 'definition',
      },
      {
        name: 'Benchmark Value',
        key: 'benchmark_value',
        editable: false,
        className: 'benchmark-value',
      },
      {
        name: '% B2C',
        key: 'b2c_percent',
        editable: false,
        className: 'b2c-percent-without-edit',
        precision: '%',
      },
      {
        name: '% B2B',
        key: 'b2b_percent',
        editable: false,
        className: 'b2b-percent-without-edit',
        precision: '%',
      },
    ],
  },
  custom_kpi: {
    columns: [
      {
        name: 'KPI Name',
        key: 'custom_name',
        editable: true,
        className: 'kpi-name',
      },
      {
        name: 'Standard KPI',
        key: 'kpi_name',
        editable: true,
        className: 'custom-name',
      },
      {
        name: 'Description',
        key: 'definition',
        editable: true,
        className: 'definition',
      },
      {
        name: 'Benchmark Value',
        key: 'benchmark_value',
        editable: true,
        className: 'benchmark-value',
      },
      {
        name: '% B2C',
        key: 'b2c_percent',
        editable: true,
        className: 'b2c-percent',
        precision: '%',
      },
      {
        name: '% B2B',
        key: 'b2b_percent',
        editable: true,
        className: 'b2b-percent',
        precision: '%',
      },
    ],
  },
};
