import { notification } from 'antd';
import chroma from 'chroma-js';
import L from 'leaflet';
import { useCallback, useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import { formatNumberWithCeil, getErrorMessageFromResponse } from 'src/lib/utils';
import {
  AUDIENCE_FEATURE_PERCENTAGE,
  COUNTY,
  DMA,
  GEOGRAPHIC,
  INDEX_VALUE,
  PENETRATION_PERCENTAGE,
  STATE,
} from '../../../constants/audienceDetailsConstants';
import audienceListService from '../../../services/audienceListService';

const useGeographicHook = ({ audienceId }) => {
  const [geographicLoading, setGeographicLoading] = useState(false);
  const [shadingLevel, setShadingLevel] = useState(10);
  const [geographicLevel, setGeographicLevel] = useState(DMA);
  const [geographicData, setGeographicData] = useState(null);
  const [dmaData, setDmaData] = useState(null);
  const [countiesData, setCountiesData] = useState(null);
  const [statesData, setStatesData] = useState(null);
  const [heatMapType, setHeatMapType] = useState(INDEX_VALUE);
  const [geoData, setGeoData] = useState(null);

  const fetchGeographicData = async (featureName) => {
    setGeographicLoading(true);
    try {
      const audienceData = await audienceListService.getAudienceFeatureProfiles(
        audienceId,
        '',
        featureName,
        1,
        GEOGRAPHIC,
        undefined,
        10000
      );
      setGeographicData(audienceData.data.data);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    } finally {
      setGeographicLoading(false);
    }
  };

  const getDmaData = async () => {
    try {
      const response = await fetch('/dma.json');
      const data = await response.json();
      setDmaData(data);
    } catch (error) {
      notification.error({
        duration: 5,
        message: 'Failed to fetch DMA data',
        placement: 'bottomRight',
      });
    }
  };

  const getCountyData = async () => {
    try {
      const response = await fetch('/counties.geojson');
      const data = await response.json();
      setCountiesData(data);
    } catch (error) {
      notification.error({
        duration: 5,
        message: 'Failed to fetch Counties data',
        placement: 'bottomRight',
      });
    }
  };

  const getStateData = async () => {
    try {
      const response = await fetch('/usStates.json');
      const data = await response.json();
      setStatesData(data);
    } catch (error) {
      notification.error({
        duration: 5,
        message: 'Failed to fetch States data',
        placement: 'bottomRight',
      });
    }
  };

  const getGradientColors = (numColors, startColor = '#fee8c8', endColor = '#990000') => {
    const scale = chroma.scale([startColor, endColor]).mode('lab');
    return scale.colors(numColors);
  };

  const calculateMinMaxValues = () => {
    if (!geographicData) return { min: 0, max: 100 };

    const values = geographicData.map((featureInfo) => {
      switch (heatMapType) {
        case INDEX_VALUE:
          return featureInfo.index_value;
        case PENETRATION_PERCENTAGE:
          return featureInfo.penetration_value * 100;
        case AUDIENCE_FEATURE_PERCENTAGE:
          return featureInfo.audience_feature_percentage;
        default:
          return featureInfo.index_value;
      }
    });

    const max = Math.max(...values);
    const min = geographicData.length === geoData?.features.length ? Math.min(...values) : 0;
    return { min: min, max: Math.ceil(max) };
  };

  const getColor = (d) => {
    const { min, max } = calculateMinMaxValues();
    const colors = getGradientColors(shadingLevel);
    const interval = (max - min) / shadingLevel;
    const index = Math.min(Math.floor((d - min) / interval), shadingLevel - 1);
    return colors[index];
  };

  const getFeatureInfo = useCallback(
    (feature) => {
      if (!feature.properties || !geographicData) return null;

      let jsonFeatureValue;
      switch (geographicLevel) {
        case DMA:
          jsonFeatureValue = feature.properties.DMA;
          break;
        case STATE:
          jsonFeatureValue = feature.id;
          break;
        case COUNTY:
          jsonFeatureValue = feature.properties.GEOID;
          break;
        default:
          jsonFeatureValue = null;
      }

      return geographicData.find((f) => f.feature_value === jsonFeatureValue);
    },
    [geographicData, geographicLevel]
  );

  const style = useCallback(
    (feature) => {
      const featureInfo = getFeatureInfo(feature);
      let heatMapValue = 0;

      if (featureInfo) {
        switch (heatMapType) {
          case INDEX_VALUE:
            heatMapValue = featureInfo.index_value;
            break;
          case PENETRATION_PERCENTAGE:
            heatMapValue = featureInfo.penetration_value * 100;
            break;
          case AUDIENCE_FEATURE_PERCENTAGE:
            heatMapValue = featureInfo.audience_feature_percentage;
            break;
          default:
            heatMapValue = featureInfo.index_value;
        }
      }

      return {
        fillColor: getColor(heatMapValue),
        weight: 1,
        opacity: 1,
        color: 'black',
        fillOpacity: 0.7,
      };
    },
    [getFeatureInfo, heatMapType, shadingLevel, geographicData]
  );

  const onEachFeature = useCallback(
    (feature, layer) => {
      const featureInfo = getFeatureInfo(feature);
      const regionName = geographicLevel === STATE ? feature.properties.name : feature.properties.NAME;

      layer.bindTooltip(
        `<div>
          <strong>${regionName}</strong><br/>
          Index Value: ${featureInfo ? formatNumberWithCeil(featureInfo.index_value) : 0}<br/>
          Penetration Percentage: ${featureInfo ? formatNumberWithCeil(featureInfo.penetration_value * 100) : 0}%<br/>
          Audience Feature Percentage: ${featureInfo ? formatNumberWithCeil(featureInfo.audience_feature_percentage) : 0}%<br/>
          Base Feature Percentage: ${featureInfo ? formatNumberWithCeil(featureInfo.base_feature_percentage) : 0}%
        </div>`,
        { permanent: false, direction: 'auto' }
      );
    },
    [getFeatureInfo, geographicLevel]
  );

  const getGeoData = useCallback(() => {
    switch (geographicLevel) {
      case COUNTY:
        return countiesData;
      case STATE:
        return statesData;
      case DMA:
      default:
        return dmaData;
    }
  }, [geographicLevel, dmaData, countiesData, statesData]);

  useEffect(() => {
    getDmaData();
    getCountyData();
    getStateData();
  }, [audienceId]);

  useEffect(() => {
    fetchGeographicData(geographicLevel);
  }, [geographicLevel, audienceId]);

  useEffect(() => {
    setGeoData(getGeoData());
  }, [getGeoData]);

  const Legend = () => {
    const map = useMap();

    useEffect(() => {
      const legend = L.control({ position: 'bottomright' });
      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'info legend');
        const { min, max } = calculateMinMaxValues();
        const header = heatMapType;
        const formatValue = (value) => value.toFixed(2);

        const grades = Array.from({ length: shadingLevel + 1 }, (_, i) =>
          formatValue(min + i * ((max - min) / shadingLevel))
        );

        const labels = grades
          .map((grade, i) => {
            if (i < grades.length - 1) {
              return `<div style="display: flex; align-items: center;">
              <span style="background:${getColor(parseFloat(grades[i]) / 2.0 + parseFloat(grades[i + 1]) / 2.0)}; width: 20px; height: 20px; display: inline-block; margin-right: 8px;"></span>
              ${grade}&ndash;${grades[i + 1]}
            </div>`;
            }
            return '';
          })
          .join('');

        div.innerHTML = `<h4>${header}</h4>${labels}`;
        return div;
      };
      legend.addTo(map);
      map.zoomControl.setPosition('bottomleft');

      return () => legend.remove();
    }, [map, shadingLevel, heatMapType, geographicData]);

    return null;
  };

  return {
    geographicLoading,
    shadingLevel,
    setShadingLevel,
    geographicLevel,
    setGeographicLevel,
    heatMapType,
    setHeatMapType,
    geoData,
    style,
    onEachFeature,
    Legend,
  };
};

export default useGeographicHook;
