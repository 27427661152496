import ApiUrls from 'src/constants/apiUrls';
import axios from 'src/http/index';

export default class CreateClientApiServices {
  static getClientSpaceMetaData = async (id) => {
    const res = await axios.get(ApiUrls.CLIENT_SPACE_META_DATA(id));
    return res?.data;
  };

  static getClientSpaceDetails = async (id) => {
    const res = await axios.get(ApiUrls.GET_CLIENT_DETAILS(id));
    return res.data;
  };

  static createClientSpace = async (data) => {
    const res = await axios.post(ApiUrls.CREATE_CLIENT_SPACE, data);
    return res.data;
  };

  static updateClientUserAccess = async (data) => {
    const res = await axios.post(ApiUrls.CLIENT_SPACE_POST_ACCESS, data);
    return res.data;
  };

  static updateClientApps = async (data) => {
    const res = await axios.post(ApiUrls.CLIENT_SPACE_POST_APPS, data);
    return res.data;
  };

  static getClientAppDetails = async (id) => {
    const res = await axios.get(ApiUrls.GET_CLIENT_APP_DETAILS(id));
    return res.data;
  };

  static getClientAccessDetails = async (id) => {
    const res = await axios.get(ApiUrls.GET_CLIENT_ACCESS_DETAILS(id));
    return res.data;
  };

  static deleteClientUserAccess = async (id, clientSpaceId) => {
    const res = await axios.delete(ApiUrls.DELETE_CLIENT_ACCESS_USER(id, clientSpaceId));
    return res.data;
  };

  static addUpdateUserToMicroApp = async (data) => {
    const res = await axios.post(ApiUrls.ADD_UPDATE_USER_MICRO_APP, data);
    return res.data;
  };

  static deleteUserFromMicroApp = async (id, clientSpaceId) => {
    const res = await axios.delete(ApiUrls.DELETE_USER_MICRO_APP(id, clientSpaceId));
    return res.data;
  };

  static getUsersByClient = async (id) => {
    const res = await axios.get(ApiUrls.GET_USERS_BY_CLIENT(id));
    return res.data;
  };

  static getKPIConfig = async (id) => {
    const res = await axios.get(ApiUrls.GET_KPI_CONFIG(id));
    return res.data;
  };

  static saveKPIConfig = async (data) => {
    const res = await axios.post(ApiUrls.CREATE_UPDATE_KPI_CONFIG, data);
    return res.data;
  };

  static deleteKPIConfig = async (id, clientSpaceId) => {
    const res = await axios.delete(ApiUrls.DELETE_KPI_CONFIG(id, clientSpaceId));
    return res.data;
  };
}
