import { Table } from 'antd';
import React from 'react';
import './MediaPlanSemKeyword.less';
import useSemKeywordsTable from './useSemKeywordTable';

const SemKeywordsTable = ({ tabData }) => {
  const { transformData, getDynamicColumns } = useSemKeywordsTable();
  const transformedData = transformData({ data: tabData });
  return (
    <div className="SEMKeywords-container">
      <div>
        <Table
          className="dark-table"
          columns={getDynamicColumns()}
          dataSource={transformedData}
          pagination={false}
          rowKey={(record, i) => `row-${i}`}
          scroll={{ x: 'max-content' }}
        />
      </div>
    </div>
  );
};

export default SemKeywordsTable;
