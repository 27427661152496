import React from 'react';
import FlightingTable from './FlightingTable';
import './MediaPlanFlighting.less';
import useMediaPlanFlighting from './useMediaPlanFlighting';

const MediaPlanFlighting = ({ data }) => {
  const { tab, tabData } = useMediaPlanFlighting({ data });
  return <>{tab && <FlightingTable tab={tab} tabData={tabData} />}</>;
};

export default MediaPlanFlighting;
