import { InfoCircleOutlined, PlusOutlined, ProfileOutlined, ToolOutlined } from '@ant-design/icons';
import { Button, Card, Input, Modal, Radio, Select, Steps, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from 'src/components/loadingSpinner';
import ClusterFeature from 'src/modules/audienceTool/clusterTool/components/clusterFeature/ClusterFeature';
import CreateCluster from 'src/modules/audienceTool/clusterTool/components/createCluster/CreateCluster';
import ShowCluster from 'src/modules/audienceTool/clusterTool/components/ShowCluster/ShowCluster';
import NcBreadCrumb from 'src/modules/newsAndCulture/components/NcBreadCrumb';
import { CLUSTER_BASED, PROPENSITY_TIERS, RULE_BASED, VIEWER } from '../../constants/constants';
import './createAudience.style.less';
import RecommendedAudiencesComponent from './RecommendedAudiencesComponent';
import BehaviourProbabilityComponent from './ruleBased/BehaviourProbabilityComponent';
import DataSourceTabLayout from './ruleBased/DataSourceTabLayout';
import useCreateAudienceHook from './useCreateAudienceHook';
import CardTooltip from 'src/components/basic/CardTooltip';

const { Title } = Typography;
const { Step } = Steps;

function CreateAudience({ history, match }) {
  const [showRecommendation, setShowRecommendation] = useState(false);

  const id = match.params.clientId;
  const audienceId = match.params.audienceId;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const audienceConfigId = queryParams.get('audience-config-id');
  const projectId = queryParams.get('audience-project-id');
  const projectName = queryParams.get('audience-project-name');

  const isEdit = !!audienceId;
  const path = location.pathname;

  const [currentStep, setCurrentStep] = useState(isEdit ? 2 : 0);

  const {
    selectedAudienceData,
    setSelectedAudienceData,
    createAudiences,
    createAudienceLoading,
    audienceSize,
    baseSize,
    audienceSizeLoading,
    getAudienceSize,
    audienceName,
    audienceDescription,
    setAudienceName,
    setAudienceDescription,
    selectedAudienceType,
    setSelectedAudienceType,
    audienceLoading,
    behaviourProbabilityData,
    setBehaviourSegmentCount,
    errors,
    setErrors,
    behaviourDirectory,
    audienceRole,
    audienceProjectList,
    audienceProjectName,
    audienceMetaData,
    businessModel,
    setBusinessModel,
    clusterId,
    isProjectModalVisible,
    setIsProjectModalVisible,
    handleAddProject,
    handleProjectChange,
    setNewProjectName,
    newProjectName,
    isReview,
    fetchAudienceBehaviorProbability,
    audienceProfileId,
    audienceState,
    validationError,
    setValidationError,
    handleUseExistingProject,
    validationLoader,
    validateAudienceName,
  } = useCreateAudienceHook(
    id,
    history,
    audienceId,
    audienceConfigId,
    isEdit,
    setCurrentStep,
    currentStep,
    projectId,
    projectName
  );
  const { t } = useTranslation();
  let pageTitle = t('audience.create_audience');
  if (path.includes('review-audience')) {
    pageTitle = 'Review Audience';
  } else if (path.includes('edit-audience')) {
    pageTitle = t('audience.edit_audience');
  }

  const handleNext = () => setCurrentStep(currentStep + 1);
  const handleBack = () => setCurrentStep(currentStep - 1);
  const handleSubmit = (profiling) =>
    createAudiences(audienceName, audienceDescription, selectedAudienceData, profiling);

  useEffect(() => {
    if (selectedAudienceType.length > 0) {
      setSelectedAudienceData({
        ...selectedAudienceData,
        audienceType: selectedAudienceType,
        rules: selectedAudienceType === RULE_BASED ? selectedAudienceData.rules : {},
        selectedClusters: selectedAudienceType === CLUSTER_BASED ? selectedAudienceData.selectedClusters : {},
      });
    }
  }, [selectedAudienceType]);

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <StepZero
            showRecommendation={showRecommendation}
            setShowRecommendation={setShowRecommendation}
            setSelectedAudienceType={setSelectedAudienceType}
            selectedAudienceType={selectedAudienceType}
            match={match}
            setAudienceName={setAudienceName}
            setAudienceDescription={setAudienceDescription}
            setCurrentStep={setCurrentStep}
            audienceRole={audienceRole}
            t={t}
            isReview={isReview}
          />
        );
      case 1:
        return (
          <StepOne
            selectedAudienceType={selectedAudienceType}
            audienceName={audienceName}
            setAudienceName={setAudienceName}
            audienceDescription={audienceDescription}
            setAudienceDescription={setAudienceDescription}
            history={history}
            match={match}
            handleBack={handleBack}
            audienceRole={audienceRole}
            audienceProjectList={audienceProjectList}
            audienceProjectName={audienceProjectName}
            setNewProjectName={setNewProjectName}
            audienceMetaData={audienceMetaData}
            businessModel={businessModel}
            setBusinessModel={setBusinessModel}
            behaviourDirectory={behaviourDirectory}
            clusterId={clusterId}
            setCurrentStep={setCurrentStep}
            isProjectModalVisible={isProjectModalVisible}
            setIsProjectModalVisible={setIsProjectModalVisible}
            handleAddProject={handleAddProject}
            handleProjectChange={handleProjectChange}
            newProjectName={newProjectName}
            isReview={isReview}
            validationError={validationError}
            setValidationError={setValidationError}
            handleUseExistingProject={handleUseExistingProject}
            validationLoader={validationLoader}
          />
        );
      case 2:
        return (
          <StepTwo
            selectedAudienceType={selectedAudienceType}
            id={id}
            audienceName={audienceName}
            audienceDescription={audienceDescription}
            selectedAudienceData={selectedAudienceData}
            setSelectedAudienceData={setSelectedAudienceData}
            audienceSize={audienceSize}
            baseSize={baseSize}
            audienceSizeLoading={audienceSizeLoading}
            getAudienceSize={getAudienceSize}
            errors={errors}
            setErrors={setErrors}
            behaviourDirectory={behaviourDirectory}
            currentStep={currentStep}
            createAudienceLoading={createAudienceLoading}
            handleNext={handleNext}
            handleBack={handleBack}
            handleSubmit={(val) => handleSubmit(val)}
            audienceRole={audienceRole}
            match={match}
            clusterId={clusterId}
            setCurrentStep={setCurrentStep}
            history={history}
            isReview={isReview}
            fetchAudienceBehaviorProbability={fetchAudienceBehaviorProbability}
            audienceProfileId={audienceProfileId}
          />
        );
      case 3:
        return (
          <StepThree
            behaviourProbabilityData={behaviourProbabilityData}
            setBehaviourSegmentCount={setBehaviourSegmentCount}
            audienceRole={audienceRole}
            selectedAudienceType={selectedAudienceType}
            clusterId={clusterId}
            isReview={isReview}
          />
        );
      default:
        return null;
    }
  };

  const isNextDisabled =
    currentStep === 1 && !(audienceName && businessModel && audienceDescription && audienceProjectName);

  const renderButtons = () => (
    <>
      {currentStep > 0 && selectedAudienceType !== CLUSTER_BASED && currentStep !== 2 && (
        <Button
          className="small button-margin-style"
          type="default"
          onClick={handleBack}
          style={{ marginRight: '10px' }}
          size="small"
          disabled={createAudienceLoading}
        >
          {t('back')}
        </Button>
      )}
      {currentStep > 0 && selectedAudienceType === CLUSTER_BASED && currentStep === 3 && (
        <Button
          className="small button-margin-style"
          type="default"
          onClick={handleBack}
          style={{ marginRight: '10px' }}
          size="small"
          disabled={createAudienceLoading}
        >
          {t('back')}
        </Button>
      )}
      {(selectedAudienceType === RULE_BASED && currentStep < 2) ||
      (selectedAudienceType === CLUSTER_BASED && currentStep === 0) ? (
        <Button
          className="small button-margin-style"
          type="primary"
          onClick={() => {
            if (currentStep === 1 && selectedAudienceType === RULE_BASED) {
              if (validateAudienceName()) {
                handleNext();
              }
            } else {
              handleNext();
            }
          }}
          disabled={isNextDisabled}
          size="small"
        >
          {t('next')}
        </Button>
      ) : (
        selectedAudienceType !== CLUSTER_BASED &&
        currentStep !== 2 && (
          <>
            <Button
              className="small button-margin-style"
              type="primary"
              onClick={() => handleSubmit(false)}
              size="small"
              loading={createAudienceLoading}
              disabled={createAudienceLoading || audienceRole === VIEWER || isReview}
            >
              {currentStep === 2 ? 'Save and Continue' : 'Save as Draft'}
            </Button>
            {currentStep === 3 && (
              <Button
                className="small button-margin-style"
                type="default"
                onClick={() => handleSubmit(true)}
                size="small"
                loading={createAudienceLoading}
                disabled={createAudienceLoading || audienceRole === VIEWER || isReview}
                style={{ marginLeft: '10px' }}
              >
                {t('audience.save_and_profile')}
              </Button>
            )}
          </>
        )
      )}
    </>
  );

  if (audienceLoading) {
    return <LoadingSpinner centered />;
  }

  return (
    <div className="createAudienceContainer">
      <NcBreadCrumb className="breadCrumb" />
      <Title level={2} className="title">
        {pageTitle}
      </Title>
      <Steps current={currentStep} direction="horizontal" className="steps">
        <Step
          title={
            <span className={currentStep >= 0 ? 'step-title-active' : 'step-title'}>{t('audience.select_method')}</span>
          }
          icon={<ToolOutlined className={currentStep >= 0 ? 'step-icon-active' : 'step-icon'} />}
          onClick={() => setCurrentStep(0)}
          style={{ cursor: 'pointer' }}
        />
        <Step
          title={
            <span className={currentStep >= 1 ? 'step-title-active' : 'step-title'}>
              {t('audience.write_project_brief')}
            </span>
          }
          icon={<InfoCircleOutlined className={currentStep >= 1 ? 'step-icon-active' : 'step-icon'} />}
          onClick={() => setCurrentStep(1)}
          style={{ cursor: 'pointer' }}
        />
        <Step
          title={
            <span className={currentStep >= 2 ? 'step-title-active' : 'step-title'}>
              {selectedAudienceType === RULE_BASED
                ? t('audience.review_select_features')
                : t('audience.confirm_features')}
            </span>
          }
          icon={<ProfileOutlined className={currentStep >= 2 ? 'step-icon-active' : 'step-icon'} />}
          onClick={() => {
            if (
              selectedAudienceType === CLUSTER_BASED &&
              ['RELEVANT_FEATURES_FOUND', 'CREATING_AUDIENCES', 'AUDIENCES_CREATED'].includes(audienceState)
            ) {
              setCurrentStep(2);
            } else if (
              selectedAudienceType === RULE_BASED &&
              (isReview || audienceRole === VIEWER || audienceProfileId)
            ) {
              setCurrentStep(2);
            }
          }}
          style={{ cursor: 'pointer' }}
        />
        <Step
          title={
            <span className={currentStep >= 3 ? 'step-title-active' : 'step-title'}>
              {selectedAudienceType === RULE_BASED ? t('audience.adjust_precision') : t('audience.audiences_created')}
            </span>
          }
          icon={<ProfileOutlined className={currentStep >= 3 ? 'step-icon-active' : 'step-icon'} />}
          onClick={() => {
            if (selectedAudienceType === RULE_BASED && (isReview || audienceRole === VIEWER || audienceProfileId)) {
              fetchAudienceBehaviorProbability(audienceProfileId);
            } else if (selectedAudienceType === CLUSTER_BASED && audienceState == 'AUDIENCES_CREATED') {
              setCurrentStep(3);
            }
          }}
          style={{ cursor: 'pointer' }}
        />
      </Steps>
      <div
        style={{
          width: '100%',
          minHeight: '650px',
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className="button-container">{renderButtons()}</div>
        <div>{renderStepContent()}</div>
      </div>
    </div>
  );
}

const StepZero = ({
  showRecommendation,
  setShowRecommendation,
  setSelectedAudienceType,
  selectedAudienceType,
  match,
  setAudienceName,
  setAudienceDescription,
  setCurrentStep,
  audienceRole,
  t,
  isReview,
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '20px',
    }}
  >
    {!showRecommendation && (
      <>
        <h2 style={{ color: '#fff', textAlign: 'center', marginBottom: '20px', fontWeight: 'bold' }}>
          {t('audience.create_audience_question1')}
        </h2>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
          <Card
            className={`card ${selectedAudienceType === RULE_BASED ? 'card-primary' : 'card-gray'} card-hover`}
            onClick={() => audienceRole !== VIEWER && !isReview && setSelectedAudienceType(RULE_BASED)}
          >
            <Radio
              checked={selectedAudienceType === RULE_BASED}
              onChange={() => setSelectedAudienceType(RULE_BASED)}
              style={{ position: 'absolute', top: '10px', left: '10px' }}
              disabled={audienceRole === VIEWER || isReview}
            />
            <Card.Meta
              title={<span className="card-meta-title">{t('audience.rule_based')}</span>}
              description={
                <span className="card-meta-description card-meta-description-digital">
                  {t('audience.create_an_audience_based_on_rules')}{' '}
                  <CardTooltip title={t('audience.create_audience_based_on_rules_content')}>
                    <InfoCircleOutlined />
                  </CardTooltip>
                </span>
              }
            />
          </Card>
          <Card
            className={`card ${selectedAudienceType === CLUSTER_BASED ? 'card-primary' : 'card-gray'} card-hover`}
            onClick={() => audienceRole !== VIEWER && !isReview && setSelectedAudienceType(CLUSTER_BASED)}
          >
            <Radio
              checked={selectedAudienceType === CLUSTER_BASED}
              onChange={() => setSelectedAudienceType(CLUSTER_BASED)}
              style={{ position: 'absolute', top: '10px', left: '10px' }}
              disabled={audienceRole === VIEWER || isReview}
            />
            <Card.Meta
              title={<span className="card-meta-title">{t('audience.cluster')}</span>}
              description={
                <span className="card-meta-description card-meta-description-digital">
                  {t('audience.create_cluster_based_audiences')}.{' '}
                  <CardTooltip title={t('audience.create_cluster_based_audiences_content')}>
                    <InfoCircleOutlined />
                  </CardTooltip>
                </span>
              }
            />
          </Card>
          <Card className="card card-disabled">
            <Radio
              checked={selectedAudienceType === PROPENSITY_TIERS}
              onChange={() => setSelectedAudienceType(PROPENSITY_TIERS)}
              style={{ position: 'absolute', top: '10px', left: '10px' }}
              disabled
            />
            <Card.Meta
              title={<span className="card-meta-title">{t('audience.propensity_tiers')}</span>}
              description={
                <span className="card-meta-description card-meta-description-digital">
                  {t('audience.coming_soon')}{' '}
                  <CardTooltip title={t('audience.propensity_tiers_content')}>
                    <InfoCircleOutlined />
                  </CardTooltip>
                </span>
              }
            />
          </Card>
        </div>
      </>
    )}
    {showRecommendation && (
      <RecommendedAudiencesComponent
        match={match}
        setShowRecommendation={setShowRecommendation}
        setAudienceName={setAudienceName}
        setAudienceDescription={setAudienceDescription}
        setCurrentStep={setCurrentStep}
        setSelectedAudienceType={setSelectedAudienceType}
      />
    )}
  </div>
);

const StepOne = ({
  selectedAudienceType,
  audienceName,
  setAudienceName,
  audienceDescription,
  setAudienceDescription,
  history,
  match,
  handleBack,
  audienceRole,
  audienceProjectList,
  audienceProjectName,
  audienceMetaData,
  businessModel,
  setBusinessModel,
  behaviourDirectory,
  clusterId,
  setCurrentStep,
  isProjectModalVisible,
  setIsProjectModalVisible,
  handleAddProject,
  handleProjectChange,
  setNewProjectName,
  newProjectName,
  isReview,
  validationError,
  setValidationError,
  handleUseExistingProject,
  validationLoader,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {selectedAudienceType === CLUSTER_BASED && (
        <div className="formItem" style={{ marginTop: '10px' }}>
          <CreateCluster
            history={history}
            match={match}
            fromAudience={true}
            onBackFromAudience={() => handleBack()}
            behaviourDirectory={behaviourDirectory}
            typeConfigId={clusterId}
            setCurrentStep={setCurrentStep}
          />
        </div>
      )}
      {selectedAudienceType === RULE_BASED && (
        <>
          <div className="formItem">
            <label>{t('audience.project_name')}</label>
            <p className="helper-text">{t('audience.select_an_existing_project_name_or_type_a_new_one')}</p>
            <div style={{ display: 'flex', gap: '10px' }}>
              <Select
                showSearch
                placeholder={t('audience.select_project_name')}
                value={audienceProjectName || undefined} // Ensure placeholder is shown when value is empty
                onChange={handleProjectChange}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                disabled={audienceRole === VIEWER || isReview}
                style={{ width: '100%' }}
              >
                {audienceProjectList.map((project) => (
                  <Select.Option key={project.id} value={project.name}>
                    {project.name}
                  </Select.Option>
                ))}
              </Select>
              <Button
                type="primary"
                onClick={() => setIsProjectModalVisible(true)}
                disabled={audienceRole === VIEWER || isReview}
              >
                <PlusOutlined />
                {t('audience.add_project')}
              </Button>
            </div>
          </div>
          <div className="formItem">
            <label>{t('audience.audience_name')}</label>
            <Input
              placeholder={t('audience.enter_name_of_the_audience')}
              className="input"
              value={audienceName}
              onChange={(e) => setAudienceName(e.target.value)}
              disabled={audienceRole === VIEWER || isReview || audienceProjectName === ''}
            />
          </div>
          <div className="formItem">
            <label>{t('audience.business_model')}</label>
            <Select
              showSearch
              placeholder={t('audience.select_business_model')}
              value={businessModel || undefined} // Ensure placeholder is shown when value is empty
              onChange={(value) => setBusinessModel(value)}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              disabled={audienceRole === VIEWER || isReview}
              style={{ width: '100%' }}
            >
              {audienceMetaData['business_model'].map((model) => (
                <Select.Option key={model} value={model}>
                  {model}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="formItem">
            <label>{t('audience.describe_audience')}</label>
            <Input.TextArea
              placeholder={t('audience.describe_the_audience')}
              className="input"
              value={audienceDescription}
              rows={4}
              onChange={(e) => setAudienceDescription(e.target.value)}
              disabled={audienceRole === VIEWER || isReview}
            />
          </div>
        </>
      )}
      <Modal
        title={t('audience.add_project')}
        visible={isProjectModalVisible}
        onCancel={() => {
          setIsProjectModalVisible(false);
          setNewProjectName('');
          setValidationError(false);
        }}
        footer={null} // Remove default footer buttons
        style={{ borderRadius: '8px', padding: '20px' }} // Add custom styling
      >
        <Input
          placeholder={t('audience.enter_project_name')}
          value={newProjectName}
          onChange={(e) => {
            setNewProjectName(e.target.value);
            // Reset validation error when user starts typing
            setValidationError(false);
          }}
          style={{ borderRadius: '4px', marginBottom: '10px' }} // Add custom styling
          disabled={validationLoader}
        />
        {validationError ? (
          <div
            key="error-section"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              borderRadius: '4px',
              marginBottom: '10px',
            }}
          >
            <div
              style={{
                color: 'var(--color-tag-red-txt)',
                textAlign: 'start',
                marginBottom: '10px',
              }}
            >
              {t('audience.project_exists_message')}
            </div>
            <Button
              key="use-existing"
              type="primary"
              block
              onClick={() => {
                handleUseExistingProject();
              }}
              style={{ borderRadius: '4px' }} // Add custom styling
            >
              {t('audience.use_existing_audience')}
            </Button>
          </div>
        ) : (
          <Button
            key="ok"
            type="primary"
            onClick={handleAddProject}
            style={{ width: '100%', borderRadius: '4px' }} // Add custom styling
            loading={validationLoader}
            disabled={validationLoader || newProjectName === ''}
          >
            {t('add')}
          </Button>
        )}
      </Modal>
    </>
  );
};

const StepTwo = ({
  selectedAudienceType,
  id,
  audienceName,
  audienceDescription,
  selectedAudienceData,
  setSelectedAudienceData,
  audienceSize,
  baseSize,
  audienceSizeLoading,
  getAudienceSize,
  errors,
  setErrors,
  behaviourDirectory,
  currentStep,
  createAudienceLoading,
  handleNext,
  handleBack,
  handleSubmit,
  audienceRole,
  match,
  history,
  clusterId,
  setCurrentStep,
  isReview,
  fetchAudienceBehaviorProbability,
  audienceProfileId,
}) => (
  <div className="formItem">
    {selectedAudienceType === RULE_BASED && (
      <DataSourceTabLayout
        clientId={id}
        audienceName={audienceName}
        audienceDescription={audienceDescription}
        selectedAudienceData={selectedAudienceData}
        setSelectedAudienceData={setSelectedAudienceData}
        audienceSize={audienceSize}
        baseSize={baseSize}
        audienceSizeLoading={audienceSizeLoading}
        getAudienceSize={getAudienceSize}
        errors={errors}
        setErrors={setErrors}
        behaviourDirectory={behaviourDirectory}
        fromAudience={true}
        currentStep={currentStep}
        selectedAudienceType={selectedAudienceType}
        createAudienceLoading={createAudienceLoading}
        onHandleBackFromDataSoruceTable={handleBack}
        onHandleNextFromDataSoruceTable={handleNext}
        handleSubmitFromDataSourceTable={(val) => handleSubmit(val)}
        audienceRole={audienceRole}
        isReview={isReview}
        fetchAudienceBehaviorProbability={fetchAudienceBehaviorProbability}
        audienceProfileId={audienceProfileId}
      />
    )}
    {selectedAudienceType === CLUSTER_BASED && (
      <ClusterFeature history={history} match={match} typeConfigId={clusterId} setCurrentStep={setCurrentStep} />
    )}
  </div>
);

const StepThree = ({
  behaviourProbabilityData,
  setBehaviourSegmentCount,
  audienceRole,
  selectedAudienceType,
  clusterId,
  isReview,
}) => (
  <div className="formItem">
    {selectedAudienceType === RULE_BASED && (
      <BehaviourProbabilityComponent
        behaviourProbabilityData={behaviourProbabilityData}
        setBehaviourSegmentCount={setBehaviourSegmentCount}
        audienceRole={audienceRole}
        isReview={isReview}
      />
    )}
    {selectedAudienceType === CLUSTER_BASED && <ShowCluster typeConfigId={clusterId} />}
  </div>
);
export default CreateAudience;
