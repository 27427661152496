import { Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'src/components/loadingSpinner';
import './kpiBenchmarksComponent.style.less';
import KpiConfigType from './kpiConfigType/KpiConfigType';
import useKpiBenchmarksHook from './useKpiBenchmarksHook';

const KpiBenchmarksComponent = ({ clientId }) => {
  const { kpiConfigData, kpiDetails, dataLoading, getKPIDetails } = useKpiBenchmarksHook({ clientId });
  const { t } = useTranslation();

  return (
    <div className="kpi-benchmarks-component">
      <h4 style={{ margin: '0px 50px 10px 50px', display: 'block', textAlign: 'center' }}>
        {t('client_space.kpi_benchmarks_description1')} {t('client_space.kpi_benchmarks_description2')}
      </h4>

      {dataLoading ? (
        <LoadingSpinner centered />
      ) : (
        <div style={{ marginLeft: '0.7rem' }}>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab={t('client_space.standard_kpi')} key="1">
              <KpiConfigType
                name="standard_kpi"
                action={true}
                addMore={false}
                kpiConfigData={kpiConfigData['standard_kpi']}
                kpiDetails={kpiDetails}
                getKPIDetails={getKPIDetails}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('client_space.client_specific_kpi')} key="2">
              <KpiConfigType
                name="client_specific_kpi"
                action={false}
                addMore={false}
                kpiConfigData={kpiConfigData['client_specific_kpi']}
                kpiDetails={kpiDetails}
                getKPIDetails={getKPIDetails}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('client_space.non_standard_kpi')} key="3">
              <KpiConfigType
                name="custom_kpi"
                action={true}
                addMore={true}
                kpiConfigData={kpiConfigData['custom_kpi']}
                kpiDetails={kpiDetails}
                getKPIDetails={getKPIDetails}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default KpiBenchmarksComponent;
