import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { ClusterToolApiServices } from '../../services/ClusterToolApiServices';

export const useShowCluster = (typeConfigId) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [clusterData, setClusterData] = useState({});
  const [cloneModal, setCloneModal] = useState(false);
  const { clusterId: paramClusterId, clientId } = useParams();
  const clusterId = paramClusterId || typeConfigId;

  const getClusters = async (id) => {
    try {
      const res = await ClusterToolApiServices.getClusterConfig(id);
      setClusterData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    if (clusterId) {
      getClusters(clusterId);
    }
  }, [clusterId]);

  return {
    loading,
    clusterData,
    history,
    clientId,
    cloneModal,
    setCloneModal,
  };
};
