import { useCallback, useEffect, useState } from 'react';
import { BEHAVIOURAL, DEMOGRAPHIC } from 'src/modules/audienceTool/audienceManager/constants/audienceDetailsConstants';
import {
  ACQUISITION_CRM_LOOKALIKES,
  E1,
  EXCLUDED_DEMOGRAPHIC_FEATURES,
  EXCLUDED_E1_FEATURES,
  FIRST_PARTY,
  TRANSUNION,
} from '../../../constants/constants';

const useBrowseFeaturesHook = ({
  fields,
  behaviourDirectory,
  browsedSelectedItems,
  clusterConfig,
  clusterType,
  rule,
  targetRule,
  setTargetRule,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [folderStructure, setFolderStructure] = useState([]);
  const [selectedItems, setSelectedItems] = useState(browsedSelectedItems);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedField, setSelectedField] = useState(null);
  const [editingItem, setEditingItem] = useState(null);

  const handleCheckboxChange = useCallback((checked, node) => {
    if (checked) {
      if (rule === 'cluster_target_rules') {
        if (targetRule && targetRule !== null && node.feature_name !== targetRule.field) {
          {
            setTargetRule(null);
          }
        }
        if (clusterType !== ACQUISITION_CRM_LOOKALIKES) {
          setSelectedItems((prev) => [...prev, node]);
        }
        if (node.valueEditorType !== undefined && clusterType === ACQUISITION_CRM_LOOKALIKES) {
          setEditingItem(null);
          setSelectedField(node);
          setModalVisible(true);
        }
      } else {
        setSelectedItems((prev) => [...prev, node]);
      }
    } else {
      if (rule === 'cluster_target_rules') {
        setTargetRule(null);
      }
      setSelectedItems((prev) => prev.filter((item) => item.feature_name !== node.feature_name));
    }
  }, []);

  const handleRemoveItem = useCallback((item) => {
    if (rule === 'cluster_target_rules') {
      setTargetRule(null);
    }
    setSelectedItems((prev) => prev.filter((i) => i.feature_name !== item.feature_name));
  }, []);

  const handleSearchChange = (e) => setSearchQuery(e.target.value);

  const handleEditItem = useCallback(
    (item) => {
      const findField = (nodes) => {
        for (const node of nodes) {
          if (node.feature_name === item.feature_name) return node;
          if (node.children) {
            const found = findField(node.children);
            if (found) return found;
          }
        }
        return null;
      };
      const field = findField(folderStructure);
      if (field) {
        setSelectedField(field);
        setEditingItem(item);
        setModalVisible(true);
      }
    },
    [folderStructure]
  );

  const updateSelectedItem = useCallback(
    (field, operator, value) => {
      setSelectedItems((prev) => {
        const existingIndex = prev.findIndex((item) => item.feature_name === field.feature_name);
        const newItem = { ...field, operator, value };
        if (existingIndex !== -1) {
          const updated = [...prev];
          updated[existingIndex] = newItem;
          return updated;
        }
        return [...prev, newItem];
      });

      if (rule === 'cluster_target_rules') {
        setTargetRule(
          Array.isArray(value)
            ? value.length > 0
              ? { field: field.feature_name, operator, value, rule_type: field.source.toLowerCase() }
              : null
            : value.value
              ? { field: field.feature_name, operator, value, rule_type: field.source.toLowerCase() }
              : null
        );
      }

      setModalVisible(false);
      setEditingItem(null);
    },
    [rule, setTargetRule]
  );

  const closeModal = () => {
    setModalVisible(false);
    setEditingItem(null);
  };

  const filterNodes = (nodes, query) => {
    return nodes
      .map((node) => {
        if (node.children) {
          const filteredChildren = filterNodes(node.children, query);
          if (filteredChildren.length > 0 || node.name.toLowerCase().includes(query.toLowerCase())) {
            return { ...node, children: filteredChildren };
          }
        } else if (node.name.toLowerCase().includes(query.toLowerCase())) {
          return node;
        }
        return null;
      })
      .filter((node) => node !== null);
  };

  const convertToFolderStructure = useCallback(() => {
    const demographicAttributes = {
      name: 'Demographic Attributes',
      children: fields.demographic_features.reduce((acc, feature) => {
        if (EXCLUDED_DEMOGRAPHIC_FEATURES.includes(feature.name)) return acc;

        const categoryNode = acc.find((node) => node.name === (feature.featureCategory || 'Uncategorized')) || {
          name: feature.featureCategory || 'Uncategorized',
          children: [],
        };

        if (!acc.includes(categoryNode)) acc.push(categoryNode);

        let targetNode = categoryNode;

        [feature.secondLevelCategory, feature.thirdLevelCategory].forEach((level) => {
          if (level) {
            let levelNode = targetNode.children.find((node) => node.name === level);
            if (!levelNode) {
              levelNode = { name: level, children: [] };
              targetNode.children.push(levelNode);
            }
            targetNode = levelNode;
          }
        });

        targetNode.children.push({
          ...feature,
          name: feature.label || feature.name,
          source: TRANSUNION,
          category: DEMOGRAPHIC,
          feature_name: feature.name,
        });

        return acc;
      }, []),
    };

    const firstPartyBehaviourAttributes = {
      name: '1P Behaviour Attribute',
      children: fields.first_party_features.reduce((acc, feature) => {
        const categoryNode = acc.find((node) => node.name === (feature.featureCategory || 'Uncategorized')) || {
          name: feature.featureCategory || 'Uncategorized',
          children: [],
        };

        if (!acc.includes(categoryNode)) acc.push(categoryNode);

        let targetNode = categoryNode;

        [feature.secondLevelCategory, feature.thirdLevelCategory].forEach((level) => {
          if (level) {
            let levelNode = targetNode.children.find((node) => node.name === level);
            if (!levelNode) {
              levelNode = { name: level, children: [] };
              targetNode.children.push(levelNode);
            }
            targetNode = levelNode;
          }
        });

        targetNode.children.push({
          ...feature,
          name: feature.label || feature.name,
          source: feature.category || FIRST_PARTY,
          category: BEHAVIOURAL,
          feature_name: feature.name,
        });

        return acc;
      }, []),
    };

    const thirdPartyBehaviours = {
      name: '3P Behaviours and Attitudes',
      children: Object.keys(behaviourDirectory).map((category) => {
        const iterateChildren = (node) => {
          return Object.keys(node || {})
            .map((subCategory) => {
              const excludedCategories = EXCLUDED_E1_FEATURES;
              if (excludedCategories.some((excluded) => subCategory.toLowerCase().includes(excluded))) {
                return null;
              }
              return {
                name: subCategory,
                source: E1,
                category: BEHAVIOURAL,
                feature_name: subCategory,
                children: iterateChildren(node[subCategory]),
              };
            })
            .filter((child) => child !== null);
        };

        return {
          name: category,
          source: E1,
          category: BEHAVIOURAL,
          feature_name: category,
          children: iterateChildren(behaviourDirectory[category]),
        };
      }),
    };

    let structure = [];
    const rules = clusterConfig[rule][clusterType];

    if (rules.DEMOGRAPHIC.TRANSUNION) structure.push(demographicAttributes);
    if (rules.BEHAVIOURAL.E1) structure.push(thirdPartyBehaviours);
    if (rules.BEHAVIOURAL.FIRST_PARTY) structure.push(firstPartyBehaviourAttributes);

    if (searchQuery) structure = filterNodes(structure, searchQuery);

    setFolderStructure(structure);
  }, [fields, behaviourDirectory, searchQuery, clusterConfig, rule, clusterType]);

  useEffect(() => {
    convertToFolderStructure();
  }, [convertToFolderStructure]);

  useEffect(() => {
    setSelectedItems(browsedSelectedItems);
  }, [browsedSelectedItems]);

  return {
    searchQuery,
    folderStructure,
    selectedItems,
    modalVisible,
    selectedField,
    editingItem,
    handleSearchChange,
    handleCheckboxChange,
    handleRemoveItem,
    handleEditItem,
    updateSelectedItem,
    closeModal,
  };
};

export default useBrowseFeaturesHook;
