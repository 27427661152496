import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import mediaPlanService from '../../../services/mediaPlanService';

const useMediaplanCreateLayoutHook = () => {
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const [metaData, setMetaData] = useState({});
  const { clientId, mediaPlanId, scenarioId } = useParams();
  const [mediaPlanData, setMediaPlanData] = useState(null);
  const [mediaplanCreated, setMediaPlanCreated] = useState(false);
  const [editScenario, setEditScenario] = useState(false);

  const location = useLocation();
  let reviewScreen = location.pathname.includes('review-scenario');
  let editScreen = location.pathname.includes('edit-scenario');
  let createScenario = location.pathname.includes('create-scenario');
  let createMediaPlan = location.pathname.includes('create-media-plan');

  const getTitle = () => {
    if (reviewScreen) {
      return 'Review Scenario';
    } else if (editScreen) {
      return 'Edit Scenario';
    } else if (createScenario) {
      return 'Create Scenario';
    } else if (createMediaPlan) {
      return 'Create Media Plan';
    }
  };

  const getMetaData = async () => {
    try {
      const res = await mediaPlanService.getMetaData(clientId);
      setMetaData(res?.data);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };
  const getMediaPlan = async () => {
    try {
      let res;
      if (scenarioId) {
        res = await mediaPlanService.getMediaPlanBasicInfo(scenarioId);
        setEditScenario(true);
        if (!reviewScreen) {
          setStep(1);
        }
      } else if (mediaPlanId) {
        res = await mediaPlanService.getMediaPlanDetails(mediaPlanId);
        setMediaPlanCreated(true);
      }
      setMediaPlanData(res?.data);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };

  const fetchData = async () => {
    setLoading(true);
    await getMetaData();
    await getMediaPlan();
    if (reviewScreen) {
      setStep(3);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    loading,
    step,
    metaData,
    mediaPlanData,
    setStep,
    setMediaPlanData,
    mediaplanCreated,
    editScenario,
    getTitle,
    reviewScreen,
  };
};

export default useMediaplanCreateLayoutHook;
