import { Col, Row, Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'src/components/basic';
import LoadingSpinner from 'src/components/loadingSpinner';
import AudienceConstraint from '../audienceConstraint/AudienceConstraint';
import ChannelConstraint from '../channelConstraint/ChannelConstraint';
import './scenarioConstraint.less';
import useScenarioConstraint from './useScenarioConstraint';

const ScenarioConstraint = ({ metaData, mediaPlanData, setStep, setMediaPlanData, reviewScreen }) => {
  const { t } = useTranslation();
  const {
    tab,
    setTab,
    channelConstraint,
    audienceConstraint,
    loading,
    onChangeChannelConstraint,
    onChangeAudienceConstraint,
    triggerResponse,
    configureLoading,
  } = useScenarioConstraint({
    mediaPlanData,
    metaData,
    setStep,
    setMediaPlanData,
  });
  if (loading) {
    return <LoadingSpinner centered />;
  }
  return (
    <div className="scenarioConstraintContainer">
      <Row gutter={[20, 20]} justify="end">
        <Col>
          <SecondaryButton onClick={() => setStep(0)} className="small">
            {t('back')}
          </SecondaryButton>
        </Col>
        <Col>
          <PrimaryButton
            onClick={() => {
              if (reviewScreen) {
                setStep(2);
              } else {
                triggerResponse();
              }
            }}
            className="small"
            loading={configureLoading}
          >
            {t('next')}
          </PrimaryButton>
        </Col>
      </Row>
      <div className="scenarioConstraintHeader">
        <p>{t('mediaplan.constraint_header')}</p>
      </div>
      <Tabs
        activeKey={tab}
        onChange={(val) => {
          setTab(val);
        }}
      >
        <Tabs.TabPane tab={t('channel')} key="channel">
          <ChannelConstraint
            mediaPlanData={mediaPlanData}
            channelConstraint={channelConstraint}
            onChangeChannelConstraint={onChangeChannelConstraint}
            reviewScreen={reviewScreen}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('mediaplan.audience')} key="audiences">
          <AudienceConstraint
            audienceConstraint={audienceConstraint}
            onChangeAudienceConstraint={onChangeAudienceConstraint}
            mediaPlanData={mediaPlanData}
            reviewScreen={reviewScreen}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default ScenarioConstraint;
