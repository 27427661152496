import React from 'react';
import { Form, Button, Switch, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import './analysisSettingsComponent.less';
import useAnalysisSettingsHooks from './analysisSettingsHooks';

const AnalysisSettingsComponent = ({
  analysisData = null,
  setAnalysisData = () => {},
  appConfig = null,
  setAppConfig = () => {},
  isClientSpace = false,
}) => {
  const { t } = useTranslation();
  const { form, updateDefaultFilters, initialValues, loading } = useAnalysisSettingsHooks({
    analysisData,
    setAnalysisData,
    appConfig,
    setAppConfig,
    isClientSpace,
  });

  return (
    <div className={'settings-tab-content'}>
      <Form
        className="settings-form"
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFinish={updateDefaultFilters}
      >
        <Form.Item label={t('news_and_culture.exclude_nodes')} name="exclude_nodes">
          <Select mode="tags" placeholder={t('news_and_culture.exclude_nodes_help_text')} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item label={t('news_and_culture.exclude_handles')} name="exclude_handles">
          <Select mode="tags" placeholder={t('news_and_culture.exclude_handles_help_text')} style={{ width: '100%' }} />
        </Form.Item>
        {!isClientSpace && (
          <Form.Item
            label={t('news_and_culture.apply_at_client_level')}
            name="apply_at_client_level"
            valuePropName="checked"
            layout="horizontal"
          >
            <Switch />
          </Form.Item>
        )}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('save')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AnalysisSettingsComponent;
