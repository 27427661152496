import { notification, Modal } from 'antd';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import CustomGptApiServices from 'src/modules/CustomGpt/services/customGptApiServices';

export default function useGithubTool({ id, configData, updateCustomApp, onClose, toolData, reload }) {
  const formRef = useRef();
  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(true);
  const [pemFile, setPemFile] = useState(null);
  const [isAnalyzingPem, setIsAnalyzingPem] = useState(false);
  const [apiPemFileName, setApiPemFileName] = useState(null);
  const [uploadedPemFileName, setUploadedPemFileName] = useState(null);
  const [configureLoading, setConfigureLoading] = useState(false);
  const { t } = useTranslation();

  const getInitialValues = () => {
    setLoading(true);
    try {
      if (toolData) {
        const apiFileName = toolData.fields_config?.github_app_private_key_file_name || null;
        setApiPemFileName(apiFileName);
        setInitialValues({
          ...toolData.fields_config,
          pemFileName: apiFileName,
        });
      } else {
        setInitialValues({
          name: configData?.config?.name?.default_value || '',
          description: configData?.config?.description?.default_value || '',
          agent_prompt: configData?.config?.agent_prompt?.default_value || '',
          pemFileName: null,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error initializing values',
        description: error.message || 'An unexpected error occurred.',
        placement: 'bottomRight',
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePemFileUpload = (file) => {
    try {
      if (!file) {
        throw new Error('No file provided.');
      }
      setPemFile(file);
      setUploadedPemFileName(file.name);
      setIsAnalyzingPem(true);

      setTimeout(() => {
        setIsAnalyzingPem(false);
      }, 1000);
    } catch (error) {
      notification.error({
        message: 'File Upload Error',
        description: error.message || 'An error occurred while uploading the file.',
        placement: 'bottomRight',
      });
    }
    return false;
  };

  const removePemFile = () => {
    Modal.confirm({
      title: t('client_space.remove_pem_file_confirmation_title'),
      content: t('client_space.remove_pem_file_confirmation_content'),
      onOk: () => {
        try {
          setPemFile(null);
          setUploadedPemFileName(null);

          setInitialValues((prevValues) => ({
            ...prevValues,
            pemFileName: apiPemFileName,
          }));

          formRef.current?.setFields([
            {
              name: 'pemFile',
              errors: [t('client_space.pem_required_error')],
            },
          ]);

          notification.info({
            message: t('client_space.removed_pem_file_confirmation_title'),
            description: t('client_space.removed_pem_file_confirmation_content'),
            placement: 'bottomRight',
          });
        } catch (error) {
          notification.error({
            message: 'Error removing file',
            description: error.message || 'An unexpected error occurred.',
            placement: 'bottomRight',
          });
        }
      },
    });
  };

  const validatePemFile = async () => {
    try {
      if (!pemFile && !initialValues.pemFileName) {
        throw new Error(t('client_space.pem_required_error'));
      }
    } catch (error) {
      return Promise.reject(error.message);
    }
    return Promise.resolve();
  };

  const handleDataUpload = async (data) => {
    try {
      setConfigureLoading(true);
      const formData = new FormData();
      if (pemFile) {
        formData.append('file', pemFile);
      }
      formData.append('capability_name', configData?.capability_name || '');

      const fieldsConfig = {
        agent_prompt: data?.agent_prompt,
        github_app_id: data?.github_app_id,
        github_repository: data?.github_repository,
        name: data?.name,
        description: data?.description,
      };

      if (!pemFile && initialValues.pemFileName) {
        fieldsConfig.github_app_private_key_file_name = initialValues.pemFileName;
      }

      formData.append('fields_config', JSON.stringify(fieldsConfig));

      const response = await CustomGptApiServices.configureCapabilities(id, formData);
      if (response?.success) {
        notification.success({
          message: 'Data uploaded successfully',
          duration: 6,
          placement: 'bottomRight',
        });

        setConfigureLoading(false);
        updateCustomApp(response.data);
        reload();
        onClose();
      } else {
        throw new Error('API response indicates failure.');
      }
    } catch (error) {
      setConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg || 'Failed to upload data',
        duration: 6,
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    getInitialValues();
  }, []);

  return {
    formRef,
    initialValues,
    loading,
    handlePemFileUpload,
    isAnalyzingPem,
    pemFile,
    removePemFile,
    validatePemFile,
    handleDataUpload,
    apiPemFileName,
    configureLoading,
    uploadedPemFileName,
  };
}
