/* eslint-disable */
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { Dropdown, Menu, notification } from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import GlobalTag from 'src/components/globalTag/GlobalTag';
import { NEW_CAMPAIGN_STATUS_BACKGROUND_COLOR } from 'src/components/globalTag/globalTagConstant';
import AppUrls from 'src/constants/appUrls';
import { formatString, getErrorMessageFromResponse, numberWithCommas, shortenStr } from 'src/lib/utils';
import mediaPlanService from '../../../services/mediaPlanService';

export default function useMediaPlanListing() {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [metaData, setMetaData] = useState({});
  const [cloneModal, setCloneModal] = useState({ visible: false, mediaplan_id: null, name: '', scenario_id: null });
  const [userPersmisionDrawer, setUserPermissionDrawer] = useState({ visible: false, data: null });
  const [filters, setFilters] = useState({
    statusFilter: null,
    kpiFilter: null,
    sortOrders: {},
  });
  const [mediaData, setMediaData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageNumber, setPageNUmber] = useState(1);
  const [totalPageData, setTotalPageData] = useState(0);
  const [userLists, setUserLists] = useState([]);
  const timeRef = useRef();
  const filterRef = useRef({
    statusFilter: null,
    kpiFilter: null,
    sortOrders: {},
    page: 1,
    searchTerm: '',
  });

  const history = useHistory();

  const { clientId } = useParams();

  const formatScenarios = (scenarios, startDate, endDate, parentArchived) => {
    return scenarios.map((scenario) => {
      return {
        ...scenario,
        name: scenario.scenario_name,
        status: scenario.state,
        audience: '-',
        primaryKpi: '-',
        budget: scenario.budget,
        start_date: '-',
        end_date: '-',
        role: scenario?.your_role,
        created_on: dayjs(scenario?.scenario_created_on).format('MMM D, YYYY'),
        updated_on: dayjs(scenario?.scenario_updated_on).format('MMM D, YYYY'),
        created_by: `${scenario?.scenario_created_by?.first_name} ${scenario?.scenario_created_by?.last_name}`,
        scenario_id: scenario.scenario_id,
        parentArchived: parentArchived,
      };
    });
  };

  const formatMediaData = (data) => {
    return data
      .map((item, id) => {
        const scenarios = formatScenarios(item.scenario, item?.start_date, item?.end_date, item.is_archived);
        return {
          key: (id + 1).toString(),
          id: item.mediaplan_id,
          name: item.mediaplan_name,
          status: '-',
          audience: item?.audience_mapping?.map((audience) => audience.audience_name).join(', '),
          primaryKpi: item?.json_data?.kpis,
          budget: '-',
          start_date: dayjs(item.start_date).format('MMM D, YYYY'),
          end_date: dayjs(item.end_date).format('MMM D, YYYY'),
          role: item?.your_role,
          created_on: dayjs(item?.mediaplan_created_on).format('MMM D, YYYY'),
          updated_on: dayjs(item?.mediaplan_updated_on).format('MMM D, YYYY'),
          created_by: `${item?.mediaplan_created_by?.first_name} ${item?.mediaplan_created_by?.last_name}`,
          scenarios: scenarios,
          can_edit: item.can_edit,
          is_archived: item.is_archived,
        };
      })
      .flat();
  };

  const getColumns = () => {
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 150,
        fixed: 'left',
        sorter: true,
        sortOrder: filters.sortOrders?.name,
        onHeaderCell: () => ({
          onClick: async () => {
            let sortOrder;
            if (filters.sortOrders?.name === 'ascend') {
              sortOrder = 'descend';
            } else if (filters.sortOrders?.name === 'descend') {
              sortOrder = null;
            } else {
              sortOrder = 'ascend';
            }
            handleSortChanges('name', sortOrder);
          },
        }),
        render: (name, record) => {
          const status = record?.status;
          return (
            <div
              style={{ display: 'flex', alignItems: 'center', cursor: status === 'CREATED' ? 'pointer' : 'inherit' }}
              onClick={() => {
                console.log(record, 'record');
                if (status === 'CREATED') {
                  history.push(AppUrls.REVIEW_SCENARIO_MEDIA_PLAN(clientId, record.scenario_id));
                }
              }}
            >
              {name}
            </div>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (state) => {
          if (state === '-') {
            return '-';
          } else {
            return <GlobalTag color={NEW_CAMPAIGN_STATUS_BACKGROUND_COLOR[state]}>{formatString(state)}</GlobalTag>;
          }
        },
      },
      {
        title: 'Audiences',
        dataIndex: 'audience',
        key: 'audience',
      },
      {
        title: 'Primary KPI',
        dataIndex: 'primaryKpi',
        key: 'primaryKpi',
        render: (primaryKpi) => {
          if (primaryKpi === '-') {
            return '-';
          } else {
            return shortenStr(primaryKpi, 25);
          }
        },
      },
      {
        title: 'Budget',
        dataIndex: 'budget',
        key: 'budget',
        render: (budget) => {
          if (budget === '-') {
            return '-';
          } else {
            return `$${numberWithCommas(budget)}`;
          }
        },
        sorter: true,
        sortOrder: filters.sortOrders?.budget,
        onHeaderCell: () => ({
          onClick: async () => {
            let sortOrder;
            if (filters.sortOrders?.budget === 'ascend') {
              sortOrder = 'descend';
            } else if (filters.sortOrders?.budget === 'descend') {
              sortOrder = null;
            } else {
              sortOrder = 'ascend';
            }
            handleSortChanges('budget', sortOrder);
          },
        }),
      },
      {
        title: 'Start Date',
        dataIndex: 'start_date',
        key: 'start_date',
        sorter: true,
        width: 120,
        sortOrder: filters.sortOrders?.start_date,
        onHeaderCell: () => ({
          onClick: async () => {
            let sortOrder;
            if (filters.sortOrders?.start_date === 'ascend') {
              sortOrder = 'descend';
            } else if (filters.sortOrders?.start_date === 'descend') {
              sortOrder = null;
            } else {
              sortOrder = 'ascend';
            }
            handleSortChanges('start_date', sortOrder);
          },
        }),
      },
      {
        title: 'End Date',
        dataIndex: 'end_date',
        key: 'end_date',
        width: 120,
        sorter: true,
        sortOrder: filters.sortOrders?.end_date,
        onHeaderCell: () => ({
          onClick: async () => {
            let sortOrder;
            if (filters.sortOrders?.end_date === 'ascend') {
              sortOrder = 'descend';
            } else if (filters.sortOrders?.end_date === 'descend') {
              sortOrder = null;
            } else {
              sortOrder = 'ascend';
            }
            handleSortChanges('end_date', sortOrder);
          },
        }),
      },
      {
        title: 'Created',
        dataIndex: 'created_on',
        key: 'created_on',
        sorter: true,
        width: 120,
        sortOrder: filters.sortOrders?.created_on,
        onHeaderCell: () => ({
          onClick: async () => {
            let sortOrder;
            if (filters.sortOrders?.created_on === 'ascend') {
              sortOrder = 'descend';
            } else if (filters.sortOrders?.created_on === 'descend') {
              sortOrder = null;
            } else {
              sortOrder = 'ascend';
            }
            handleSortChanges('created_on', sortOrder);
          },
        }),
      },
      {
        title: 'Last Updated',
        dataIndex: 'updated_on',
        key: 'updated_on',
        width: 120,
        sorter: true,
        sortOrder: filters.sortOrders?.updated_on,
        onHeaderCell: () => ({
          onClick: async () => {
            let sortOrder;
            if (filters.sortOrders?.updated_on === 'ascend') {
              sortOrder = 'descend';
            } else if (filters.sortOrders?.updated_on === 'descend') {
              sortOrder = null;
            } else {
              sortOrder = 'ascend';
            }
            handleSortChanges('updated_on', sortOrder);
          },
        }),
      },
      {
        title: 'Owner',
        dataIndex: 'created_by',
        key: 'created_by',
        sorter: true,
        sortOrder: filters.sortOrders?.created_by,
        onHeaderCell: () => ({
          onClick: async () => {
            let sortOrder;
            if (filters.sortOrders?.created_by === 'ascend') {
              sortOrder = 'descend';
            } else if (filters.sortOrders?.created_by === 'descend') {
              sortOrder = null;
            } else {
              sortOrder = 'ascend';
            }
            handleSortChanges('created_by', sortOrder);
          },
        }),
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        width: 100,
        render: (_, record) => {
          let actionMenu = record?.scenario_id ? scenarioLevelMenu(record) : mediaplanMenu(record);
          return (
            <Dropdown overlay={actionMenu} trigger={['click']}>
              <MenuUnfoldOutlined
                style={{ color: '#007bff', cursor: 'pointer' }}
                onClick={(e) => e.stopPropagation()}
              />
            </Dropdown>
          );
        },
        fixed: 'right',
      },
    ];
  };

  // Fetch media plans based on updated payload
  const getMediaPlan = async (page, filters, searchValue, autoRefresh = false) => {
    try {
      !autoRefresh ? setLoading(true) : setLoading(false);
      const payload = {
        client_space_id: clientId,
      };
      if (searchValue) {
        payload.search = [
          {
            column: 'name',
            value: searchValue,
          },
        ];
      }
      if (filters.statusFilter || filters.kpiFilter) {
        let filter = [];
        if (filters.statusFilter) {
          filter.push({
            column: 'state',
            value: [filters.statusFilter],
          });
        }
        if (filters.kpiFilter) {
          filter.push({
            column: 'kpi',
            value: [filters.kpiFilter],
          });
        }
        payload.filter = filter;
      }
      if (filters.sortOrders && Object.keys(filters.sortOrders).length > 0) {
        payload.sort = [
          {
            column: Object.keys(filters.sortOrders)[0],
            value: filters.sortOrders[Object.keys(filters.sortOrders)[0]] === 'ascend' ? 'ASC' : 'DESC',
          },
        ];
      }
      const res = await mediaPlanService.getMediaPlanList(page, payload);
      let converMediaData = formatMediaData(res?.data?.data);
      setTotalPageData(res?.data?.total);
      setMediaData(converMediaData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching media plans:', error);
    }
  };

  const getUserDetails = async () => {
    try {
      const res = await mediaPlanService.getUsersByClient(clientId);
      setUserLists(res?.data?.internal);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };

  const archiveMediaPlan = async (mediaplanId, state) => {
    try {
      await mediaPlanService.deleteMediaPlan(mediaplanId);
      getMediaPlan(page, filters, searchTerm);
      notification.success({
        message: `Media Plan ${!state ? 'Archived' : 'Unarchived'} Successfully.`,
        duration: 3,
        placement: 'bottomRight',
      });
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 3,
        placement: 'bottomRight',
      });
    }
  };

  const archiveScenario = async (scenarioId, state) => {
    try {
      await mediaPlanService.archiveScenario(scenarioId);
      getMediaPlan(page, filters, searchTerm);
      notification.success({
        message: `Scenario ${!state ? 'Archived' : 'Unarchived'} Successfully.`,
        duration: 3,
        placement: 'bottomRight',
      });
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 3,
        placement: 'bottomRight',
      });
    }
  };

  const mediaplanMenu = (record) => {
    const canClone = true;
    const canEdit = record?.role === 'OWNER' || record?.role === 'ADMIN';
    return (
      <Menu style={{ backgroundColor: '#333', color: '#fff' }}>
        {canEdit && (
          <>
            <Menu.Item
              key="create_scenario"
              className="mediaplanActionMenu"
              onClick={() => history.push(AppUrls.CREATE_SCENARIO_MEDIA_PLAN(clientId, record.id))}
            >
              Create Scenario
            </Menu.Item>
            <Menu.Item
              key="users_and_permissions"
              className="mediaplanActionMenu"
              onClick={() => setUserPermissionDrawer({ visible: true, data: record })}
            >
              Share
            </Menu.Item>
          </>
        )}
        <Menu.Item
          key="clone"
          className="mediaplanActionMenu"
          onClick={() => setCloneModal({ visible: true, mediaplan_id: record.id, name: record.name })}
        >
          Clone
        </Menu.Item>
        {canEdit && (
          <Menu.Item
            key="restore"
            className="mediaplanActionMenu"
            onClick={() => archiveMediaPlan(record.id, record?.is_archived)}
          >
            {record?.is_archived ? 'Unarchive' : 'Archive'}
          </Menu.Item>
        )}
      </Menu>
    );
  };

  const scenarioLevelMenu = (record) => {
    const canEdit = record?.role === 'OWNER' || record?.role === 'ADMIN';
    return (
      <Menu style={{ backgroundColor: '#333', color: '#fff' }}>
        {(record?.status === 'DRAFT' || record?.status === 'ADDING_CONSTRAINTS') && canEdit && (
          <Menu.Item
            key="review"
            className="mediaplanActionMenu"
            onClick={() => {
              history.push(AppUrls.EDIT_SCENARIO_MEDIA_PLAN(clientId, record.scenario_id));
            }}
          >
            Edit
          </Menu.Item>
        )}
        {record?.status === 'CREATED' && (
          <Menu.Item
            key="review"
            className="mediaplanActionMenu"
            onClick={() => {
              history.push(AppUrls.REVIEW_SCENARIO_MEDIA_PLAN(clientId, record.scenario_id));
            }}
          >
            Review
          </Menu.Item>
        )}
        {canEdit && (
          <>
            <Menu.Item
              key="edit"
              className="mediaplanActionMenu"
              onClick={() => setCloneModal({ visible: true, scenario_id: record.scenario_id, name: record.name })}
            >
              Clone
            </Menu.Item>
            {!record?.parentArchived && (
              <Menu.Item
                key="restore"
                className="mediaplanActionMenu"
                onClick={() => archiveScenario(record.scenario_id, record?.state === 'ARCHIVED')}
              >
                {record?.state === 'ARCHIVED' ? 'Unarchive' : 'Archive'}
              </Menu.Item>
            )}
          </>
        )}
      </Menu>
    );
  };

  const closeCloneModal = async () => {
    setCloneModal({ visible: false, mediaplan_id: null, scenario_id: null, name: '' });
    await getMediaPlan(page, filters, searchTerm);
  };

  const getMetaData = async () => {
    try {
      const res = await mediaPlanService.getMetaData(clientId);
      setMetaData(res?.data);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };

  const handleStatusAndKpi = (val, type) => {
    let filter = { ...filters, [type]: val };
    filterRef.current = {
      ...filterRef.current,
      [type]: val,
    };
    setFilters(filter);
    getMediaPlan(1, filter, searchTerm);
  };

  const handleSortChanges = (key, value) => {
    let sortFilter = {};
    if (value === 'ascend') {
      sortFilter = { [key]: 'ascend' };
    } else if (value === 'descend') {
      sortFilter = { [key]: 'descend' };
    } else {
      delete sortFilter[key];
    }
    let filter = { ...filters, sortOrders: sortFilter };
    setFilters(filter);
    filterRef.current = {
      ...filterRef.current,
      sortOrders: sortFilter,
    };
    getMediaPlan(page, filter, searchTerm);
  };

  const closeUsersAndPermissionsDrawer = () => {
    setUserPermissionDrawer({ visible: false, data: null });
  };

  const refreshData = async () => {
    let filters = filterRef.current;
    let filterSearchTerm = filterRef.current.searchTerm;
    getMediaPlan(page, filters, filterSearchTerm, true);
  };

  useEffect(() => {
    timeRef.current = window.setInterval(async () => {
      if (!loading) {
        await refreshData();
      }
    }, 10000);
    return () => {
      if (timeRef.current) {
        window.clearInterval(timeRef.current);
      }
    };
  }, [loading]);

  useEffect(() => {
    getMetaData();
    getUserDetails();
  }, []);

  useEffect(() => {
    getMediaPlan(1, {}, '');
  }, []);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    filterRef.current = {
      ...filterRef.current,
      searchTerm: e.target.value,
    };
    debouncedSearch(e.target.value);
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      setPage(1);
      filterRef.current = {
        ...filterRef.current,
        page: 1,
      };
      let filter = { ...filterRef.current };
      getMediaPlan(1, filter, query);
    }, 1000),
    []
  );

  const onPaginationChange = (page) => {
    setPage(page);
    filterRef.current = {
      ...filterRef.current,
      page: page,
    };
    getMediaPlan(page, filters, searchTerm);
  };

  return {
    mediaData,
    page,
    loading,
    onPaginationChange,
    history,
    clientId,
    cloneModal,
    setCloneModal,
    closeCloneModal,
    archiveMediaPlan,
    getColumns,
    filters,
    metaData,
    handleStatusAndKpi,
    handleChange,
    searchTerm,
    pageNumber,
    setPageNUmber,
    totalPageData,
    closeUsersAndPermissionsDrawer,
    userPersmisionDrawer,
    userLists,
  };
}
