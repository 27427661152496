import { ExportOutlined, InfoCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { Drawer, Row, Tabs, Tooltip } from 'antd';
import 'echarts-wordcloud';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/basic';
import QuadrantChartComponent from '../components/analysisQuadrants/analysisQuadrants.component';
import { AnalysisViewContent } from '../components/AnalysisViewContent';
import JobTriggersDrawer from '../components/JobTriggersDrawer';
import NcBreadCrumb from '../components/NcBreadCrumb';
import RawDataContent from '../components/RawDataContent';
import { useAnalysisOverviewHook } from '../hooks/useAnalysisOverviewHook';
import Style from '../NewsAndCulture.style';
import AnalysisSettingsComponent from '../components/analysisSettingsComponent/analysisSettingsComponent';
import LoadingSpinner from 'src/components/loadingSpinner';

const AnalysisOverviewScreen = () => {
  const {
    analysisOverview,
    jobDrawer,
    setJobDrawer,
    handleClickForName,
    latestJobTimeStamp,
    onChatButtonClick,
    setAnalysisOverview,
    settingsDrawer,
    setSettingsDrawer,
    loading,
  } = useAnalysisOverviewHook();
  const { t } = useTranslation();

  if (loading) {
    return (
      <div className={Style.newsAndCultureContainer}>
        <LoadingSpinner centered />
      </div>
    );
  }

  return (
    <div className={Style.newsAndCultureContainer}>
      <Row justify={'space-between'}>
        <NcBreadCrumb
          className={Style.breadCrumbCreateScreen}
          name={analysisOverview?.name}
          onClick={handleClickForName}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {latestJobTimeStamp ? (
            <div style={{ color: 'var(--color-white)' }}>
              {t('news_and_culture.last_refreshed_on')}: {moment(latestJobTimeStamp?.job_triggered_at).format('LLL')}
            </div>
          ) : null}
          <Tooltip title={t('news_and_culture.data_pulls')} placement="bottom">
            <InfoCircleOutlined
              onClick={() => {
                setJobDrawer(true);
              }}
              className={Style.infoIcon}
            />
          </Tooltip>
        </div>
      </Row>
      <div className={Style.analysisParent}>
        <Tabs defaultActiveKey="1" className={Style.customOverviewTabs}>
          <Tabs.TabPane tab="Analysis View" key="1">
            {analysisOverview && <AnalysisViewContent analysisOverview={analysisOverview} />}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Data Source" key="2">
            <RawDataContent />
          </Tabs.TabPane>
          <Tabs.TabPane tab="2 x 2" key="3">
            <QuadrantChartComponent analysisOverview={analysisOverview} />
          </Tabs.TabPane>
        </Tabs>
        <div className={Style.headerButtons}>
          {analysisOverview?.advanced_settings?.ai_app_id && (
            <PrimaryButton onClick={onChatButtonClick}>
              {t('news_and_culture.chat')}
              <ExportOutlined />
            </PrimaryButton>
          )}
          <PrimaryButton onClick={() => setSettingsDrawer(true)}>
            {t('settings')}
            <SettingOutlined />
          </PrimaryButton>
        </div>
      </div>
      <Drawer
        open={jobDrawer}
        onClose={() => setJobDrawer(false)}
        title={t('news_and_culture.data_pulls')}
        width="50%"
        placement="right"
        maskClosable={true}
      >
        <JobTriggersDrawer analysisOverview={analysisOverview} />
      </Drawer>
      <Drawer
        open={settingsDrawer}
        onClose={() => setSettingsDrawer(false)}
        title={t('settings')}
        width="50%"
        placement="right"
        maskClosable={true}
      >
        <AnalysisSettingsComponent analysisData={analysisOverview} setAnalysisData={setAnalysisOverview} />
      </Drawer>
    </div>
  );
};

export default AnalysisOverviewScreen;
