import { Layout } from 'antd';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import assets from 'src/assets';
import AppUrls from 'src/constants/appUrls';
import './MediaPlanLayout.less';

import MediaPlanCreateLayout from '../mediaPlanCreateLayout/MediaPlanCreateLayout';
import MediaPlansListing from '../mediaPlanListing/MediaPlansListing';

const { Content } = Layout;

const MediaPlanLayout = () => {
  return (
    <>
      <Layout className="layout">
        <Content className="page-container">
          <Switch>
            <Route exact path={AppUrls.MEDIA_PLAN_LISTING(':clientId')} component={MediaPlansListing} />
            <Route
              path={[
                AppUrls.CREATE_MEDIA_PLAN(':clientId'),
                AppUrls.EDIT_MEDIA_PLAN(':clientId', ':mediaPlanId'),
                AppUrls.CREATE_SCENARIO_MEDIA_PLAN(':clientId', ':mediaPlanId'),
                AppUrls.EDIT_SCENARIO_MEDIA_PLAN(':clientId', ':scenarioId'),
                AppUrls.REVIEW_SCENARIO_MEDIA_PLAN(':clientId', ':scenarioId'),
              ]}
              component={MediaPlanCreateLayout}
            />
            <Redirect to={AppUrls.MEDIA_PLAN_LISTING(':clientId')} />
          </Switch>
        </Content>
      </Layout>
      <div className={'decorativeBackgroundImage'}>
        <img className="select-disable" alt="decorative element" src={assets.getImage('ClientSpaceWave')} />
      </div>
    </>
  );
};

export default MediaPlanLayout;
