import { Checkbox, Col, Collapse, Form, InputNumber, Popover, Radio, Row, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/basic';
import LoadingSpinner from 'src/components/loadingSpinner';
import useConfigureSem from './useConfigureSem';

const SemMaxAggressiveness = [
  { data: 'LOW', value: 1 },
  { data: 'MEDIUM', value: 2 },
  { data: 'HIGH', value: 3 },
];

const ConfigureSem = ({ data, setSemConfigurationModal, fetchSemData }) => {
  const { loading, formRef, configureLoading, semConfigData, saveSemConfig } = useConfigureSem({
    data,
    setSemConfigurationModal,
    fetchSemData,
  });
  const { t } = useTranslation();
  if (loading) {
    return <LoadingSpinner centered />;
  }
  return (
    <div>
      <Row justify="end" style={{ marginBottom: '1rem' }}>
        <PrimaryButton
          onClick={() => {
            formRef.current.submit();
          }}
          loading={configureLoading}
          className="small"
        >
          {t('mediaplan.save_and_forecast')}
        </PrimaryButton>
      </Row>
      <Form
        ref={formRef}
        initialValues={semConfigData}
        onFinish={(val) => {
          saveSemConfig(val);
        }}
        layout="vertical"
      >
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <Form.Item name="keywords" label="Enter Keywords">
              <Select
                mode="tags"
                style={{ width: '100%' }}
                maxTagCount="responsive"
                maxTagPlaceholder={(omittedValues) => (
                  <Popover
                    color="black"
                    overlayClassName="tagPopover"
                    content={
                      <div className="tagWrapper">
                        {omittedValues.map(({ label }, id) => (
                          <div className={`tagText`} key={id}>
                            {label}&nbsp;&nbsp;&nbsp;
                          </div>
                        ))}
                      </div>
                    }
                  >
                    <span>+{omittedValues.length}</span>
                  </Popover>
                )}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="brand_keyword" label="Brand Keywords">
              <Select
                mode="tags"
                maxTagCount="responsive"
                maxTagPlaceholder={(omittedValues) => (
                  <Popover
                    color="black"
                    overlayClassName="tagPopover"
                    content={
                      <div className="tagWrapper">
                        {omittedValues.map(({ label }, id) => (
                          <div className={`tagText`} key={id}>
                            {label}&nbsp;&nbsp;
                          </div>
                        ))}
                      </div>
                    }
                  >
                    <span>+{omittedValues.length}</span>
                  </Popover>
                )}
                style={{ width: '100%' }}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Enter Competitor Keyword" name="competitor_keyword">
              <Select
                mode="tags"
                style={{ width: '100%' }}
                maxTagCount="responsive"
                maxTagPlaceholder={(omittedValues) => (
                  <Popover
                    color="black"
                    overlayClassName="tagPopover"
                    content={
                      <div className="tagWrapper">
                        {omittedValues.map(({ label }, id) => (
                          <div className={`tagText`} key={id}>
                            {label}&nbsp;&nbsp;
                          </div>
                        ))}
                      </div>
                    }
                  >
                    <span>+{omittedValues.length}</span>
                  </Popover>
                )}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Enter Local Keywords" name="local_keyword">
              <Select
                mode="tags"
                style={{ width: '100%' }}
                maxTagCount="responsive"
                maxTagPlaceholder={(omittedValues) => (
                  <Popover
                    color="black"
                    overlayClassName="tagPopover"
                    content={
                      <div className="tagWrapper">
                        {omittedValues.map(({ label }, id) => (
                          <div className={`tagText`} key={id}>
                            {label}&nbsp;&nbsp;
                          </div>
                        ))}
                      </div>
                    }
                  >
                    <span>+{omittedValues.length}</span>
                  </Popover>
                )}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Collapse className={'analysisCollapse'}>
          <Collapse.Panel key={'1'} forceRender header="Advanced Settings">
            <Row gutter={[30, 0]}>
              <Col span={12}>
                <Form.Item name="brand_max_aggressiveness" label="Brand Aggressiveness">
                  <Select>
                    {SemMaxAggressiveness?.map((item, idx) => (
                      <Select.Option key={idx} value={item.value}>
                        {item.data}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="nb_max_aggressiveness" label="Non Brand Aggressiveness">
                  <Select>
                    {SemMaxAggressiveness?.map((item, idx) => (
                      <Select.Option key={idx} value={item.value}>
                        {item.data}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="brand_ctr_factor" label="Brand CTR Improvement">
                  <Select>
                    {SemMaxAggressiveness?.map((item, idx) => (
                      <Select.Option key={idx} value={item.value}>
                        {item.data}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="nb_ctr_factor" label="Non Brand CTR Improvement">
                  <Select>
                    {SemMaxAggressiveness?.map((item, idx) => (
                      <Select.Option key={idx} value={item.value}>
                        {item.data}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="brand_cpc_factor" label="Brand CPC">
                  <InputNumber formatter={(value) => `${value}%`} style={{ width: '100%' }} min={0} max={100} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="nb_cpc_factor" label="Non Brand CPC">
                  <InputNumber formatter={(value) => `${value}%`} style={{ width: '100%' }} min={0} max={100} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="top_keyword_percentage" label="Top Keyword Percentage">
                  <InputNumber formatter={(value) => `${value}%`} style={{ width: '100%' }} min={0} max={100} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="kpi_factor">
                  <Radio.Group>
                    <Radio value={true}>Awareness</Radio>
                    <Radio value={false}>DR</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item name="forecast_by_adgroup">
                  <Radio.Group>
                    <Radio value={true}>Forecast by Ad Group</Radio>
                    <Radio value={false}>Forecast by Keyword</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="include_bing_value" valuePropName="checked">
                  <Checkbox>Include Bing Value</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Collapse.Panel>
        </Collapse>
      </Form>
    </div>
  );
};

export default ConfigureSem;
