import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Card, Col, Drawer, Form, Input, InputNumber, Modal, Radio, Row, Select, Slider, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { PrimaryButton, SecondaryButton } from 'src/components/basic';
import ConditionalRender from 'src/components/conditionalRender';
import LoadingSpinner from 'src/components/loadingSpinner';
import appUrls from 'src/constants/appUrls';
import { numberWithCommas } from 'src/lib/utils';
import { VIEWER } from 'src/modules/audienceTool/audienceManager/constants/constants';
import { CustomGptService } from 'src/modules/CustomGpt/services/customGptServices';
import NcBreadCrumb from 'src/modules/newsAndCulture/components/NcBreadCrumb';
import {
  ACQUISITION_CRM_LOOKALIKES,
  CLUSTER_TOOL_TIPS,
  CLUSTERING_TYPE,
  DEFAULT_AUDIENCE_NAME,
} from '../../constants/constants';
import BrowseFeatureDrawerComponent from './browseFeatures/BrowseFeatureDrawerComponent';
import Style from './createClusters.style';
import useCreateClusterHook from './useCreateClusterHook';
import { useTranslation } from 'react-i18next';
import CardTooltip from 'src/components/basic/CardTooltip';

export default function CreateCluster({
  history,
  match,
  fromAudience = false,
  onBackFromAudience,
  behaviourDirectory = undefined,
  typeConfigId = undefined,
  setCurrentStep,
}) {
  const {
    loading,
    formRef,
    createClusterConfig,
    configureLoading,
    getAudienceSize,
    audienceCount,
    audienceListLoading,
    initialValues,
    setEditDescription,
    viewMode,
    id,
    clusterId,
    savedChanges,
    setSavedChanges,
    audienceConfigId,
    audienceRole,
    clusterConfig,
    clusterType,
    drawerState,
    selectedItems,
    updateSelectedItems,
    showDrawer,
    closeDrawer,
    audienceListOptions,
    fields,
    containsFirstParty,
    setFormChanged,
    formChanged,
    targetRule,
    setTargetRule,
    getTargetValuesDisplay,
    handleClusterTypeChange,
    editClusterType,
    setEditClusterType,
    editMode,
  } = useCreateClusterHook({ match, history, fromAudience, typeConfigId });
  const { t } = useTranslation();

  const handleSave = (triggerFeatureSelection) => {
    if (editMode && initialValues?.state !== 'PROJECT_BRIEF_WRITTEN') {
      Modal.confirm({
        title: 'Warning',
        content: t('audience.cluster_warning'),
        okText: 'Yes',
        cancelText: 'No',
        onOk: () => {
          formRef.current.setFieldsValue({
            trigger_feature_selection: triggerFeatureSelection,
          });
          formRef.current.submit();
        },
      });
    } else {
      formRef.current.setFieldsValue({
        trigger_feature_selection: triggerFeatureSelection,
      });
      formRef.current.submit();
    }
  };
  if (loading) {
    return <LoadingSpinner centered={true} />;
  }

  return (
    <>
      {!fromAudience && <NcBreadCrumb className={Style.breadCrumb} />}
      <Row justify="space-between">
        <Col></Col>
        <Col>
          <Row gutter={[20, 0]}>
            <ConditionalRender shouldRender={fromAudience}>
              <Col>
                <PrimaryButton
                  type="default"
                  className="small"
                  disabled={configureLoading}
                  onClick={() => {
                    if (clusterType !== null) {
                      if (editClusterType || viewMode || audienceRole === VIEWER) onBackFromAudience();
                      else setEditClusterType(true);
                    } else {
                      onBackFromAudience();
                    }
                  }}
                >
                  Back
                </PrimaryButton>
              </Col>
            </ConditionalRender>
            <ConditionalRender
              shouldRender={
                (!!clusterId &&
                  initialValues?.state !== 'FINDING_RELEVANT_FEATURES' &&
                  initialValues?.state !== 'PROJECT_BRIEF_WRITTEN' &&
                  !formChanged) ||
                editClusterType
              }
            >
              <Col>
                <SecondaryButton
                  className="small"
                  loading={configureLoading}
                  onClick={() => {
                    if (editClusterType) {
                      setEditClusterType(false);
                    } else if (viewMode) {
                      if (match.path.includes('audience-tool')) {
                        setCurrentStep(2);
                      } else {
                        history.push(appUrls.CLUSTER_CONFIG_FEATURES(id, clusterId, audienceConfigId));
                      }
                    } else if (!formChanged) {
                      if (match.path.includes('audience-tool')) {
                        setCurrentStep(2);
                      } else {
                        history.push(appUrls.CLUSTER_CONFIG_FEATURES(id, clusterId, audienceConfigId));
                      }
                    } else if (savedChanges) {
                      formRef.current.submit();
                    } else {
                      if (match.path.includes('audience-tool')) {
                        setCurrentStep(2);
                      } else {
                        history.push(appUrls.CLUSTER_CONFIG_FEATURES(id, clusterId, audienceConfigId));
                      }
                    }
                  }}
                >
                  Next
                </SecondaryButton>
              </Col>
            </ConditionalRender>
            {/* Show "Save and Continue" when changes are detected */}
            <ConditionalRender shouldRender={formChanged && clusterType !== null && !editClusterType}>
              <Col>
                <SecondaryButton
                  loading={configureLoading}
                  className="small"
                  onClick={() => {
                    handleSave(true);
                  }}
                >
                  Save and Continue
                </SecondaryButton>
              </Col>
            </ConditionalRender>
            <ConditionalRender
              shouldRender={
                (!clusterId || (!!clusterId && initialValues?.state === 'PROJECT_BRIEF_WRITTEN')) &&
                clusterType !== null &&
                !editClusterType
              }
            >
              <Col>
                <PrimaryButton
                  loading={configureLoading}
                  className="small save-draft"
                  onClick={() => {
                    handleSave(false);
                  }}
                >
                  Save as draft
                </PrimaryButton>
              </Col>
              <Col>
                <SecondaryButton
                  loading={configureLoading}
                  className="small"
                  onClick={() => {
                    handleSave(true);
                  }}
                >
                  Save and continue
                </SecondaryButton>
              </Col>
            </ConditionalRender>
          </Row>
        </Col>
      </Row>
      <div className={Style.formContainer}>
        {clusterType !== null && !editClusterType && (
          <Row justify="space-between" align="middle" className={Style.rowSpacer}>
            <Col>
              <span>Cluster Type:</span>
              <h2 className={Style.clusterTypeTitle}>{CLUSTERING_TYPE(true)[clusterType]}</h2>
            </Col>
          </Row>
        )}
        <Form
          initialValues={initialValues}
          ref={formRef}
          labelAlign="left"
          requiredMark={false}
          layout="vertical"
          scrollToFirstError
          onFinish={(val) => {
            val.trigger_feature_selection = formRef.current.getFieldValue('trigger_feature_selection');
            createClusterConfig(val);
          }}
          onValuesChange={(changedValues) => {
            const hasChanges = Object.keys(initialValues).some((key) => initialValues[key] !== changedValues[key]);
            setFormChanged(hasChanges);

            if (!savedChanges) {
              setSavedChanges(true);
            }
            if (changedValues?.description) {
              setEditDescription(true);
            }
            getAudienceSize(changedValues?.input_audience_config_id);
          }}
        >
          <Row gutter={[30, 0]}>
            <ConditionalRender shouldRender={clusterType === null || editClusterType}>
              <Form.Item name="clusters_type" rules={[{ required: true, message: 'Please select a cluster type' }]}>
                <div className={Style.clusterTypeContainer}>
                  <h2 className={Style.clusterTypeHeader}>{t('audience.clusterTypeHeader')}</h2>
                  {Object.entries(CLUSTERING_TYPE(containsFirstParty)).map(([key, value]) => (
                    <Card
                      key={key}
                      className={`
  ${Style.customCard}
  ${clusterType === key ? 'card-primary' : 'card-gray'}
  card-hover
  ${viewMode || audienceRole === VIEWER ? 'card-disabled' : ''}
  `}
                      onClick={() => {
                        if (!viewMode && audienceRole !== VIEWER && formRef.current) {
                          handleClusterTypeChange(key);
                        }
                      }}
                    >
                      <Radio
                        checked={clusterType === key}
                        onChange={() => handleClusterTypeChange(key)}
                        className={Style.radioPositioning}
                        disabled={viewMode || audienceRole === VIEWER}
                      />
                      <Card.Meta
                        title={value}
                        description={
                          <span className="card-meta-description">
                            <CardTooltip title={CLUSTER_TOOL_TIPS[key]}>
                              <InfoCircleOutlined />
                            </CardTooltip>
                          </span>
                        }
                      />
                    </Card>
                  ))}
                </div>
              </Form.Item>
            </ConditionalRender>
            <ConditionalRender shouldRender={clusterType !== null && !editClusterType}>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="Project Name*"
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value) {
                          if (value.trim().length === 0) {
                            return Promise.reject(new Error('Cluster name is required'));
                          } else {
                            const regex = /^[a-zA-Z0-9 ]+$/;
                            if (!regex.test(value)) {
                              return Promise.reject(
                                new Error('Cluster name should only contain alphanumeric characters and spaces')
                              );
                            } else {
                              return Promise.resolve();
                            }
                          }
                        }
                        return Promise.resolve();
                      },
                    },
                    { required: true, message: 'Cluster Name is required' },
                  ]}
                >
                  <Input placeholder="Enter name" disabled={viewMode || audienceRole === VIEWER} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="description" label="Additional Project Specific Context">
                  <TextArea
                    autoSize={{ maxRows: 6, minRows: 3 }}
                    placeholder="Enter description"
                    disabled={viewMode || audienceRole === VIEWER}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="input_audience_config_id" label="Are you sub-segmenting an existing Audience?">
                  <Select
                    showArrow
                    disabled={viewMode || audienceRole === VIEWER || clusterType === null}
                    loading={audienceListLoading}
                  >
                    <Select.Option value={undefined}>{DEFAULT_AUDIENCE_NAME[clusterType]}</Select.Option>
                    {audienceListOptions?.map((audience, idx) => {
                      return (
                        <Select.OptGroup
                          label={
                            <div
                              style={{
                                fontSize: '14px',
                                color: 'gray',
                                fontWeight: 'bold',
                              }}
                            >
                              <div>{audience?.name}</div>
                            </div>
                          }
                          key={idx}
                        >
                          {audience?.audience_config?.map((config) => {
                            return (
                              <Select.Option value={config?.id} key={config?.id}>
                                &nbsp;&nbsp;&nbsp;{config?.name}
                              </Select.Option>
                            );
                          })}
                        </Select.OptGroup>
                      );
                    })}
                  </Select>
                </Form.Item>
                <h4 className={Style.audienceSize}>
                  <i>
                    Audience size:&nbsp;
                    {audienceListLoading ? <Spin /> : audienceCount ? <b>{numberWithCommas(audienceCount)}</b> : 'N/A'}
                  </i>
                </h4>
              </Col>
              <Col span={12}>
                <h4 className={Style.sectionHeader}>Specify the number of clusters you want to create*</h4>
                <Row gutter={[20, 0]}>
                  <Col>
                    <Form.Item
                      name="number_of_clusters"
                      rules={CustomGptService.validations({ required: true }, 'Number of Clusters')}
                    >
                      <Slider
                        className={Style.sliderContainer}
                        marks={{ 2: 2, 20: 20 }}
                        min={2}
                        max={20}
                        defaultValue={2}
                        disabled={viewMode || audienceRole === VIEWER}
                      ></Slider>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name="number_of_clusters">
                      <InputNumber min={2} max={20} disabled={viewMode || audienceRole === VIEWER} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="target_values"
                  label={<span className={Style.featureLabel}>Segment on Attributes That Predict</span>}
                  rules={[
                    {
                      required: clusterType !== ACQUISITION_CRM_LOOKALIKES,
                      message: 'Target Values are required',
                    },
                    ...CustomGptService.validations({}, 'Target Values'),
                  ]}
                  extra={t('audience.segment_on_attributes_tool_tip')}
                >
                  <TextArea className={Style.targetValueDisplay} value={getTargetValuesDisplay()} disabled />
                  <PrimaryButton
                    className="small"
                    onClick={() => showDrawer('target_values')}
                    disabled={clusterType === null || viewMode || audienceRole === VIEWER}
                    style={{ marginBottom: '10px' }}
                  >
                    Add
                    <PlusOutlined />
                  </PrimaryButton>
                </Form.Item>
              </Col>

              {/* Exclude Features */}
              <Col span={12}>
                <Form.Item
                  name="features_to_exclude"
                  label={<span className={Style.featureLabel}>Select Features to Exclude&nbsp;</span>}
                  extra={t('audience.Select_features_to_exclude_tool_tip')}
                >
                  <TextArea
                    className={Style.featureExtraText}
                    value={selectedItems.features_to_exclude.map((item) => item.name).join(', ')}
                    disabled
                  />
                  <PrimaryButton
                    className="small"
                    onClick={() => showDrawer('features_to_exclude')}
                    disabled={clusterType === null || viewMode || audienceRole === VIEWER}
                    style={{ marginBottom: '10px' }}
                  >
                    Add
                    <PlusOutlined />
                  </PrimaryButton>
                </Form.Item>
              </Col>
            </ConditionalRender>
          </Row>
        </Form>

        {/* Drawers */}
        {clusterType !== null && (
          <>
            <Drawer
              title="Segment on Attributes That Predict*"
              placement="right"
              onClose={() => closeDrawer('target_values')}
              visible={drawerState.target_values}
              width="60%"
            >
              <BrowseFeatureDrawerComponent
                key={'target_values' + clusterType + selectedItems.target_values.length}
                fields={fields}
                behaviourDirectory={behaviourDirectory}
                browsedSelectedItems={selectedItems.target_values}
                onUpdateBrowsedItems={(items) => updateSelectedItems('target_values', items)}
                clusterConfig={clusterConfig}
                clusterType={clusterType}
                rule="cluster_target_rules"
                targetRule={targetRule}
                setTargetRule={setTargetRule}
              />
            </Drawer>
            <Drawer
              title="Select Features to Exclude"
              placement="right"
              onClose={() => closeDrawer('features_to_exclude')}
              visible={drawerState.features_to_exclude}
              width="60%"
            >
              <BrowseFeatureDrawerComponent
                key={'features_to_exclude' + clusterType + selectedItems.features_to_exclude.length}
                fields={fields}
                behaviourDirectory={behaviourDirectory}
                browsedSelectedItems={selectedItems.features_to_exclude}
                onUpdateBrowsedItems={(items) => updateSelectedItems('features_to_exclude', items)}
                clusterConfig={clusterConfig}
                clusterType={clusterType}
                rule="feature_exclusion_rules"
              />
            </Drawer>
          </>
        )}
      </div>
    </>
  );
}
