import { useState } from 'react';

const useMediaPlanSemKeyword = ({ data }) => {
  const [tab, setTab] = useState(data);
  const [tabData, setTabData] = useState(data);

  const onTabChange = (objective, data) => {
    setTab(objective);
    setTabData(data);
  };

  return {
    tab,
    setTab,
    tabData,
    setTabData,
    onTabChange,
  };
};

export default useMediaPlanSemKeyword;
