import { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import {
  OPERATOR_EQUALS,
  OPERATOR_IN,
  OPERATOR_NOT_IN,
  OPERATOR_BETWEEN,
  OPERATOR_NOT_BETWEEN,
  OPERATOR_CONTAINS,
  OPERATOR_STARTS_WITH,
  OPERATOR_ENDS_WITH,
  OPERATOR_LIKE,
  OPERATOR_NOT_LIKE,
} from 'src/modules/audienceTool/audienceManager/constants/queryBuilderConstants';
import { DatePicker, Input, Select } from 'antd';
import React from 'react';

const useOperatorValueSelectorHook = (initialOperator, initialValue, field, onConfirm, onClose) => {
  const [selectedOperator, setSelectedOperator] = useState(initialOperator || OPERATOR_EQUALS);
  const [value, setValue] = useState(initialValue || '');

  useEffect(() => {
    setSelectedOperator(initialOperator || OPERATOR_EQUALS);
    setValue(initialValue || '');
  }, [initialOperator, initialValue]);

  const filterOptions = (input, option) => {
    const optionText = option.children;
    return optionText.toLowerCase().includes(input.toLowerCase());
  };

  const getSelectedValues = (value, hasPredefinedValues) => {
    if (Array.isArray(value)) {
      return value
        .map(
          (val) => hasPredefinedValues.find((preVal) => preVal.value === val) || { value: val, value_description: val }
        )
        .filter((val) => val !== undefined && val.value !== '');
    } else {
      const foundValue = hasPredefinedValues.find((preVal) => preVal.value === value);
      return foundValue || { value: value, value_description: value };
    }
  };

  const formatValue = (val) => {
    const hasPredefinedValues = field.values || [];
    val = getSelectedValues(val, hasPredefinedValues);

    if (Array.isArray(val)) {
      return val.map((v) => ({ value: v.value, value_description: v.value_description }));
    }
    return { value: val.value, value_description: val.value_description };
  };

  const handleConfirm = () => {
    if (value === '' || (Array.isArray(value) && value.length === 0)) {
      onClose();
      return;
    }
    const formattedValue = formatValue(value);
    onConfirm(selectedOperator, formattedValue);
    onClose();
  };

  const handleClear = () => {
    const defaultValue =
      selectedOperator === OPERATOR_BETWEEN || selectedOperator === OPERATOR_NOT_BETWEEN ? ['', ''] : '';
    setValue(defaultValue);
    onConfirm(selectedOperator, defaultValue);
    onClose();
  };

  const renderValueInput = useCallback(() => {
    const format = field.format || 'YYYYMMDD';
    const hasPredefinedValues = field.values || [];

    switch (selectedOperator) {
      case OPERATOR_IN:
      case OPERATOR_NOT_IN:
        if (field.valueEditorType === 'select') {
          return (
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Select values"
              value={Array.isArray(value) ? value.filter((val) => val !== '') : value !== '' ? [value] : []}
              onChange={(val) => setValue(val)}
              showSearch
              filterOption={filterOptions}
            >
              {hasPredefinedValues
                .sort((a, b) => a.value_description.localeCompare(b.value_description))
                .map((val) => (
                  <Select.Option key={val.value} value={val.value}>
                    {val.value_description}
                  </Select.Option>
                ))}
            </Select>
          );
        } else {
          return (
            <Input
              placeholder="Enter comma-separated values"
              value={Array.isArray(value) ? value.join(', ') : value}
              onChange={(e) => setValue(e.target.value)}
            />
          );
        }

      case OPERATOR_BETWEEN:
      case OPERATOR_NOT_BETWEEN: {
        const betweenValue = Array.isArray(value) ? value : ['', ''];
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {field.valueEditorType === 'date' ? (
              <>
                <DatePicker
                  value={betweenValue[0] ? dayjs(betweenValue[0], format) : null}
                  onChange={(date) => setValue([date ? dayjs(date).format(format) : null, betweenValue[1]])}
                  placeholder="Start"
                  style={{ width: '50%', marginRight: 10 }}
                />
                <DatePicker
                  value={betweenValue[1] ? dayjs(betweenValue[1], format) : null}
                  onChange={(date) => setValue([betweenValue[0], date ? dayjs(date).format(format) : null])}
                  placeholder="End"
                  style={{ width: '50%' }}
                />
              </>
            ) : (
              <>
                {field.min !== undefined && field.max !== undefined && (
                  <div style={{ marginBottom: '8px', color: '#555' }}>
                    Allowed range: {field.min} - {field.max}
                  </div>
                )}
                <Input
                  type={field.valueEditorType === 'number' ? 'number' : 'text'}
                  value={betweenValue[0]}
                  onChange={(e) => setValue([e.target.value, betweenValue[1]])}
                  placeholder="Start"
                  style={{ width: '50%', marginRight: 10 }}
                  min={field.min !== undefined ? field.min : undefined}
                  max={field.max !== undefined ? field.max : undefined}
                />
                <Input
                  type={field.valueEditorType === 'number' ? 'number' : 'text'}
                  value={betweenValue[1]}
                  onChange={(e) => setValue([betweenValue[0], e.target.value])}
                  placeholder="End"
                  style={{ width: '50%' }}
                  min={field.min !== undefined ? field.min : undefined}
                  max={field.max !== undefined ? field.max : undefined}
                />
              </>
            )}
          </div>
        );
      }
      case OPERATOR_CONTAINS:
      case OPERATOR_STARTS_WITH:
      case OPERATOR_ENDS_WITH:
      case OPERATOR_LIKE:
      case OPERATOR_NOT_LIKE:
        return <Input placeholder="Enter text" value={value} onChange={(e) => setValue(e.target.value)} />;

      default:
        switch (field.valueEditorType) {
          case 'select':
          case 'boolean':
            return (
              <Select
                style={{ width: '100%' }}
                placeholder="Select value"
                value={value !== '' ? value : undefined}
                onChange={(val) => setValue(val)}
                showSearch
                filterOption={filterOptions}
              >
                {hasPredefinedValues
                  .sort((a, b) => a.value_description.localeCompare(b.value_description))
                  .map((val) => (
                    <Select.Option key={val.value} value={val.value}>
                      {val.value_description}
                    </Select.Option>
                  ))}
              </Select>
            );
          case 'number': {
            let errorMessage = `Value should be between ${field.min} and ${field.max}`;
            const isInvalid =
              field.min !== undefined && field.max !== undefined && (value < field.min || value > field.max);

            return (
              <>
                {field.min !== undefined && field.max !== undefined && (
                  <div style={{ marginBottom: '8px', color: '#555' }}>
                    Allowed range: {field.min} - {field.max}
                  </div>
                )}
                <Input
                  type="number"
                  placeholder="Enter number"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  min={field.min !== undefined ? field.min : undefined}
                  max={field.max !== undefined ? field.max : undefined}
                />
                {isInvalid && <div className="error">{errorMessage}</div>}
              </>
            );
          }
          case 'date':
            return (
              <DatePicker
                style={{ width: '100%' }}
                value={value ? dayjs(value, format) : null}
                onChange={(date) => setValue(date ? dayjs(date).format(format) : null)}
                showMonthYearPicker={format === 'YYYYMM'}
              />
            );
          default:
            if (hasPredefinedValues.length > 0) {
              return (
                <Select
                  style={{ width: '100%' }}
                  placeholder="Select value"
                  value={value !== '' ? value : undefined}
                  onChange={(val) => setValue(val)}
                  showSearch
                  filterOption={filterOptions}
                >
                  {hasPredefinedValues
                    .sort((a, b) => a.value_description.localeCompare(b.value_description))
                    .map((val) => (
                      <Select.Option key={val.value} value={val.value}>
                        {val.value_description}
                      </Select.Option>
                    ))}
                </Select>
              );
            } else {
              return <Input placeholder="Enter value" value={value} onChange={(e) => setValue(e.target.value)} />;
            }
        }
    }
  }, [selectedOperator, value, field]);

  return {
    selectedOperator,
    setSelectedOperator,
    value,
    setValue,
    renderValueInput,
    handleConfirm,
    handleClear,
  };
};

export default useOperatorValueSelectorHook;
