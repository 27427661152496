import { notification } from 'antd';
import { useRef, useState } from 'react';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import audienceListService from '../../services/audienceListService';

const useAudienceCloneForm = ({ closeCloneModal, onClone, data, handleAudienceEdit, handleClusterClone }) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);

  const handleClone = async (value) => {
    try {
      setLoading(true);
      let res = null;
      if (data.isAudienceProject) {
        res = await audienceListService.cloneAudience(data.audienceProjectId, value?.cloned_project_name);
      } else {
        res = await audienceListService.cloneAudienceProfile(data.audienceProfileId, value?.cloned_project_name);
      }
      notification.success({
        message: 'Audience cloned successfully',
        duration: 3,
        placement: 'bottomRight',
      });
      closeCloneModal();
      setLoading(false);
      if (data?.type === 'RULE_BASED') {
        if (data.isAudienceProject) {
          await onClone();
        } else {
          handleAudienceEdit(data.audienceProjectId, res.data.id);
        }
      } else {
        handleClusterClone(res.data.id, res.data.audience_config[0].id);
      }
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 3,
        placement: 'bottomRight',
      });
    }
  };
  return {
    loading,
    formRef,
    handleClone,
  };
};

export default useAudienceCloneForm;
