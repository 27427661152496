import { CloseCircleOutlined, CloseOutlined, InfoCircleOutlined, SaveFilled, SettingFilled } from '@ant-design/icons';
import {
  Card,
  Col,
  DatePicker,
  Drawer,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Popover,
  Row,
  Select,
  Slider,
  Spin,
  Switch,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import IconGenerator from 'src/components/IconGenerator';
import { PrimaryButton, SecondaryButton } from 'src/components/basic';
import ConditionalRender from 'src/components/conditionalRender';
import KnowledgeGraphComponent from 'src/modules/newsAndCulture/components/knowledgeGraph/knowledgeGraph.component';
import Style from '../NewsAndCulture.style';
import useNewsAndCulture from '../hooks/useNewsAndCultureLayout';
import ArticlesTableDrawer from './ArticlesTableDrawer';
import { DateSlider } from './DateSlider';
import KnowledgeGraphSummary from './KnowledgeGraphSummary';
import FloatLabel from './floatLabel/floatLabel.component';
import KnowledgeGraphComponentStats from './knowledgeGraphStats/knowledgeGraphStats.component';
import { KNOWLEDGE_GRAPH_RENDERS } from '../constants';
const { RangePicker } = DatePicker;

export const AnalysisViewContent = ({ analysisOverview }) => {
  const {
    loading,
    drawerVisible,
    selectedWord,
    selectedLink,
    analysisId,
    handleNodeClick,
    setDrawerVisible,
    handleLinkClick,
    knowledgeGraphFilters,
    onChangeKnowledgeGraphFilters,
    knowledgeGraph,
    nodes,
    knowledgeGraphLoading,
    summaryDrawer,
    dates,
    setSummaryDrawer,
    setColorBy,
    colorBy,
    applyFiltersKnowledgeGraph,
    knowledgeGraphStats,
    handleSliderChange,
    selectedDate,
    handleNodePerformanceNodeClick,
    datesForDatePicker,
    handleNodesFilter,
    handleCommunitiesFilter,
    knowledgeGraphCommunities,
    handlePopupScroll,
    handleSearch,
    handleDropdownVisibleChange,
    loadingNodesOptions,
    containerRef,
    knowledgeGraphChanged,
    knowledgeGraphSavedFilters,
    maxJobTriggeredAt,
    showSaveFilterModal,
    setShowSaveFilterModal,
    saveKnowledgeGraphSavedFilters,
    formRef,
    applySavedFilters,
    selectedFilter,
    showMyFilters,
    setShowMyFilters,
    userData,
    constructKnowledgeGraphFilters,
    showMoreOptions,
    setShowMoreOptions,
    handles,
    loadingHandlesOptions,
    handlePopupScrollHandle,
    handleSearchHandle,
    handleDropdownVisibleChangeHandle,
    analysisListData,
    appliedDates,
    changeKnowledgeGraphFilterFromPopover,
    selectedHandles,
    debouncedOnChangeKnowledgeGraphFilters,
  } = useNewsAndCulture({ analysisOverview });
  const { t } = useTranslation();

  const memoizedKnowledgeGraph = useMemo(() => {
    const COLOR_BY_MAPPER = {};
    if (knowledgeGraph && knowledgeGraph.platform_categories) {
      COLOR_BY_MAPPER.platform = {
        category_key: 'platform_category',
        category_legend_key: 'platform_categories',
        name: 'Platform',
      };
    }
    if (knowledgeGraph?.analysis_categories) {
      COLOR_BY_MAPPER.analysis = {
        category_key: 'analysis_category',
        category_legend_key: 'analysis_categories',
        name: 'Analysis',
      };
    }
    if (knowledgeGraph?.community_categories) {
      COLOR_BY_MAPPER.community = {
        category_key: 'community_category',
        category_legend_key: 'community_categories',
        name: 'Community',
      };
    }
    if (knowledgeGraph?.cluster_categories) {
      COLOR_BY_MAPPER.cluster = {
        category_key: 'cluster_category',
        category_legend_key: 'cluster_categories',
        name: 'Cluster',
      };
    }
    if (knowledgeGraph?.custom_categories) {
      Object.keys(knowledgeGraph.custom_categories).map((categoryName) => {
        COLOR_BY_MAPPER[categoryName] = {
          category_key: `custom_${categoryName}`,
          category_legend_key: `custom_${categoryName}`,
          name: categoryName,
        };
        knowledgeGraph[`custom_${categoryName}`] = Object.values(knowledgeGraph.custom_categories[categoryName]).map(
          (category) => ({
            id: category.id,
            name: category.name,
            description: category.description,
          })
        );
        return null; // Return a placeholder value for sonarqube fix
      });
    }

    return knowledgeGraph?.nodes
      ? {
          nodes: knowledgeGraph.nodes
            .map((element) => {
              element.symbolSize = 10 + (element.mentions * 50) / element.max_mentions;
              element.label = {
                show: element.symbolSize > 15,
                textStyle: {
                  color: '#ffffff',
                },
              };
              element.category = element[COLOR_BY_MAPPER[colorBy]?.category_key];
              element.emphasis = {
                label: {
                  show: true,
                },
              };
              return element;
            })
            .filter((element) => element.category !== undefined),
          links: knowledgeGraph.links,
          categories: knowledgeGraph[COLOR_BY_MAPPER[colorBy]?.category_legend_key],
          color_by_mapper: COLOR_BY_MAPPER,
        }
      : null;
  }, [knowledgeGraph, knowledgeGraphStats, colorBy]);

  return (
    <div className={Style.tabContent} ref={containerRef}>
      <Card className={Style.analysisCardView}>
        {loading ? (
          <div style={{ textAlign: 'center', padding: '50px' }}>
            <Spin size="medium" />
          </div>
        ) : (
          <>
            <>
              <Row gutter={[10, 10]}>
                <Col span={4}>
                  <Row gutter={[10, 10]} className={Style.knowledgeGraphFilters}>
                    <Col span={24}>
                      <FloatLabel
                        label={t('news_and_culture.saved_filter')}
                        style={{ backgroundColor: 'var(--color-charcoal-surface-2)' }}
                        value={selectedFilter?.id}
                        helpText={t('news_and_culture.saved_filter_help_text')}
                      >
                        <Select
                          showSearch
                          className={Style.savedFilterSelect}
                          style={{ width: '100%' }}
                          showArrow
                          // placeholder="Saved Filters"
                          value={selectedFilter?.id}
                          onChange={(value) =>
                            applySavedFilters(knowledgeGraphSavedFilters?.find((elem) => elem.id === value))
                          }
                          // onClear={() => clearSavedFilters()}
                          popupClassName={Style.savedFilterPopup}
                          allowClear
                          optionLabelProp="label"
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          dropdownRender={(menu) => (
                            <div>
                              <div className={Style.customDropdownHeader}>
                                <span style={{ paddingRight: '10px' }}>
                                  {t('news_and_culture.show_only_my_filters')}:{' '}
                                </span>
                                <Switch
                                  style={{ width: '10%' }}
                                  checked={showMyFilters}
                                  onChange={() => setShowMyFilters(!showMyFilters)}
                                />
                              </div>
                              {menu}
                            </div>
                          )}
                        >
                          {knowledgeGraphSavedFilters
                            ?.filter((elem) => {
                              if (showMyFilters) {
                                return elem.filter_owner === userData?.id;
                              }
                              return true;
                            })
                            .map((elem, idx) => {
                              return (
                                <Select.Option key={idx} value={elem.id} label={elem.filter_name}>
                                  <Popover
                                    placement="right"
                                    overlayClassName={Style.savedFilterPopover}
                                    zIndex={9999}
                                    mouseEnterDelay={0.5}
                                    content={
                                      <div className={Style.popoverContent}>
                                        <div>
                                          <strong>{t('news_and_culture.filter_name')}:</strong> {elem.filter_name}
                                        </div>
                                        {elem.filter_description && (
                                          <div>
                                            <strong>{t('news_and_culture.filter_description')}:</strong>{' '}
                                            {elem.filter_description}
                                          </div>
                                        )}
                                        <div>
                                          <strong>{t('news_and_culture.created_by')}:</strong>{' '}
                                          {elem.filter_owner_data?.first_name
                                            ? `${elem.filter_owner_data.first_name} ${elem.filter_owner_data.last_name}`
                                            : elem.filter_owner_data?.email}
                                        </div>
                                      </div>
                                    }
                                  >
                                    <div className={Style.savedFilterOptions}>
                                      <div className={Style.userIcon}>
                                        <IconGenerator
                                          height={24}
                                          width={24}
                                          initial={
                                            elem.filter_owner_data?.first_name
                                              ? elem.filter_owner_data?.first_name.charAt(0) +
                                                elem.filter_owner_data?.last_name.charAt(0)
                                              : elem.filter_owner_data?.email.slice(0, 2)
                                          }
                                          backgroundColor={'var(--color-white)'}
                                          color="var(--color-full-black)"
                                        />
                                      </div>
                                      <div className={Style.filterContent}>
                                        <div className={Style.filterName}>{elem.filter_name}</div>
                                        <div className={Style.filterDate}>
                                          {moment(elem.max_job_triggered_at).format('LLL')}
                                        </div>
                                      </div>
                                    </div>
                                  </Popover>
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </FloatLabel>
                      {selectedFilter?.id && (
                        <div className={Style.dataSnapshotMessage}>
                          <Tooltip
                            overlayClassName={Style.tooltipDegreeOfSeparation}
                            title={'Remove saved filter above to view the latest data.'}
                            placement="bottom"
                          >
                            <div>{t('news_and_culture.browsing_data_as_of')}:</div>
                            <b>{moment(selectedFilter.max_job_triggered_at).format('LLL')}</b>
                          </Tooltip>
                        </div>
                      )}
                    </Col>
                    <div
                      className={
                        showMoreOptions
                          ? Style.knowledgeGraphFiltersBoxDynamicExpanded
                          : Style.knowledgeGraphFiltersBoxDynamic
                      }
                    >
                      <div className={Style.knowledgeGraphFiltersWrapper}>
                        {/* <div className={Style.knowledgeGraphFiltersWrapper}> */}
                        <Col span={'50%'} className="col-wrapper">
                          <Col span={24}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                height: '2rem',
                              }}
                            >
                              <h3 style={{ fontWeight: 'bold' }}>Filter by: </h3>
                              <div>
                                {!showMoreOptions && <SettingFilled onClick={() => setShowMoreOptions(true)} />}
                              </div>
                            </div>
                          </Col>
                          {/* {!useAISearch ? (
                            <> */}
                          <Col span={24}>
                            <FloatLabel
                              label="Platform"
                              style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                              value={knowledgeGraphFilters.analysisType}
                              helpText="Source platform of the data in the knowledge graph."
                            >
                              <Select
                                onChange={(value) => onChangeKnowledgeGraphFilters('analysisType', value)}
                                style={{ width: '100%' }}
                                value={knowledgeGraphFilters.analysisType}
                                allowClear
                                mode="multiple"
                                maxTagCount={2}
                                maxTagPlaceholder={(omittedValues) => (
                                  <Popover
                                    color="black"
                                    overlayClassName={Style.tagPopover}
                                    content={
                                      <div className={Style.tagWrapper}>
                                        {omittedValues.map(({ label }, id) => (
                                          <div className={Style.tagText} key={id}>
                                            {label}&nbsp;&nbsp;&nbsp;
                                            <CloseOutlined
                                              onClick={() => {
                                                changeKnowledgeGraphFilterFromPopover(
                                                  'analysisType',
                                                  label.toLowerCase()
                                                );
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    }
                                  >
                                    <span>+{omittedValues.length}</span>
                                  </Popover>
                                )}
                                showArrow
                              >
                                <Select.Option value={'news'}>{t('news_and_culture.news')}</Select.Option>
                                <Select.Option value={'twitter'}>{t('news_and_culture.twitter')}</Select.Option>
                                <Select.Option value={'facebook'}>{t('news_and_culture.facebook')}</Select.Option>
                                <Select.Option value={'instagram'}>{t('news_and_culture.instagram')}</Select.Option>
                                <Select.Option value={'threads'}>{t('news_and_culture.threads')}</Select.Option>
                                <Select.Option value={'tiktok'}>{t('news_and_culture.tiktok')}</Select.Option>
                              </Select>
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label={t('news_and_culture.nodes')}
                              style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                              value={
                                knowledgeGraphFilters.render_type === KNOWLEDGE_GRAPH_RENDERS.COMMUNITIES.value
                                  ? undefined
                                  : knowledgeGraphFilters.nodes
                              }
                              helpText={t('news_and_culture.nodes_help_text')}
                            >
                              <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                showArrow
                                onPopupScroll={handlePopupScroll}
                                value={
                                  knowledgeGraphFilters.render_type === KNOWLEDGE_GRAPH_RENDERS.COMMUNITIES.value
                                    ? undefined
                                    : knowledgeGraphFilters.nodes
                                }
                                onChange={(value) => onChangeKnowledgeGraphFilters('nodes', value)}
                                onSearch={handleSearch}
                                maxTagCount={1}
                                maxTagPlaceholder={(omittedValues) => (
                                  <Popover
                                    color="black"
                                    overlayClassName={Style.tagPopover}
                                    content={
                                      <div className={Style.tagWrapper}>
                                        {omittedValues.map(({ label }, id) => (
                                          <div className={Style.tagText} key={id}>
                                            {label}&nbsp;&nbsp;&nbsp;
                                            <CloseOutlined
                                              onClick={() => {
                                                changeKnowledgeGraphFilterFromPopover('nodes', label);
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    }
                                  >
                                    <span>+{omittedValues.length}</span>
                                  </Popover>
                                )}
                                allowClear
                                disabled={
                                  knowledgeGraphLoading ||
                                  knowledgeGraphFilters.render_type === KNOWLEDGE_GRAPH_RENDERS.COMMUNITIES.value
                                }
                                onDropdownVisibleChange={handleDropdownVisibleChange}
                                notFoundContent={<></>}
                                dropdownRender={(menu) => {
                                  return (
                                    <>
                                      {nodes.length === 0 && !loadingNodesOptions ? (
                                        <Empty description="No data found" style={{ padding: '0.75rem 0' }} />
                                      ) : (
                                        <>
                                          {menu}
                                          {loadingNodesOptions && (
                                            <div
                                              style={{
                                                textAlign: 'center',
                                                padding: '8px',
                                              }}
                                            >
                                              <Spin size="small" />
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </>
                                  );
                                }}
                              >
                                {nodes?.map((elem, index) => {
                                  return (
                                    <Select.Option key={index} value={elem}>
                                      {elem}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label={t('news_and_culture.communities')}
                              style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                              value={knowledgeGraphFilters.communities || undefined}
                              helpText={t('news_and_culture.communities_help_text')}
                            >
                              <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                showArrow
                                value={knowledgeGraphFilters.communities || undefined}
                                onChange={(value) => onChangeKnowledgeGraphFilters('communities', value)}
                                maxTagCount={1}
                                maxTagPlaceholder={(omittedValues) => (
                                  <Popover
                                    color="black"
                                    overlayClassName={Style.tagPopover}
                                    content={
                                      <div className={Style.tagWrapper}>
                                        {omittedValues.map(({ label }, id) => (
                                          <div className={Style.tagText} key={id}>
                                            {label}&nbsp;&nbsp;&nbsp;
                                            <CloseOutlined
                                              onClick={() => {
                                                changeKnowledgeGraphFilterFromPopover('communities', label);
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    }
                                  >
                                    <span>+{omittedValues.length}</span>
                                  </Popover>
                                )}
                                allowClear
                                disabled={knowledgeGraphLoading}
                              >
                                {knowledgeGraphCommunities?.map((elem, idx) => {
                                  return (
                                    <Select.Option key={idx} value={elem}>
                                      {elem}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </FloatLabel>
                          </Col>
                          <Col span={24} style={{ height: '54px' }}>
                            <FloatLabel
                              label={t('news_and_culture.date_range')}
                              style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                              value={[moment(dates?.start_date), moment(dates?.end_date)]}
                            >
                              <RangePicker
                                onChange={(value) => onChangeKnowledgeGraphFilters('date_range', value)}
                                value={[dayjs(dates?.start_date), dayjs(dates?.end_date)]}
                                style={{ width: '100%' }}
                                allowClear={false}
                                disabled={knowledgeGraphLoading}
                                disabledDate={(val) => {
                                  if (val?.isBefore(dayjs(datesForDatePicker?.start_date).startOf('d'))) {
                                    return true;
                                  }
                                  if (val?.isAfter(dayjs().endOf('d'))) {
                                    return true;
                                  }
                                  return false;
                                }}
                              />
                            </FloatLabel>
                          </Col>
                          <Col span={24} style={{ paddingTop: '10px' }}>
                            Top{' '}
                            {knowledgeGraphFilters.render_type === KNOWLEDGE_GRAPH_RENDERS.CLUSTER.value
                              ? 'Clusters'
                              : 'Communities'}
                            : {knowledgeGraphFilters.topPercentage}%
                            <Slider
                              className={Style.sliderCommunities} // Adjust the class name if needed
                              min={1} // Set the minimum value
                              max={100} // Set the maximum value, adjust as needed
                              value={knowledgeGraphFilters.topPercentage}
                              onChange={(value) => {
                                onChangeKnowledgeGraphFilters('topPercentage', value);
                              }}
                              step={1} // Set the step value
                              marks={{
                                1: '1%',
                                50: '50%',
                                100: '100%',
                              }}
                              tooltip={{
                                formatter: (value) => `${value}%`,
                              }}
                            />
                          </Col>
                          <Col span={24}>
                            <Tooltip
                              className={`${Style.tooltipDegreeOfSeparation} ${
                                knowledgeGraphFilters?.nodes?.length === 0 ||
                                knowledgeGraphFilters?.render_type === KNOWLEDGE_GRAPH_RENDERS.COMMUNITIES.value
                                  ? 'disabled'
                                  : ''
                              }`}
                              overlayClassName={Style.tooltipDegreeOfSeparation}
                              title={
                                knowledgeGraphFilters?.nodes?.length === 0 ||
                                knowledgeGraphFilters?.render_type === KNOWLEDGE_GRAPH_RENDERS.COMMUNITIES.value
                                  ? 'Applicable only when filtering on Nodes.'
                                  : ''
                              }
                              placement="top"
                            >
                              {t('news_and_culture.degree_of_separation')}: {knowledgeGraphFilters.degreeOfSeparation}
                              <Slider
                                min={1} // Set the minimum value
                                max={10} // Set the maximum value, adjust as needed
                                value={knowledgeGraphFilters.degreeOfSeparation}
                                onChange={(value) => {
                                  onChangeKnowledgeGraphFilters('degreeOfSeparation', value);
                                }}
                                step={1} // Set the step value
                                marks={{
                                  1: '1',
                                  10: '10',
                                }}
                                disabled={
                                  knowledgeGraphFilters?.nodes?.length === 0 ||
                                  knowledgeGraphFilters?.render_type === KNOWLEDGE_GRAPH_RENDERS.COMMUNITIES.value
                                }
                              />
                            </Tooltip>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label={t('news_and_culture.render_type')}
                              style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                              value={knowledgeGraphFilters.render_type}
                              helpText={t('news_and_culture.render_type_help_text')}
                            >
                              <Select
                                onChange={(value) => onChangeKnowledgeGraphFilters('render_type', value)}
                                style={{ width: '100%' }}
                                value={knowledgeGraphFilters.render_type}
                                showArrow
                              >
                                {Object.values(KNOWLEDGE_GRAPH_RENDERS).map((elem, idx) => {
                                  return (
                                    <Select.Option key={idx} value={elem.value} label={elem.name}>
                                      <Popover
                                        placement="right"
                                        overlayClassName={Style.savedFilterPopover}
                                        zIndex={9999}
                                        mouseEnterDelay={0.5}
                                        content={<div className={Style.popoverContent}>{elem.description}</div>}
                                      >
                                        <div className={Style.savedFilterOptions}>{elem.name}</div>
                                      </Popover>
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </FloatLabel>
                          </Col>
                          {/* <Col span={24}>
                                <Collapse className="analysisCollapse">
                                  <Collapse.Panel header="Advanced Settings" key="1">
                                    
                                  </Collapse.Panel>
                                </Collapse>
                              </Col> */}
                          {/* </>
                          ) : (
                            <Input
                              // placeholder="Search"
                              onChange={(e) => onChangeKnowledgeGraphFilters("custom_search_query", e.target.value)}
                              value={knowledgeGraphFilters.custom_search_query}
                            />
                          )} */}
                        </Col>
                        <Col span={'50%'} className="col-wrapper">
                          <Col span={24}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                height: '2rem',
                              }}
                            >
                              <div>
                                {/* // <Switch
                                //   style={{ width: "10%" }}
                                //   checked={useAISearch}
                                //   onChange={() => {
                                //     setUseAISearch(!useAISearch);
                                //     onChangeKnowledgeGraphFilters("custom_search_query", null);
                                //   }}
                                // /> */}
                              </div>
                            </div>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label={t('news_and_culture.search_connections')}
                              style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                              value={knowledgeGraphFilters.custom_search_query}
                              helpTextPopoverStyle={{ maxWidth: '300px', fontSize: '11px' }}
                              helpText={
                                <div>
                                  {t('news_and_culture.search_connection_help_text')}
                                  <br />
                                  <strong>{t('news_and_culture.example')}:</strong>{' '}
                                  <div style={{ padding: '0px 10px' }}>
                                    <div
                                      style={{
                                        color: 'green',
                                        padding: '10px 0px',
                                        width: '100%',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {t('news_and_culture.music_concerts')}
                                    </div>
                                    <div>{t('news_and_culture.music_concerts_help_text')}</div>
                                    <hr style={{ opacity: '0.3' }} />
                                    <div
                                      style={{
                                        color: 'red',
                                        padding: '10px 0px',
                                        width: '100%',
                                        textAlign: 'center',
                                      }}
                                    >
                                      {t('news_and_culture.music_concerts_trending')}
                                    </div>
                                    <div>{t('news_and_culture.music_concerts_trending_help_text')}</div>
                                  </div>
                                </div>
                              }
                            >
                              <Input
                                onChange={(e) => onChangeKnowledgeGraphFilters('custom_search_query', e.target.value)}
                                value={knowledgeGraphFilters.custom_search_query}
                              />
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label={t('news_and_culture.exclude_nodes')}
                              style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                              value={
                                knowledgeGraphFilters.render_type === KNOWLEDGE_GRAPH_RENDERS.COMMUNITIES.value
                                  ? undefined
                                  : knowledgeGraphFilters.exclude_nodes
                              }
                              helpText={t('news_and_culture.exclude_nodes_help_text')}
                            >
                              <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                showArrow
                                onPopupScroll={handlePopupScroll}
                                value={
                                  knowledgeGraphFilters.render_type === KNOWLEDGE_GRAPH_RENDERS.COMMUNITIES.value
                                    ? undefined
                                    : knowledgeGraphFilters.exclude_nodes
                                }
                                onChange={(value) => onChangeKnowledgeGraphFilters('exclude_nodes', value)}
                                onSearch={handleSearch}
                                maxTagCount={1}
                                maxTagPlaceholder={(omittedValues) => (
                                  <Popover
                                    color="black"
                                    overlayClassName={Style.tagPopover}
                                    content={
                                      <div className={Style.tagWrapper}>
                                        {omittedValues.map(({ label }, id) => (
                                          <div className={Style.tagText} key={id}>
                                            {label}&nbsp;&nbsp;&nbsp;
                                            <CloseOutlined
                                              onClick={() => {
                                                changeKnowledgeGraphFilterFromPopover('exclude_nodes', label);
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    }
                                  >
                                    <span>+{omittedValues.length}</span>
                                  </Popover>
                                )}
                                allowClear
                                disabled={
                                  knowledgeGraphLoading ||
                                  knowledgeGraphFilters.render_type === KNOWLEDGE_GRAPH_RENDERS.COMMUNITIES.value
                                }
                                onDropdownVisibleChange={handleDropdownVisibleChange}
                                notFoundContent={<></>}
                                dropdownRender={(menu) => {
                                  return (
                                    <>
                                      {nodes.length === 0 && !loadingNodesOptions ? (
                                        <Empty description="No data found" style={{ padding: '0.75rem 0' }} />
                                      ) : (
                                        <>
                                          {menu}
                                          {loadingNodesOptions && (
                                            <div
                                              style={{
                                                textAlign: 'center',
                                                padding: '8px',
                                              }}
                                            >
                                              <Spin size="small" />
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </>
                                  );
                                }}
                              >
                                {nodes?.map((elem, index) => {
                                  return (
                                    <Select.Option key={index} value={elem}>
                                      {elem}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label={t('news_and_culture.handles')}
                              style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                              value={knowledgeGraphFilters.handles}
                              helpText={t('news_and_culture.handles_help_text')}
                            >
                              <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                showArrow
                                onPopupScroll={handlePopupScrollHandle}
                                value={knowledgeGraphFilters.handles}
                                onChange={(value) => onChangeKnowledgeGraphFilters('handles', value)}
                                onSearch={handleSearchHandle}
                                maxTagCount={1}
                                maxTagPlaceholder={(omittedValues) => (
                                  <Popover
                                    color="black"
                                    overlayClassName={Style.tagPopover}
                                    content={
                                      <div className={Style.tagWrapper}>
                                        {omittedValues.map(({ label }, id) => (
                                          <div className={Style.tagText} key={id}>
                                            {label}&nbsp;&nbsp;&nbsp;
                                            <CloseOutlined
                                              onClick={() => {
                                                changeKnowledgeGraphFilterFromPopover('handles', label);
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    }
                                  >
                                    <span>+{omittedValues.length}</span>
                                  </Popover>
                                )}
                                allowClear
                                disabled={knowledgeGraphLoading}
                                onDropdownVisibleChange={handleDropdownVisibleChangeHandle}
                                notFoundContent={<></>}
                                dropdownRender={(menu) => {
                                  return (
                                    <>
                                      {handles.length === 0 && !loadingHandlesOptions ? (
                                        <Empty description="No data found" style={{ padding: '0.75rem 0' }} />
                                      ) : (
                                        <>
                                          {menu}
                                          {loadingHandlesOptions && (
                                            <div
                                              style={{
                                                textAlign: 'center',
                                                padding: '8px',
                                              }}
                                            >
                                              <Spin size="small" />
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </>
                                  );
                                }}
                              >
                                {handles?.map((elem, index) => {
                                  return (
                                    <Select.Option key={index} value={elem}>
                                      {elem}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label={t('news_and_culture.exclude_handles')}
                              style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                              value={knowledgeGraphFilters.exclude_handles}
                              helpText={t('news_and_culture.exclude_handles_help_text')}
                            >
                              <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                showArrow
                                onPopupScroll={handlePopupScrollHandle}
                                value={knowledgeGraphFilters.exclude_handles}
                                onChange={(value) => onChangeKnowledgeGraphFilters('exclude_handles', value)}
                                onSearch={handleSearchHandle}
                                maxTagCount={1}
                                maxTagPlaceholder={(omittedValues) => (
                                  <Popover
                                    color="black"
                                    overlayClassName={Style.tagPopover}
                                    content={
                                      <div className={Style.tagWrapper}>
                                        {omittedValues.map(({ label }, id) => (
                                          <div className={Style.tagText} key={id}>
                                            {label}&nbsp;&nbsp;&nbsp;
                                            <CloseOutlined
                                              onClick={() => {
                                                changeKnowledgeGraphFilterFromPopover('exclude_handles', label);
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    }
                                  >
                                    <span>+{omittedValues.length}</span>
                                  </Popover>
                                )}
                                allowClear
                                disabled={knowledgeGraphLoading}
                                onDropdownVisibleChange={handleDropdownVisibleChangeHandle}
                                notFoundContent={<></>}
                                dropdownRender={(menu) => {
                                  return (
                                    <>
                                      {handles.length === 0 && !loadingHandlesOptions ? (
                                        <Empty description="No data found" style={{ padding: '0.75rem 0' }} />
                                      ) : (
                                        <>
                                          {menu}
                                          {loadingHandlesOptions && (
                                            <div
                                              style={{
                                                textAlign: 'center',
                                                padding: '8px',
                                              }}
                                            >
                                              <Spin size="small" />
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </>
                                  );
                                }}
                              >
                                {handles?.map((elem, index) => {
                                  return (
                                    <Select.Option key={index} value={elem}>
                                      {elem}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            {t('news_and_culture.relevance_score')}:
                            <Popover
                              content={t('news_and_culture.relevance_score_help_text')}
                              overlayStyle={{ maxWidth: '250px', fontSize: '11px' }}
                            >
                              &nbsp;
                              <InfoCircleOutlined style={{ fontSize: '12px' }} />
                            </Popover>
                            <Slider
                              className={Style.slider} // Adjust the class name if needed
                              min={0} // Set the minimum value
                              max={10} // Set the maximum value, adjust as needed
                              value={knowledgeGraphFilters.importance_score || 0}
                              onChange={(value) => {
                                onChangeKnowledgeGraphFilters('importance_score', value);
                              }}
                              step={1} // Set the step value
                              marks={{
                                0: '0',
                                10: '10',
                              }}
                            />
                          </Col>
                          <Col span={24}>
                            {t('news_and_culture.opportunity_score')}:
                            <Popover
                              content={t('news_and_culture.opportunity_score_content')}
                              overlayStyle={{ maxWidth: '250px', fontSize: '11px' }}
                            >
                              &nbsp;
                              <InfoCircleOutlined style={{ fontSize: '12px' }} />
                            </Popover>
                            <Slider
                              min={0} // Set the minimum value
                              max={10} // Set the maximum value, adjust as needed
                              value={knowledgeGraphFilters.opportunity_score || 0}
                              onChange={(value) => {
                                onChangeKnowledgeGraphFilters('opportunity_score', value);
                              }}
                              step={1} // Set the step value
                              marks={{
                                0: '0',
                                10: '10',
                              }}
                            />
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label="Section"
                              style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                              value={knowledgeGraphFilters.analysisQueries || undefined}
                            >
                              <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                showArrow
                                value={knowledgeGraphFilters.analysisQueries || undefined}
                                onChange={(value) => onChangeKnowledgeGraphFilters('analysisQueries', value)}
                                allowClear
                                maxTagCount={1}
                                maxTagPlaceholder={(omittedValues) => (
                                  <Popover
                                    color="black"
                                    overlayClassName={Style.tagPopover}
                                    content={
                                      <div className={Style.tagWrapper}>
                                        {omittedValues.map(({ label, value }, id) => (
                                          <div className={Style.tagText} key={id}>
                                            {label}&nbsp;&nbsp;&nbsp;
                                            <CloseOutlined
                                              onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                changeKnowledgeGraphFilterFromPopover('analysisQueries', value);
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    }
                                  >
                                    <span>+{omittedValues.length}</span>
                                  </Popover>
                                )}
                                disabled={knowledgeGraphLoading}
                                optionFilterProp="children"
                              >
                                {analysisOverview?.section_data?.map((section) => (
                                  <Select.Option key={section?.section_id} value={section?.section_id}>
                                    {section?.section_name}
                                  </Select.Option>
                                ))}
                              </Select>
                            </FloatLabel>
                          </Col>
                        </Col>
                        <Col span={'50%'} className="col-wrapper">
                          <Col span={24}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                height: '2rem',
                              }}
                            >
                              <div>
                                {showMoreOptions && <CloseCircleOutlined onClick={() => setShowMoreOptions(false)} />}
                                {/* // <Switch
                                //   style={{ width: "10%" }}
                                //   checked={useAISearch}
                                //   onChange={() => {
                                //     setUseAISearch(!useAISearch);
                                //     onChangeKnowledgeGraphFilters("custom_search_query", null);
                                //   }}
                                // /> */}
                              </div>
                            </div>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label={t('news_and_culture.data_type')}
                              style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                              value={knowledgeGraphFilters.post_types}
                              helpText={t('news_and_culture.data_type_help_text')}
                            >
                              <Select
                                onChange={(value) => onChangeKnowledgeGraphFilters('post_types', value)}
                                style={{ width: '100%' }}
                                value={knowledgeGraphFilters.post_types}
                                allowClear
                                mode="multiple"
                                maxTagCount={2}
                                maxTagPlaceholder={(omittedValues) => (
                                  <Popover
                                    color="black"
                                    overlayClassName={Style.tagPopover}
                                    content={
                                      <div className={Style.tagWrapper}>
                                        {omittedValues.map(({ label }, id) => (
                                          <div className={Style.tagText} key={id}>
                                            {label}&nbsp;&nbsp;&nbsp;
                                            <CloseOutlined
                                              onClick={() => {
                                                changeKnowledgeGraphFilterFromPopover(
                                                  'post_types',
                                                  label.toLowerCase()
                                                );
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    }
                                  >
                                    <span>+{omittedValues.length}</span>
                                  </Popover>
                                )}
                                showArrow
                              >
                                <Select.Option value={'POST'}>{t('news_and_culture.post')}</Select.Option>
                                <Select.Option value={'COMMENT'}>{t('news_and_culture.comment')}</Select.Option>
                              </Select>
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label={t('news_and_culture.author_overlap')}
                              style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                              value={knowledgeGraphFilters.author_overlap_analysis_ids || undefined}
                              helpText={t('news_and_culture.author_overlap_help_text')}
                            >
                              <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                showArrow
                                value={knowledgeGraphFilters.author_overlap_analysis_ids || undefined}
                                onChange={(value) =>
                                  onChangeKnowledgeGraphFilters('author_overlap_analysis_ids', value)
                                }
                                maxTagCount={1}
                                maxTagPlaceholder={(omittedValues) => (
                                  <Popover
                                    color="black"
                                    overlayClassName={Style.tagPopover}
                                    content={
                                      <div className={Style.tagWrapper}>
                                        {omittedValues.map(({ label, value }, id) => (
                                          <div className={Style.tagText} key={id}>
                                            {label}&nbsp;&nbsp;&nbsp;
                                            <CloseOutlined
                                              onClick={() => {
                                                changeKnowledgeGraphFilterFromPopover(
                                                  'author_overlap_analysis_ids',
                                                  value
                                                );
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    }
                                  >
                                    <span>+{omittedValues.length}</span>
                                  </Popover>
                                )}
                                allowClear
                                disabled={knowledgeGraphLoading}
                                optionFilterProp="children"
                              >
                                {analysisListData?.map((elem, idx) => {
                                  return (
                                    <Select.Option key={idx} value={elem.id}>
                                      {elem.name}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            <Tooltip
                              className={`${Style.tooltipDegreeOfSeparation} ${
                                !knowledgeGraphFilters?.author_overlap_analysis_ids ||
                                knowledgeGraphFilters?.author_overlap_analysis_ids?.length === 0
                                  ? 'disabled'
                                  : ''
                              }`}
                              overlayClassName={Style.tooltipDegreeOfSeparation}
                              title={
                                !knowledgeGraphFilters?.author_overlap_analysis_ids ||
                                knowledgeGraphFilters?.author_overlap_analysis_ids?.length === 0
                                  ? t('news_and_culture.applicable_only_when_filtering_on_author_overlap')
                                  : ''
                              }
                              placement="top"
                            >
                              <div className={Style.emptyBox}>
                                {t('news_and_culture.show_analysis')}{' '}
                                <Popover
                                  content={t('news_and_culture.show_analysis_help_text')}
                                  overlayStyle={{ maxWidth: '250px', fontSize: '11px', zIndex: 9999 }}
                                >
                                  &nbsp;
                                  <InfoCircleOutlined style={{ fontSize: '12px' }} />
                                </Popover>
                                :&nbsp;&nbsp;
                                <Switch
                                  style={{ width: '10%' }}
                                  disabled={
                                    !knowledgeGraphFilters?.author_overlap_analysis_ids ||
                                    knowledgeGraphFilters?.author_overlap_analysis_ids?.length === 0
                                  }
                                  checked={knowledgeGraphFilters.include_author_overlap_analysis_data || false}
                                  onChange={(value) =>
                                    onChangeKnowledgeGraphFilters('include_author_overlap_analysis_data', value)
                                  }
                                />
                              </div>
                            </Tooltip>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label={t('news_and_culture.likes_range')}
                              style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                              helpText={t('news_and_culture.likes_range_help_text')}
                              value={1}
                            >
                              <div className={Style.inputNumberRangeWrapper}>
                                <InputNumber
                                  className={Style.inputNumberRange}
                                  min={0}
                                  onChange={(value) => debouncedOnChangeKnowledgeGraphFilters('minLikes', value)}
                                  style={{ width: '50%' }}
                                  placeholder="Min"
                                  value={knowledgeGraphFilters.minLikes}
                                />
                                <InputNumber
                                  className={Style.inputNumberRange}
                                  min={0}
                                  onChange={(value) => debouncedOnChangeKnowledgeGraphFilters('maxLikes', value)}
                                  style={{ width: '50%' }}
                                  placeholder="Max"
                                  value={knowledgeGraphFilters.maxLikes}
                                />
                              </div>
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label={t('news_and_culture.comments_range')}
                              style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                              helpText={t('news_and_culture.comments_range_help_text')}
                              value={1}
                            >
                              <div className={Style.inputNumberRangeWrapper}>
                                <InputNumber
                                  className={Style.inputNumberRange}
                                  min={0}
                                  onChange={(value) => debouncedOnChangeKnowledgeGraphFilters('minComments', value)}
                                  style={{ width: '50%' }}
                                  placeholder="Min"
                                  value={knowledgeGraphFilters.minComments}
                                />
                                <InputNumber
                                  className={Style.inputNumberRange}
                                  min={0}
                                  onChange={(value) => debouncedOnChangeKnowledgeGraphFilters('maxComments', value)}
                                  style={{ width: '50%' }}
                                  placeholder="Max"
                                  value={knowledgeGraphFilters.maxComments}
                                />
                              </div>
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label={t('news_and_culture.views_range')}
                              style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                              helpText={t('news_and_culture.views_range_help_text')}
                              value={1}
                            >
                              <div className={Style.inputNumberRangeWrapper}>
                                <InputNumber
                                  className={Style.inputNumberRange}
                                  min={0}
                                  onChange={(value) => debouncedOnChangeKnowledgeGraphFilters('minViews', value)}
                                  style={{ width: '50%' }}
                                  placeholder="Min"
                                  value={knowledgeGraphFilters.minViews}
                                />
                                <InputNumber
                                  className={Style.inputNumberRange}
                                  min={0}
                                  onChange={(value) => debouncedOnChangeKnowledgeGraphFilters('maxViews', value)}
                                  style={{ width: '50%' }}
                                  placeholder="Max"
                                  value={knowledgeGraphFilters.maxViews}
                                />
                              </div>
                            </FloatLabel>
                          </Col>
                          <Col span={24}>
                            <FloatLabel
                              label={t('news_and_culture.followers_range')}
                              style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                              helpText={t('news_and_culture.followers_range_help_text')}
                              value={1}
                            >
                              <div className={Style.inputNumberRangeWrapper}>
                                <InputNumber
                                  className={Style.inputNumberRange}
                                  min={0}
                                  onChange={(value) => debouncedOnChangeKnowledgeGraphFilters('minFollowers', value)}
                                  style={{ width: '50%' }}
                                  placeholder="Min"
                                  value={knowledgeGraphFilters.minFollowers}
                                />
                                <InputNumber
                                  className={Style.inputNumberRange}
                                  min={0}
                                  onChange={(value) => debouncedOnChangeKnowledgeGraphFilters('maxFollowers', value)}
                                  style={{ width: '50%' }}
                                  placeholder="Max"
                                  value={knowledgeGraphFilters.maxFollowers}
                                />
                              </div>
                            </FloatLabel>
                          </Col>
                        </Col>
                        {/* </div> */}
                      </div>
                    </div>
                    <Col span={24}>
                      <PrimaryButton
                        className={Style.applyButton}
                        loading={knowledgeGraphLoading}
                        style={{ marginTop: '1rem', width: '100%' }}
                        onClick={() => applyFiltersKnowledgeGraph()}
                      >
                        {t('news_and_culture.apply_filters')}
                      </PrimaryButton>
                    </Col>
                    <Col span={24}>
                      <PrimaryButton
                        className={Style.applyButton}
                        style={{ marginTop: '1rem', width: '100%' }}
                        onClick={() => setShowSaveFilterModal(true)}
                      >
                        {t('news_and_culture.save_filters')}
                      </PrimaryButton>
                    </Col>
                  </Row>
                </Col>
                {memoizedKnowledgeGraph && memoizedKnowledgeGraph.nodes.length > 0 ? (
                  <Col span={20}>
                    <Row>
                      <Col span={24}>
                        <ConditionalRender
                          shouldRender={!!(memoizedKnowledgeGraph && memoizedKnowledgeGraph.nodes.length > 0)}
                        >
                          <div className={Style.summarizeButton}>
                            <DateSlider
                              minDate={dates?.start_date}
                              maxDate={dates?.end_date}
                              handleSliderChange={handleSliderChange}
                              selectedDate={moment.utc(selectedDate)}
                            />
                            <FloatLabel
                              label={t('news_and_culture.color_by')}
                              style={{ backgroundColor: 'var(--color-charcoal-surface-2)' }}
                              value={colorBy}
                              helpText={t('news_and_culture.color_by_help_text')}
                            >
                              <Select
                                style={{ width: '130px' }}
                                // placeholder="Color by"
                                onChange={(val) => setColorBy(val)}
                                value={colorBy}
                              >
                                {' '}
                                {memoizedKnowledgeGraph?.color_by_mapper &&
                                  Object.keys(memoizedKnowledgeGraph?.color_by_mapper)?.map((key) => (
                                    <Select.Option className={Style.colorByOption} key={key} value={key}>
                                      {memoizedKnowledgeGraph?.color_by_mapper[key].name}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </FloatLabel>
                            <PrimaryButton onClick={() => setSummaryDrawer(true)}>
                              {t('news_and_culture.analyze_trends')}
                            </PrimaryButton>
                          </div>
                        </ConditionalRender>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <KnowledgeGraphComponent
                          nodes={memoizedKnowledgeGraph.nodes}
                          links={memoizedKnowledgeGraph.links}
                          categories={memoizedKnowledgeGraph.categories}
                          onNodeClick={handleNodeClick}
                          onLinkClick={handleLinkClick}
                          knowledgeGraphChanged={knowledgeGraphChanged}
                        />
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col span={20}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '50px',
                        width: '100%',
                        height: '700px',
                      }}
                    >
                      <Empty description="No Data" />
                    </div>
                  </Col>
                )}
              </Row>
            </>
            {knowledgeGraphStats && Object.values(knowledgeGraphStats).length !== 0 && (
              <KnowledgeGraphComponentStats
                knowledgeGraphStats={knowledgeGraphStats}
                handleNodePerformanceNodeClick={handleNodePerformanceNodeClick}
                setNodesFilter={handleNodesFilter}
                setCommunitiesFilter={handleCommunitiesFilter}
                jobTriggers={analysisOverview?.job_triggers}
                analysisId={analysisId}
                containerRef={containerRef}
                analysisOverview={analysisOverview}
                analysisListData={analysisListData}
              />
            )}
          </>
        )}
        <ArticlesTableDrawer
          selectedHandles={selectedHandles}
          selectedWord={selectedWord}
          selectedLink={selectedLink}
          visible={drawerVisible && (selectedHandles || selectedWord || selectedLink)}
          analysisId={analysisId}
          onClose={() => setDrawerVisible(false)}
          postTypes={knowledgeGraphFilters?.post_types}
          secondary_analysis_ids={knowledgeGraphFilters?.author_overlap_analysis_ids}
          start_date={appliedDates?.start_date}
          end_date={appliedDates?.end_date}
        />
        <Modal
          width={600}
          open={showSaveFilterModal}
          destroyOnClose
          footer={null}
          onCancel={() => setShowSaveFilterModal(false)}
          maskClosable={true}
        >
          <div style={{ fontSize: '1.2rem', fontWeight: '600', marginBottom: '1rem' }}>
            <SaveFilled style={{ fontSize: '1.25rem' }} /> {t('news_and_culture.save_applied_filter')}
          </div>
          <Form
            ref={formRef}
            layout="vertical"
            onFinish={(val) => saveKnowledgeGraphSavedFilters(analysisId, val.filter_name, val.filter_description)}
          >
            <Form.Item
              name="filter_name"
              label={t('news_and_culture.filter_name')}
              rules={[{ required: true, message: 'Please enter a filter name' }]}
            >
              <Input placeholder={t('news_and_culture.filter_name_placeholder')} />
            </Form.Item>
            <Form.Item name="filter_description" label={t('news_and_culture.filter_description')}>
              <Input.TextArea placeholder={t('news_and_culture.filter_description_placeholder')} />
            </Form.Item>
          </Form>
          <span>
            {t('news_and_culture.filter_snapshot')} <b>{moment(maxJobTriggeredAt).format('LLL')}</b>.
          </span>
          <br />
          <Row justify="end">
            <SecondaryButton className="small" loading={loading} onClick={() => formRef.current.submit()}>
              {t('news_and_culture.save_filter')}
            </SecondaryButton>
          </Row>
        </Modal>
      </Card>
      <Drawer
        open={summaryDrawer}
        onClose={() => setSummaryDrawer(false)}
        title={t('news_and_culture.trend_summary')}
        placement="right"
        width="80%"
        destroyOnClose
        maskClosable={true}
      >
        <KnowledgeGraphSummary
          knowledgeGraphFilters={knowledgeGraphFilters}
          analysisId={analysisId}
          constructKnowledgeGraphFilters={constructKnowledgeGraphFilters}
        />
      </Drawer>
    </div>
  );
};

export default AnalysisViewContent;
