import { Col, Form, Input, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'src/components/basic';
import useMediaplanCloneForm from './useMediaplanCloneForm';

const MediaPlanCloneForm = ({ closeCloneModal, data }) => {
  const { formRef, handleClone, loading } = useMediaplanCloneForm({ data, closeCloneModal });
  const { t } = useTranslation();

  return (
    <div>
      <Form ref={formRef} onFinish={handleClone}>
        <Form.Item
          name="cloned_mediaplan_name"
          rules={[
            {
              required: true,
              message: t('mediaplan.please_enter_name'),
            },
          ]}
        >
          <Input placeholder={t('mediaplan.enter_name')} />
        </Form.Item>
        <Row justify="space-between" style={{ marginTop: '5rem' }}>
          <Col>
            <SecondaryButton disabled={loading} className="small" comfy onClick={closeCloneModal}>
              {t('cancel')}
            </SecondaryButton>
          </Col>

          <Col>
            <PrimaryButton loading={loading} className="small" onClick={() => formRef.current.submit()}>
              {data?.scenario_id ? t('mediaplan.clone_scenario') : t('mediaplan.clone_mediaplan')}
              {}
            </PrimaryButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default MediaPlanCloneForm;
