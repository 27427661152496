import { Col, Collapse, Row, Table } from 'antd';
import React from 'react';
import { formatString, numberWithCommas } from 'src/lib/utils';
import useAdGroupCollapse from './useAdGroupCollapse';

const AdGroupCollapse = ({
  adGroup,
  idx,
  keywordData,
  setkeywordData,
  setSelectedData,
  selectedData,
  reviewScreen,
}) => {
  const { columns } = useAdGroupCollapse({
    keywordData,
    setkeywordData,
    adGroup,
    setSelectedData,
    selectedData,
    reviewScreen,
  });
  return (
    <Collapse className={'regularCollapse'} accordion={true} defaultActiveKey={[0]}>
      <Collapse.Panel
        header={
          <b>
            <Row justify="space-between">
              <Col>{formatString(adGroup?.name)}</Col>
              <Col>
                Min Budget: ${numberWithCommas(Math.round(adGroup?.min_spend))} | Max Budget: $
                {numberWithCommas(Math.round(adGroup?.max_spend))} &nbsp;&nbsp;
              </Col>
            </Row>
          </b>
        }
        key={idx}
      >
        <Table
          className="dark-table"
          columns={columns || []}
          dataSource={keywordData[adGroup?.name] || []}
          scroll={{ y: 300 }}
          pagination={{
            pageSize: 5,
            showSizeChanger: false,
            position: ['bottomCenter'],
            hideOnSinglePage: true,
          }}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export default AdGroupCollapse;
