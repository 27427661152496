import { Col, Form, InputNumber, Row, Cascader } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomGptService } from '../services/customGptServices';
import './createForm.less';
import Style from './createForm.style';

export default function LlmConfig({ metaData, setChosenLLM, cascaderOptions, selectedValue, setSelectedValue }) {
  const { t } = useTranslation();
  return (
    <div className="chooseLlmModelContainer">
      <div className={Style.advancedSettingHeader}>{t('client_space.llm_configuration')}</div>
      <Row className={Style.CustomCascaderWrapper} gutter={[30, 0]} style={{ margin: '0 6px' }}>
        {/* Model Selection using Cascader */}
        <Col xl={12} xs={24}>
          <Form.Item
            name={['llm_config', 'model_name']}
            label={t('client_space.model_name')}
            rules={CustomGptService.validations({ required: true }, 'Model name')}
          >
            <Cascader
              options={cascaderOptions}
              placeholder="Select a model"
              value={selectedValue}
              onChange={(value) => {
                if (value.length === 2) {
                  const selectedModel = value[1];
                  setChosenLLM(selectedModel);
                  setSelectedValue(value);
                }
              }}
              displayRender={(label) => label[label.length - 1]}
              dropdownRender={(menus) => <div className="modelLLmDropdown">{menus}</div>}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item
            name={['llm_config', 'temperature']}
            label={t('client_space.temprature')}
            rules={CustomGptService.validations(
              { required: true, max_length_number: true },
              'Temperature',
              metaData?.llm_config?.temperature
            )}
          >
            <InputNumber max={1} min={0} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item
            name={['llm_config', 'max_tokens']}
            rules={CustomGptService.validations(
              { required: true, max_length_number: true },
              'Maximum output words',
              metaData?.llm_config?.max_tokens
            )}
            label={t('client_space.tokens')}
          >
            <InputNumber max={4096} min={0} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
