import { Col, Form, Input, Row, Skeleton, Upload, Card, Typography } from 'antd';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { SecondaryButton } from 'src/components/basic';
import ConditionalRender from 'src/components/conditionalRender';
import { CustomGptService } from 'src/modules/CustomGpt/services/customGptServices';
import useGithubTool from '../hooks/useGithubTool';
import Style from './githubToolStyle';
import CommonStyle from 'src/modules/CustomGpt/components/capabilities.style.js';
import { GithubOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
export default function GithubTool({ toolData, configData, id, aiAppData, updateCustomApp, onClose, reload }) {
  const {
    formRef,
    loading,
    handleDataUpload,
    initialValues,
    configureLoading,
    handlePemFileUpload,
    isAnalyzingPem,
    pemFile,
    removePemFile,
    validatePemFile,
    apiPemFileName,
  } = useGithubTool({
    id,
    configData,
    updateCustomApp,
    onClose,
    toolData,
    reload,
    aiAppData,
  });
  const { t } = useTranslation();
  return (
    <div>
      <ConditionalRender shouldRender={loading}>
        <Skeleton paragraph={{ rows: 8 }} />
      </ConditionalRender>
      <ConditionalRender shouldRender={!loading}>
        <div className={Style.form}>
          <Form
            onFinish={(val) => {
              handleDataUpload(val);
            }}
            requiredMark={false}
            ref={formRef}
            layout="vertical"
            initialValues={initialValues}
          >
            <Row gutter={24} align="middle" style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px' }}>
              <Col style={{ width: '120px' }}>
                <Typography.Text style={{ color: 'var(--color-gale-gray)' }} strong>
                  Name
                </Typography.Text>
              </Col>
              <Col flex="auto">
                <Typography.Text style={{ fontStyle: 'italic', color: 'var(--color-white)' }}>
                  {initialValues.name || 'No name provided'}
                </Typography.Text>
              </Col>
            </Row>
            <Row gutter={24} align="middle" style={{ display: 'flex', alignItems: 'center', paddingBottom: '40px' }}>
              <Col style={{ width: '120px' }}>
                <Typography.Text style={{ color: 'var(--color-gale-gray)' }} strong>
                  Description
                </Typography.Text>
              </Col>
              <Col flex="auto">
                <Typography.Text style={{ fontStyle: 'italic', color: 'var(--color-white)' }}>
                  {initialValues.description || 'No description provided'}
                </Typography.Text>
              </Col>
            </Row>
            <Row gutter={[30, 0]}>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="github_app_id"
                  label={'App ID*'}
                  rules={CustomGptService.validations(
                    { required: true, max_length_string: true },
                    'App ID',
                    configData?.config?.github_app_id
                  )}
                >
                  <Input placeholder={configData?.config?.github_app_id?.val || 'eg. 12344'} />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="github_repository"
                  label="Repository*"
                  rules={[
                    { required: true, message: 'Repository is required' }, // "Repository is required" if empty
                    {
                      pattern: /^[a-zA-Z0-9_-]+\/[a-zA-Z0-9_-]+$/,
                      message: 'Repository format must be {username}/{repo-name}', // Validate format
                    },
                  ]}
                >
                  <TextArea
                    className={Style.textAreaStyle}
                    autoSize={{ minRows: 1, maxRows: 2 }}
                    placeholder="{username}/{repo-name}"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={24}>
                <Form.Item
                  name="agent_prompt"
                  label="Agent Prompt*"
                  rules={CustomGptService.validations(
                    { required: true, max_length_string: true },
                    'agent_prompt',
                    configData?.config?.agent_prompt
                  )}
                >
                  <TextArea autoSize={{ minRows: 5, maxRows: 7 }} />
                </Form.Item>
              </Col>
              {apiPemFileName && (
                <Col xs={24} lg={24}>
                  <p className={Style.existingFileText}>Existing file: {apiPemFileName}</p>
                </Col>
              )}
              <Col xs={24} lg={24} className={Style.uploadFileContainer}>
                {pemFile ? (
                  <>
                    <p className={Style.selectedFileTitle}>Selected GitHub Private Key File</p>
                    <Card className={Style.uploadedFileCard}>
                      <span className={Style.fileName}>{pemFile.name}</span>
                      <DeleteOutlined className={Style.deleteIcon} onClick={removePemFile} />
                    </Card>
                  </>
                ) : (
                  <Form.Item
                    name="pemFile"
                    label="Upload GitHub Private Key (.pem)*"
                    rules={[{ validator: validatePemFile }]}
                  >
                    <Upload.Dragger
                      beforeUpload={handlePemFileUpload}
                      accept=".pem"
                      showUploadList={false}
                      maxCount={1}
                      className={Style.uploadDragger}
                    >
                      <p className="ant-upload-drag-icon">
                        <GithubOutlined className={Style.uploadIcon} />
                      </p>
                      {isAnalyzingPem ? (
                        <div className={Style.analyzingText}>
                          <UploadOutlined />
                          <p>{t('client_space.pem_uploading_prompt')}</p>
                        </div>
                      ) : (
                        <p className="ant-upload-text">{t('client_space.pem_upload_prompt')}</p>
                      )}
                    </Upload.Dragger>
                  </Form.Item>
                )}
              </Col>
              <Col xs={24} lg={24} className={Style.uploadGuideContainer}>
                <p className={Style.guideText}>
                  {t('client_space.create_github_prompt')}{' '}
                  <a
                    href="https://docs.github.com/en/apps/creating-github-apps/registering-a-github-app/registering-a-github-app#registering-a-github-app"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={Style.guideLink}
                  >
                    {t('client_space.github_capability_help_link')}
                  </a>
                  .
                </p>
                <p className={Style.guideText}>
                  {t('client_space.create_secretkey_github_prompt')}{' '}
                  <a
                    href="https://docs.github.com/en/apps/creating-github-apps/authenticating-with-a-github-app/managing-private-keys-for-github-apps#generating-private-keys"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={Style.guideLink}
                  >
                    {t('client_space.github_capability_help_link')}
                  </a>
                  .
                </p>
              </Col>
            </Row>
            <Row justify="end" className={CommonStyle.globalSave}>
              <SecondaryButton loading={configureLoading} onClick={() => formRef.current.submit()}>
                Save
              </SecondaryButton>
            </Row>
          </Form>
        </div>
      </ConditionalRender>
    </div>
  );
}
