import { Col, Row, Slider } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FloatInput } from 'src/components/floatingLabelTextAndSelectBox';
import { numberWithCommas } from 'src/lib/utils';

const AudienceConstraint = ({ audienceConstraint, mediaPlanData, onChangeAudienceConstraint, reviewScreen }) => {
  const { t } = useTranslation();
  const formatter = (value) => `$${numberWithCommas(value)}`;
  return (
    <div className="channel-constraint">
      <Row className="constraint">
        <Col className="constraint-header" span={7}>
          {t('mediaplan.audience')}
        </Col>
        <Col className="constraint-header" span={17}>
          {t('mediaplan.budget_min_max')}
        </Col>
      </Row>
      {audienceConstraint?.map((audience, idx) => {
        let range = [audience.min_spend, audience.max_spend];
        return (
          <Row align="middle" style={{ marginLeft: '8px', marginBottom: '10px' }} key={idx}>
            <Col span={7}>{audience.audience_name}</Col>
            <Col span={17}>
              <div style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
                <FloatInput
                  onChange={(val) => onChangeAudienceConstraint('min', idx, val)}
                  addonBefore="$"
                  placeholder="Min spend"
                  disabled={reviewScreen}
                  value={range[0]}
                  formatter={(value) => `${numberWithCommas(value)}`}
                />
                <Slider
                  style={{ width: '85%' }}
                  range
                  value={range}
                  disabled={reviewScreen}
                  min={0}
                  tooltip={{
                    formatter,
                  }}
                  max={mediaPlanData?.budget}
                  step={1}
                  onChange={(value) => {
                    onChangeAudienceConstraint('range', idx, value);
                  }}
                  marks={{
                    [0]: `$${numberWithCommas(0)}`,
                    [mediaPlanData?.budget]: `$${numberWithCommas(mediaPlanData?.budget)}`,
                  }}
                />
                <FloatInput
                  onChange={(val) => onChangeAudienceConstraint('max', idx, val)}
                  addonBefore="$"
                  placeholder="Max spend"
                  disabled={reviewScreen}
                  value={range[1]}
                  formatter={(value) => `${numberWithCommas(value)}`}
                />
              </div>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default AudienceConstraint;
