import { notification } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { getErrorMessageFromResponse } from 'src/lib/utils';

const SemMaxAggressiveness = [
  { data: 'LOW', value: 1 },
  { data: 'MEDIUM', value: 2 },
  { data: 'HIGH', value: 3 },
];

const useSemConfiguration = ({ data, updateApps, elem }) => {
  const [loading, setLoading] = useState(true);
  const [configureLoading, setConfigureLoading] = useState(false);

  const [semConfigData, setSemConfigData] = useState({
    keywords: [],
    brand_keyword: [],
    competitor_keyword: [],
    local_keyword: [],
    brand_max_aggressiveness: 3,
    nb_max_aggressiveness: 1,
    brand_ctr_factor: 1,
    nb_ctr_factor: 1,
    brand_cpc_factor: 10,
    nb_cpc_factor: 10,
    top_keyword_percentage: 80,
    kpi_factor: true,
    include_bing_value: true,
    forecast_by_adgroup: true,
  });
  const formRef = useRef();

  const fetchData = async () => {
    try {
      setLoading(true);
      let tempData = data[elem];
      if (tempData) {
        if (tempData?.app_config?.sem_config) {
          let tempSemConfig = {
            ...semConfigData,
            ...tempData?.app_config?.sem_config,
            brand_cpc_factor: tempData?.app_config?.sem_config?.brand_cpc_factor
              ? tempData?.app_config?.sem_config?.brand_cpc_factor * 100
              : 0,
            nb_cpc_factor: tempData?.app_config?.sem_config?.nb_cpc_factor
              ? tempData?.app_config?.sem_config?.nb_cpc_factor * 100
              : 0,
            top_keyword_percentage: tempData?.app_config?.sem_config?.top_keyword_percentage
              ? tempData?.app_config?.sem_config?.top_keyword_percentage * 100
              : 0,
          };
          setSemConfigData(tempSemConfig);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 3,
        placement: 'bottomRight',
      });
    }
  };

  const saveSemConfig = async (val) => {
    try {
      setConfigureLoading(true);
      const payload = { ...val };
      payload.brand_cpc_factor = val.brand_cpc_factor / 100;
      payload.nb_cpc_factor = val.nb_cpc_factor / 100;
      payload.top_keyword_percentage = val.top_keyword_percentage / 100;
      let appConfigData = data?.[elem]?.app_config;
      let finalPayload = {};
      if (appConfigData) {
        finalPayload = { ...appConfigData, sem_config: payload };
      } else {
        finalPayload = {
          sem_config: payload,
        };
      }
      await updateApps(elem, 'config', finalPayload);
      setConfigureLoading(false);
    } catch (error) {
      setConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 3,
        placement: 'bottomRight',
      });
    }
  };

  const onChangeSemConfig = (key, value) => {
    setSemConfigData((prev) => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    loading,
    SemMaxAggressiveness,
    semConfigData,
    onChangeSemConfig,
    saveSemConfig,
    configureLoading,
    formRef,
  };
};

export default useSemConfiguration;
