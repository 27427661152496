import { Tooltip } from 'antd';
import React from 'react';

function CardTooltip({ title, children }) {
  return (
    <Tooltip
      placement="bottom"
      overlayStyle={{ border: '1px solid #fff', padding: '10px', borderRadius: '5px', backgroundColor: '#000' }}
      title={title}
    >
      {children}
    </Tooltip>
  );
}
export default CardTooltip;
