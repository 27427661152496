import { notification } from 'antd';
import { useRef, useState } from 'react';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import mediaPlanService from '../../../services/mediaPlanService';

const useMediaplanCloneForm = ({ data, closeCloneModal }) => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleClone = async (val) => {
    try {
      setLoading(true);
      let payload;
      if (data?.scenario_id) {
        payload = {
          scenario_id: data?.scenario_id,
          scenario_name: val?.cloned_mediaplan_name,
        };
        await mediaPlanService.cloneScenario(payload);
        notification.success({
          message: 'Scenario cloned successfully',
          duration: 3,
          placement: 'bottomRight',
        });
      } else {
        payload = {
          mediaplan_id: data?.mediaplan_id,
          mediaplan_name: val?.cloned_mediaplan_name,
        };
        await mediaPlanService.cloneMediaPlan(payload);
        notification.success({
          message: 'Mediaplan cloned successfully',
          duration: 3,
          placement: 'bottomRight',
        });
      }
      await closeCloneModal();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 3,
        placement: 'bottomRight',
      });
    }
  };

  return {
    formRef,
    handleClone,
    loading,
  };
};

export default useMediaplanCloneForm;
