import { notification } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import appUrls from 'src/constants/appUrls';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { SEM_STATUS } from '../../../constants/constants';
import mediaPlanService from '../../../services/mediaPlanService';

const useSemProcess = ({ mediaPlanData }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [keywordData, setKeywordData] = useState({});
  const [configureLoading, setConfigureLoading] = useState(false);
  const [skipSemLoading, setSkipSemLoading] = useState(false);
  const [forecastData, setForecastData] = useState(false);
  const [selectedData, setSelectedData] = useState({
    unselect: [],
    select: [],
  });
  const [semConfigurationModal, setSemConfigurationModal] = useState(false);
  const { clientId } = useParams();
  const history = useHistory();

  const scenarioId = mediaPlanData?.scenario_id;
  const apiInterval = useRef(null);

  const fetchSemData = async () => {
    try {
      const res = await mediaPlanService.getSemData(scenarioId);
      if (res.data.status === 'CREATED') {
        if (apiInterval.current) {
          clearInterval(apiInterval.current);
        }
        let keywordArr = {};
        if (res?.data?.ad_group_data) {
          for (const adGroup of res.data.ad_group_data) {
            const keywords = await mediaPlanService.getSemKeyword(adGroup.id);
            keywordArr = { ...keywordArr, [adGroup.name]: keywords.data };
          }
        }
        setKeywordData(keywordArr);
      }
      if (SEM_STATUS.includes(res.data.status)) {
        setData(res.data);
        setLoading(false);
        if (apiInterval.current) {
          clearInterval(apiInterval.current);
        }
        apiInterval.current = setInterval(() => {
          fetchSemData();
        }, 5000);
      } else {
        if (res.data.status === 'ERROR') {
          setForecastData(true);
        }
        setData(res.data);
        setLoading(false);
      }
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      setLoading(false);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };

  const keywordUpdate = async () => {
    try {
      setConfigureLoading(true);
      let payload = {
        ...selectedData,
      };
      await mediaPlanService.updateSemKeyword(payload);
      notification.success({
        message: 'Keywords updated successfully',
        duration: 5,
        placement: 'bottomRight',
      });
      let semTriggerPayload = {
        scenario_id: mediaPlanData?.scenario_id,
      };
      await mediaPlanService.semRetrigger(semTriggerPayload);
      await fetchSemData();
      setSelectedData({
        unselect: [],
        select: [],
      });
      setForecastData(false);
      setConfigureLoading(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };

  const triggerScenario = async () => {
    try {
      setConfigureLoading(true);
      let payloadForReccomendation = {
        scenario_id: mediaPlanData?.scenario_id,
        skip_sem_constraint: false,
      };
      await mediaPlanService.postMediaPlanScenarioRecommendation(payloadForReccomendation);
      setConfigureLoading(false);
      history.push(appUrls.MEDIA_PLAN_LISTING(clientId));
    } catch (error) {
      setConfigureLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };

  const skipSem = async () => {
    try {
      setSkipSemLoading(true);
      let payloadForReccomendation = {
        scenario_id: mediaPlanData?.scenario_id,
        skip_sem_constraint: true,
      };
      await mediaPlanService.postMediaPlanScenarioRecommendation(payloadForReccomendation);
      setSkipSemLoading(false);
      history.push(appUrls.MEDIA_PLAN_LISTING(clientId));
    } catch (error) {
      setSkipSemLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    fetchSemData();
    return () => {
      clearInterval(apiInterval.current);
    };
  }, []);

  return {
    data,
    loading,
    keywordData,
    setKeywordData,
    setSelectedData,
    selectedData,
    keywordUpdate,
    setForecastData,
    forecastData,
    triggerScenario,
    configureLoading,
    skipSem,
    skipSemLoading,
    semConfigurationModal,
    setSemConfigurationModal,
    fetchSemData,
  };
};

export default useSemProcess;
