import { CopyOutlined, SnippetsOutlined } from '@ant-design/icons';
import { Drawer, Input, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QueryBuilder from 'react-querybuilder';
import { SecondaryButton } from 'src/components/basic';
import ConditionalRender from 'src/components/conditionalRender';
import LoadingSpinner from 'src/components/loadingSpinner';
import useQueryBuilderHook from '../hooks/useQueryBuilderHook';
import Style from '../NewsAndCulture.style';
import InstagramQueryBuilder from './instagramQueryBuilder/InstagramQueryBuilder';

const CreateAnalysisQuery = ({
  drawerVisible,
  setDrawerVisible,
  queryData,
  addUpdateQuery,
  addUpdateQueryInstagram,
  setInstaFormData,
  instaFormData,
  UpdatedAnalysisCategories,
}) => {
  const {
    handleQueryChange,
    query,
    loading,
    numberOfPosts,
    setNumberOfPosts,
    handleCopy,
    handlePaste,
    queryFieldHeader,
    queryTitle,
    updatedFields,
    postsRefreshWindow,
    numberOfComments,
    setPostsRefreshWindow,
    setNumberOfComments,
  } = useQueryBuilderHook({
    queryData,
    setInstaFormData,
    instaFormData,
    UpdatedAnalysisCategories,
  });
  const { t } = useTranslation();

  return queryData ? (
    <Drawer
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{t('news_and_culture.query_builder')}</span>
          <div>
            <Tooltip title="Copy Query" zIndex={10000000}>
              <CopyOutlined onClick={handleCopy} style={{ marginRight: '10px', cursor: 'pointer', fontSize: '18px' }} />
            </Tooltip>
            <Tooltip title="Paste Query" zIndex={10000000}>
              <SnippetsOutlined onClick={handlePaste} style={{ cursor: 'pointer', fontSize: '18px' }} />
            </Tooltip>
          </div>
        </div>
      }
      width="90%"
      placement="right"
      onClose={() => {
        setDrawerVisible(false);
      }}
      open={drawerVisible}
      closable={true}
      className={Style.createAnalysisQueryContainer}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <ConditionalRender shouldRender={loading}>
        <div style={{ display: 'flex', alignItems: 'center', height: '100vh' }}>
          <LoadingSpinner centered={true} />
        </div>
      </ConditionalRender>
      <ConditionalRender shouldRender={!loading}>
        <div className={Style.createAnalysisQueryContainer}></div>
        <h3 style={{ color: 'white', fontWeight: 'bold' }}>{queryTitle}</h3>

        <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 160px)', scrollbarWidth: 'none' }}>
          {queryFieldHeader !== 'instagram' ? (
            <div className={Style.queryBuilder}>
              <div className={Style.queryOptions}>
                <div className={Style.articleNumContainer}>
                  <label className={Style.articleNumLabel}>{t('news_and_culture.num_of_posts')}: </label>
                  <Input
                    placeholder={t('news_and_culture.num_of_posts_placeholder')}
                    className={Style.articleNumInput}
                    onChange={(e) => setNumberOfPosts(e.target.value)}
                    value={numberOfPosts || ''}
                  />
                </div>
                {queryFieldHeader === 'twitter' && (
                  <div className={Style.articleNumContainer}>
                    <label className={Style.articleNumLabel}>{t('news_and_culture.num_of_comments')}: </label>
                    <Input
                      placeholder={t('news_and_culture.num_of_comments_placeholder')}
                      className={Style.articleNumInput}
                      onChange={(e) => setNumberOfComments(e.target.value)}
                      value={numberOfComments || ''}
                    />
                  </div>
                )}
                {['twitter', 'tiktok'].includes(queryFieldHeader) && (
                  <div className={Style.articleNumContainer}>
                    <label className={Style.articleNumLabel}>{t('news_and_culture.posts_refresh_window')}: </label>
                    <Input
                      placeholder={t('news_and_culture.posts_refresh_window_placeholder')}
                      className={Style.articleNumInput}
                      onChange={(e) => setPostsRefreshWindow(e.target.value)}
                      value={postsRefreshWindow || ''}
                    />
                  </div>
                )}
              </div>
              {query.rules.length > 0 ? (
                <QueryBuilder
                  defaultQuery={query}
                  query={query}
                  fields={updatedFields}
                  onQueryChange={(val) => {
                    handleQueryChange(val);
                  }}
                  showNotToggle
                />
              ) : (
                <QueryBuilder
                  defaultQuery={queryData.query.json_query}
                  fields={updatedFields}
                  onQueryChange={(val) => {
                    handleQueryChange(val);
                  }}
                  showNotToggle
                  resetOnOperatorChange={false}
                />
              )}
            </div>
          ) : (
            <InstagramQueryBuilder instaFormData={instaFormData} setInstaFormData={setInstaFormData} />
          )}
        </div>
        <div className={Style.queryFooterBtns}>
          <SecondaryButton
            className={'small'}
            onClick={() => {
              queryFieldHeader === 'instagram'
                ? addUpdateQueryInstagram(instaFormData, queryData)
                : addUpdateQuery(query, queryData, numberOfPosts, postsRefreshWindow, numberOfComments);
            }}
          >
            {t('news_and_culture.validate')}
          </SecondaryButton>
          <SecondaryButton className={'small'} onClick={() => setDrawerVisible(false)}>
            {t('cancel')}
          </SecondaryButton>
        </div>
      </ConditionalRender>
    </Drawer>
  ) : null;
};

export default CreateAnalysisQuery;
