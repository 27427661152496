import { InfoCircleOutlined, LeftOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Empty, Input, Pagination, Row, Skeleton, Tooltip, Select } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/basic';
import GlobalTag from 'src/components/globalTag/GlobalTag';
import { USE_LISTING_STATUS_COLOR } from 'src/components/globalTag/globalTagConstant';
import appUrls from 'src/constants/appUrls';
import Style from '../NewsAndCulture.style';
import useListingScreen from '../hooks/useListingScreen';
import { formatStatus } from '../utils';

const { Search } = Input;
const { RangePicker } = DatePicker;

const ListingScreen = () => {
  const {
    analysisList,
    currentPage,
    pageSize,
    searchTerm,
    dateRange,
    setCurrentPage,
    handleSearch,
    handleDateFilter,
    loading,
    totalPages,
    clientId,
    history,
    handleCardClick,
    canCreate,
    handleStatusFilter,
    statusFilter,
    options,
  } = useListingScreen();
  const { t } = useTranslation();

  return (
    <div className={Style.cardListingContainer}>
      <div className={Style.contentParent}>
        <div className={Style.content}>
          <div className={Style.filterContainerParent}>
            <div className={Style.filterContainer}>
              <Search
                placeholder={t('news_and_culture.search_analysis')}
                onSearch={handleSearch}
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                className={Style.searchInputGroup}
              />
              <Select
                placeholder="Filter by Status"
                onChange={handleStatusFilter}
                value={statusFilter}
                allowClear
                style={{ width: '25%' }}
              >
                {options.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    <GlobalTag color={USE_LISTING_STATUS_COLOR[option.value]}>{formatStatus(option.value)}</GlobalTag>
                  </Select.Option>
                ))}
              </Select>
              <RangePicker onChange={handleDateFilter} value={dateRange} className={Style.rangePicker} />
            </div>
            <PrimaryButton
              className={Style.createButton}
              onClick={() => history.push(appUrls.CREATE_LISTING_SCREEN(clientId))}
              // View only access for time being
              disabled={canCreate ? false : true}
            >
              {t('create')}
              <PlusOutlined />
            </PrimaryButton>
          </div>
          <Row gutter={[16, 16]} className={Style.cardRow}>
            {loading ? (
              Array.from({ length: 12 }).map((_, index) => (
                <Col key={index} className={Style.cardCol} xs={24} sm={12} md={8} lg={6}>
                  <Card className={Style.analysisCard}>
                    <Skeleton active />
                  </Card>
                </Col>
              ))
            ) : analysisList?.length > 0 ? (
              analysisList?.map((item, index) => (
                <Col key={index} className={Style.cardCol} xs={24} sm={12} md={8} lg={6}>
                  <Card
                    title={item.name}
                    className={Style.analysisCard}
                    onClick={() => handleCardClick(item)}
                    style={{
                      cursor: item?.can_view_analysis ? 'pointer' : 'default',
                    }}
                    hoverable={item?.can_view_analysis}
                    extra={
                      item?.can_view_analysis && (
                        <Tooltip title="View Query" placement="top">
                          <InfoCircleOutlined
                            onClick={(e) => {
                              e.stopPropagation();
                              history.push(appUrls.UPDATE_LISTING_SCREEN(clientId, item?.id), { mode: 'Done' });
                            }}
                          />
                        </Tooltip>
                      )
                    }
                  >
                    <p>
                      {t('news_and_culture.status')}:{' '}
                      <GlobalTag color={USE_LISTING_STATUS_COLOR[item?.derived_state]}>
                        {formatStatus(item?.derived_state)}
                      </GlobalTag>
                    </p>
                    <p>
                      {t('news_and_culture.created_on')}: {dayjs(item?.created_on).format('MM-DD-YYYY')}
                    </p>
                  </Card>
                </Col>
              ))
            ) : (
              <div className={Style.emptyContainer}>
                <Empty description={t('news_and_culture.no_data_found')} />
              </div>
            )}
          </Row>
        </div>
        {totalPages > 1 && (
          <div className={Style.paginationContainer}>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={totalPages}
              onChange={(page) => setCurrentPage(page)}
              showSizeChanger={false}
              itemRender={(page, type, originalElement) => {
                if (type === 'prev') {
                  return <Button type="text" icon={<LeftOutlined />} />;
                }
                if (type === 'next') {
                  return <Button type="text" icon={<RightOutlined />} />;
                }
                if (type === 'page') {
                  return (
                    <Button
                      type="text"
                      style={{
                        fontWeight: page === currentPage ? 'bold' : 'normal',
                      }}
                    >
                      {page}
                    </Button>
                  );
                }
                return originalElement;
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ListingScreen;
