import { useCallback, useState } from 'react';
import { BEHAVIOUR, DEMOGRAPHIC, FIRST_PARTY, OPERATOR_IS } from '../../../../constants/queryBuilderConstants';
import React from 'react';
import { Checkbox, Collapse } from 'antd';

const useBrowseFeatureHook = ({
  fieldDirectory,
  browsedSelectedItems,
  setBrowsedSelectedItems,
  ruleType,
  searchQuery,
  setSearchQuery,
}) => {
  const [selectedField, setSelectedField] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingItem, setEditingItem] = useState(null);

  const handleCheckboxChange = useCallback(
    (checked, field) => {
      if (checked) {
        if (ruleType === BEHAVIOUR) {
          setBrowsedSelectedItems((prevSelected) => [
            ...prevSelected,
            {
              field: field.name,
              operator: OPERATOR_IS,
              value: { value: '1', value_description: 'True' },
              field_readable_name: field.name,
              behaviour_name: field.name,
              rule_type: ruleType,
            },
          ]);
        } else {
          setSelectedField(field);
          setModalVisible(true);
          setEditingItem(null);
        }
      } else {
        setBrowsedSelectedItems((prevSelected) => prevSelected.filter((item) => item.field !== field.name));
      }
    },
    [setBrowsedSelectedItems]
  );

  const handleRemoveItem = useCallback(
    (fieldName) => {
      setBrowsedSelectedItems((prevSelected) => prevSelected.filter((item) => item.field !== fieldName));
    },
    [setBrowsedSelectedItems]
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleEditItem = (item) => {
    const editItem = fieldDirectory.find((field) => field.name === item.field);
    setEditingItem(item);
    setSelectedField(editItem);
    setModalVisible(true);
  };

  const filterTree = (node, query) => {
    return Object.keys(node).reduce((acc, key) => {
      const isLeaf = Object.keys(node[key]).length === 0;

      if (key.toLowerCase().includes(query.toLowerCase())) {
        acc[key] = node[key];
      } else if (!isLeaf) {
        const filteredChild = filterTree(node[key], query);
        if (Object.keys(filteredChild).length > 0) {
          acc[key] = filteredChild;
        }
      }

      return acc;
    }, {});
  };

  const filterFieldList = (list, query) => {
    return list.filter((field) => field.label.toLowerCase().includes(query.toLowerCase()));
  };

  const filterGroupedByCategory = (grouped, query) => {
    return Object.keys(grouped).reduce((acc, category) => {
      const filteredFields = grouped[category].filter((field) =>
        field.label.toLowerCase().includes(query.toLowerCase())
      );
      if (filteredFields.length > 0) {
        acc[category] = filteredFields;
      }
      return acc;
    }, {});
  };

  const renderCheckbox = (item) => (
    <div key={item.id || item.name} className="tree-node">
      <Checkbox
        checked={browsedSelectedItems.some((selected) => selected.field === item.name)}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => handleCheckboxChange(e.target.checked, item)}
      >
        {item.label}
      </Checkbox>
    </div>
  );

  const renderTree = (node, path = '') => {
    return Object.keys(node).map((key) => {
      const currentPath = path ? `${path} > ${key}` : key;
      const isLeaf = Object.keys(node[key]).length === 0;

      return (
        <div key={currentPath} className="tree-node">
          {isLeaf ? (
            renderCheckbox({ name: key, label: key })
          ) : (
            <Collapse>
              <Collapse.Panel key={currentPath} header={key}>
                {renderTree(node[key], currentPath)}
              </Collapse.Panel>
            </Collapse>
          )}
        </div>
      );
    });
  };

  const renderFieldDirectoryList = (groupedFields) => {
    return Object.keys(groupedFields).map((key) => {
      const isLeaf = !Array.isArray(groupedFields[key]);

      return (
        <div key={`node-${key}`} className="tree-node">
          {isLeaf ? (
            renderCheckbox(groupedFields[key])
          ) : (
            <Collapse>
              <Collapse.Panel key={`panel-${key}`} header={key}>
                {renderFieldDirectoryList(groupedFields[key])}
              </Collapse.Panel>
            </Collapse>
          )}
        </div>
      );
    });
  };

  const renderFolderStructure = (filteredGroupedByCategory) => {
    return Object.keys(filteredGroupedByCategory).map((category) => (
      <Collapse key={category}>
        <Collapse.Panel key={category} header={category}>
          {filteredGroupedByCategory[category].map((item) => renderCheckbox(item))}
        </Collapse.Panel>
      </Collapse>
    ));
  };

  const getFilteredTree = () => {
    return ruleType === BEHAVIOUR ? filterTree(fieldDirectory, searchQuery) : null;
  };

  const getFilteredDemographicFields = () => {
    if (ruleType !== DEMOGRAPHIC) return null;

    const filteredFields = filterFieldList(fieldDirectory, searchQuery);
    const groupedByCategory = filteredFields.reduce((acc, field) => {
      const { featureCategory, secondLevelCategory, thirdLevelCategory } = field;

      if (featureCategory) {
        if (!acc[featureCategory]) acc[featureCategory] = [];
        if (secondLevelCategory) {
          if (!acc[featureCategory][secondLevelCategory]) acc[featureCategory][secondLevelCategory] = [];
          if (thirdLevelCategory) {
            if (!acc[featureCategory][secondLevelCategory][thirdLevelCategory])
              acc[featureCategory][secondLevelCategory][thirdLevelCategory] = [];
            acc[featureCategory][secondLevelCategory][thirdLevelCategory].push(field);
          } else {
            if (!Array.isArray(acc[featureCategory][secondLevelCategory])) {
              acc[featureCategory][secondLevelCategory] = [];
            }
            acc[featureCategory][secondLevelCategory].push(field);
          }
        } else {
          if (!Array.isArray(acc[featureCategory])) {
            acc[featureCategory] = [];
          }
          acc[featureCategory].push(field);
        }
      } else {
        acc.push(field);
      }

      return acc;
    }, {});

    return groupedByCategory;
  };

  const getGroupedByCategory = () => {
    return ruleType === FIRST_PARTY
      ? fieldDirectory.reduce((acc, field) => {
          if (!acc[field.featureCategory]) {
            acc[field.featureCategory] = [];
          }
          acc[field.featureCategory].push(field);
          return acc;
        }, {})
      : null;
  };

  const getFilteredGroupedByCategory = () => {
    const groupedByCategory = getGroupedByCategory();
    return ruleType !== BEHAVIOUR && ruleType !== DEMOGRAPHIC
      ? filterGroupedByCategory(groupedByCategory, searchQuery)
      : null;
  };

  const filteredTree = getFilteredTree();
  const filteredDemographicFields = getFilteredDemographicFields();
  const filteredGroupedByCategory = getFilteredGroupedByCategory();

  return {
    handleRemoveItem,
    handleSearchChange,
    filteredTree,
    filteredDemographicFields,
    filteredGroupedByCategory,
    renderTree,
    renderFieldDirectoryList,
    renderFolderStructure,
    selectedField,
    modalVisible,
    setModalVisible,
    setSelectedField,
    handleCheckboxChange,
    editingItem,
    setEditingItem,
    handleEditItem,
  };
};

export default useBrowseFeatureHook;
