import { css } from '@linaria/core';

export const createTaskContainer = css`
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  @media screen and (min-width: 768px) {
    padding: 3rem 54px;
  }
`;

export const contentParent = css`
  width: 80%;
  margin: 0 auto;
  background-color: var(--color-90-black);
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 8px var(--color-box-shadow);
`;

export const backButton = css`
  color: var(--color-60-gray);
  display: inline-flex;
  gap: 4px;
  cursor: pointer;
  margin-bottom: 36px;
  &:hover {
    color: var(--color-30-gray);
  }
`;

export const arrowIcon = css`
  font-size: 10px;
`;

export const stepContent = css`
  margin-top: 48px;
  background-color: var(--color-charcoal-surface-2);
  padding: 24px;
  border-radius: 8px;
`;

export const stepsStyle = css`
  .ant-steps-item-title {
    color: var(--color-60-gray) !important;
  }
  .ant-steps-item-wait .ant-steps-icon svg {
    fill: var(--color-60-gray) !important; /* Inactive step icon color */
  }
  .ant-steps-item-process .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
    color: var(--color-link) !important; /* Active step title color */
  }

  .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
    color: var(--color-link) !important; /* Completed step title color */
  }
`;

export const buttonContainer = css`
  display: flex;
  justify-content: space-between; /* Space buttons evenly */
  margin-top: 36px;
`;

export const modalConfirm = css`
  .ant-modal-confirm-btns {
    justify-content: space-between;
    .ant-btn.ant-btn {
      padding: 10px 21px !important;
      fontsize: 13px;
      min-width: 80px;
      width: fit-content !important;
    }
  }
`;
