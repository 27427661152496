import { PlusOutlined } from '@ant-design/icons';
import { Col, Drawer, Input, Modal, Row, Select, Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/basic';
import AppUrls from 'src/constants/appUrls';
import { formatString } from 'src/lib/utils';
import MediaPlanCloneForm from '../mediaplanCloneForm/MediaplanCloneForm';
import MediaplanUsersAndPermissons from '../MediaplanUsersAndPermissions/MediaplanUsersAndPermissons';
import './MediaPlansListing.less';
import useMediaPlanListing from './useMediaPlanListing';

const MediaPlansListing = () => {
  // const appContext = React.useContext(AppContext.Context);
  const { t } = useTranslation();
  const {
    loading,
    mediaData,
    history,
    clientId,
    cloneModal,
    setCloneModal,
    closeCloneModal,
    getColumns,
    filters,
    metaData,
    handleChange,
    searchTerm,
    handleStatusAndKpi,
    totalPageData,
    page,
    onPaginationChange,
    userLists,
    userPersmisionDrawer,
    closeUsersAndPermissionsDrawer,
  } = useMediaPlanListing();

  return (
    <div className="media-listing-parent">
      <div className="page-header-layout">
        <div>
          <h2>{t('mediaplan.media_plans')}</h2>
        </div>
        <div>
          <PrimaryButton
            className="small"
            comfy
            icon={<PlusOutlined />}
            onClick={() =>
              history.push({
                pathname: AppUrls.CREATE_MEDIA_PLAN(clientId),
              })
            }
          >
            {t('mediaplan.new_plan')}
          </PrimaryButton>
        </div>
      </div>
      <Row gutter={[20, 20]} style={{ marginTop: '16px' }}>
        <Col span={5}>
          <label style={{ color: '#fff', marginRight: '8px' }}>{t('mediaplan.search_by_name')}:</label>
          <Input
            value={searchTerm}
            onChange={handleChange}
            placeholder={t('mediaplan.search_by_name')}
            disabled={loading}
          />
        </Col>
        <Col span={5}>
          <label style={{ color: '#fff', marginRight: '8px' }}>{t('mediaplan.filter_by_status')}:</label>
          <Select
            value={filters.statusFilter}
            placeholder={t('mediaplan.select_status')}
            allowClear
            style={{ width: '100%' }}
            disabled={loading}
            onChange={(val) => handleStatusAndKpi(val, 'statusFilter')}
          >
            {metaData?.status?.map((kpi, id) => {
              return (
                <Select.Option key={id} value={kpi}>
                  {formatString(kpi)}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
        <Col span={5}>
          <label style={{ color: '#fff', marginRight: '8px' }}>{t('mediaplan.filter_by_kpi')}:</label>
          <Select
            onChange={(val) => handleStatusAndKpi(val, 'kpiFilter')}
            placeholder={t('mediaplan.select_by_kpi')}
            style={{ width: '100%' }}
            disabled={loading}
            value={filters.kpiFilter}
            allowClear
          >
            {metaData?.kpis?.map((kpi, id) => {
              return (
                <Select.Option key={id} value={kpi}>
                  {kpi}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
      </Row>
      <div>
        <div style={{ marginTop: 32 }}>
          <Table
            columns={getColumns()}
            dataSource={mediaData}
            className="dark-table-listing"
            loading={loading}
            rowKey="key"
            childrenColumnName="scenarios"
            pagination={{
              pageSize: 10,
              total: totalPageData,
              showSizeChanger: false,
              current: page,
              position: ['bottomCenter'],
              onChange: (page) => onPaginationChange(page),
            }}
          />
        </div>
      </div>
      <Modal
        maskClosable
        destroyOnClose
        open={cloneModal.visible}
        onCancel={() => setCloneModal({ visible: false, mediaplan_id: null, name: '' })}
        footer={null}
        title={cloneModal?.name}
      >
        <MediaPlanCloneForm closeCloneModal={closeCloneModal} data={cloneModal} />
      </Modal>
      <Drawer
        width="70%"
        title={userPersmisionDrawer?.data?.name}
        placement="right"
        destroyOnClose
        onClose={closeUsersAndPermissionsDrawer}
        open={userPersmisionDrawer.visible}
        maskClosable={false}
      >
        <MediaplanUsersAndPermissons data={userPersmisionDrawer?.data} userLists={userLists} />
      </Drawer>
    </div>
  );
};

export default MediaPlansListing;
