import React, { useContext } from 'react';
import { useEffect } from 'react';
import LoadingSpinner from 'src/components/loadingSpinner';
import { useOktaAuth } from '@okta/okta-react';
import AppUrls from 'src/constants/appUrls';
import AppContext from 'src/context/appContext';
import ApiUrls from 'src/constants/apiUrls';
import axios from 'axios';
import { Row } from 'antd';
import * as Sentry from '@sentry/browser';
import Cookie from 'src/lib/cookie';

const OktaLogin = ({ history }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const appContext = useContext(AppContext.Context);
  const code = urlParams.get('code');
  const { oktaAuth } = useOktaAuth();
  const transactionStorage = JSON.parse(sessionStorage.getItem('okta-transaction-storage'));
  let codeVerifier = '';
  if (transactionStorage && transactionStorage.codeVerifier) {
    codeVerifier = transactionStorage.codeVerifier;
  }

  async function fetchOktaLink() {
    try {
      oktaAuth.signInWithRedirect({ originalUri: '/login/' });
    } catch (error) {
      console.error(error);
    }
  }

  async function getTokens(code, codeVerifier) {
    try {
      const payload = {
        code: code,
        code_verifier: codeVerifier,
        ...(Cookie.get('source') && { source: Cookie.get('source').toUpperCase() }),
      };
      const res = await axios.post(ApiUrls.OKTA_LOGIN, payload);
      const token = res?.data?.data?.token;
      await appContext?.loginWithOkta(token);
      try {
        if (window.clarity) {
          window.clarity('identify', res?.data?.data?.user?.email);
          window.clarity('set', 'customUserId', { userId: res?.data?.data?.user?.id });
        }
        Sentry.setUser({ id: res?.data?.data?.user?.id });
      } catch (clarityError) {
        console.error('Clarity error:', clarityError);
      }
    } catch (error) {
      await signout();
    }
  }

  const signout = async () => {
    await oktaAuth.revokeAccessToken();
    oktaAuth
      .closeSession()
      .then((res) => {
        if (res) {
          history.push(AppUrls.AUTH_BASE);
        }
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    if (codeVerifier?.length && code?.length) {
      getTokens(code, codeVerifier);
    } else {
      fetchOktaLink();
    }
  }, []);

  return (
    <div style={{ height: '100%' }}>
      <Row style={{ height: '100%' }} justify="center" align="middle">
        <LoadingSpinner centered />
      </Row>
    </div>
  );
};

export default OktaLogin;
