import { ACQUISITION_CRM_LOOKALIKES, LOOKALIKE } from '../constants/constants';

export class ClusterToolServices {
  static formatCreateClusterConfigPayload(data, clientId, targetRule, clusterType) {
    const targetValue = data.target_values && data.target_values.length > 0 ? data.target_values[0] : {};
    const payload = {
      ...data,
      client_space_id: clientId,
      description: data.description !== undefined ? data.description : '',
      clusters_type: clusterType,
      target_source:
        clusterType === ACQUISITION_CRM_LOOKALIKES && (!targetValue.source || targetValue.source === '')
          ? LOOKALIKE
          : targetValue.source || null,
      target_feature:
        clusterType === ACQUISITION_CRM_LOOKALIKES && (!targetValue.feature_name || targetValue.feature_name === '')
          ? LOOKALIKE
          : targetValue.feature_name || null,
      target_category: targetValue.category || null,
      target_feature_query_data: targetRule && targetRule !== null ? targetRule : null,
    };
    return payload;
  }

  static formatInitialValuesFeatures(data) {
    let feature = [];
    let nonFeature = [];
    let nonActive = [];
    data.forEach((item) => {
      let obj = item;
      if (item?.is_invalid) {
        nonActive.push(obj);
      } else {
        if (item?.is_feature) {
          feature.push(obj);
        } else {
          nonFeature.push(obj);
        }
      }
    });
    return [...feature, ...nonFeature, ...nonActive];
  }
}
