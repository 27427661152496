import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import CreateClientApiServices from '../services/createClientSpaceApiServices';

const useKpiBenchmarksHook = ({ clientId }) => {
  const [kpiDetails, setKpiDetails] = useState([]);
  const [kpiConfigData, setKpiConfigData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  const showErrorMessage = (error) => {
    const { error_message, details, msg } = getErrorMessageFromResponse(error);
    const errorMessage = error_message || details?.errors || msg || 'Failed to create Audience';

    notification.error({
      key: details?.resource_name || 'Error',
      message: errorMessage,
      duration: 5,
      placement: 'bottomRight',
    });
  };

  const getKPIDetails = async () => {
    try {
      const response = await CreateClientApiServices.getKPIConfig(clientId);
      setKpiConfigData(response?.data?.kpi_config_data);
      setKpiDetails(response?.data?.kpi_details);
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const fetchData = async () => {
    setDataLoading(true);
    await getKPIDetails();
    setDataLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    kpiConfigData,
    kpiDetails,
    dataLoading,
    getKPIDetails,
  };
};

export default useKpiBenchmarksHook;
