import { notification } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import appUrls from 'src/constants/appUrls';
import { DEFAULT_CHART_GREY, PIE_CHART_COLORS } from 'src/constants/constants';
import { formatString, getErrorMessageFromResponse, numberWithCommas } from 'src/lib/utils';
import mediaPlanService from '../../../services/mediaPlanService';
const lightGreyColor = DEFAULT_CHART_GREY;

const colors = PIE_CHART_COLORS;

export default function useMediaplanOutput() {
  const [loading, setLoading] = useState(true);
  const [gantChartData, setGantChartData] = useState([]);
  const { clientId, scenarioId } = useParams();
  const [chanelDataOptions, setChannelDataOption] = useState({});
  const [audienceSpend, setAudienceSpend] = useState({});
  const [foreCast, setForeCast] = useState({});
  const [funnelStage, setFunnelStage] = useState({});
  const [spendByWeek, setSpendByWeek] = useState({});
  const [rawForecastData, setRawForecastData] = useState(null);
  const [semData, setSemData] = useState(null);
  const responseId = scenarioId;
  const history = useHistory();

  const { t } = useTranslation();

  const goBackToListing = () => {
    history.push(appUrls.MEDIA_PLAN_LISTING(clientId));
  };

  const funnelOption = (data) => {
    let keyIndicator;
    if (data.length > 0) {
      let audienceValue = data?.[0]?.audience_name_value?.[0];
      let audienceValueKeys = Object.keys(audienceValue);
      audienceValueKeys.forEach((key) => {
        if (key !== 'audience_name' && key !== 'budget') {
          keyIndicator = key;
        }
      });
    }
    let option = {
      darkMode: true,
      title: {
        text: `Total ${formatString(keyIndicator)} by Audience`,
        top: 6,
        textStyle: {
          fontWeight: 500,
          fontSize: '1.17em',
          color: lightGreyColor,
        },
        left: 10,
      },
      toolbox: {
        right: 20,
        top: 36,
        feature: {
          saveAsImage: { show: true, iconStyle: { normal: { borderColor: lightGreyColor } } },
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          return `<b>${params?.name}</b>: $${numberWithCommas(Math.round(params.value))}`;
        },
      },
      legend: {
        type: 'scroll',
        width: '100%',
        top: 40,
        left: 30,
        data: data.map((item) => item.audience_name),
        textStyle: {
          color: lightGreyColor,
        },
      },
      series: [
        {
          name: t('mediaplan.total_spend_by_funnel_stage'),
          type: 'pie',
          radius: '50%',
          data: data.map((item) => ({
            name: item?.audience_name,
            value: Math.round(item?.audience_name_value?.[0]?.[keyIndicator]) || 0,
          })),
          label: {
            formatter: function (params) {
              return `{bold|${params.name}}\n{value|$${numberWithCommas(Math.round(params.value))}}`;
            },
            color: lightGreyColor,
            rich: {
              bold: {
                fontWeight: 'bold',
                color: lightGreyColor,
              },
              value: {
                color: lightGreyColor,
              },
            },
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '18',
              fontWeight: 'bold',
            },
          },
          color: colors,
        },
      ],
    };
    return option;
  };

  const audienceOption = (data) => {
    let option = {
      darkMode: true,
      title: {
        text: t('mediaplan.total_spend_by_audience'),
        top: 6,
        textStyle: {
          fontWeight: 500,
          fontSize: '1.17em',
          color: lightGreyColor,
        },
        left: 10,
      },
      toolbox: {
        right: 20,
        top: 36,
        feature: {
          saveAsImage: { show: true, iconStyle: { normal: { borderColor: lightGreyColor } } },
        },
      },
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          return `${params?.name}<br>$${numberWithCommas(params.value)}`;
        },
      },
      legend: {
        type: 'scroll',
        width: '80%',
        top: 40,
        left: 30,
        data: data.map((item) => item.audience_name),
        textStyle: {
          color: lightGreyColor,
        },
      },
      series: [
        {
          name: t('mediaplan.total_spend_by_audience'),
          type: 'pie',
          radius: '50%',
          top: 10,
          data: data.map((item) => ({
            name: item?.audience_name,
            value: item?.audience_name_value?.[0]?.budget || 0,
          })),
          label: {
            formatter: function (params) {
              return `{bold|${params.name}}\n{value|$${numberWithCommas(params.value)}}`;
            },
            color: lightGreyColor,
            rich: {
              bold: {
                fontWeight: 'bold',
                color: lightGreyColor,
              },
              value: {
                color: lightGreyColor,
              },
            },
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '18',
              fontWeight: 'bold',
            },
          },
          color: colors,
        },
      ],
    };

    return option;
  };

  const getAudienceSpendData = async () => {
    try {
      const payload = {
        scenario_id: responseId,
        group_by: ['audience_name'],
      };
      const res = await mediaPlanService.getMediaPlanOutput(payload);
      const formmatedData = audienceOption(res.data);
      setAudienceSpend(formmatedData);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };

  const getForecastData = async () => {
    try {
      const payload = {
        scenario_id: responseId,
        overview: true,
        group_by: ['audience_name', 'channel'],
      };
      const res = await mediaPlanService.getMediaPlanOutput(payload);
      setRawForecastData(res.data);
      const formmatedData = audienceOption(res.data);
      setForeCast(formmatedData);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };

  const getFunnelSpendData = async () => {
    try {
      let payload = {
        scenario_id: responseId,
        group_by: ['audience_name'],
      };
      const res = await mediaPlanService.getMediaPlanOutput(payload);
      const formmatedData = funnelOption(res.data);
      setFunnelStage(formmatedData);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };

  const chanelOption = (data) => {
    return {
      darkMode: true,
      title: {
        text: t('mediaplan.total_spend_by_channel'),
        textStyle: {
          fontWeight: 500,
          fontSize: '1.17em',
          color: lightGreyColor,
        },
        left: 10,
      },
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          const { name, value } = params[0].data;
          return `<b>${name}</b>: $${numberWithCommas(value)}`;
        },
        axisPointer: {
          type: 'none',
        },
      },
      toolbox: {
        right: 20,
        feature: {
          saveAsImage: { show: true, iconStyle: { borderColor: lightGreyColor } },
        },
      },
      grid: {
        bottom: 130,
      },
      xAxis: {
        type: 'category',
        data: data.map((item) => item.channel),
        axisLabel: {
          color: lightGreyColor,
          interval: 0,
          hideOverlap: false,
          rotate: 45,
          formatter: (value) => {
            const len = value.length;
            const ellipsis = len > 24 ? '...' : '';
            return value.slice(0, 24) + ellipsis;
          },
        },
        splitLine: { show: false },
      },
      yAxis: {
        type: 'value',
        nameTextStyle: {
          color: lightGreyColor,
          padding: 60,
        },
        nameLocation: 'center',
        splitLine: {
          show: true,
          lineStyle: {
            color: '#444444',
          },
        },
        axisLabel: {
          color: lightGreyColor,
          formatter: (value) => `$${numberWithCommas(value)}`,
        },
      },
      series: [
        {
          name: 'Budget',
          type: 'bar',
          data: data.map((item) => ({
            name: item.channel,
            value: item.channel_value.length > 0 ? item.channel_value[0].budget : 0, // Now representing budget
            budget: item.channel_value.length > 0 ? item.channel_value[0].profit : 0, // Storing profit separately
          })),
          label: {
            show: false,
            position: 'top',
            formatter: ({ data }) => `$${numberWithCommas(data.value)}`,
            color: lightGreyColor,
          },
          emphasis: {
            label: {
              show: true,
              position: 'top',
              formatter: ({ data }) => `$${numberWithCommas(data.value)}`,
              color: lightGreyColor,
            },
          },
          barMaxWidth: 50,
          itemStyle: { color: colors[0] }, // Use the first color from the defined colors
        },
      ],
    };
  };

  const chanelSpend = async () => {
    try {
      const payload = {
        scenario_id: responseId,
        group_by: ['channel'],
      };
      const res = await mediaPlanService.getMediaPlanOutput(payload);
      const formmatedData = chanelOption(res.data);
      setChannelDataOption(formmatedData);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };

  const spendByWeekOption = (data) => {
    let granularityValue = 'Week';
    if (data.length > 0) {
      granularityValue = formatString(data[0].granularity);
    }
    const weekNumbers = data.map((item) => `${granularityValue} ${item.granularity_number}`);
    const audienceName = [
      ...new Set(data.flatMap((item) => item.granularity_number_value.map((goal) => goal.audience_name))),
    ];

    const seriesData = audienceName.map((goal, index) => ({
      name: goal,
      type: 'bar',
      stack: 'total',
      emphasis: { focus: 'series' },
      data: Array(weekNumbers.length).fill(0),
      barMaxWidth: 50,
      itemStyle: { color: colors[index % colors.length] },
    }));

    data.forEach((weekData, weekIndex) => {
      weekData.granularity_number_value.forEach((goalData) => {
        const goalIndex = audienceName.indexOf(goalData.audience_name);
        if (goalIndex !== -1) {
          seriesData[goalIndex].data[weekIndex] = goalData.audience_name_value[0].budget;
        }
      });
    });
    let option = {
      title: {
        text: t('mediaplan.total_spend_by_granularity', { granularity: granularityValue }),
        textStyle: {
          fontWeight: 500,
          fontSize: '1.17em',
          color: lightGreyColor,
        },
        left: 10,
      },
      tooltip: {
        trigger: 'axis',
        formatter: function (params) {
          let paramNameIndex = params[0].dataIndex;
          let paramData = data[paramNameIndex];
          let startDate = dayjs(paramData?.start_date).format('MMM DD, YYYY');
          let endDate = dayjs(paramData?.end_date).format('MMM DD, YYYY');
          let res = `<strong>${formatString(data[paramNameIndex].granularity)} ${data[paramNameIndex].granularity_number}<br/>${startDate} to ${endDate}<br/></strong>`;
          params.forEach((param) => {
            console.log(data);
            res += `${param.marker} ${param.seriesName}: $${numberWithCommas(param.value)}<br/>`;
          });
          return res;
        },
        axisPointer: {
          type: 'none',
        },
      },
      grid: {
        top: '25%',
      },
      xAxis: {
        type: 'category',
        data: weekNumbers,
        axisLabel: {
          color: lightGreyColor,
        },
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: lightGreyColor,
          formatter: function (value) {
            return `$${numberWithCommas(value)}`;
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#444444',
          },
        },
      },
      toolbox: {
        right: 20,
        feature: {
          saveAsImage: { show: true, iconStyle: { borderColor: lightGreyColor } },
        },
      },
      dataZoom: [
        {
          type: 'slider',
          start: 0,
          end: 100,
          height: 20,
          bottom: 10,
        },
      ],
      series: seriesData,
      colors: colors,
    };

    return option;
  };

  const getSpendByWeek = async () => {
    try {
      const payload = {
        scenario_id: responseId,
        group_by: ['granularity_number', 'audience_name'],
      };
      const res = await mediaPlanService.getMediaPlanOutput(payload);
      const formmatedData = spendByWeekOption(res.data);
      setSpendByWeek(formmatedData);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };

  const getSemKeywordsData = async () => {
    try {
      const res = await mediaPlanService.getSemData(scenarioId);
      setSemData(res.data);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await mediaPlanService.getMediaPlanFlightingResponse(responseId);
      await chanelSpend();
      await getAudienceSpendData();
      await getForecastData();
      await getFunnelSpendData();
      await getSpendByWeek();
      await getSemKeywordsData();
      setGantChartData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        message: msg,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    loading,
    gantChartData,
    chanelDataOptions,
    audienceSpend,
    foreCast,
    funnelStage,
    spendByWeek,
    goBackToListing,
    rawForecastData,
    semData,
  };
}
